import React, { useEffect, useState } from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api'
import { Form, Image } from 'react-bootstrap'
import { Button, ButtonGroup, Box, Avatar, Typography, useTheme, useMediaQuery, Select, MenuItem } from '@mui/material'
import { appColor, screenSize, SEARCH_GOOGLE_IMAGE, USER_LOCAL_DATA } from '../../constants'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import CustomNavbar from '../navbar/Navbar'
import moment from 'moment'
import { CleaningServices, ClearAllOutlined, Delete, DeleteForever, Edit, PublishedWithChanges, SearchOutlined } from "@mui/icons-material";
import LoadingPage from '../../common/loadingPage'
import { ADMIN_GET_ALL_P2P_GQL, ADMIN_REVIEW_ACTION_P2P_GQL } from '../../apollo/crypto'
import { useLazyQuery, useMutation } from '@apollo/client'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { currencyFormat } from '../../utils'
import P2PRequestItem from '../../components/p2p-request/p2p-request-item'
import { Chat, CheckCircle, Close, CloseRounded, Verified, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom'
import NewMCSpringModal from '../../common/newMCPopupModal'
import NewCusSpringModal from '../../common/newCusPopupModal'
import NewAdminReviewSpringModal from '../../common/newAdminReviewPopupModal'
import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/system';

const Input = React.forwardRef(function CustomInput(props, ref) {
    return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
});

export default function AdminP2PReviewPage() {

    const routeNavigate = useNavigate();
    const [myMC, setMyMC] = useState();
    const [userID, setUserID] = useState();
    const { t } = useTranslation();
    const [limit, setLimit] = useState(1000);
    const [skip, setSkip] = useState(0);
    const [totalDocs, setTotalDocs] = useState(0);
    const [allp2pTransaction, setAllp2pTransaction] = useState();
    const [p2pType, setp2pType] = useState("BUY"); // BUY, SELL
    const [transactionId, setTransactionId] = useState(""); //transactionId
    const [transactionIdSearch, setTransactionIdSearch] = useState(""); //transactionId
    const [scrollPosition, setScrollPosition] = useState(0);


    const [mcDetail, setMCDetail] = React.useState();
    const [openMCModal, setOpenMCModal] = React.useState(false);
    const handleOpenMCModal = () => setOpenMCModal(!openMCModal);

    const [cusDetail, setCusDetail] = React.useState();
    const [openCusModal, setOpenCusModal] = React.useState(false);
    const handleOpenCusModal = () => setOpenCusModal(!openCusModal);

    const [p2pTranDetail, setP2PTranDetail] = React.useState();
    const [openAdminReviewModal, setOpenAdminReviewModal] = React.useState(false);
    const handleOpenAdminReviewModal = () => setOpenAdminReviewModal(!openAdminReviewModal);


    // get user
    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (
                        parseData
                    ) {
                        setUserID(parseData?.user?._id);
                    }
                }

            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);

    const handleChangeInputSearch = (e) => {
        setTransactionIdSearch(e.target.value)
    }
    const handleSetSearchValue = () => {
        setTransactionId(transactionIdSearch)
    }
    const handleClearSearchValue = () => {
        setTransactionId("")
        setTransactionIdSearch("")
    }

    const [
        AdminRevewActionP2P
    ] = useMutation(ADMIN_REVIEW_ACTION_P2P_GQL, {
        onCompleted: (data) => {
            handleOpenAdminReviewModal();
        }
    });
    const handleConfirmCancel = async (_id) => {
        // console.log('_id :>> ', _id);
        await AdminRevewActionP2P(
            {
                variables:
                {
                    data: {
                        _id: _id,
                        transectionStatus: "ADMIN_CANCELLED",
                        user: userID
                    }
                }
            })
    }
    const handleConfirmApprove = async (_id) => {
        // console.log('_id :>> ', _id);
        await AdminRevewActionP2P(
            {
                variables:
                {
                    data: {
                        _id: _id,
                        transectionStatus: "ADMIN_APPROVED",
                        user: userID
                    }
                }
            })
    }

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const [reviewOpen, setReviewOpenOpen] = useState(false);
    const handleReviewOpen = () => {
        setReviewOpenOpen(true)
    }
    const handleReviewClose = () => {
        setReviewOpenOpen(false)
    }
    const confirmTypeList = [
        "NONE",
        "PENDING",
        "APPROVED",
        "CANCELLED"
    ]
    const [merchantConfirmType, setMerchantConfirmType] = useState("NONE");
    const handleChangeMCConfirmType = (event) => {
        setMerchantConfirmType(event.target.value);
    }
    const [customerConfirmType, setCustomerConfirmType] = useState("NONE");
    const handleChangeCUSConfirmType = (event) => {
        setMerchantConfirmType(event.target.value);
    }
    const transactionStatusList = [
        "NORMAL",
        "PROBLEM",
        "TIMEOUT",
        "ADMIN_CANCELLED",
        "ADMIN_APPROVED"
    ]
    const [transectionStatus, setTransectionStatus] = useState("PROBLEM");
    const handleChangeTransectionStatus = (event) => {
        setTransectionStatus(event.target.value);
    }


    const [adminGetAllP2PTransaction, { loading }] = useLazyQuery(ADMIN_GET_ALL_P2P_GQL, {
        variables: {
            "query": {
                "skip": 0,
                "limit": 20,
                "tradeType": p2pType,
                "merchantConfirmType": merchantConfirmType,
                "customerConfirmType": customerConfirmType,
                "transectionStatus": transectionStatus,
                "transactionId": transactionId,
            }
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // console.log(`getMyMC data`, data)
            if (data.adminGetAllP2PTransaction.error == true) {
                console.log(`adminGetAllP2PTransaction error`, data.adminGetAllP2PTransaction.error);
            } else {
                setAllp2pTransaction(data?.adminGetAllP2PTransaction?.data)
                setTotalDocs(data?.adminGetAllP2PTransaction?.meta?.totalDocs)
            }
        },
        onError: (error) => {
            console.log(`adminGetAllP2PTransaction error`, error)
        }
    })
    const setP2PTypeHandle = (indexType) => {
        setp2pType(indexType)
    }


    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("lg"));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        adminGetAllP2PTransaction();
        setIsMobile(isMobileSize)
    }, [isMobileSize, p2pType])


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        loading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div
                className="color-body"
                style={{ width: "100%", minHeight: "100vh", color: "white", padding: 10 }}
            >
                {scrollPosition === 0 ? <>
                    <CustomNavbar />
                    <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />

                </> : <></>}

                {/* Add button on the right */}
                <Typography variant="h5">{t("p2p-review")}</Typography>
                <Typography variant="h6">{t("total")}: {totalDocs} </Typography>
                <Box
                    sx={{
                        paddingX: isMobile ? 2 : 10,
                    }}>
                    <Box
                        sx={{
                            backgroundColor: appColor.bgDark,

                        }}>
                        <Box
                            position={'sticky'}
                            display={'flex'}
                            flexDirection={'row'}
                            sx={{
                                display: 'flex',
                                flexDirection: "row",
                                top: 0,
                                zIndex: 2,
                                padding: '10px',
                                boxShadow: 3,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: appColor.bgDark,
                                paddingX: isMobile ? 2 : 4,
                                width: '100%',
                                gap: 0.5,
                            }}
                        >
                            <ButtonGroup
                                size={isMobile ? 'small' : 'large'}
                                variant="outlined"
                                aria-label="Basic button group"
                            >
                                <Button
                                    size={isMobile ? 'small' : 'large'}
                                    sx={{
                                        backgroundColor: p2pType == "BUY" ? appColor.green : 'transparent',
                                        border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                                        color: p2pType == "BUY" ? appColor.white : appColor.textGrey,
                                        ":hover": {
                                            backgroundColor: p2pType == "BUY" ? appColor.greenActive : 'transparent',
                                            border: `0.5px solid ${appColor.green}`,
                                        },
                                    }}
                                    onClick={() => setP2PTypeHandle("BUY")}

                                >{t("BUY")}</Button>
                                <Button
                                    size={isMobile ? 'small' : 'large'}
                                    sx={{
                                        backgroundColor: p2pType == "SELL" ? appColor.red : 'transparent',
                                        border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                                        color: p2pType == "SELL" ? appColor.white : appColor.textGrey,
                                        ":hover": {
                                            backgroundColor: p2pType == "SELL" ? appColor.redActive : 'transparent',
                                            border: `0.5px solid ${appColor.red}`,
                                        }
                                    }}
                                    onClick={() => setP2PTypeHandle("SELL")}
                                >{t("SELL")}</Button>
                                <Box sx={{
                                    px: 0.5
                                }} />
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: 1,
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography>{t("merchant")}</Typography>
                                    <Select
                                        labelId="trade-type-select-standard-label"
                                        id="trade-type-select-standard"
                                        value={merchantConfirmType}
                                        onChange={handleChangeMCConfirmType}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                        {confirmTypeList.map((confirmType, index) => (
                                            <MenuItem key={index} value={confirmType}>{confirmType}</MenuItem>
                                        ))}
                                    </Select>
                                    <Typography>{t("customer")}</Typography>
                                    <Select
                                        labelId="trade-type-select-standard-label"
                                        id="trade-type-select-standard"
                                        value={customerConfirmType}
                                        onChange={handleChangeCUSConfirmType}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                        {confirmTypeList.map((confirmType, index) => (
                                            <MenuItem key={index} value={confirmType}>{confirmType}</MenuItem>
                                        ))}
                                    </Select>
                                    <Typography>{t("status")}</Typography>
                                    <Select
                                        labelId="trade-type-select-standard-label"
                                        id="trade-type-select-standard"
                                        value={transectionStatus}
                                        onChange={handleChangeTransectionStatus}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                        {transactionStatusList.map((confirmType, index) => (
                                            <MenuItem key={index} value={confirmType}>{confirmType}</MenuItem>
                                        ))}
                                    </Select>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            gap: 1
                                        }}
                                    >

                                        <Input
                                            aria-label="Transaction input"
                                            placeholder="Enter transaction id here..."
                                            value={transactionIdSearch}
                                            onChange={handleChangeInputSearch}
                                        />
                                        {transactionId == "" ?
                                            <SearchOutlined
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleSetSearchValue}
                                            />
                                            :
                                            <CleaningServices
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={handleClearSearchValue}
                                            />

                                        }

                                        {/* handleClearSearchValue */}
                                    </Box>
                                    <Close sx={{
                                        width: 24,
                                        height: 24,
                                        cursor: "pointer",
                                        // padding: 2,
                                        // backgroundColor: appColor.red,
                                        color: appColor.textGrey
                                    }}
                                        onClick={() => {
                                            routeNavigate(-1)
                                        }}
                                    />
                                </Box>
                            </ButtonGroup>
                            {scrollPosition === 0 ? <></> : <><Typography variant="h6">{t("total")}: {totalDocs} </Typography></>}

                        </Box>
                        <Box sx={{
                            height: 10,
                        }} />
                        {isMobile ?
                            <>
                                <Box
                                >
                                    {allp2pTransaction && allp2pTransaction?.map((p2p, index) => (
                                        <P2PRequestItem
                                            onClick={() => {
                                                toast("Sorry, Please Manage on Desktop or PC for make sure the action is correctly.")
                                                // handleReviewOpen()
                                                // setP2p(p2p)
                                            }}
                                            key={index} p2pType={p2pType} p2p={p2p} myMC={myMC} setP2PTypeHandle={setP2PTypeHandle} />
                                    ))}
                                </Box>

                            </> :
                            <>
                                <TableContainer
                                    sx={{
                                        backgroundColor: appColor.bgDark,
                                    }}
                                    component={Paper}>
                                    <Table sx={{
                                        minWidth: 650,
                                    }} aria-label="simple table">
                                        <TableHead

                                        >
                                            <TableRow

                                            >
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >#</TableCell>
                                                {/* <TableCell>{t("ID")}</TableCell> */}
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Trade Type")}</TableCell>
                                                {/* <TableCell>{t("Trade Asset")}</TableCell> */}
                                                {/* <TableCell>{t("Fiat Unit Type")}</TableCell> */}
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Price")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Fiat amount")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Crypto amount")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Merchant")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Customer")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("merchantConfirmType")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("customerConfirmType")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("transectionStatus")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("created_at")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Action")}</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allp2pTransaction && allp2pTransaction?.map((p2p, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row">
                                            {p2p._id}
                                        </TableCell> */}
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {p2p.tradeType}
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row">
                                            {p2p.assetType}
                                        </TableCell> */}
                                                    {/* <TableCell component="th" scope="row">
                                            {p2p.fiatUnitType}
                                        </TableCell> */}
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {p2p.fiatUnitType} {currencyFormat(parseInt(p2p.price))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {p2p.fiatUnitType}  {currencyFormat(parseInt(p2p.fiatAmount))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {p2p.assetType}  {currencyFormat(parseInt(p2p.amount))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                gap: 0.5,
                                                                border: `1px solid ${appColor.textGrey}`,
                                                                borderRadius: 5,
                                                                backgroundColor: appColor.bgDark,
                                                                p: 0.5,
                                                            }}
                                                        >
                                                            {p2p.merchant?.image ? <img src={`${S3_BUCKET_END_POINT}/images/${p2p.merchant?.image}`} style={{ width: 22, height: 22, borderRadius: 22, border: "1px solid #fff" }} /> : <Avatar
                                                                sx={{
                                                                    backgroundColor: appColor.textWhite,
                                                                    width: '22px',
                                                                    height: '22px'
                                                                }}
                                                            />}
                                                            <div
                                                                style={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    width: '6rem',
                                                                }}>
                                                                <Typography
                                                                    noWrap
                                                                    sx={{
                                                                        textDecoration: "none",
                                                                    }}>
                                                                    {p2p.merchant.merchantName}
                                                                </Typography>
                                                            </div>
                                                            <Visibility
                                                                sx={{
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => {
                                                                    handleOpenMCModal()
                                                                    setMCDetail(p2p.merchant)
                                                                }}
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                gap: 0.5,
                                                                border: `1px solid ${appColor.textGrey}`,
                                                                borderRadius: 5,
                                                                backgroundColor: appColor.bgDark,
                                                                p: 0.5
                                                            }}
                                                        >
                                                            {p2p.customer?.image ? <img src={p2p.customer?.image && p2p.customer?.image.includes(SEARCH_GOOGLE_IMAGE) ? p2p.customer?.image : `${S3_BUCKET_END_POINT}/images/${p2p.customer?.image}`} style={{ width: 22, height: 22, borderRadius: 22, border: "1px solid #fff" }} /> : <Avatar
                                                                sx={{
                                                                    backgroundColor: appColor.textWhite,
                                                                    width: '22px',
                                                                    height: '22px'
                                                                }}
                                                            />}
                                                            <div
                                                                style={{
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    width: '6rem',
                                                                }}>
                                                                <Typography
                                                                    noWrap
                                                                    sx={{
                                                                        textDecoration: "none",
                                                                    }}>
                                                                    {p2p.customer.fullName}
                                                                </Typography>
                                                            </div>
                                                            <Visibility sx={{
                                                                cursor: "pointer"
                                                            }}
                                                                onClick={() => {
                                                                    handleOpenCusModal()
                                                                    setCusDetail(p2p.customer)
                                                                }}
                                                            />
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        <Typography variant="body2"
                                                            sx={{
                                                                color: appColor.white,
                                                                backgroundColor: p2p.merchantConfirmType == "APPROVED" ? appColor.greenActive : (p2p.merchantConfirmType == "CANCELLED" ? appColor.red : appColor.textGrey),
                                                                px: 1,
                                                                borderRadius: 2,
                                                            }}
                                                        > {p2p.merchantConfirmType}</Typography>

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        <Typography variant="body2"
                                                            sx={{
                                                                color: appColor.white,
                                                                backgroundColor: p2p.customerConfirmType == "APPROVED" ? appColor.greenActive : (p2p.customerConfirmType == "CANCELLED" ? appColor.red : appColor.textGrey),
                                                                px: 1,
                                                                borderRadius: 2,
                                                            }}
                                                        > {p2p.customerConfirmType}</Typography>
                                                    </TableCell>
                                                    {/* 
                                                    NORMAL
                                                    PROBLEM
                                                    TIMEOUT
                                                    ADMIN_CANCELLED
                                                    ADMIN_APPROVED
                                                     */}
                                                    <TableCell component="th" scope="row" align='center'>
                                                        <Typography variant="body2"
                                                            sx={{
                                                                color: appColor.white,
                                                                backgroundColor:
                                                                    p2p.transectionStatus == "NORMAL" ? appColor.greenActive :
                                                                        p2p.transectionStatus == "PROBLEM" ? appColor.yellow :
                                                                            p2p.transectionStatus == "TIMEOUT" ? appColor.orange :
                                                                                p2p.transectionStatus == "ADMIN_CANCELLED" ? appColor.red :
                                                                                    p2p.transectionStatus == "ADMIN_APPROVED" ? appColor.greenActive :
                                                                                        appColor.textGrey,
                                                                px: 1,
                                                                borderRadius: 2,
                                                            }}
                                                        > {p2p.transectionStatus}</Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {moment.unix(p2p.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row">
                                            {adv.isPublish === true ? t("Yes") : t("No")}
                                        </TableCell> */}
                                                    <TableCell component="th" scope="row"
                                                    >
                                                        {/* merchantConfirmType */}
                                                        {/* customerConfirmType */}
                                                        {/* PENDING */}
                                                        {/* APPROVED */}
                                                        {/* CANCELLED */}

                                                        {/* routeNavigate(`/chat/${p2p?._id}`) */}
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <Avatar
                                                                sx={{
                                                                    width: 24,
                                                                    height: 24,
                                                                    cursor: "pointer",
                                                                    padding: 2,
                                                                    backgroundColor: appColor.textGrey,
                                                                    "&:hover": {
                                                                        backgroundColor: appColor.textGrey
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    routeNavigate(`/admin-chat-preview/${p2p?._id}`)
                                                                }}
                                                            >
                                                                {" "}
                                                                <Chat sx={{ width: "14px", color: appColor.white }} />
                                                            </Avatar>
                                                            <div style={{ width: 10 }} />
                                                            <Avatar
                                                                sx={{
                                                                    width: 34,
                                                                    height: 34,
                                                                    cursor: "pointer",
                                                                    backgroundColor: appColor.info,
                                                                    "&:hover": {
                                                                        backgroundColor: appColor.infoActive,
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    handleOpenAdminReviewModal();
                                                                    setP2PTranDetail(p2p);
                                                                    // handleOpen()
                                                                    // handleMCUpdateOpen()
                                                                    // setadvDataUpdate(adv)
                                                                }}
                                                            >
                                                                <PublishedWithChanges fontSize='10px' sx={{
                                                                    color: appColor.white,
                                                                }} />
                                                            </Avatar>
                                                        </div>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                    </Box>
                </Box>
                <NewMCSpringModal isMobile={isMobile} open={openMCModal} handleOpenMCModal={handleOpenMCModal} mcDetail={mcDetail} />
                <NewCusSpringModal isMobile={isMobile} open={openCusModal} handleOpenCusModal={handleOpenCusModal} cusDetail={cusDetail} />
                <NewAdminReviewSpringModal isMobile={isMobile} open={openAdminReviewModal} handleOpenAdminReviewModal={handleOpenAdminReviewModal} p2pTranDetail={p2pTranDetail} handleConfirmCancel={handleConfirmCancel} handleConfirmApprove={handleConfirmApprove} />
            </div >
    )
}


const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const InputElement = styled('input')(
    ({ theme }) => `
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
import React from 'react'
import { appColor } from '../../constants'
import { Typography, Divider, useMediaQuery, Box, Button, ButtonGroup, Avatar } from '@mui/material'
import { Message } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { currencyFormat } from '../../utils'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import P2PRequestItemContentDisplay from '../p2p-request/item-content-display'
const P2PMyAdvsItem = ({ p2pType, advs, onClick }) => {
    const routeNavigate = useNavigate();
    const { t, i18n } = useTranslation();
    return (
        <Box
            onClick={onClick}
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                paddingX: 2,
                paddingY: 1,
                cursor: "pointer",
            }}
        >
            {/* <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <Typography
                        variant='body1'
                        sx={{
                            fontWeight: "bold",
                            color: p2pType == "BUY" ? appColor.textGreenActive : appColor.red,
                        }}>
                        {p2pType}
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            fontWeight: "bold",
                            color: appColor.textGrey,
                        }}>
                        {advs?.assetType}
                    </Typography>
                </Box>


            </Box> */}

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    variant='body2'
                    sx={{
                        color: appColor.textGrey,
                    }}>
                    {t("Trade Type")}
                </Typography>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1
                }}>
                    <Typography
                        variant='body1'
                        sx={{
                            fontWeight: "bold",
                            color: p2pType == "BUY" ? appColor.textGreenActive : appColor.red,
                        }}>
                        {p2pType}
                    </Typography>

                </Box>
            </Box>

            <P2PRequestItemContentDisplay
                title={t("Order")}
                value={advs?._id}
                isCopy={true}
            />
            <P2PRequestItemContentDisplay
                title={t("Trade Asset")}
                value={advs?.tradeType}
            />

            <P2PRequestItemContentDisplay
                title={t("Fiat Unit Type")}
                value={advs?.fiatUnitType}
            />
            <P2PRequestItemContentDisplay
                title={t("Tradable Quantity")}
                value={`${advs?.assetType} ${currencyFormat(parseInt(advs?.tradableQuantity))}`}
            />

            <P2PRequestItemContentDisplay
                title={t("Price")}
                value={`${advs?.fiatUnitType} ${currencyFormat(parseFloat(advs?.price))}`}
            />
            <P2PRequestItemContentDisplay
                title={t("Min Single Trans Quantity")}
                value={`${advs?.fiatUnitType} ${currencyFormat(parseFloat(advs?.minSingleTransQuantity))}`}
            />
            <P2PRequestItemContentDisplay
                title={t("Max Single Trans Quantity")}
                value={`${advs?.fiatUnitType} ${currencyFormat(parseFloat(advs?.maxSingleTransQuantity))}`}
            />
            <P2PRequestItemContentDisplay
                title={t("created_at")}
                value={moment.unix(advs.createdAt).format("DD-MM-YYYY HH:mm:ss")}
            />


            <Divider
                sx={{
                    width: "100%",
                }}
                color={appColor.textGrey}
            />
        </Box>
    )
}

export default P2PMyAdvsItem
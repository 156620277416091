import React, { useEffect, useState } from "react";
import CustomNavbar from "../navbar/Navbar";
import { END_POINT_SEVER } from "../../api";
import axios from "axios";
import { Table } from "react-bootstrap";
import moment from "moment";
import { MODAL_STYLE, currencyFormat } from "../../utils";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { USER_LOCAL_DATA, appC, appColor, appColorolor } from "../../constants";
import { toast } from "react-toastify";
import { Delete, DeleteForever, Edit } from "@mui/icons-material";
import { useTranslation } from 'react-i18next'
import LoadingPage from "../../common/loadingPage";

const CostPage = () => {
  const { t } = useTranslation();
  const [costs, setCosts] = useState([]);
  const [moneyID, setMoneyID] = useState("");
  const [money, setMoney] = useState(0);
  const [userID, setUserID] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [showAddFundModal, setShowAddFundModal] = useState(false);
  const handleCloseFundModal = () => {
    setShowAddFundModal(false);
    setIsUpdate(false);
  };
  const [showModelDeleteConfirm, setshowModelDeleteConfirm] = useState(false);
  const handleCloseModelDeleteConfirm = () => {
    setshowModelDeleteConfirm(false);
  };
  const handleDeleteConfirm = async () => {
    try {
      var config = {
        method: "delete",
        url: `${END_POINT_SEVER}/api/cost/${moneyID}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const tradeHistoryHandle = await axios(config);
      //   console.log("tradeHistoryHandle ", tradeHistoryHandle.status);
      if (tradeHistoryHandle?.data) {
        // remove data from costs
        const _costs = costs.filter((value) => value?._id !== moneyID);
        setCosts(_costs);
        handleCloseModelDeleteConfirm();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCostHistory = async () => {
    try {
      setIsLoading(true)
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/cost`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const tradeHistoryHandle = await axios(config);
      if (tradeHistoryHandle?.data) {
        setCosts(tradeHistoryHandle.data?.costHistory);
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  useEffect(() => {
    const getLoalData = async () => {
      try {
        const _localData = await localStorage.getItem(USER_LOCAL_DATA);
        if (_localData) {
          let parseData = JSON.parse(_localData);
          if (parseData["user"] && parseData["user"]["_id"]) {
            setUserID(parseData["user"]["_id"]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLoalData();
  }, []);

  useEffect(() => {
    fetchCostHistory();
  }, []);

  const handleAddORUpdateFund = async () => {
    var fundAddData = JSON.stringify({
      money: money,
      createdBy: userID,
    });
    var fundUpdateData = JSON.stringify({
      money: money,
    });
    var urlPath = `${END_POINT_SEVER}/api/cost${isUpdate ? `/${moneyID}` : ""}`;
    //   convert urlPath to url
    var url = new URL(urlPath);

    var config = {
      method: isUpdate ? "put" : "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: isUpdate ? fundUpdateData : fundAddData,
    };
    const resp = await axios(config);
    if (resp.status === 200) {
      setCosts((prev) => [resp.data?.costHistory, ...prev]);
      // update cost to latest data

      handleCloseFundModal();
      toast(t("success"), { type: "success" });
    }
  };
  return (
    isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
      <LoadingPage />
    </div>
      :
    <div
      className="color-body"
      style={{ width: "100%", minHeight: "100vh", color: "white", padding: 10 }}
    >
      <CustomNavbar />
      <div style={{ height: 100 }} />

      {/* Add button on the right */}
      <Typography variant="h4">
        {t("capital-cost")} ${costs?.length > 0 ? currencyFormat(costs[0]["money"]) : 0}
      </Typography>

      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{
          width: "100%",
          justifyContent: "end",
          justifyItems: "end",
          px: 4,
        }}
      >
        <Button
          onClick={() => setShowAddFundModal(true)}
          style={{
            borderRadius: 28,
            color: "white",
            minWidth: "170px",
            height: 45,
            borderColor: "#fff",
            background: appColor.green,
            width: "50px",
          }}
        >
          {t("increase-the-cost")}
        </Button>
        <Box />
      </Box>

      <div style={{ padding: 20 }}>
        <div>{t("history-of-cost-changes")}</div>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>{t("amount-of-money")}</th>
              <th>{t("time-to-create")}</th>
              <th>{t("time-to-update")}</th>
            </tr>
          </thead>
          <tbody>
            {costs &&
              costs.map((value, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>${value?.money ? currencyFormat(value?.money) : 0}</td>
                  <td>{moment(value?.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                  <td>{moment(value?.updatedAt).format("yyyy/MM/DD HH:mm")}</td>
                </tr>
              ))}
          </tbody>
        </Table>
        {/* START EDIT */}
        <Modal
          open={showAddFundModal}
          onClose={handleCloseFundModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...MODAL_STYLE, width: 400, backgroundColor: appColor.darkColor, color: "#fff" }}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                }}
              >
                {isUpdate ? t("update-the-cost") : t("increase-the-cost")}
              </Typography>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: 2,
              }}
            >
              <Grid>
                <p>{t("amount-of-money")}</p>
                <input
                  type="text"
                  value={money}
                  onChange={(event) => setMoney(event?.target?.value)}
                  style={{ width: "100%", height: 40 }}
                />
              </Grid>
              <br />
              <Button
                onClick={handleAddORUpdateFund}
                style={{
                  borderRadius: 28,
                  color: "white",
                  minWidth: "170px",
                  height: 45,
                  borderColor: "#fff",
                  background: appColor.green,
                  width: "100%",
                }}
              >
                {isUpdate ? t("edit") : t("save")}
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* END EDIT */}
        {/* START DELETE CONFIRM */}
        <Modal
          open={showModelDeleteConfirm}
          onClose={handleCloseModelDeleteConfirm}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...MODAL_STYLE, width: 400, backgroundColor: appColor.darkColor, color: "#fff" }}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                }}
              >
                {t("do-you-really-want-to-delete")}
              </Typography>
              <DeleteForever
                color="error"
                sx={{
                  width: 100,
                  height: 100,
                }}
              />
            </Box>

            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                gap: 2,
              }}
            >
              <br />
              <Button
                onClick={handleDeleteConfirm}
                style={{
                  borderRadius: 28,
                  color: "white",
                  minWidth: "170px",
                  height: 45,
                  borderColor: "#fff",
                  background: appColor.red,
                  width: "100%",
                }}
              >
                {t("confirm-delete")}
              </Button>
            </Box>
          </Box>
        </Modal>
        {/* END DELETE CONFIRM */}
      </div>
    </div>
  );
};

export default CostPage;

import React, { useState, useEffect } from "react";
import {
  faArrowTrendDown,
  faArrowTrendUp,
  faCircleCheck,
  faCircleXmark,
  faCoins,
  faMoneyBillTransfer,
  faStar,
  faStarHalfStroke,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomNavbar from "../navbar/Navbar";
import { Table } from "react-bootstrap";
import { END_POINT_SEVER } from "../../api";
import axios from "axios";
import { USER_LOCAL_DATA, appColor, screenSize } from "../../constants";
import moment from "moment";
import { useTranslation } from 'react-i18next'
import { appProfitCalculator } from "../../utils";
import {
  Avatar,
  MenuItem,
  Select,
  useMediaQuery
} from "@mui/material";
import { FaBtc, FaEthereum, FaEuroSign } from "react-icons/fa";
import { SiBinance } from "react-icons/si";
import { AiOutlineGold } from "react-icons/ai";
import { useTheme } from "@emotion/react";
import LoadingPage from "../../common/loadingPage";
export default function TradeHistories() {
  const { t } = useTranslation();
  const [userData, setUserData] = useState();
  const [realCreditCardData, setRealCreditCardData] = useState();
  const [demoCreditCardData, setDemoCreditCardData] = useState();
  const [bonusCreditCardData, setBonusCreditCardData] = useState();
  const [activeCard, setActiveCard] = useState();
  const [creditHistoryData, setTradeHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const getLoalData = async () => {
      try {
        const _localData = await localStorage.getItem(USER_LOCAL_DATA);
        if (_localData) {
          let parseData = JSON.parse(_localData);
          if (parseData["user"] && parseData["user"]["_id"]) {
            getUserData(parseData["user"]["_id"]);
            getCreditData(parseData["user"]["_id"]);
            getTradeHistoryData(parseData["user"]["_id"]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLoalData();
  }, []);

  useEffect(() => {
    if (userData) {
      getTradeHistoryData(userData["_id"]);
    }
  }, [activeCard, userData]);

  const getUserData = async (userId) => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/users/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const userHandle = await axios(config);
      if (userHandle?.data) {
        setUserData(userHandle.data?.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCreditData = async (userId) => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const creditHandle = await axios(config);
      if (creditHandle?.data) {
        for (var i = 0; i < creditHandle.data?.credit.length; i++) {
          if (creditHandle.data?.credit[i].type == "REAL")
            setRealCreditCardData(creditHandle.data?.credit[i]);
          if (creditHandle.data?.credit[i].type == "DEMO")
            setDemoCreditCardData(creditHandle.data?.credit[i]);
          if (creditHandle.data?.credit[i].type == "BONUS")
            setBonusCreditCardData(creditHandle.data?.credit[i]);
          if (creditHandle.data?.credit[i].isUse == true)
            setActiveCard(creditHandle.data?.credit[i].type);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTradeHistoryData = async (userId) => {
    try {
      setIsLoading(true)
      console.log("activeCard: ", activeCard)
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/trade/?user=${userId}&&card=${activeCard == "DEMO" ?
          demoCreditCardData["_id"]
          : activeCard == "BONUS" ?
            bonusCreditCardData["_id"]
            : realCreditCardData["_id"]
          }`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const tradeHistoryHandle = await axios(config);
      if (tradeHistoryHandle?.data) {
        // console.log('tradeHistoryHandle?.data :>> ', tradeHistoryHandle?.data);
        setTradeHistoryData(tradeHistoryHandle.data?.trade);
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }


  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(isMobileSize)
  }, [isMobileSize])

  const isIPadSize = useMediaQuery(theme.breakpoints.down('md'));
  const [isIPad, setIsIPad] = useState(false);
  useEffect(() => {
    setIsIPad(isIPadSize)
  }, [isIPadSize])
  return (
    isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
      <LoadingPage />
    </div>
      :
      <div
        className="color-body"
        style={{
          width: "100%",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomNavbar />
        <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />
        {screenSize().width > 700 ? <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", paddingLeft: 30 }}>
            <img src={"/tether-usdt-logo.png"} style={{ height: 80, width: 80 }} />
            <div style={{ width: 20 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <h1 style={{ color: activeCard == "DEMO" ? appColor.yellow : appColor.greenActive }}>
                {activeCard == "DEMO" ? (demoCreditCardData?.credit ? currencyFormat(demoCreditCardData?.credit) : 0)
                  : activeCard == "REAL" ? (realCreditCardData?.credit ? currencyFormat(realCreditCardData?.credit) : 0)
                    : (bonusCreditCardData?.credit ? currencyFormat(bonusCreditCardData?.credit) : 0)
                }{" "}
                USDT
                {/* <span style={{ color: "#fff", fontSize: 20, paddingLeft: 8, marginLeft: 20 }}>
                  ({activeCard == "DEMO" ? t("demo-credit") : activeCard == "REAL" ? t("real-credit") : "Bonus"})
                  {" "}
                  <FontAwesomeIcon
                    onClick={() =>
                      setActiveCard(activeCard == "DEMO" ? "REAL" : "DEMO")
                    }
                    icon={faMoneyBillTransfer}
                    style={{ color: "white", cursor: "pointer", paddingLeft: 8 }}
                  />
                </span> */}
                {" "}
                <Select
                  sx={{
                    height: isMobile ? 30 : 40,
                    border: '1px solid ' + appColor.yellowActive,
                    marginLeft: 20,
                    height: 32
                  }}
                  value={activeCard}
                  onChange={(event) => setActiveCard(event.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={"DEMO"}>Demo</MenuItem>
                  <MenuItem value={"REAL"}>Real</MenuItem>
                  <MenuItem value={"BONUS"}>Bonus</MenuItem>
                </Select>
              </h1>
              <h5 style={{ color: "#b6b6b6" }}>{t("list-of-trade-history")}</h5>
            </div>
          </div>
        </div> : <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", paddingLeft: 20 }}>
            <img src={"/tether-usdt-logo.png"} style={{ height: 40, width: 40 }} />
            <div style={{ width: 10 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <h5 style={{ color: activeCard == "DEMO" ? appColor.yellow : appColor.greenActive }}>
                {activeCard == "DEMO" ? (demoCreditCardData?.credit ? currencyFormat(demoCreditCardData?.credit) : 0)
                  : activeCard == "REAL" ? (realCreditCardData?.credit ? currencyFormat(realCreditCardData?.credit) : 0)
                    : (bonusCreditCardData?.credit ? currencyFormat(bonusCreditCardData?.credit) : 0)
                }{" "}
                USDT
                {/* <span style={{ color: "#fff", fontSize: 14, paddingLeft: 8 }}>
                  ({activeCard == "DEMO" ? t("demo-credit") : t("real-credit")})
                  {" "}
                  <FontAwesomeIcon
                    onClick={() =>
                      setActiveCard(activeCard == "DEMO" ? "REAL" : "DEMO")
                    }
                    icon={faMoneyBillTransfer}
                    style={{ color: "white", fontSize: 18, cursor: "pointer", paddingLeft: 8 }}
                  />
                </span> */}
                {" "}
                <Select
                  sx={{
                    height: isMobile ? 30 : 40,
                    border: '1px solid ' + appColor.yellowActive,
                    marginLeft: 20,
                    height: 24
                  }}
                  value={activeCard}
                  onChange={(event) => setActiveCard(event.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={"DEMO"}>Demo</MenuItem>
                  <MenuItem value={"REAL"}>Real</MenuItem>
                  <MenuItem value={"BONUS"}>Bonus</MenuItem>
                </Select>
              </h5>
              <div style={{ color: "#b6b6b6", fontSize: 14 }}>{t("list-of-trade-history")}</div>
            </div>
          </div>
        </div>}
        <div
          style={{
            paddingLeft: isMobile ? 10 : isIPadSize ? 50 : 130,
            paddingRight: isMobile ? 10 : isIPadSize ? 50 : 130,
            paddingBottom: 10
          }}
        >
          {creditHistoryData &&
            creditHistoryData.map((value, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  paddingTop: 4,
                  paddingLeft: isMobile ? 2 : 10,
                  paddingRight: isMobile ? 2 : 10,
                  backgroundColor: index % 2 ? appColor.background : appColor.bgDark,
                  color: appColor.textWhite,
                  fontSize: 12,
                }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      alignItems: 'center',
                      width: isMobile ? "30vw" : "30vw",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 16,
                        height: 16,
                        backgroundColor: "#FFF",
                        p: 0.5,
                      }}
                    >
                      <img src={value?.binaryLogo} style={{ width: 16, height: 16, borderRadius: 16 }} />
                    </Avatar>
                    <div>{value?.binaryName}</div>
                    {/* <div>+80%</div> */}
                  </div>
                  {value?.openPrice && (
                    <div style={{ color: "#fff", width: isMobile ? "30vw" : "20vw", textAlign: "left" }}><b style={{
                      color: value?.openPrice > value?.closePrice
                        ? appColor.greenActive
                        : value?.openPrice < value?.closePrice
                          ? appColor.redActive
                          : "gray"
                    }}>{t("open")}</b>:&nbsp;&nbsp; ${value?.openPrice ? currencyFormat(value?.openPrice) : ""}</div>
                  )}

                  {/* yyyy/MM/DD  */}
                  <div style={{ width: isMobile ? "30vw" : "30vw", textAlign: "right" }}>{moment(value?.createdAt).format("HH:mm")}</div>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: "flex-start"
                }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      alignItems: "flex-start",
                      width: isMobile ? "30vw" : "30vw",
                    }}
                  >
                    {value?.type == "BUY" ? (
                      <span>
                        <FontAwesomeIcon
                          icon={faArrowTrendUp}
                          style={{ color: appColor.greenActive }}
                        />{" "}
                        {t("buy")}
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faArrowTrendDown}
                          style={{ color: appColor.redActive }}
                        />{" "}
                        {t("sell")}
                      </span>
                    )}
                    <div
                      style={{
                        fontWeight: 'bold',
                      }}
                    >${value?.money ? currencyFormat(value?.money) : 0}</div>
                  </div>
                  {value?.closePrice && (
                    <div style={{ color: "#fff", width: isMobile ? "30vw" : "20vw", textAlign: "left" }}><b style={{
                      color: value?.openPrice < value?.closePrice
                        ? appColor.greenActive
                        : value?.openPrice > value?.closePrice
                          ? appColor.redActive
                          : "gray"
                    }}>{t("close")}</b>:&nbsp;&nbsp; ${value?.closePrice ? currencyFormat(value?.closePrice) : ""}</div>
                  )}

                  <div style={{ width: isMobile ? "30vw" : "30vw", textAlign: "right" }}>
                    {value?.isProfit ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: "row",
                          fontWeight: 'bold',
                          fontSize: 16,
                          color: appColor.greenActive,
                          textAlign: "right",
                          justifyContent: "flex-end"
                        }}
                      >
                        <span>
                          +
                        </span>
                        {" "}
                        <p>${parseFloat(value?.closePrice) == parseFloat(value?.openPrice) ? 0 : currencyFormat(appProfitCalculator(value.money, value.profit))}</p>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: "row",
                          color: appColor.redActive,
                          fontWeight: 'bold',
                          fontSize: 16,
                          textAlign: "right",
                          justifyContent: "flex-end"
                        }}
                      >
                        <span>
                          -
                        </span>
                        {" "}
                        <p>${currencyFormat(value?.money)}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>


        {/* <div style={{ padding: screenSize().width > 700 ? 20 : 0 }}>
        {screenSize().width > 700 ? <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>#</th>
              <th>{t("amount-of-money")}</th>
              <th>{t("binary-trade")}</th>
              <th>{t("type")}</th>
              <th>{t("status")}</th>
              <th>{t("open-price")}</th>
              <th>{t("close-price")}</th>
              <th>{t("time")}</th>
            </tr>
          </thead>
          <tbody>
            {creditHistoryData &&
              creditHistoryData.map((value, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>${value?.money ? currencyFormat(value?.money) : 0}</td>
                  <td>{value?.binary ?? "-"}</td>
                  <td>
                    {value?.type == "BUY" ? (
                      <span>
                        <FontAwesomeIcon
                          icon={faArrowTrendUp}
                          style={{ color: appColor.greenActive }}
                        />{" "}
                        {t("buy")}
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faArrowTrendDown}
                          style={{ color: appColor.redActive }}
                        />{" "}
                        {t("sell")}
                      </span>
                    )}
                  </td>
                  <td>
                    {value?.isProfit ? (
                      <span>
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          style={{ color: appColor.greenActive }}
                        />{" "}
                        {t("profit")}
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          style={{ color: appColor.redActive }}
                        />{" "}
                        {t("lose")}
                      </span>
                    )}
                  </td>
                  <td
                    style={{
                      color:
                        value?.openPrice > value?.closePrice
                          ? appColor.greenActive
                          : value?.openPrice < value?.closePrice
                            ? appColor.redActive
                            : "#fff",
                    }}
                  >
                    ${value?.openPrice ? currencyFormat(value?.openPrice) : "-"}
                  </td>
                  <td
                    style={{
                      color:
                        value?.openPrice < value?.closePrice
                          ? appColor.greenActive
                          : value?.openPrice > value?.closePrice
                            ? appColor.redActive
                            : "#fff",
                    }}
                  >
                    ${value?.closePrice ? currencyFormat(value?.closePrice) : "-"}
                  </td>
                  <td>{moment(value?.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                </tr>
              ))}
          </tbody>
        </Table> : <div style={{ padding: 10, paddingTop: 0 }}>
          {creditHistoryData && creditHistoryData.map((value, index) => <div key={index} style={{ paddingTop: 10 }}>
            <div key={index} style={{ border: "1px solid gray", borderRadius: 5, display: "flex", flexDirection: "row", width: "100%", height: 160, padding: 8, alignItems: "center", justifyContent: "start" }}>
              <div style={{ width: 10 }} />
              <div style={{ color: "#fff" }}>{index + 1}</div>
              <div style={{ width: 10 }} />
              <div style={{ height: 120, borderRightWidth: 0.5, borderRightColor: "#b6b6b6", borderRightStyle: "solid" }} />
              <div style={{ width: 20 }} />
              <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                <div style={{ color: "#fff" }}><b style={{ color: "gray" }}>{t("amount-of-money")}</b>:&nbsp;&nbsp; ${value?.money ? currencyFormat(value?.money) : 0}</div>
                <div style={{ color: "#fff" }}><b style={{ color: "gray" }}>{t("type")}</b>:&nbsp;&nbsp; {value?.type == "BUY" ?
                  <span><FontAwesomeIcon icon={faArrowTrendUp} style={{ color: appColor.greenActive }} /></span> :
                  <span><FontAwesomeIcon icon={faArrowTrendDown} style={{ color: appColor.redActive }} /></span>
                } {value?.type == "BUY" ? t("buy") : t("sell")}</div>
                <div style={{ color: "#fff" }}><b style={{ color: "gray" }}>{t("status")}</b>:&nbsp;&nbsp; {value?.isProfit ?
                  <span><FontAwesomeIcon icon={faCircleCheck} style={{ color: appColor.greenActive }} /></span> :
                  <span><FontAwesomeIcon icon={faCircleXmark} style={{ color: appColor.redActive }} /></span>
                } {value?.isProfit ? t("profit") : t("lose")}</div>
                <div style={{ color: "#fff" }}><b style={{
                  color: value?.openPrice > value?.closePrice
                    ? appColor.greenActive
                    : value?.openPrice < value?.closePrice
                      ? appColor.redActive
                      : "gray"
                }}>{t("open-price")}</b>:&nbsp;&nbsp; ${value?.openPrice ? currencyFormat(value?.openPrice) : "-"}</div>
                <div style={{ color: "#fff" }}><b style={{
                  color: value?.openPrice < value?.closePrice
                    ? appColor.greenActive
                    : value?.openPrice > value?.closePrice
                      ? appColor.redActive
                      : "gray"
                }}>{t("close-price")}</b>:&nbsp;&nbsp; ${value?.closePrice ? currencyFormat(value?.closePrice) : "-"}</div>
                <div style={{ color: "#fff" }}><b style={{ color: "gray" }}>{t("time")}</b>:&nbsp;&nbsp; {moment(value?.createdAt).format("yyyy/MM/DD HH:mm")}</div>
              </div>
            </div>
          </div>)}
        </div>}
      </div> */}
      </div>
  );
}

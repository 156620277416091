import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { USER_LOCAL_DATA, appColor } from "../../constants";
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CREATE_MC_GQL, GET_MC_SETTING_GQL, MC_SETTING_GQL } from "../../apollo/crypto";

export default function P2PSetup({ closeShowP2PPopup, isShowPopup }) {
  const { t } = useTranslation();
  const [minimumBalance, setMinimumBalance] = useState(1);
  const [userID, setUserID] = useState();

  useEffect(() => {
    const getLoalData = async () => {
      try {
        const _localData = await localStorage.getItem(USER_LOCAL_DATA);
        if (_localData) {
          let parseData = JSON.parse(_localData);
          if (
            parseData
          ) {
            setUserID(parseData?.user?._id);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLoalData();
  }, []);

  const onSave = async () => {
    try {
      let data = {
        // conver minimumBalance t into integer
        minimumBalance: parseInt(minimumBalance),
        user: userID
      }
      await mutationCreateMCSetting(
        { variables: { data: data } }
      ).then((res) => {
        closeShowP2PPopup(false)
        // console.log('res1 ', res)
        getCurrentMCSetting().then((res) => {
          // console.log('res2 ', res)
          setMinimumBalance(res)
        }).catch((err) => {
          console.log('err', err);
        })
      }).catch((err) => {
        console.log('err', err)
      })


    } catch (error) {
      console.log(error);
    }
  };

  const [mutationCreateMCSetting] = useMutation(MC_SETTING_GQL);


  const [getMygetMCSetting] = useLazyQuery(GET_MC_SETTING_GQL, {
    fetchPolicy: 'network-only',
  })
  const getCurrentMCSetting = async () => {
    const { data } = await getMygetMCSetting();
    // console.log('getMCSetting', data.getMCSetting.data.minimumBalance)
    // setMCSetting()
    return data?.getMCSetting?.data?.minimumBalance

  }
  useEffect(() => {
    getCurrentMCSetting().then((res) => {
      setMinimumBalance(res)
    }).catch((err) => {
      console.log('err', err);
    })
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.7)",
        display: isShowPopup ? "flex" : "none",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        top: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 600,
          backgroundColor: appColor.darkColor,
          borderRadius: 8,
          padding: 20,
          color: "#fff"
        }}
      >
        <h4>  {t("set-up-p2p")}</h4>
        <div style={{ height: 20 }} />
        <p>CURRENT MINIMUM MC: $ {minimumBalance}</p>

        <div style={{ height: 20 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ width: 150 }}>{t("minimumBalance")}:</div>
            <input
              type="number"
              style={{ width: 400, textAlign: "center" }}
              value={minimumBalance}
              min={0}
              onChange={(e) => {
                let rateNumber = parseInt(e.target.value);
                if (rateNumber < 0) {
                  rateNumber = 0;
                } else {
                  setMinimumBalance(e.target.value);
                }
              }}
            />
          </div>
          <br />
        </div>
        <div style={{ height: 30 }} />
        <Button
          onClick={() => onSave()}
          style={{
            borderRadius: 28,
            color: "white",
            height: 45,
            borderWidth: 0,
            backgroundColor: appColor.green,
            width: "100%",
          }}
        >
          <div>{t("save")}</div>
        </Button>
      </div>
      <div style={{ height: 10 }} />
      <div
        onClick={() => closeShowP2PPopup(false)}
        style={{
          width: 50,
          height: 50,
          borderRadius: 50,
          backgroundColor: "rgba(255,255,255,0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon
          icon={faClose}
          style={{ color: "#fff", fontSize: 22 }}
        />
      </div>
    </div>
  );
}

import { faClose, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import {
  FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID,
  USER_LOCAL_DATA,
} from "../../constants";
import { END_POINT_SEVER } from "../../api";
import axios from "axios";
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
// import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { toast } from 'react-toastify';
import { Dropdown, Spinner } from "react-bootstrap";
import AppContext from '../../appProvider/context';

export default function NewHomeOverview() {
  const sliding = [
    {
      id: 1,
      img: "btc.svg",
      coin: "BTC/USDT",
      price: "57,693.00",
      type: "plus",
      percent: "1.87",
    },
    {
      id: 2,
      img: "eth.svg",
      coin: "ETH/USDT",
      price: "2,445.95",
      type: "plus",
      percent: "3.44",
    },
    {
      id: 3,
      img: "bnb.png",
      coin: "BNB/USDT",
      price: "509.1",
      type: "minus",
      percent: "0.37",
    },
    {
      id: 4,
      img: "bch.png",
      coin: "BCH/USDT",
      price: "312.9",
      type: "plus",
      percent: "1.62",
    },
    {
      id: 5,
      img: "sol.png",
      coin: "SOL/USDT",
      price: "133.03",
      type: "plus",
      percent: "4.67",
    },
    {
      id: 6,
      img: "ltc.png",
      coin: "LTC/USDT",
      price: "65.95",
      type: "plus",
      percent: "2.2",
    },
    {
      id: 7,
      img: "ordi.png",
      coin: "ORDI/USDT",
      price: "30.42",
      type: "minus",
      percent: "0.26",
    },
    {
      id: 8,
      img: "avax.png",
      coin: "AVAX/USDT",
      price: "21.92",
      type: "plus",
      percent: "3.2",
    },
    {
      id: 9,
      img: "uni.png",
      coin: "UNI/USDT",
      price: "6.39",
      type: "plus",
      percent: "6.51",
    },
    {
      id: 10,
      img: "apt.png",
      coin: "APT/USDT",
      price: "6.00",
      type: "minus",
      percent: "0.66",
    },
    {
      id: 11,
      img: "near.png",
      coin: "NEAR/USDT",
      price: "3.856",
      type: "plus",
      percent: "4.61",
    },
    {
      id: 12,
      img: "pendle.png",
      coin: "PENDLE/USDT",
      price: "2.85",
      type: "plus",
      percent: "2.51",
    },
    {
      id: 13,
      img: "stx.png",
      coin: "STX/USDT",
      price: "1.448",
      type: "plus",
      percent: "2.55",
    },
    {
      id: 14,
      img: "wld.png",
      coin: "WLD/USDT",
      price: "1.413",
      type: "plus",
      percent: "3.28",
    },
    {
      id: 15,
      img: "op.png",
      coin: "OP/USDT",
      price: "1.355",
      type: "plus",
      percent: "3.12",
    },
    {
      id: 16,
      img: "snx.png",
      coin: "SNX/USDT",
      price: "1.35",
      type: "plus",
      percent: "6.97",
    },
    {
      id: 17,
      img: "sui.png",
      coin: "SUI/USDT",
      price: "0.8042",
      type: "plus",
      percent: "1.57",
    },
    {
      id: 18,
      img: "xrp.png",
      coin: "XRP/USDT",
      price: "0.5569",
      type: "plus",
      percent: "1.31",
    },
    {
      id: 19,
      img: "arb.png",
      coin: "ARB/USDT",
      price: "0.4964",
      type: "plus",
      percent: "2.62",
    },
    {
      id: 20,
      img: "strk.png",
      coin: "STRK/USDT",
      price: "0.398",
      type: "plus",
      percent: "11.48",
    },
    {
      id: 21,
      img: "ftm.png",
      coin: "FTM/USDT",
      price: "0.3935",
      type: "plus",
      percent: "3.01",
    },
    {
      id: 22,
      img: "matic.png",
      coin: "MATIC/USDT",
      price: "0.3822",
      type: "minus",
      percent: "2.12",
    },
    {
      id: 23,
      img: "ada.png",
      coin: "ADA/USDT",
      price: "0.323",
      type: "plus",
      percent: "2.73",
    },
    {
      id: 24,
      img: "aevo.png",
      coin: "AEVO/USDT",
      price: "0.319",
      type: "plus",
      percent: "4.59",
    },
    {
      id: 25,
      img: "pyth.png",
      coin: "PYTH/USDT",
      price: "0.268",
      type: "plus",
      percent: "4.4",
    },
    {
      id: 26,
      img: "ena.png",
      coin: "ENA/USDT",
      price: "0.218",
      type: "plus",
      percent: "0.92",
    },
    {
      id: 27,
      img: "w.png",
      coin: "W/USDT",
      price: "0.200",
      type: "plus",
      percent: "3.56",
    },
    {
      id: 28,
      img: "trx.png",
      coin: "TRX/USDT",
      price: "0.1495",
      type: "minus",
      percent: "0.73",
    },
    {
      id: 29,
      img: "doge.png",
      coin: "DOGE/USDT",
      price: "0.09711",
      type: "plus",
      percent: "2.23",
    },
    {
      id: 30,
      img: "shib.png",
      coin: "SHIB/USDT",
      price: "0.00001343",
      type: "plus",
      percent: "3.78",
    },
  ];

  const { setStateLanguage } = useContext(AppContext);
  const { t, i18n } = useTranslation();
  const routeNavigate = useNavigate();

  const [isScrollDown, setIsScrollDown] = useState(false)
  const [loginUser, setLoginUser] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isFacebookLoading, setIsFacebookLoading] = useState(false);
  const [vipUserData, setVipUserData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [loginFail, setLoginFail] = useState("");
  const [registerType, setRegisterType] = useState("REGISTER");
  const [dataRegister, setDataRegister] = useState({
    fullname: "",
    phone: "",
    username: "",
    password: "",
    confirmPassword: "",
    vipCode: "",
  })
  const [otpCode, setOtpCode] = useState("");
  const [alreadyHaveEmail, setAlreadyHaveEmail] = useState(false);
  const [alreadyHaveEmailText, setAlreadyHaveEmailText] = useState("");

  const [isShowRegister, setIsShowRegister] = useState(false);
  const [isShowLogin, setIsShowLogin] = useState(false);

  const handleShowRegister = () => setIsShowRegister(true);
  const handleShowRegisterLogin = () => {
    setIsShowLogin(false);
    setIsShowRegister(true);
  }
  const handleShowLoginRegister = () => {
    setIsShowRegister(false);
    setIsShowLogin(true);
  }
  const handleCloseRegister = () => {
    setIsShowRegister(false);
    setRegisterType("REGISTER")
    setOtpCode("")
    setDataRegister({
      fullname: "",
      phone: "",
      username: "",
      password: "",
      confirmPassword: "",
      vipCode: ""
    })

  }

  const handleShowLogin = () => setIsShowLogin(true);
  const handleCloseLogin = () => setIsShowLogin(false);

  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
    setStateLanguage(value)
    localStorage.setItem('appLanguage', value)
  };

  useEffect(() => {
    const fetchCryptoListings = () => {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://sandbox-api.coinmarketcap.com/v1/cryptocurrency/listings/latest',
        headers: {
          'X-CMC_PRO_API_KEY': 'b54bcf4d-1bca-4e8e-9a24-22ff2c3d462c',
        },
      };

      axios.request(config)
        .then((response) => {
          console.log("response.data", response.data)
          // setData(response.data);
          // setLoading(false); // Set loading to false after data is fetched
        })
        .catch((error) => {
          // setError(error);
          // setLoading(false); // Set loading to false even if there's an error
        });
    };

    fetchCryptoListings();
    // Function to handle scroll event
    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Get vertical scroll position

      if (scrollPosition > 100) {
        setIsScrollDown(true);
      } else {
        setIsScrollDown(false);
      }
    };

    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
      const getAppLanguage = async () => {
          const appLanguage = localStorage.getItem('appLanguage')
          if (appLanguage) {
              setStateLanguage(appLanguage)
          } else {
              setStateLanguage("thailand")
          }
      }
      getAppLanguage()

    }, [])
    
  useEffect(() => {
    if (dataRegister?.vipCode && vipUserData == null) getVipMember(dataRegister?.vipCode);
  }, [dataRegister?.vipCode]);

  const getVipMember = async (code) => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/users/?vipCode=${code}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const userHandle = await axios(config);
      if (userHandle?.data && userHandle?.data?.users?.length > 0) {
        console.log("userHandle?.data: ", userHandle?.data?.users[0])
        setVipUserData(userHandle?.data?.users[0])
      }
    } catch (error) {
      console.log(error)
    }
  }


  const onError = () => {
    console.log("Login Failed");
  };

  const onFacebookLogin = async (response) => {
    try {
      setIsFacebookLoading(true);
      const loginData = JSON.stringify({
        token: response.accessToken,
        userID: response.userID,
      });
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${END_POINT_SEVER}/api/auth/facebook-login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: loginData,
      };
      const loginHandle = await axios(config);
      if (loginHandle.data) {
        localStorage.setItem(USER_LOCAL_DATA, JSON.stringify(loginHandle.data));
        routeNavigate("/signal/BTCOTCUSDT");
      }
      setIsFacebookLoading(false);
    } catch (error) {
      console.log(error);
      setIsFacebookLoading(false);
    }
  };
  const onGoogleLogin = async (response) => {
    try {
      setIsGoogleLoading(true);
      console.log("response.credential: ", response.credential);
      const loginData = JSON.stringify({ token: response.credential });
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${END_POINT_SEVER}/api/auth/google-login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: loginData,
      };
      const loginHandle = await axios(config);
      if (loginHandle.data) {
        localStorage.setItem(USER_LOCAL_DATA, JSON.stringify(loginHandle.data));
        routeNavigate("/signal/BTCOTCUSDT");
      }
      setIsGoogleLoading(false);
    } catch (error) {
      console.log(error);
      setIsGoogleLoading(false);
    }
  };

  function isValidEmail(email) {
    var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  }

  const onRegister = async () => {
    try {
      setIsLoading(true)
      setLoginFail("");
      if (!otpCode) {
        toast(t("please-enter-the-information-completely"));
        setIsLoading(false)
        return;
      }

      const verifyCode = await verifyOtp();
      if (!verifyCode) {
        toast(t("fail-confirm-code"));
        setIsLoading(false)
        return;
      }

      let newData = {
        fullName: dataRegister?.fullname,
        phone: dataRegister?.phone,
        status: "ACTIVE",
        role: "USER",
        userName: dataRegister?.username,
        password: dataRegister?.password,
        verifyCode: verifyCode
      };
      if (dataRegister?.vipCode) newData = { ...newData, vipS1Code: dataRegister?.vipCode }
      if (vipUserData?.vipS1?.vipCode) newData = { ...newData, vipS2Code: vipUserData?.vipS1?.vipCode }
      if (vipUserData?.vipS2?.vipCode) newData = { ...newData, vipS3Code: vipUserData?.vipS2?.vipCode }
      if (vipUserData?._id) newData = { ...newData, vipS1: vipUserData?._id }
      if (vipUserData?.vipS1?._id) newData = { ...newData, vipS2: vipUserData?.vipS1?._id }
      if (vipUserData?.vipS2?._id) newData = { ...newData, vipS3: vipUserData?.vipS2?._id }

      var registerData = JSON.stringify(newData);
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${END_POINT_SEVER}/api/auth/register`,
        headers: {
          "Content-Type": "application/json",
        },
        data: registerData,
      };
      const registerHandle = await axios(config);
      if (registerHandle.data) {
        localStorage.setItem(
          USER_LOCAL_DATA,
          JSON.stringify(registerHandle.data)
        );
        setRegisterType("SUCCESS")
        setTimeout(() => {
          routeNavigate("/signal/BTCOTCUSDT");
          setRegisterType("REGISTER")
        }, 2000);
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      if (error?.response?.data?.errorCode == "USERNAME_HAS_ALREADY_EXIST") {
        toast(t("this-email-already-exists-please-login-or-use-a-new-email-to-register"))
        setAlreadyHaveEmail(true)
        console.log("USERNAME_HAS_ALREADY_EXIST")
        return;
      }
      toast(t("something-went-wrong-please-try-again"));
    }
  };

  const requestOtp = async () => {
    try {
        setIsLoading(true)
        setLoginFail("");
        console.log('a')
        if (!dataRegister?.username || !dataRegister?.password || !dataRegister?.phone || !dataRegister?.fullname) {
          toast(t("please-enter-the-information-completely"));
          setIsLoading(false)
          return;
        }
        console.log('b')
        if (!isValidEmail(dataRegister?.username)) {
          toast(t("email-is-incorrect-please-try-again"));
          setIsLoading(false)
          return;
        }
        console.log('c')
        if (dataRegister?.password != dataRegister?.confirmPassword) {
          toast(t("confirm-password-and-password-does-not-match"));
          setIsLoading(false)
            return;
        }
        console.log('d')
        
        const data = JSON.stringify({ "email": dataRegister?.username });
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${END_POINT_SEVER}/api/otp/request-otp`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        const requestOtpHandle = await axios(config);
        if (requestOtpHandle.data.message == "FINISHED") {
          console.log('ok')
            // setFormType("VERIFY_OTP")
            setRegisterType("VERIFY_OTP")
        }
        setIsLoading(false)
    } catch (error) {
        console.log(error)
        setIsLoading(false)
    }
  }

  const verifyOtp = async () => {
    try {
      let data = JSON.stringify({
        "email": dataRegister?.username,
        "code": otpCode
      });
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${END_POINT_SEVER}/api/otp/verify-otp`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      const verifyOtpHandle = await axios(config);
      if (verifyOtpHandle.data.message == "VERIFIED") {
        return verifyOtpHandle.data.verifyCode
      }
    } catch (error) {
      console.log(error)
      return
    }
  }

  const onLogin = async () => {
    try {
      setIsLoading(true)
      setLoginFail("");
      if (!loginUser || !loginPassword) {
        toast.warn(t("please-enter-your-username-and-password-completely"));
        setIsLoading(false)
        return
      }

      var loginData = JSON.stringify({
        userName: loginUser,
        password: loginPassword,
      });
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${END_POINT_SEVER}/api/auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: loginData,
      };
      const loginHandle = await axios(config);
      if (loginHandle.data) {
        localStorage.setItem(USER_LOCAL_DATA, JSON.stringify(loginHandle.data));
        routeNavigate("/signal/BTCOTCUSDT");
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
      if (error?.response?.data?.errorCode == "INVALID_USERNAME_OR_PASSWORD") {
        toast.error(t("email-or-password-is-incorrect-please-try-again"))
        setAlreadyHaveEmail(true)
      }
      setLoginFail(t("invalid-username-or-password"));
    }
  };

  const downloadPlayStore = () => {
    
  }
  return (
    <div className="xo-home-page">
      <div className="refresh-container">
        <div className="refresh-spinner">
          <div className="spinner"></div>
        </div>

        <div className="content">
          {/* <nav className='xo-navbar'>
            <div className='logo-banner'>
              <img src={`${process.env.PUBLIC_URL}/xo-images/new-logo-full.png`} alt="" />
            </div>
            <div className="nav-right">
              <button className="btn btn-login text-nowrap">เข้าสู่ระบบ</button>
              <button className="btn btn-sign-up text-nowrap text-white">ลงชื่อเข้าใช้</button>
              <div className="dropdown language">
                <button className="btn d-flex align-items-center">
                  <FontAwesomeIcon icon={faGlobe} fontSize={20} color='var(--color-gray)' />
                </button>
                <ul className="dropdown-menu language-menu" style={{ marginTop: '5rem' }}>
                  <a className="dropdown-item d-flex align-items-center">
                    <img src={`${process.env.PUBLIC_URL}/xo-images/lang-en.svg`} height={15} width={20} alt="No" />
                    <span className="ms-2">English</span>
                  </a>
                </ul>
              </div>
            </div>
          </nav> */}

          {/* <div className='xo-content'> */}
          {console.log("isScrollDown", isScrollDown)}
          <div id="app-navbar" style={{ backgroundColor: isScrollDown ? 'var(--color-bg-nav-scroll-down)' : 'transparent' }}>
            <div className="logo-banner">
              <img
                src={process.env.PUBLIC_URL + "/xo-images/new-logo-full.png"}
              />
            </div>
            <div className="nav-right">
              <button
                className="btn btn-login text-nowrap"
                onClick={handleShowLogin}
              >
                {t("login")}
              </button>
              <button
                className="btn btn-sign-up text-nowrap text-white"
                onClick={handleShowRegister}
              >
                {t("sign-up")}
              </button>
              <div className="setting">
                <div>
                  {/* <div className="dropdown language">
                    <a
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="btn"
                    >
                      <FontAwesomeIcon
                        icon={faGlobe}
                        fontSize={20}
                        color="var(--color-gray)"
                      />
                    </a>
                    <ul className="dropdown-menu language-menu">
                      <li>
                        <a
                          role="button"
                          className="dropdown-item d-flex align-items-center"
                        >
                          <img
                            src="./BX Trade_files/lang-en.svg"
                            height="15"
                            width="20"
                            alt="No"
                          />
                          <span className="ms-2">English</span>
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider p-0 m-0" />
                      </li>
                      <li>
                        <a
                          role="button"
                          className="dropdown-item d-flex align-items-center"
                        >
                          <img
                            src="./BX Trade_files/lang-th.svg"
                            height="15"
                            width="20"
                            alt="No"
                          />
                          <span className="ms-2">ไทย</span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ background: "transparent", border: "transparent" }}>
                      <FontAwesomeIcon
                        icon={faGlobe}
                        fontSize={20}
                        color="var(--color-gray)"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleChangeLanguage('english')}>
                        <img
                          src={process.env.PUBLIC_URL + "/xo-images/lang-en.svg"}
                          height="15"
                          width="20"
                          alt="No"
                        />
                        &nbsp;English
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleChangeLanguage('thailand')}>
                        <img
                          src={process.env.PUBLIC_URL + "/xo-images/lang-th.svg"}
                          height="15"
                          width="20"
                          alt="No"
                        />
                        &nbsp;ไทย
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>

          {/* <section className='poster-container'>
              <div className="poster-inner">
                <div className="poster-container-one">
                  <h1>ดีที่สุดในคลาสการเทรดไบนารี่ออฟชั่น</h1>
                  <h5>เราพร้อมที่จะเริ่มต้นสร้างอาชีพการค้าที่ประสบความสำเร็จบนแพลตฟอร์มนี้.</h5>
                  <button className="btn-trade-now">เทรดเลย</button>
                </div>
                <div className="poster-container-two">
                  <img className='poser-image' height={420} src={process.env.PUBLIC_URL + '/xo-images/poster.svg'} alt="" />
                </div>
                <div className="hide-footer-img"></div>
              </div>

              <div className="coin-card-container">
                <div className="title">
                  <img src={process.env.PUBLIC_URL + '/xo-images/advertising.png'} />
                  เข้าร่วมแพลตฟอร์มของเราเพื่อซื้อขายสกุลเงินดิจิทัลชั้นนำได้ทันที
                </div>
                <div className="sliding">
                  <div className="sliding-content">
                    {sliding?.map((item) => (
                      <div key={item?.id} className="coin-card shadow-bottom-mini">
                        <div className="symbol">
                          <img alt="symbol" src={process.env.PUBLIC_URL + '/xo-images/' + item?.img} />
                          <span>{item?.coin}</span>
                        </div>
                        <div className="value">
                          <div className="price">
                            <div className="coin-price">
                              ${item?.price}
                            </div>
                            <div className={`coin-percent${item?.type === 'plus' ? ' text-success' : ' text-danger'}`}>
                              <span>{item?.type === 'plus' ? '+' : '-'}</span>{item?.percent}%
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="slide-left"></div>
                  <div className="slide-right"></div>
                </div>
              </div>
            </section> */}

          <section className="poster-container">
            <div className="poster-inner">
              <div className="grid-container-one">
                <div className="grid-column best-in-class">
                  <h1>{t("best-trade-in-binary")}</h1>
                  <h5 className="for-desktop">
                    {t("We will start this building a successful career on the platform")}
                  </h5>
                  <h5 className="for-mobile">
                    {t("We will start this building")}
                    <br />
                    {t("a successful career")}
                    <br />
                    {t("on the platform")}
                  </h5>
                  <button className="btn-trade-now text-white" onClick={handleShowLogin}>{t("Trade Now")}</button>
                </div>
                <div className="grid-column animation-image">
                  {/* <div className="dotlottie">
                      <template shadowrootmode="open">
                        <div
                          id="animation-container"
                          lang="en"
                          role="img"
                          aria-label="lottie-animation-container"
                          className="main"
                        >
                          <div
                            id="animation"
                            className="animation"
                            style={{ background: 'transparent' }}
                          >
                            <img className='poser-image' height={420} src={process.env.PUBLIC_URL + '/xo-images/poster.svg'} alt="" />
                          </div>
                        </div>
                      </template>
                    </div> */}

                  <img
                    className="poser-image"
                    height={420}
                    src={process.env.PUBLIC_URL + "/xo-images/poster.svg"}
                    alt=""
                  />

                  {/* <div className="hide-footer-video"></div> */}
                  <div className="coin-amimation">
                    <img
                      src={process.env.PUBLIC_URL + "/xo-images/bnb-large.png"}
                      alt="bnb"
                      className="coin bnb"
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/xo-images/trx.png"}
                      alt="trx"
                      className="coin trx"
                    />
                    <img
                      src={process.env.PUBLIC_URL + "/xo-images/sol.png"}
                      alt="sol"
                      className="coin sol"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="coin-card-container">
                <div className="title">
                  <img
                    src={process.env.PUBLIC_URL + "/xo-images/advertising.png"}
                  />
                  {t("Join our platform to instantly trade leading cryptocurrencies")}
                </div>
                <div className="sliding">
                  <div className="sliding-content">
                    {sliding?.map((item) => (
                      <div
                        key={item?.id}
                        className="coin-card shadow-bottom-mini"
                      >
                        <div className="symbol">
                          <img
                            alt="symbol"
                            src={
                              process.env.PUBLIC_URL + "/xo-images/" + item?.img
                            }
                          />
                          <span>{item?.coin}</span>
                        </div>
                        <div className="value">
                          <div className="price">
                            <div className="coin-price">${item?.price}</div>
                            <div
                              className={`coin-percent${item?.type === "plus"
                                  ? " text-success"
                                  : " text-danger"
                                }`}
                            >
                              <span>{item?.type === "plus" ? "+" : "-"}</span>
                              {item?.percent}%
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="slide-left"></div>
                  <div className="slide-right"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="gradient-panel1">
            <div>
              <div className="coin-table-container">
                <div className="navigator">
                  <div className="nav">
                    <div className="nav-item nav-animation active">
                      {t("Highest price")}
                    </div>
                    {/* <div className="nav-item nav-animation">กำไรสูงสุด</div> */}
                  </div>
                </div>
                <div className="table-layout">
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>{t("name")}</th>
                        <th>{t("final price")}</th>
                        <th>{t("change 24h")}</th>
                        <th>{t("Buy and sell")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sliding?.map((item, index) => (
                        <tr key={item?.id} hidden={index + 1 > 7}>
                          <td className="td-coin">
                            <img
                              alt="Currency"
                              src={
                                process.env.PUBLIC_URL +
                                "/xo-images/" +
                                item?.img
                              }
                            />
                            <span>{item?.coin}</span>
                          </td>
                          <td>${item?.price}</td>
                          <td
                            className={
                              item?.type === "plus"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            <span>{item?.type === "plus" ? "+" : "-"}</span>
                            {item?.percent}
                          </td>
                          <td>
                            <button className="btn-animation" onClick={handleShowLogin}>{t("Trade")}</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="list-layout">
                  <div>
                    <div className="item-list shadow-bottom-mini">
                      <div className="coin">
                        <img
                          alt="coin"
                          src={process.env.PUBLIC_URL + "/xo-images/btc.svg"}
                        />
                        <div className="pair">
                          <label>
                            BTC
                            <span className="token">/ USDT</span>
                          </label>
                        </div>
                      </div>
                      <div className="price">
                        <div className="last">
                          <label>$57,693.00</label>
                        </div>
                        <div className="change up">
                          <span>+</span>1.87%
                        </div>
                      </div>
                    </div>
                    <div className="item-list shadow-bottom-mini">
                      <div className="coin">
                        <img
                          alt="coin"
                          src={process.env.PUBLIC_URL + "/xo-images/eth.svg"}
                        />
                        <div className="pair">
                          <label>
                            ETH
                            <span className="token">/ USDT</span>
                          </label>
                        </div>
                      </div>
                      <div className="price">
                        <div className="last">
                          <label>$2,445.95</label>
                        </div>
                        <div className="change up">
                          <span>+</span>3.44%
                        </div>
                      </div>
                    </div>
                    <div className="item-list shadow-bottom-mini">
                      <div className="coin">
                        <img
                          alt="coin"
                          src={process.env.PUBLIC_URL + "/xo-images/bnb.png"}
                        />
                        <div className="pair">
                          <label>
                            BNB
                            <span className="token">/ USDT</span>
                          </label>
                        </div>
                      </div>
                      <div className="price">
                        <div className="last">
                          <label>$509.1</label>
                        </div>
                        <div className="change down">-0.37%</div>
                      </div>
                    </div>
                    <div className="item-list shadow-bottom-mini">
                      <div className="coin">
                        <img
                          alt="coin"
                          src={process.env.PUBLIC_URL + "/xo-images/bch.png"}
                        />
                        <div className="pair">
                          <label>
                            BCH
                            <span className="token">/ USDT</span>
                          </label>
                        </div>
                      </div>
                      <div className="price">
                        <div className="last">
                          <label>$312.9</label>
                        </div>
                        <div className="change up">
                          <span>+</span>1.62%
                        </div>
                      </div>
                    </div>
                    <div className="item-list shadow-bottom-mini">
                      <div className="coin">
                        <img
                          alt="coin"
                          src={process.env.PUBLIC_URL + "/xo-images/sol.png"}
                        />
                        <div className="pair">
                          <label>
                            SOL
                            <span className="token">/ USDT</span>
                          </label>
                        </div>
                      </div>
                      <div className="price">
                        <div className="last">
                          <label>$133.03</label>
                        </div>
                        <div className="change up">
                          <span>+</span>4.67%
                        </div>
                      </div>
                    </div>
                    <div className="item-list shadow-bottom-mini">
                      <div className="coin">
                        <img
                          alt="coin"
                          src={process.env.PUBLIC_URL + "/xo-images/ltc.png"}
                        />
                        <div className="pair">
                          <label>
                            LTC
                            <span className="token">/ USDT</span>
                          </label>
                        </div>
                      </div>
                      <div className="price">
                        <div className="last">
                          <label>$65.95</label>
                        </div>
                        <div className="change up">
                          <span>+</span>2.2%
                        </div>
                      </div>
                    </div>
                    <div className="item-list shadow-bottom-mini">
                      <div className="coin">
                        <img
                          alt="coin"
                          src={process.env.PUBLIC_URL + "/xo-images/ordi.png"}
                        />
                        <div className="pair">
                          <label>
                            ORDI
                            <span className="token">/ USDT</span>
                          </label>
                        </div>
                      </div>
                      <div className="price">
                        <div className="last">
                          <label>$30.42</label>
                        </div>
                        <div className="change down">-0.26%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="curved-bottom-div">
              <img
                src={process.env.PUBLIC_URL + "/xo-images/curved-bottom.svg"}
                alt=""
              />
            </div>
          </section>

          <section className="gradient-panel2">
            <div className="benefit-content">
              <div className="card-title">
                <span className="easy">{t("easily")}</span>
                <span className="text">{t("gradient-panel2-text")}</span>
              </div>
              <div className="card-image">
                <div>
                  <div className="lists">
                    <div className="sliding">
                      <div className="item">
                        <img
                          src={process.env.PUBLIC_URL + "/xo-images/1.png"}
                          alt=""
                        />
                      </div>
                      <div className="item">
                        <img
                          src={process.env.PUBLIC_URL + "/xo-images/2.png"}
                          alt=""
                        />
                      </div>
                      <div className="item">
                        <img
                          src={process.env.PUBLIC_URL + "/xo-images/3.png"}
                          alt=""
                        />
                      </div>
                      <div className="item">
                        <img
                          src={process.env.PUBLIC_URL + "/xo-images/4.png"}
                          alt=""
                        />
                        <div className="volume">$131.66M</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="our-benefits">
              <div className="our-title">{t("our benefits")}</div>
              <div className="our-container">
                <div className="item shadow-bottom">
                  <header>
                    <div className="icon">
                      <img
                        src={process.env.PUBLIC_URL + "/xo-images/5.png"}
                        alt=""
                      />
                    </div>
                    <div>{t("Profit 95%")}</div>
                  </header>
                  <div className="text">
                    {t("our benefits text1")}
                  </div>
                </div>
                <div className="item shadow-bottom">
                  <header>
                    <div className="icon">
                      <img
                        src={process.env.PUBLIC_URL + "/xo-images/6.png"}
                        alt=""
                        className="percent"
                      />
                    </div>
                    <div>{t("Minimum investment 1 USD")}</div>
                  </header>
                  <div className="text">
                    {t("our benefits text2")}
                  </div>
                </div>
                <div className="item shadow-bottom">
                  <header>
                    <div className="icon">
                      <img
                        src={process.env.PUBLIC_URL + "/xo-images/7.png"}
                        alt=""
                        className="percent-arrow"
                      />
                    </div>
                    <div>{t("options")}</div>
                  </header>
                  <div className="text">
                    {t("our benefits text3")}
                  </div>
                </div>
                <div className="item shadow-bottom">
                  <header>
                    <div className="icon">
                      <img
                        src={process.env.PUBLIC_URL + "/xo-images/8.png"}
                        alt=""
                      />
                    </div>
                    <div>{t("vip program")}</div>
                  </header>
                  <div className="text">
                    {t("our benefits text4")}
                  </div>
                </div>
              </div>
              <div className="volume-container">
                <div className="item">
                  <img
                    src={process.env.PUBLIC_URL + "/xo-images/volume.png"}
                    alt=""
                  />
                  <div className="title">{t("Total trading volume")}</div>
                  <div className="text">$131.66M</div>
                </div>
                <div className="item">
                  <img
                    src={process.env.PUBLIC_URL + "/xo-images/volume24.png"}
                    alt=""
                  />
                  <div className="title">{t("24H Value")}</div>
                  <div className="text">$1.99M</div>
                </div>
                <div className="item">
                  <img
                    src={process.env.PUBLIC_URL + "/xo-images/active_user.png"}
                    alt=""
                  />
                  <div className="title">{t("Active user")}</div>
                  <div className="text">212.1K</div>
                </div>
                <div className="item">
                  <img
                    src={process.env.PUBLIC_URL + "/xo-images/trade_fee.png"}
                    alt=""
                  />
                  <div className="title">{t("Total trading profit")}</div>
                  <div className="text">$29.51M</div>
                </div>
                <div className="item">
                  <img
                    src={process.env.PUBLIC_URL + "/xo-images/earned.png"}
                    alt=""
                  />
                  <div className="title">{t("Commission received")}</div>
                  <div className="text">$6.01M</div>
                </div>
              </div>
              <div className="spot-screen-mac">
                <div className="image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/xo-images/spot-screen-mac.png"
                    }
                    alt="mac"
                  />
                </div>
                <div className="text">
                  <span className="extra">{t("buy")}</span>
                  <span className="big"> {t("and")} </span>
                  <span className="extra">{t("sell")}</span>
                  <br />
                  <span className="big">{t("Cryptocurrency easily")}</span>
                  <br />
                  <div className="normal">
                    {t("spot-screen-mac-text")}
                  </div>
                </div>
              </div>
              <div className="trade-screen-ipad">
                <div className="col-box ipad-left">
                  <div className="title">
                    <span className="text-contrast">{t("buy sell")} </span>{t("better")}
                    <br />
                    <span className="text-contrast">{t("every")}&nbsp;</span>{t("where")}&nbsp;
                    <span className="text-contrast">{t("every")}&nbsp;</span>{t("time")}.
                  </div>
                  <div className="text">
                    {t("trade-screen-ipad-text")}
                  </div>
                </div>
                <div className="col-box ipad-right">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/xo-images/trade_screen_tablet.png"
                    }
                    className="img-ipad"
                  />
                </div>
              </div>
              <div className="trade-screen-mac">
                <div className="col-box mac-left">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/xo-images/trade_screen_mac.png"
                    }
                  />
                </div>
                <div className="col-box mac-right">
                  <div className="title">
                    <span className="text-contrast">{t("make money")}&nbsp;</span>
                    {t("commissions")}
                    <br />
                    <span className="text-contrast">{t("every2")}&nbsp;</span>{t("minute")}.
                  </div>
                  <div className="text">
                    {t("trade-screen-mac-text")}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* </div> */}

          <footer>
            
            <div className="footer-container">
              <div className="inner">
                <div className="grid">
                  <div>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/xo-images/new-logo-full.png"
                      }
                    />
                    <div className="mt-2 mb-1 opacity-75">
                      {t("credits")}
                    </div>
                    <div>
                      {/* อีเมล: */}
                      {t("email")}:
                      &nbsp;
                      <a
                        href="mailto:support@forexxo.com"
                        style={{ color: "var(--color-contrast)" }}
                      >
                        support@forexxo.com
                      </a>
                    </div>
                  </div>
                  <div className="opacity-75">
                    <div className="mt-3 mb-1">
                      {t("Finance LLC operating license")}
                    </div>
                    <div className="mb-1">{t("Registered for the 3rd time in 2019")}</div>
                    <div>
                      {t("address 3rd floor")}
                    </div>
                  </div>
                  <div>
                    <a className="link mt-3 mb-1">{t("help")}</a>
                    <a className="link mb-1">{t("Terms of use")}</a>
                    <a className="link mb-1">{t("Our Privacy Policy")}</a>
                    <a className="link mb-1">{t("about us")}</a>
                    <a className="link">{t("How to register")}</a>
                  </div>
                </div>
                <hr className="my-5" />
                <div className="mb-2 opacity-75 risk-warning">
                 
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <a href="https://foreign-xo-bucket.s3.ap-southeast-1.amazonaws.com/app-release.apk">
                    <img onClick={() => downloadPlayStore()} src="/Google_Play_Store_badge_EN.png" style={{width: 200, height: 60}}/>
                    </a>
                    &nbsp;&nbsp;&nbsp;
                    <img src="/App_Store_black.png" className="app-store" style={{width: 200, height: 60}}/>
                    </div>
                  
                  
                  {/* <i>
                    {t("warnings")}
                  </i> */}
                </div>
                <div className="opacity-75 risk-warning">
                  {/* <i>
                    {t("warnings2")}
                  </i> */}
                </div>
              </div>
            </div>
          </footer>

          <div className="card-on-side-bar-container" hidden={!isShowLogin}>
            <div id="card-on-sidebar" className="card show">
              <div className="card-header">
                <div>
                  <img src={"./xo-images/new-logo-full.png"} className="logo" />
                </div>
                <button className="btn-close-modal" onClick={handleCloseLogin}>
                  <FontAwesomeIcon icon={faClose} />
                </button>
              </div>
              <div className="card-body">
                <div className="card-body-inner">
                  <h3 className="title">{t("login to your account")}</h3>
                  <form
                    className="ng-untouched ng-pristine ng-invalid"
                  >
                    <div className="mb-4">
                      <input
                        type="email"
                        maxLength="50"
                        className="form-control main ng-untouched ng-pristine ng-invalid bg-white"
                        placeholder="example@email.com"
                        value={loginUser}
                        onChange={e => setLoginUser(e.target.value)}
                      />
                    </div>
                    <div className="mb-2">
                      <div className="input-group-password">
                        <input
                          minlength="8"
                          maxLength="20"
                          autocomplete="on"
                          className="form-control main ng-untouched ng-pristine ng-invalid bg-white"
                          type="password"
                          placeholder={t("Password")}
                          value={loginPassword}
                          onChange={e => setLoginPassword(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="text-end mb-3">
                      <a>
                        {t("forget password")}
                      </a>
                    </div>
                    <button
                      type="button"
                      className="btn btn-sign-in text-white mt-3"
                      onClick={onLogin}
                    >
                      <span>{t("login")}</span>
                    </button>
                  </form>
                  <div className="my-4">
                    <div className="line mt-3 mb-3 text-center">
                      <div>{t("or login with")}</div>
                    </div>
                  </div>

                  {/* <FacebookLogin
                    appId={FACEBOOK_APP_ID}
                    fields="name,email,picture"
                    callback={onFacebookLogin}
                    render={(renderProps) => (
                      <button
                        className="btn btn-facebook mb-3 text-white"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <img src={process.env.PUBLIC_URL + "/xo-images/facebook-icon.svg"} alt="" />
                        {" "}
                        {t("login-with-facebook")}
                      </button>
                    )}
                  /> */}
                  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                    <GoogleLogin
                      onSuccess={onGoogleLogin}
                      onError={onError}
                      render={(renderProps) => (
                        <button
                          className="btn btn-google"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                          <img src={process.env.PUBLIC_URL + "/xo-images/google-icon.svg"} alt="" />
                          {" "}
                          {t("login-with-google")}
                        </button>
                      )}
                    />
                  </GoogleOAuthProvider>
                </div>
              </div>
              <div className="card-footer">
                <div className="mb-1 text-white" onClick={() => handleShowRegisterLogin()}>
                  {" "}
                  {t("want an account")} <a> {t("create an account")}</a>
                </div>
              </div>
            </div>
          </div>

          <div className="card-on-side-bar-container" hidden={!isShowRegister}>
            <div id="card-on-sidebar" className="card show">
              <div className="card-header">
                <div>
                  <img src="./xo-images/new-logo-full.png" className="logo" />
                </div>
                <button
                  className="btn-close-modal"
                  tabindex="0"
                  onClick={handleCloseRegister}
                >
                  <FontAwesomeIcon icon={faClose} />
                </button>
              </div>
              <div className="card-body">
                <div className="card-body-inner">
                  {registerType === "REGISTER" ?
                    <div>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control main ng-untouched ng-pristine ng-invalid bg-white mb-3"
                        placeholder={t("Full Name")}
                        value={dataRegister?.fullname}
                        onChange={e => setDataRegister({ ...dataRegister, fullname: e.target.value })}
                      />
                      <input
                        type="phone"
                        maxLength="50"
                        className="form-control main ng-untouched ng-pristine ng-invalid bg-white mb-3"
                        placeholder={t("Phone Number")}
                        value={dataRegister?.phone}
                        onChange={e => setDataRegister({ ...dataRegister, phone: e.target.value })}
                      />
                      <input
                        type="email"
                        formcontrolname="email"
                        maxLength="50"
                        className="form-control main ng-untouched ng-pristine ng-invalid bg-white mb-3"
                        placeholder={t("Email")}
                        value={dataRegister?.username}
                        onChange={e => setDataRegister({ ...dataRegister, username: e.target.value })}
                      />
                      <input
                        type="password"
                        maxLength="50"
                        className="form-control main ng-untouched ng-pristine ng-invalid bg-white mb-3"
                        placeholder={t("Password")}
                        value={dataRegister?.password}
                        onChange={e => setDataRegister({ ...dataRegister, password: e.target.value })}
                      />
                      <input
                        type="password"
                        maxLength="50"
                        className="form-control main ng-untouched ng-pristine ng-invalid bg-white mb-3"
                        placeholder={t("Confirm Password")}
                        value={dataRegister?.confirmPassword}
                        onChange={e => setDataRegister({ ...dataRegister, confirmPassword: e.target.value })}
                      />
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control main ng-untouched ng-pristine ng-invalid bg-white mb-3"
                        placeholder={t("Code")}
                        value={dataRegister?.vipCode}
                        onChange={e => setDataRegister({ ...dataRegister, vipCode: e.target.value })}
                      />
                      <button className="btn btn-register-email" tabindex="0" onClick={requestOtp}>
                        {isLoading ? <Spinner animation="border" role="status"></Spinner> : t("sign-up")}
                      </button>
                      <div className="horizontal-line mb-4 mt-4">
                        <span className="text">{t("or sign-up with")}</span>
                      </div>
                      {/* <button className="btn btn-facebook mb-3 text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.339"
                          height="21.332"
                          viewBox="0 0 11.339 21.332"
                        >
                          <path
                            id="Path_71950"
                            data-name="Path 71950"
                            d="M25.162,21.893l.588-3.831H22.074V15.575a1.916,1.916,0,0,1,2.16-2.07h1.671V10.243a20.347,20.347,0,0,0-2.967-.259c-3.028,0-5.007,1.835-5.007,5.157v2.92H14.566v3.831h3.366v9.262a13.374,13.374,0,0,0,4.142,0V21.893Z"
                            transform="translate(-14.566 -9.984)"
                            fill="#fff"
                          ></path>
                        </svg>{" "}
                        ลงชื่อเข้าใช้ด้วย Facebook{" "}
                      </button>
                      <button className="btn btn-google">
                        <svg
                          id="Component_1_1"
                          data-name="Component 1 – 1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                        >
                          <rect
                            id="Rectangle_6721"
                            data-name="Rectangle 6721"
                            width="23"
                            height="23"
                            rx="11.5"
                            fill="#fff"
                          ></rect>
                          <g id="google" transform="translate(3.147 2.87)">
                            <g
                              id="Group_25522"
                              data-name="Group 25522"
                              transform="translate(0 0)"
                            >
                              <path
                                id="Path_71963"
                                data-name="Path 71963"
                                d="M12.107,5.568a5.128,5.128,0,0,1,3.309,1.208L18.01,4.307A8.713,8.713,0,0,0,4.293,6.851L7.216,9.1a5.161,5.161,0,0,1,4.891-3.536Z"
                                transform="translate(-3.384 -2)"
                                fill="#d94f3d"
                              ></path>
                              <path
                                id="Path_71964"
                                data-name="Path 71964"
                                d="M5.569,18.108a5.132,5.132,0,0,1,.264-1.618L2.909,14.236a8.7,8.7,0,0,0,0,7.743l2.923-2.253a5.132,5.132,0,0,1-.264-1.618Z"
                                transform="translate(-2 -9.384)"
                                fill="#f2c042"
                              ></path>
                              <path
                                id="Path_71965"
                                data-name="Path 71965"
                                d="M32.427,20H24.1v3.569h4.718a4.253,4.253,0,0,1-1.806,2.443l2.9,2.236C31.767,26.584,32.856,23.879,32.427,20Z"
                                transform="translate(-15.337 -12.863)"
                                fill="#5085ed"
                              ></path>
                              <path
                                id="Path_71966"
                                data-name="Path 71966"
                                d="M15.058,30.889a5.543,5.543,0,0,1-2.951.729,5.161,5.161,0,0,1-4.891-3.536L4.293,30.334a8.732,8.732,0,0,0,7.814,4.852,8.67,8.67,0,0,0,5.852-2.062Z"
                                transform="translate(-3.384 -17.739)"
                                fill="#57a75c"
                              ></path>
                            </g>
                          </g>
                        </svg>{" "}
                        ลงชื่อเข้าใช้ด้วย Google{" "}
                      </button> */}
                      {/* <FacebookLogin
                        appId={FACEBOOK_APP_ID}
                        fields="name,email,picture"
                        callback={onFacebookLogin}
                        render={(renderProps) => (
                          <button
                            className="btn btn-facebook mb-3 text-white"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <img src={process.env.PUBLIC_URL + "/xo-images/facebook-icon.svg"} alt="" />
                            {" "}
                            {t("login-with-facebook")}
                          </button>
                        )}
                      /> */}
                      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                        <GoogleLogin
                          onSuccess={onGoogleLogin}
                          onError={onError}
                          render={(renderProps) => (
                            <button
                              className="btn btn-google"
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                            >
                              <img src={process.env.PUBLIC_URL + "/xo-images/google-icon.svg"} alt="" />
                              {" "}
                              {t("login-with-google")}
                            </button>
                          )}
                        />
                      </GoogleOAuthProvider>
                    </div>
                    : 
                    <div style={{ textAlign: 'start' }}>
                      <p style={{ color: "#fff", fontSize: 16 }} className="mb-4">
                        {t("confirm-code-from")} <span style={{ fontWeight: "bold" }}>{dataRegister?.username}</span>
                      </p>
                      <input
                        type="text"
                        maxLength="50"
                        className="form-control main ng-untouched ng-pristine ng-invalid bg-white mb-3"
                        placeholder="OTP Code"
                        value={otpCode}
                        onChange={e => setOtpCode(e.target.value)}
                      />
                      <button className="btn btn-register-email" tabindex="0" onClick={onRegister}>
                        {isLoading ? <Spinner animation="border" role="status"></Spinner> : t("sign-up")}
                      </button>
                    </div>
                  }
                </div>
              </div>
              <div className="card-footer">
                <div className="mb-1 text-white" onClick={() => handleShowLoginRegister()}>
                  {" "}
                  {t("do you have an account")}{" "}
                  <a>{t("login")}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

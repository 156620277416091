import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { END_POINT_SEVER } from '../../api'
import { Typography, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Table } from "react-bootstrap";
import CustomNavbar from '../navbar/Navbar'
import moment from 'moment'
import LoadingPage from '../../common/loadingPage'
import { appCreditShowREAL, appCreditShowUSDT, currencyFormat } from '../../utils'

export default function VolumnPage() {
    const [volumnDialy, setVolumnDialy] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalMoney, setTotalMoney] = useState(0);

    useEffect(() => {
        getVolumnDialy()
    }, [])

    const getVolumnDialy = async () => {
        try {
            setIsLoading(true)
            const startDate = moment().add(-3, 'months').format('yyyy-MM-DD');
            const endDate = moment().format('yyyy-MM-DD');
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/valumn-dialy?startDate=${startDate}&endDate=${endDate}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const valumnDialyRequest = await axios(config)

            setTotalAmount(valumnDialyRequest?.data?.totalAmount)
            setTotalMoney(valumnDialyRequest?.data?.totalMoney)
            if (valumnDialyRequest?.data?.valumnDialy?.length > 0) {
                setVolumnDialy(valumnDialyRequest?.data?.valumnDialy)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    return (
        isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div
                className="color-body"
                style={{ width: "100%", minHeight: "100vh", color: "white", padding: 10 }}
            >
                <CustomNavbar />
                <div style={{ height: 100 }} />
                <Typography variant="h5">Volumn List</Typography>
                <Typography variant="h6">Amount: {totalAmount} records</Typography>
                <Divider />
                <Typography variant="h6">Total Volumn: {currencyFormat(parseFloat(totalMoney))} USDT</Typography>
                <div style={{ padding: 20 }}>
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Down Trade (USDT)</th>
                                <th>Up Trade (USDT)</th>
                                <th>Total Volume (USDT)</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {volumnDialy && volumnDialy.map((volumn, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{currencyFormat(parseFloat(volumn?.sellMoney))}</td>
                                    <td>{currencyFormat(parseFloat(volumn?.buyMoney))}</td>
                                    <td>{currencyFormat(parseFloat(volumn?.totalMoney))}</td>
                                    <td>{moment(volumn.dateTime).format("DD/MM/yyyy")}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
    )
}

import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { screenSize } from '../../constants'
import i18n from '../../i18n';
import LoadingPage from '../../common/loadingPage';
import { useNavigate } from 'react-router-dom';

export default function Policy() {
    const routeNavigate = useNavigate();
    const currentLanguage = i18n.language;
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        const timeoutId = setTimeout(() => {
            setIsLoading(false)
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, []);
    return (
        isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div
                style={{
                    backgroundImage: 'url("/login-background.png")',
                    width: "100vw",
                    minHeight: "100vh",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        height: 55,
                        width: "100vw",
                        justifyContent: "space-between",
                        alignItems: "end",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            height: 50,
                            alignItems: "end",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            sx={{
                                cursor: "pointer",
                                alignItems: screenSize().width > 700 ? "center" : "end",
                            }}
                        >

                            <img src="/coin-logo-text.png" style={{ height: 45 }} onClick={() => routeNavigate("/")} />
                        </Box>
                    </div>
                    <div> </div>
                </div>
                <iframe src={currentLanguage == "english" ? "/privacy-policy-en.pdf" : "/privacy-policy-th.pdf"} width="100%" height="100%" />
            </div>
    )
}

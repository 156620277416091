import React from 'react'
import loadingAnimationData from '../images/loading-animation.json'
import Lottie from 'react-lottie';

export default function LoadingPage() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className='color-body' style={{ width: "100%", minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <img src="/coin-logo-text.png" style={{ height: 70 }} />
            <div style={{ width: 10 }} />
            <Lottie
                options={defaultOptions}
                height={120}
                width={120}
            />
        </div>
    )
}

import React, { useEffect, useState } from 'react'
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import Slide from '@mui/material/Slide';
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Slide,
    Avatar, Typography,
    Divider, TextField,
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { USER_LOCAL_DATA, appColor } from '../constants';
import { currencyFormat } from '../utils';

import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_MC_GQL, REQUEST_P2P_TRANSACTION_GQL, START_P2P_COUNTDOWN } from '../apollo/crypto';
import { END_POINT_SEVER } from '../api';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { SEND_MESSAGE } from '../apollo/crypto';
import { NumericFormat } from 'react-number-format';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@mui/icons-material';
import { S3_BUCKET_END_POINT } from "../api"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const P2PBuySellPopup = ({
    isMobile,
    p2pType,
    open,
    handleClickOpen,
    handleClose,
    adv,
}) => {
    const [userID, setUserID] = useState();
    const [amountToPay, setamountToPay] = useState(0); //FIAT (LAK)
    const [amountReceive, setamountReceive] = useState(0); //USDT
    const routeNavigate = useNavigate();
    const [myMC, setMyMC] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const toastAlert = (type, msg,) => {
        toast(msg, { type: type == "error" ? "error" : "success" });
    };

    const [usdtWalletData, setUsdtWalletData] = useState();

    const handleChangeAmountToPay = (event) => {
        const value = event.target.value;
        const valueFloat = parseFloat(value.replace(/,/g, ''));
        const maxFloat = parseFloat(adv?.maxSingleTransQuantity.replace(/,/g, ''));
        if (valueFloat >= 0) {
            if (
                valueFloat <=
                parseFloat(maxFloat)
            ) {
                setamountToPay(valueFloat)
                setamountReceive(valueFloat / parseFloat(adv.price))

            } else {
                setamountToPay(maxFloat.toFixed(2))
                setamountReceive(maxFloat.toFixed(2) / parseFloat(adv.price).toFixed(2))
            }
            // setamountToPay(newPrice)
            // setamountReceive(currencyFormat(newPrice / adv.price))
        } else {
            setamountToPay(0);
            setamountReceive(0);
        }
    }
    const handleChangeAmountReceive = (event) => {
        const value = event.target.value;
        const valueFloat = parseFloat(value.replace(/,/g, ''));
        const maxFloat = parseFloat(adv?.maxSingleTransQuantity.replace(/,/g, '')) / parseFloat(adv.price);
        if (valueFloat >= 0) {
            if (
                valueFloat <=
                parseFloat(maxFloat)
            ) {
                setamountReceive(valueFloat)
                setamountToPay(valueFloat * parseFloat(adv.price))
            } else {
                setamountReceive(maxFloat.toFixed(2))
                setamountToPay(maxFloat.toFixed(2) * parseFloat(adv.price).toFixed(2))
            }
            // setamountReceive(newPrice)
            // setamountToPay(currencyFormat(newPrice * adv.price))
        } else {
            setamountReceive(0);
            setamountToPay(0);
        }

    }

    // get user
    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (
                        parseData
                    ) {
                        setUserID(parseData?.user?._id);
                    }
                }

            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);


    // CREDIT
    const getCreditData = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const creditHandle = await axios(config);
            if (creditHandle?.data) {
                for (var i = 0; i < creditHandle.data?.credit.length; i++) {
                    if (creditHandle.data?.credit[i].type == "USDT")
                        setUsdtWalletData(creditHandle.data?.credit[i]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (userID) getCreditData(userID)
    }, [userID]);


    // create handle sell/buy
    const [sendMessage] = useMutation(SEND_MESSAGE)
    const [startP2PCountDown] = useMutation(START_P2P_COUNTDOWN)
    const [mutationCreateRequestP2PTransaction] = useMutation(REQUEST_P2P_TRANSACTION_GQL, {
        onCompleted: async (data) => {
            if (data?.requestP2PTransaction?.error?.error == true) {
                toastAlert("error", data?.requestP2PTransaction?.error?.errorMsg)
                setIsLoading(false)
                return
            }

            if (adv?.mcMCDetail) {
                sendMessage({
                    variables: {
                        data: {
                            room: data?.requestP2PTransaction?.data?._id,
                            sender: myMC?.user,
                            message: `${adv?.mcMCDetail}`,
                            type: "TEXT"
                        }
                    }
                })
            }

            startP2PCountDown({
                variables: {
                    roomId: data?.requestP2PTransaction?.data?._id,
                    mimute: 15
                }
            })
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${data?.requestP2PTransaction?.data?.merchant}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                var configSendEmail = {
                    method: "post",
                    url: `${END_POINT_SEVER}/api/otp/send-message-by-email`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        email: [userHandle?.data?.user?.email],
                        title: data?.requestP2PTransaction?.data?.tradeType == "BUY" ? "You have new request to buy P2P" : "You have new request to sell P2P",
                        message: data?.requestP2PTransaction?.data?.tradeType == "BUY" ? `You have new request to buy P2P, \n See more description as https://www.coinoptions.net/chat/${data?.requestP2PTransaction?.data?._id}` : `You have new request to sell P2P, \n See more description as https://www.coinoptions.net/chat/${data?.requestP2PTransaction?.data?._id}`
                    }),
                };
                await axios(configSendEmail);
            }

            if (data?.requestP2PTransaction.error.error == false) {
                // if (p2pType == "BUY") {
                //     if (myMC?.bankAccountNumber) await onSendMessage("TEXT", data?.requestP2PTransaction?.data?._id)
                //     if (myMC?.bankAccountQR) await onSendMessage("IMAGE", data?.requestP2PTransaction?.data?._id)
                // }
                toastAlert('success', 'Create P2P Transaction Success')
                routeNavigate("/chat/" + data?.requestP2PTransaction?.data?._id)
                setIsLoading(false)
                handleClose()
            } else {
                toastAlert("error", `ERROR: ${data?.requestP2PTransaction.error.errorMsg}`)
                setIsLoading(false)
                handleClose()
            }
        },
        onError: (error) => {
            toastAlert("error", `${error}`)
            setIsLoading(false)
        }
    })

    const onSendMessage = async (type, room) => {
        try {
            if (type == "IMAGE") {
                await sendMessage({
                    variables: {
                        data: {
                            room: room,
                            sender: myMC?.user,
                            message: myMC?.bankAccountQR,
                            type: "IMAGE"
                        }
                    }
                })
            } else {
                await sendMessage({
                    variables: {
                        data: {
                            room: room,
                            sender: myMC?.user,
                            message: `Thank you for order,`,
                            type: type
                        }
                    }
                })
                await sendMessage({
                    variables: {
                        data: {
                            room: room,
                            sender: myMC?.user,
                            message: `This is my bank account info`,
                            type: type
                        }
                    }
                })
                await sendMessage({
                    variables: {
                        data: {
                            room: room,
                            sender: myMC?.user,
                            message: `Bank name: ${myMC?.bankName}`,
                            type: type
                        }
                    }
                })
                await sendMessage({
                    variables: {
                        data: {
                            room: room,
                            sender: myMC?.user,
                            message: `Bank account name: ${myMC?.bankAccountName}`,
                            type: type
                        }
                    }
                })
                await sendMessage({
                    variables: {
                        data: {
                            room: room,
                            sender: myMC?.user,
                            message: `Bank account number: ${myMC?.bankAccountNumber}`,
                            type: type
                        }
                    }
                })
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const [getMyMC] = useLazyQuery(GET_MC_GQL, {
        variables: { userId: adv?.user },
        fetchPolicy: 'network-only',
        onCompleted: async (data) => {

            // console.log(`getMyMC data`, data)
            if (data.getMyMerchant.error == true) {
                setIsLoading(false)
                console.log(`getMyMC error`, data.getMyMerchant.error);
            } else {
                setMyMC(data.getMyMerchant.data)
                const floatValue = await parseFloat(typeof amountToPay == "string" ? amountToPay.replace(/,/g, '') : amountToPay);
                if (floatValue == 0) {
                    if (p2pType == "BUY") {
                        toastAlert("error", `Please enter amount to BUY`)
                    } else {
                        toastAlert("error", `Please enter amount to SELL`)
                    }
                    setIsLoading(false)
                    return
                }
                if (floatValue < parseFloat(adv?.tradableQuantity)) {
                    toastAlert("error", `Your amount to pay is over than available`)
                    setIsLoading(false)
                    return
                }

                let dataP2PTransaction = {
                    adv: adv?._id,
                    customer: userID,
                    amount: parseFloat(amountReceive),
                    fiatAmount: floatValue
                }
                await mutationCreateRequestP2PTransaction({
                    variables: {
                        data: dataP2PTransaction
                    }
                })
                setIsLoading(false)


            }
        },
        onError: (error) => {
            setIsLoading(false)
            console.log(`getMyMC error`, error)
        }
    })
    const handleCreateP2PTransaction = async () => {
        setIsLoading(true);
        setTimeout(async () => {
            await getMyMC();
        }, 1000)
    }


    const handleSellAllUSDT = () => {
        let newPrice = parseFloat(usdtWalletData?.credit).toFixed(2);
        if (newPrice >= 0) {
            setamountReceive(newPrice)
            setamountToPay(currencyFormat(newPrice * adv.price))
        } else {
            setamountReceive(0);
        }
    }

    const handleBuyAllUSDT = () => {
        setamountToPay(parseInt(adv?.maxSingleTransQuantity))
        setamountReceive(currencyFormat(parseInt(adv?.maxSingleTransQuantity) / adv.price))
    }

    return (
        <React.Fragment>
            <Dialog
                fullScreen={isMobile ? true : false}
                maxWidth={'lg'}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    width: '100%',
                }}
            >
                {/* AVATAR */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'end',
                            p: 2,
                            backgroundColor: 'transparent',
                        }}
                    >
                        <CloseOutlined
                            sx={{
                                cursor: 'pointer',
                                color: appColor.textWhite
                            }}
                            onClick={handleClose}
                        />
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={isMobile ? 'column-reverse' : 'row'}
                        sx={{
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            backgroundColor: appColor.bgDark,
                            py: 2
                        }}
                    >
                        {/*START LEFT */}
                        <Box
                            sx={{
                                backgroundColor: appColor.bgDark,
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    paddingTop: '20px',
                                    paddingLeft: isMobile ? "0px" : "16px",
                                    paddingRight: isMobile ? "0px" : "16px",
                                }}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    sx={{
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        width: '300px',
                                    }}
                                >
                                    {adv?.userImage ? <img src={`${S3_BUCKET_END_POINT}/images/${adv?.userImage}`} style={{ width: 30, height: 30, borderRadius: 30, border: "1px solid #fff" }} /> : <Avatar
                                        sx={{
                                            backgroundColor: appColor.textWhite,
                                            width: '30px',
                                            height: '30px'
                                        }}
                                    />}
                                    <Box sx={{
                                        width: 10
                                    }} />
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        sx={{
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                        }}
                                    >
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Typography sx={{
                                            color: appColor.infoActive
                                        }}>{adv?.merchantName}</Typography>
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Box display={'flex'} flexDirection={'column'}
                                            sx={{
                                                justifyContent: 'start',
                                                alignItems: 'start'
                                            }}>
                                            <Typography
                                                sx={{
                                                    color: appColor.textGrey
                                                }}
                                            >71 orders  |  97.30% completion  </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    height: 4
                                }} />
                                {/* START PAYMENT */}
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    sx={{
                                        width: '300px',
                                    }}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey
                                            }}
                                        >Payment Time Limit </Typography>
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Typography >15 Minutes</Typography>
                                    </Box>
                                    <Box sx={{
                                        height: 4
                                    }} />
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey
                                            }}
                                        >Avg. Release Time </Typography>
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Typography > 1.7 Minutes</Typography>
                                    </Box>
                                </Box>
                                {/* END PAYMENT */}
                                <Box sx={{
                                    height: 4
                                }} />
                                {/* START AVAILABLE */}
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    sx={{
                                        width: '300px',
                                    }}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey
                                            }}
                                        >Available </Typography>
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Typography >{currencyFormat(parseInt(adv?.tradableQuantity))} {adv?.assetType}</Typography>
                                    </Box>
                                    <Box sx={{
                                        height: 4
                                    }} />
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey
                                            }}
                                        >Min </Typography>
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Typography>{adv?.fiatUnitType} {currencyFormat(parseInt(adv?.minSingleTransQuantity))}</Typography>
                                    </Box>
                                    <Box sx={{
                                        height: 4
                                    }} />
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}>
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey
                                            }}
                                        >Max </Typography>
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Typography>{adv?.fiatUnitType} {currencyFormat(parseInt(adv?.maxSingleTransQuantity))}</Typography>
                                    </Box>
                                    <Box sx={{
                                        height: 4
                                    }} />
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        sx={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey
                                            }}
                                        >Positive Feedback
                                        </Typography>
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Box display={'flex'} flexDirection={'row'}>
                                            <FontAwesomeIcon
                                                icon={faThumbsUp}
                                                style={{ fontSize: 14, color: appColor.green }}
                                            />
                                            <Box sx={{
                                                width: 10
                                            }} />
                                            <Typography > 86.76%</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    height: 4
                                }} />
                                {/* END AVAILABLE */}
                                <Divider
                                    sx={{ width: "100%", }}
                                    color={appColor.background}
                                />
                                <Box sx={{
                                    height: 4
                                }} />
                                <Box display={'flex'} sx={{
                                    width: '300px',
                                }}><Typography sx={{
                                    color: appColor.red,
                                }} >|</Typography> <Typography >BCEL</Typography></Box>

                                <Box sx={{
                                    height: 4
                                }} />
                                <Divider
                                    sx={{
                                        width: "100%",
                                    }}
                                    color={appColor.bgDarkSecond}
                                />

                                <Box sx={{
                                    height: 4
                                }} />

                                <Typography sx={{
                                    color: appColor.textGrey,
                                    textAlign: "left",
                                    width: '300px',
                                }}>Advertisers' Terms (Please read carefully)</Typography>
                                <Box sx={{
                                    height: 4
                                }} />
                                <Typography sx={{
                                    color: appColor.textGrey,
                                    textAlign: "justify",
                                    width: '300px',
                                }}>{adv?.mcMCDetail}</Typography>
                                {/* <Typography sx={{
                                    color: appColor.textGrey
                                }}>ໂທ: 020 926 474 84</Typography> */}

                            </Box>
                        </Box>
                        {/*END LEFT */}
                        {/*START RIGHT */}
                        <Box
                            sx={{
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    paddingX: 4,
                                    paddingBottom: isMobile ? 0 : 10,
                                }}
                            >

                                {/* <Box sx={{
                                    height: 4
                                }} /> */}

                                {/* START PAYMENT */}
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    sx={{
                                        width: '300px',
                                    }}
                                >
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        sx={{
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                        }}>
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey
                                            }}
                                        >{t('Price')}</Typography>
                                        <Box sx={{
                                            width: 10
                                        }} />
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 'bold',
                                                color: appColor.green
                                            }}
                                        >{currencyFormat(parseInt(adv?.price))} {adv?.fiatUnitType}</Typography>
                                    </Box>
                                    <Box sx={{
                                        height: 4
                                    }} />
                                </Box>
                                {/* END PAYMENT */}

                                {/* START I WANT TO PAY */}
                                <Box
                                    display={'flex'}
                                    flexDirection={p2pType == "BUY" ? 'column' : 'column-reverse'}
                                    sx={{
                                        width: '300px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            // backgroundColor: appColor.background,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            justifyItems: 'center'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey2,

                                            }}
                                        >Enter amount</Typography>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        sx={{
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                            border: `1px solid ${appColor.bgGrey}`,
                                            borderRadius: 2,
                                            padding: 2,
                                        }}>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            sx={{
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}
                                        >
                                            <NumericFormat
                                                customInput={TextField}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                id="outlined-basic"
                                                placeholder='Enter Amount'
                                                variant="standard"
                                                value={amountToPay}
                                                sx={{
                                                    width: '100%'
                                                }}
                                                onChange={handleChangeAmountToPay}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    endAdornment: <Box
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        sx={{
                                                            justifyContent: 'end',
                                                            justifyItems: 'end',
                                                            alignItems: 'center',
                                                            width: '100%', gap: 2,
                                                        }}
                                                    >
                                                        <Typography >{adv?.fiatUnitType}</Typography>
                                                        {p2pType == "BUY" && (
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: appColor.white,
                                                                    bgcolor: appColor.background,
                                                                    px: 1,
                                                                    borderRadius: 2,
                                                                    cursor: 'pointer'

                                                                }}
                                                                onClick={handleBuyAllUSDT}
                                                            >
                                                                {t('ALL')}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        height: 4
                                    }} />
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        sx={{
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                            border: `1px solid ${appColor.bgGrey}`,
                                            borderRadius: 2,
                                            padding: 2,
                                        }}>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            sx={{
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}
                                        >
                                            <NumericFormat
                                                customInput={TextField}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                id="outlined-basic"
                                                placeholder='0.00'
                                                variant="standard"
                                                value={amountReceive}
                                                sx={{
                                                    width: '100%'
                                                }}
                                                onChange={handleChangeAmountReceive}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    endAdornment: <Box
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        sx={{
                                                            // justifyContent: 'space-',
                                                            justifyContent: 'end',
                                                            justifyItems: 'end',
                                                            alignItems: 'center',
                                                            width: '100%', gap: 2,
                                                            // bgcolor: appColor.bgGrey,
                                                        }}
                                                    >
                                                        <Typography >{adv?.assetType}</Typography>
                                                        {p2pType == "SELL" && (
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: appColor.white,
                                                                    bgcolor: appColor.background,
                                                                    px: 1,
                                                                    borderRadius: 2,
                                                                    cursor: 'pointer'

                                                                }}
                                                                onClick={handleSellAllUSDT}
                                                            >
                                                                {t('ALL')}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                {/* END I WANT TO PAY */}
                            </Box>
                            {/*END RIGHT */}

                            <DialogActions>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    px: 3,
                                }}>
                                    <Button
                                        disabled={isLoading}
                                        size={isMobile ? "small" : "large"}
                                        onClick={() => {
                                            handleCreateP2PTransaction()
                                        }}
                                        sx={{
                                            width: '100%',
                                            color: appColor.background,
                                            backgroundColor: p2pType === "BUY" ? appColor.green : appColor.red,
                                            fontWeight: 'bold',
                                            paddingY: 1,

                                            // width: isMobile ? '100%' : 'none',
                                            ":hover": {
                                                backgroundColor: p2pType === "BUY" ? appColor.greenActive : appColor.redActive
                                            }
                                        }
                                        }>
                                        <Box sx={{
                                            width: 2,
                                            fontWeight: 'bold'
                                        }} />
                                        {isLoading ? <Spinner animation="border" role="status"></Spinner> : <span>{p2pType} USDT</span>}
                                    </Button>
                                </Box>
                            </DialogActions>
                        </Box>
                    </Box>
                </Box>
                {/* </DialogContent> */}

            </Dialog>
        </React.Fragment>
    )
}

export default P2PBuySellPopup
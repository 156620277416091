import React, { useEffect, useState } from 'react'
import { Box, Grid, ButtonGroup, Button, Avatar, AppBar, Typography, Menu, MenuItem, FormControl, Select } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faListAlt, faReceipt, faRefresh, faStore, faStoreAlt } from '@fortawesome/free-solid-svg-icons'
import CreateMcPagePopup from './mc/mc-page'
import { USER_LOCAL_DATA, appColor } from '../constants'
import { useLazyQuery } from '@apollo/client'
import { GET_MC_GQL, GET_MC_SETTING_GQL } from '../apollo/crypto'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { List, MoreVert } from '@mui/icons-material'
import Badge from '@mui/material/Badge';

const P2PNavMenu = ({
    p2pType,
    setP2PTypeHandle,
    isMobile,
    getAvs,
    p2pCusMeta,
    p2pMCMeta,
    fiatUnitType,
    setFiatUnitType,
    checkUserData
}) => {
    const { t, i18n } = useTranslation();
    const routeNavigate = useNavigate();

    const [myMC, setMyMC] = useState();
    const [userID, setUserID] = useState();


    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        getMyMC();
    }


    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (
                        parseData
                    ) {
                        setUserID(parseData?.user?._id);
                    }
                }

            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);

    const [getMyMC] = useLazyQuery(GET_MC_GQL, {
        variables: {
            userId: userID
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // console.log(`getMyMC data`, data)
            if (data.getMyMerchant.error == true) {
                console.log(`getMyMC error`, data.getMyMerchant.error);
            } else {
                setMyMC(data.getMyMerchant.data)
            }
        },
        onError: (error) => {
            console.log(`getMyMC error`, error)
        }
    })
    useEffect(() => {
        getMyMC()
    }, []);

    const handleChangeFiatUnitType = (event) => {
        setFiatUnitType(event.target.value);
    }

    return (
        <>
            <CreateMcPagePopup
                isMobile={isMobile}
                open={open}
                handleClose={handleClose}
            />
            <Box
                position={'sticky'}
                display={'flex'}
                flexDirection={'row'}
                sx={{
                    top: 60,
                    zIndex: 2,
                    paddingY: '10px',
                    boxShadow: 3,
                    justifyContent: 'space-between',
                    backgroundColor: appColor.background,
                    paddingX: isMobile ? 1 : 25,
                    width: '100%',
                }}
            >


                <ButtonGroup
                    size={isMobile ? 'small' : 'large'}
                    variant="outlined"
                    aria-label="Basic button group"

                >
                    <Button
                        size={isMobile ? 'small' : 'large'}
                        sx={{
                            px: 2,
                            backgroundColor: p2pType == "BUY" ? appColor.green : 'transparent',
                            border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                            color: p2pType == "BUY" ? appColor.white : appColor.textGrey,
                            ":hover": {
                                backgroundColor: p2pType == "BUY" ? appColor.greenActive : 'transparent',
                                border: `0.5px solid ${appColor.green}`,
                            }

                        }}
                        onClick={() => setP2PTypeHandle("BUY")}

                    >{t("BUY")}</Button>
                    <Button
                        size={isMobile ? 'small' : 'large'}
                        sx={{
                            px: 2,
                            backgroundColor: p2pType == "SELL" ? appColor.red : 'transparent',
                            border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                            color: p2pType == "SELL" ? appColor.white : appColor.textGrey,
                            ":hover": {
                                backgroundColor: p2pType == "SELL" ? appColor.redActive : 'transparent',
                                border: `0.5px solid ${appColor.red}`,
                            }
                        }}
                        onClick={() => setP2PTypeHandle("SELL")}
                    > {t("SELL")}</Button>
                    <Box width={10} />
                    {/* <FormControl sx={{ m: 1, border: '1px solid ' + appColor.yellowActive, borderRadius: 2 }}> */}
                    <Select
                        sx={{
                            height: isMobile ? 30 : 40,
                            border: '1px solid ' + appColor.yellowActive,
                        }}
                        value={fiatUnitType}
                        onChange={handleChangeFiatUnitType}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={"LAK"}>LAK</MenuItem>
                        <MenuItem value={"THB"}>THB</MenuItem>
                        <MenuItem value={"USD"}>USD</MenuItem>
                        <MenuItem value={"KHR"}>KHR</MenuItem>
                    </Select>
                    {/* </FormControl> */}

                </ButtonGroup>

                {/* <Box sx={{
                        width: isMobile ? 0 : 10,
                    }} /> */}

                {/* START BECOME MERCHANT */}

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2
                    }}>

                    <Badge badgeContent={p2pCusMeta?.countCusTransPending} color="warning">
                        {/* <List color="action" /> */}
                        <Button
                            size={isMobile ? 'small' : 'large'}
                            sx={{
                                height: isMobile ? 30 : 40,
                                color: appColor.white,
                                border: '1px solid ' + appColor.yellowActive,
                                // backgroundColor: appColor.bgDark,
                                // ":hover": {
                                //     backgroundColor: appColor.bgDark,
                                // },
                            }}
                            onClick={async () => {
                                const _checkUserData = await checkUserData()
                                if (_checkUserData == true) routeNavigate("/p2p-my-order")
                            }}
                        >

                            <FontAwesomeIcon
                                icon={faReceipt}
                                style={{ fontSize: isMobile ? 18 : 25, color: appColor.yellowActive }}
                            />
                        </Button>
                    </Badge>

                    {myMC ? <Badge badgeContent={p2pMCMeta?.countMCTransPending} color='warning'>
                        <Button
                            size={isMobile ? 'small' : 'large'}
                            sx={{
                                height: isMobile ? 30 : 40,
                                color: appColor.white,
                                backgroundColor: appColor.orange,
                                ":hover": {
                                    backgroundColor: appColor.orangeActive,
                                },
                            }}
                            onClick={async () => {
                                const _checkUserData = await checkUserData()
                                if (_checkUserData == true) routeNavigate('/p2p-order-request')
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faStoreAlt}
                                style={{ fontSize: isMobile ? 18 : 25, color: appColor.white }}
                            />
                        </Button>
                        {/* routeNavigate('/p2p-order-request') */}
                    </Badge> : <>
                        <Button
                            size={isMobile ? 'small' : 'large'}
                            sx={{
                                height: isMobile ? 30 : 40,
                                display: "flex",
                                flexDirection: "row",
                                color: appColor.white,
                                backgroundColor: appColor.orange,
                                justifyContent: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                gap: 0.5,
                                ":hover": {
                                    backgroundColor: appColor.orangeActive,
                                },
                            }}
                            onClick={async () => {
                                const _checkUserData = await checkUserData()
                                if (_checkUserData == true) handleClickOpen()
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faStore}
                                style={{ fontSize: isMobile ? 20 : 25, color: appColor.white }}
                            />
                            {isMobile ? "" : <Typography variant="caption" color="white">
                                {t("Become merchant")}
                            </Typography>}


                        </Button></>}
                    {myMC ? <>
                        <MoreVert
                            sx={{
                                color: appColor.textGrey,

                            }}
                            onClick={handleMenuClick}
                        />

                        {/* <Button
                                size={isMobile ? 'small' : 'large'}
                                id="demo-customized-button"
                                aria-controls={open ? 'demo-customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                // variant="contained"
                                disableElevation
                                onClick={handleMenuClick}
                                endIcon={<KeyboardArrowDownIcon />}
                            >
                                {t("More")}
                            </Button> */}
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {/* <MenuItem onClick={handleMenuClose}>{t("Payment Method")}</MenuItem> */}
                            {/* <MenuItem onClick={handleMenuClose}>{t("Post New Ads")}</MenuItem> */}
                            {/* <MenuItem
                                sx={{
                                    // color: appColor.white,
                                    // backgroundColor: appColor.orange,
                                    // ":hover": {
                                    //     backgroundColor: appColor.orangeActive,
                                    // },
                                }}
                                onClick={
                                    () => {
                                        routeNavigate('/p2p-order-request')
                                        handleMenuClose()
                                    }
                                }>{t("P2P Order Request")}</MenuItem> */}

                            {/* <MenuItem
                                sx={{
                                    // color: appColor.white,
                                    // backgroundColor: appColor.orange,
                                    // ":hover": {
                                    //     backgroundColor: appColor.orangeActive,
                                    // },
                                }}
                                onClick={
                                    () => {
                                        routeNavigate("/p2p-my-order");
                                        handleMenuClose()
                                    }
                                }>{t("Orders")}
                                </MenuItem> */}

                            <MenuItem
                                sx={{
                                    // color: appColor.white,
                                    // backgroundColor: appColor.orange,
                                    // ":hover": {
                                    //     backgroundColor: appColor.orangeActive,
                                    // },
                                }}
                                onClick={async () => {
                                    const _checkUserData = await checkUserData()
                                    if (_checkUserData == true) {
                                        routeNavigate('/p2p-my-adv')
                                        handleMenuClose()
                                    }
                                }}>{t("My ads")}</MenuItem>
                        </Menu>
                    </> : <>
                    </>}
                </Box>
            </Box>
        </>

    )
}

export default P2PNavMenu
import React from 'react'
import { appColor } from '../../constants'
import { Typography, Divider, useMediaQuery, Box, Button, ButtonGroup, Avatar } from '@mui/material'
import P2PRequestItemContentDisplay from './item-content-display'
import { Message } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { appFormatDateTime, currencyFormat } from '../../utils'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
const P2PRequestItem = ({ p2pType, p2p, myMC, onClick }) => {
    const routeNavigate = useNavigate();
    const { t, i18n } = useTranslation();
    return (
        <Box
            onClick={onClick}
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                paddingX: 2,
                paddingY: 1,
                cursor: "pointer",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <Typography
                        variant='body1'
                        sx={{
                            fontWeight: "bold",
                            color: p2pType == "BUY" ? appColor.textGreenActive : appColor.red,
                        }}>
                        {p2pType}
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            fontWeight: "bold",
                            color: appColor.textGrey,
                        }}>
                        {p2p?.assetType}
                    </Typography>
                </Box>

                {
                    myMC?.user === p2p?.merchant ?
                        <>
                            <Button
                                size='small'
                                sx={{
                                    backgroundColor: appColor.textGrey,
                                    color: appColor.white,
                                    fontWeight: 'bold',
                                    backgroundColor: p2p?.merchantConfirmType == "APPROVED" ? appColor.greenActive : (p2p.merchantConfirmType == "CANCELLED" ? appColor.red : appColor.orange),
                                    // ":hover": {
                                    //     backgroundColor: appColor.textGrey,
                                    //     // backgroundColor: p2pType == "BUY" ? appColor.greenActive : 'transparent',
                                    //     // border: `0.5px solid ${appColor.green}`,
                                    // }

                                }}
                            >{p2p?.merchantConfirmType}
                            </Button>
                        </> :
                        <>
                            <Button
                                size='small'
                                sx={{
                                    color: appColor.white,
                                    fontWeight: 'bold',
                                    backgroundColor: p2p.customerConfirmType == "APPROVED" ? appColor.greenActive : (p2p.customerConfirmType == "CANCELLED" ? appColor.red : appColor.orange),
                                    // border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                                    color: appColor.white,// p2pType == "BUY" ? appColor.white : appColor.textGrey,
                                    // ":hover": {
                                    //     backgroundColor: appColor.textGrey,
                                    //     // backgroundColor: p2pType == "BUY" ? appColor.greenActive : 'transparent',
                                    //     // border: `0.5px solid ${appColor.green}`,
                                    // }

                                }}
                            >{p2p?.customerConfirmType}
                            </Button>
                        </>

                }




            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Typography
                    variant='body2'
                    sx={{
                        color: appColor.textGrey,
                    }}>
                    {t("Fiat amount")}
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        fontWeight: "bold",
                        color: appColor.textGrey,
                    }}>
                    {p2p?.fiatUnitType}  {currencyFormat(parseFloat(p2p?.fiatAmount))}
                </Typography>
            </Box>
            <P2PRequestItemContentDisplay
                title={t("Price")}
                value={`${p2p?.fiatUnitType} ${currencyFormat(parseFloat(p2p?.price))}`}
            />
            <P2PRequestItemContentDisplay
                title={t("Crypto amount")}
                value={`${p2p?.assetType} ${currencyFormat(parseFloat(p2p?.amount))}`}
            />
            <P2PRequestItemContentDisplay
                title={t("Order")}
                value={p2p?._id}
                isCopy={true}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1
                    }}
                >

                    <Typography
                        variant='body1'
                        sx={{
                            color: appColor.textGrey,
                        }}>
                        {myMC?.merchantName}
                    </Typography>
                    <Message
                        onClick={() => routeNavigate(`/chat/${p2p?._id}`)}
                        sx={{
                            cursor: "pointer",
                            color: appColor.textGrey,
                            fontSize: "0.8rem"
                        }}
                    />

                </Box>
                <Typography
                    variant='body2'
                    sx={{
                        color: appColor.textGrey,
                    }}>
                    {moment.unix(p2p.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                </Typography>
            </Box>
            <Divider
                sx={{
                    width: "100%",
                }}
                color={appColor.textGrey}
            />
        </Box>
    )
}

export default P2PRequestItem
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { appColor } from "../constants";

const AppPopup = ({ children, isShowPopup, handdlePopup, width, height, style }) => {
  return (
    <div
      style={{
        ...style,
        backgroundColor: appColor.background,
        display: isShowPopup ? "flex" : "none",
        flexDirection: "column",
        width: "100vw",
        minHeight: "100vh",
        top: 0,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          width: width ?? 800,
          minHeight: height ?? 600,
          backgroundColor: appColor.darkColor,
          borderRadius: 8,
          padding: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          color: "#fff"
        }}
      >
        {children}
      </div>
      <div style={{ height: 10 }} />
      <div
        onClick={handdlePopup}
        style={{
          width: 50,
          height: 50,
          borderRadius: 50,
          backgroundColor: appColor.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon
          icon={faClose}
          style={{ color: "#fff", fontSize: 22 }}
        />
      </div>
    </div>
  );
};

export default AppPopup;

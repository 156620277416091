import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { END_POINT_SEVER } from "../../api";
import { useParams } from "react-router-dom";
import axios from "axios";
import { appColor } from "../../constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomNavbar from "../navbar/Navbar";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

export default function VipByMember() {
	const { id } = useParams();
	const [type, setType] = useState("All");
	const [time, setTime] = useState("7days");
	const [vipSearchData, setVipSearchData] = useState([]);
	const { t, i18n } = useTranslation();

	// Dashboard
	const [earnMoneyUp, setEarnMoneyUp] = useState([]);
	const [earnMoneyDown, setEarnMoneyDown] = useState([]);
	const [creditMoneyAdd, setCreditMoneyAdd] = useState([]);
	const [creditMoneyWithdraw, setCreditMoneyWithdraw] = useState([]);
	const [p2pBuy, setP2pBuy] = useState([]);
	const [p2pSell, setP2pSell] = useState([]);

	useEffect(() => {
		if (id) getUserEarnDataSearch(id, type, time);
	}, []);

	const getUserEarnDataSearch = async (userId, type, time) => {
		try {
			var config = {
				method: "get",
				url: `${END_POINT_SEVER}/api/vip-earn-history/search?to=${userId}&vipType=${type}&time=${time}`,
				headers: {
					"Content-Type": "application/json",
				},
			};
			const userHandle = await axios(config);
			if (userHandle?.data) {
				// console.log("userHandle?.data: ", userHandle?.data?.vipEarnHistory);
				setVipSearchData(userHandle?.data?.vipEarnHistory);

				const moneyUp = userHandle?.data?.earnMoneyResult?.filter(
					(item) => item.tradeType === "BUY",
				);

				const moneyDown = userHandle?.data?.earnMoneyResult?.filter(
					(item) => item.tradeType === "SELL",
				);

				const creditAdd = userHandle?.data?.creditMoneyResult?.filter(
					(item) => item.type === "ADD",
				);

				const creditWithdraw = userHandle?.data?.creditMoneyResult?.filter(
					(item) => item.type === "WITHDRAW",
				);

				const p2pBuy = userHandle?.data?.p2p?.filter(
					(item) => item._id === "BUY",
				);

				const p2pSell = userHandle?.data?.p2p?.filter(
					(item) => item._id === "SELL",
				);

				// Set the Data

				setEarnMoneyUp(moneyUp);
				setEarnMoneyDown(moneyDown);
				setCreditMoneyAdd(creditAdd);
				setCreditMoneyWithdraw(creditWithdraw);
				setP2pBuy(p2pBuy);
				setP2pSell(p2pSell);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const onTypeChange = async (_type) => {
		try {
			setType(_type);
			getUserEarnDataSearch(id, _type, time);
		} catch (error) {
			console.log(error);
		}
	};
	const onTimeChange = async (_time) => {
		try {
			setTime(_time);
			getUserEarnDataSearch(id, type, _time);
		} catch (error) {
			console.log(error);
		}
	};

	function currencyFormat(num) {
		return num ? num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
	}
	function currencyFormatNotFixed(num) {
		return num ? num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
	}

	useEffect(() => {
		if (earnMoneyUp) {
			console.log(earnMoneyUp, "Money Up");
		}
	}, [earnMoneyUp]);

	const [isMobile, setIsMobile] = useState(false);
	const theme = useTheme();
	const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		// console.log('isMobileSize :>> ', isMobileSize);
		setIsMobile(isMobileSize);
		// if (isMobile==true) {
		//   console.log("isMobile");
		//   kLineChart.current?.setStyles(
		//     {
		//       candle: {
		//         tooltip: {
		//           showRule: "none",
		//         }
		//       }
		//     });
		// } else {
		//   console.log("isDesktop");
		//   kLineChart.current?.setStyles(
		//     {
		//       candle: {
		//         tooltip: {
		//           showRule: "none",
		//         }
		//       }
		//     });
		// }
	}, [isMobileSize, isMobile, window.screen.width, window.screen.height]);

	return (
		<>
			<CustomNavbar />

			<div
				className="color-body"
				style={{
					minHeight: "100vh",
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
					color: "#fff",
					padding: isMobile ? 2 : 100,
				}}
			>
				<div
					style={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						paddingTop: 8,
					}}
				>
					<div
						style={{
							width: "100%",
							fontSize: 13,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "start",
							backgroundColor: appColor.bgDark,
							borderRadius: 5,
							padding: 8,
						}}
					>
						<div>{t("SearchBy")}</div>
						<div
							style={{
								width: "100%",
								fontSize: 13,
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<select
								onChange={(e) => onTypeChange(e.target.value)}
								value={type}
								style={{
									backgroundColor: appColor.background,
									textAlign: "center",
									borderRadius: 30,
									height: 30,
									width: "70%",
									color: "#fff",
									paddingLeft: 10,
									paddingRight: 10,
								}}
							>
								<option value={"All"}>{t("Total")}</option>
								<option value={"A1"}>A1</option>
								<option value={"A2"}>A2</option>
								<option value={"A3"}>A3</option>
							</select>
							<div style={{ height: 10 }} />
							<select
								onChange={(e) => onTimeChange(e.target.value)}
								value={time}
								style={{
									backgroundColor: "rgba(0,0,0,0.5)",
									textAlign: "center",
									borderRadius: 30,
									height: 30,
									width: "70%",
									color: "#fff",
									paddingLeft: 10,
									paddingRight: 10,
								}}
							>
								<option value={"7days"}>7 {t("DayAgo")}</option>
								<option value={"1month"}>1 {t("MonthAgo")}</option>
								<option value={"3months"}>3 {t("MonthAgo")}</option>
							</select>
							<div style={{ height: 20 }} />
							{/* <button
                            style={{
                                color: "#fff",
                                backgroundColor: "rgb(95,182,120)",
                                borderWidth: 0,
                                borderRadius: 30,
                                height: 30,
                                width: "70%",
                                textAlign: "center",
                            }}
                        >
                            <FontAwesomeIcon icon={faSearch} style={{ color: "#fff", fontSize: 16 }} />&nbsp;&nbsp;{t("Search")}
                        </button> */}
							<div style={{ height: 10 }} />
						</div>
					</div>

					<div style={{ height: 30 }} />

					<div className="vip-page-dashboard">
						<div className="vip-page-dashboard-item">
							<h4>Money Earn</h4>
							<div className="red-green-card">
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										width: "100%",
										height: "100%",
										padding: 10,
										// border: "1px solid rgb(95,182,120)",
									}}
								>
									<h5 style={{ padding: "20px 0", color: "rgb(95,182,120)" }}>
										Up
										<br />
										{currencyFormat(
											earnMoneyUp?.reduce(
												(acc, item) => acc + item.totalEarn,
												0,
											),
										)}{" "}
										$
									</h5>
									<div>
										{earnMoneyUp?.map((item, index) => (
											<div
												key={index}
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													// fontSize: 10,
												}}
											>
												<p>{item?.binary}</p>
												<p>{currencyFormat(item?.totalEarn)} $</p>
											</div>
										))}
									</div>
								</div>
								<div>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "flex-start",
											width: "100%",
											height: "100%",
											padding: 10,
											// border: "1px solid #a90000",
										}}
									>
										<h5 style={{ padding: "20px 0", color: "#a90000" }}>
											Down
											<br />
											{currencyFormat(
												earnMoneyDown?.reduce(
													(acc, item) => acc + item.totalEarn,
													0,
												),
											)}{" "}
											$
										</h5>
										<div>
											{earnMoneyDown?.map((item, index) => (
												<div
													key={index}
													style={{
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
														// fontSize: 10,
													}}
												>
													<p>{item?.binary}</p>
													<p>{currencyFormat(item?.totalEarn)} $</p>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="vip-page-dashboard-item">
							<h4>Credit Money</h4>
							<div className="red-green-card">
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										width: "100%",
										height: "100%",
										padding: 10,
									}}
								>
									<h5 style={{ padding: "20px 0", color: "rgb(95,182,120)" }}>
										Deposit
										<br />
										{currencyFormat(
											creditMoneyAdd.reduce(
												(acc, item) => acc + item.totalMoney,
												0,
											),
										)}{" "}
										$
									</h5>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										width: "100%",
										height: "100%",
										padding: 10,
									}}
								>
									<h5 style={{ padding: "20px 0", color: "#a90000" }}>
										Withdraw
										<br />
										{currencyFormat(
											creditMoneyWithdraw.reduce(
												(acc, item) => acc + item.totalMoney,
												0,
											),
										)}{" "}
										$
									</h5>
								</div>
							</div>
						</div>
						<div className="vip-page-dashboard-item">
							<h4>P2P</h4>
							<div className="red-green-card">
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										width: "100%",
										height: "100%",
										padding: 10,
									}}
								>
									<h5 style={{ padding: "20px 0", color: "rgb(95,182,120)" }}>
										Buy
										<br />
										{currencyFormat(
											p2pBuy.reduce((acc, item) => acc + item.totalMoney, 0),
										)}{" "}
										$
									</h5>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										width: "100%",
										height: "100%",
										padding: 10,
									}}
								>
									<h5 style={{ padding: "20px 0", color: "#a90000" }}>
										Sell
										<br />
										{currencyFormat(
											p2pSell.reduce((acc, item) => acc + item.totalMoney, 0),
										)}{" "}
										$
									</h5>
								</div>
							</div>
						</div>
					</div>
					<div style={{ height: 30 }} />
					<div
						style={{
							width: "100%",
							fontSize: 13,
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-around",
							alignItems: "center",
							backgroundColor: appColor.bgDark,
							height: 30,
							borderRadius: 5,
						}}
					>
						{/* <div style={{ width: "30%" }}>{t("UserName")}</div>
					<div style={{ width: "16%" }}>{t("Amount")}</div>
					<div style={{ width: "27%" }}>{t("Volumn")}</div>
					<div style={{ width: "22%" }}>{t("Commission")}</div> */}
						<div style={{ flex: 1 }}>{t("UserName")}</div>
						<div style={{ flex: 1 }}>{t("inviter")}</div>
						<div style={{ flex: 1 }}>{t("inviteAt")}</div>
						<div style={{ flex: 1 }}>VIP</div>
					</div>
					{vipSearchData?.length > 0 &&
						vipSearchData?.map((item, index) => (
							<div key={index} style={{ width: "100%" }}>
								<div style={{ height: 5 }} />
								<div
									style={{
										width: "100%",
										fontSize: 13,
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
										alignItems: "center",
										backgroundColor: appColor.bgDark,
										height: 30,
										borderRadius: 5,
									}}
								>
									<div style={{ flex: 1 }}>
										{/* {item?.from[0]?.realFirstName ?? item?.from[0]?.fullName} */}
										{item?.fullName}
									</div>
									<div style={{ flex: 1 }}>{item?.vipS1?.fullName}</div>
									<div style={{ flex: 1 }}>
										{moment(item?.createdAt).format("DD/MM/YYYY")}
									</div>
									<div style={{ flex: 1 }}>
										{id === item?.vipS1?._id
											? "A1"
											: id === item?.vipS2
												? "A2"
												: id === item?.vipS3
													? "A3"
													: "-"}
									</div>
									{/* <div style={{ width: "16%" }}>
									{currencyFormatNotFixed(item?.count ?? 0)}
								</div>
								<div style={{ width: "27%" }}
									{currencyFormat(item?.tradePrice ?? 0)}$
								</div>
								<div style={{ width: "22%" }}>
									{currencyFormat(item?.price ?? 0)}$
								</div> */}
								</div>
							</div>
						))}
				</div>
			</div>
		</>
	);
}

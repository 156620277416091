import React, { useEffect, useState } from 'react'
import CustomNavbar from '../navbar/Navbar'
import { appColor, screenSize, SEARCH_GOOGLE_IMAGE } from '../../constants'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, tableCellClasses, useMediaQuery } from '@mui/material'
import styled from '@emotion/styled';
import { FactCheckOutlined } from '@mui/icons-material';
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api';
import axios from 'axios';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { faCheckCircle, faCircleXmark, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingPage from '../../common/loadingPage';

export default function AdminVipList() {
    const routeNavigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    //  GRID STTYLE
    // const Item = styled(Paper)(({ theme }) => ({
    //     backgroundColor: appColor.bgDark,
    //     // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    //     // ...theme.typography.body2,
    //     padding: theme.spacing(1),
    //     textAlign: 'center',
    //     boxShadow: "none",
    //     color: appColor.gray,
    // }));

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,

        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,

        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        "&:hover": {
            backgroundColor: `${appColor.gray} !important`,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    // FETCH DATA FROM API
    const [users, setUsers] = useState([]);

    const [vipStatus, setVipStatus] = useState("REQUESTING");
    const [nationalNumber, setNationalNumber] = useState("");
    const [vipCode, setVipCode] = useState("");
    const [userName, setUserName] = useState("");


    const clearFilter = () => {
        setVipStatus("ALL");
        setNationalNumber("");
        setVipCode("");
        setUserName("");
        getAllVIPUsers("");
    }
    const getAllVIPUsers = async () => {
        try {
            setIsLoading(true)
            let queryData = `?`
            if (vipStatus) queryData += `vipStatus=${vipStatus}`
            if (nationalNumber) queryData += `&cardNo=${nationalNumber}`
            if (userName) queryData += `&userName=${userName}`
            if (vipCode) queryData += `&vipCode=${vipCode}`
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${queryData}&skip=0&limit=1500&orderBy=updatedAt`,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const userRes = await axios(config);
            // console.log('userRes :>> ', userRes?.data?.users);
            if (userRes?.data) {
                setUsers(userRes?.data?.users);
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    };
    useEffect(() => {
        getAllVIPUsers();
    }, [vipStatus]);


    // FETCH DATA FROM API

    // Data for tables
    function createData(_id, fullName, phone, vipStatus, image, username, email) {
        return { _id, fullName, phone, vipStatus, image, username, email };
    }
    // const rows = [
    //     createData("bountherng1", "phone", "vipStatus", "image", "username", "email"),
    // ]
    const rows = users.map((user) => {
        return createData(user._id, user.fullName, user.phone, user.vipStatus, user.image, user.username, user.email);
    });

    // filter status
    const vipStatusList = ["REQUESTING", "VERIFIED", "REJECTED"]

    const [isMobile, setIsMobile] = useState(false);
    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize, isMobile, window.screen.width, window.screen.height]);

    const goToRequestingDetail = async (id) => {
        try {
            routeNavigate(`/admin-vip-detail/${id}`);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='color-body' style={{ width: "100%", minHeight: "100dvh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <CustomNavbar />
            <div style={{ height: screenSize().width > 700 ? 90 : 80 }} />
            <Typography variant='h4'>VIP Management</Typography>
            <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                    px: 10,
                    // py: 4,
                    backgroundColor: appColor.bgDark,
                    gap: 2
                }}
            >
                {/* DROPDOWN */}
                <Grid container>
                    <Grid item xs={3} >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            sx={{
                                justifyItems: "start",
                                alignItems: "start",
                                p: 1
                            }}
                        >
                            <Typography>VIP Status</Typography>
                            <FormControl>
                                <Select
                                    fullWidth
                                    labelId="status-user"
                                    id="status-user-select"
                                    sx={{
                                        padding: 0,
                                        color: "#FFF",
                                        height: 40,
                                        width: 300,
                                        textAlign: "center",
                                        border: `${"1px solid " + appColor.gray}`,
                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                        {
                                            color: 'white',
                                        },
                                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                            color: 'white',
                                        },
                                    }}
                                    value={vipStatus}
                                    onChange={(event) => setVipStatus(event.target.value)}
                                >
                                    <MenuItem value="ALL">
                                        <Typography variant="body1" fontWeight={"bold"}>
                                            All Status
                                        </Typography>
                                    </MenuItem>
                                    {vipStatusList && vipStatusList.map((status, index) => {
                                        return (
                                            <MenuItem key={index} value={status}>
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    {status}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Box>

                    </Grid>
                    <Grid item xs={3} >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            sx={{
                                justifyItems: "start",
                                alignItems: "start",
                                p: 1
                            }}
                        >
                            <Typography>National Number</Typography>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: "center",
                                        color: "#FFFFFF ",
                                        fontWeight: "bold",
                                        padding: 8,
                                        margin: 0,
                                        border: `${"1px solid " + appColor.gray}`,
                                        borderRadius: 5,
                                    },
                                }}
                                value={nationalNumber}
                                onChange={(event) => setNationalNumber(event.target.value)}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            sx={{
                                justifyItems: "start",
                                alignItems: "start",
                                p: 1
                            }}
                        >
                            <Typography>Email</Typography>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: "center",
                                        color: "#FFFFFF ",
                                        fontWeight: "bold",
                                        padding: 8,
                                        margin: 0,
                                        border: `${"1px solid " + appColor.gray}`,
                                        borderRadius: 5,
                                    },
                                }}
                                value={userName}
                                onChange={(event) => setUserName(event.target.value)}

                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            sx={{
                                justifyItems: "start",
                                alignItems: "start",
                                p: 1
                            }}
                        >
                            <Typography>VIP Code</Typography>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                inputProps={{
                                    style: {
                                        textAlign: "center",
                                        color: "#FFFFFF ",
                                        fontWeight: "bold",
                                        padding: 8,
                                        margin: 0,
                                        border: `${"1px solid " + appColor.gray}`,
                                        borderRadius: 5,
                                    },
                                }}
                                value={vipCode}
                                onChange={(event) => setVipCode(event.target.value)}

                            />
                        </Box>
                    </Grid>

                </Grid>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    gap={2}
                    sx={{
                        justifyContent: "end",
                        p: 2
                    }}
                >
                    <Button
                        size='small'
                        variant={"container"}
                        style={{
                            color: appColor.white,
                            fontWeight: "bold",
                            fontSize: isMobile ? "12px" : "18px"
                        }}
                        sx={{
                            width: "20%",
                            color: "white",
                            borderRadius: "30px",
                            backgroundColor: appColor.background,
                            "&:hover": {
                                backgroundColor: appColor.primary,
                            },
                        }}
                        onClick={() => clearFilter()}
                    >
                        Clear
                    </Button>
                    <Button
                        size='small'
                        variant={"container"}
                        style={{
                            color: appColor.primary,
                            fontWeight: "bold",
                            fontSize: isMobile ? "12px" : "18px"
                        }}
                        sx={{
                            width: "20%",
                            color: "white",
                            borderRadius: "30px",
                            backgroundColor: appColor.white,
                            "&:hover": {
                                backgroundColor: appColor.gray,
                            },
                        }}
                        onClick={() => getAllVIPUsers()}
                    >
                        Search
                    </Button>
                </Box>
            </Box>

            <Box sx={{ overflow: "auto", px: 2, py: 4 }}>
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                    <TableContainer component={Paper}>
                        {isLoading ?
                            <div className='color-body' style={{ width: "100vw", minHeight: "40vh" }}>
                                <LoadingPage />
                            </div>
                            :
                            <Table sx={{ minWidth: "95vw" }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        {/* */}
                                        <StyledTableCell>#</StyledTableCell>
                                        <StyledTableCell>Image</StyledTableCell>
                                        <StyledTableCell>Full name</StyledTableCell>
                                        <StyledTableCell>Phone</StyledTableCell>
                                        <StyledTableCell>Status</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center">{index + 1}</StyledTableCell>
                                            <StyledTableCell>
                                                <img src={row.image && row?.image.includes(SEARCH_GOOGLE_IMAGE) ? row?.image : `${S3_BUCKET_END_POINT}/images/${row.image}`} style={{ width: 40, height: 40, borderRadius: 8 }} />
                                            </StyledTableCell>
                                            <StyledTableCell>{row.fullName}</StyledTableCell>
                                            <StyledTableCell>{row.phone}</StyledTableCell>
                                            <StyledTableCell>
                                                <div
                                                    style={{
                                                        border: `1px solid ${row.vipStatus == "VERIFIED" ? "rgb(95, 182, 120)" : row.vipStatus == "REJECTED" ? "#e34828" : "orange"}`,
                                                        paddingLeft: 8,
                                                        paddingRight: 8,
                                                        borderRadius: 20,
                                                        paddingTop: 2,
                                                        width: 130,
                                                        color: row.vipStatus == "VERIFIED" ? "rgb(95, 182, 120)" : row.vipStatus == "REJECTED" ? "#e34828" : "orange"
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={row.vipStatus == "VERIFIED" ? faCheckCircle : row.vipStatus == "REJECTED" ? faCircleXmark : faClock} /> {row.vipStatus}
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell>{row.email}</StyledTableCell>
                                            <StyledTableCell sx={{ cursor: "pointer" }}>
                                                <div style={{ width: 40, height: 40, border: "1px solid #b6b6b6", borderRadius: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <FactCheckOutlined style={{ color: "#1e1ee6" }} onClick={() => goToRequestingDetail(row._id)} />
                                                </div>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>}
                    </TableContainer>
                </Box>
            </Box>
        </div>
    )
}

// import React, { useState, useEffect } from 'react';

export const WEB_VERSION = "v1.0.3";
export const USER_LOCAL_DATA = "BotWeb@2023";
export const INDICATOR_MAIN = "IDC_MAIN";
export const INDICATOR_SUB = "IDC_SUB";
export const SEARCH_GOOGLE_IMAGE = "https://lh3.googleusercontent.com";
export const GOOGLE_CLIENT_ID = "975267526030-9mc8n3pce11ape8veoju71qt9pngrle8.apps.googleusercontent.com";
export const FACEBOOK_APP_ID = "350323347441144";
export const appColor = {
  primary: "#1e1f23",
  background: "#2a2b30",
  bgDark: "#1e1f23",
  bgDarkSecond: "#1d1d1e",
  bgDark1: "#2a2b30",
  darkColor2: "#222222",
  bgNoti: "#101011",
  green: "#2ebd85",
  yellow: "#FFDD3D",
  greenActive: "#29ad78",
  greenTrade: "#01b22d",
  yellowActive: "#FFD700",
  red: "#f7475c",
  redActive: "#db394b",
  white: "#fff",
  black: "#000",
  gray: "#f0f0f0",
  grayOpacity: "rgba(0, 0, 0, 0.8)",
  warning: "#f5a623",
  info: "#4a90e2",
  infoActive: "#3b7dd8",
  darkColor: "#1e1d29",
  textGreenActive: "#2ebd85",
  textRedActive: "#f7475c",
  textGrey: "#818287",
  textWhite: "#ececec",
  textGrey2: "#7c7c7c",
  bgGrey: "#DCDCDC",
  orange: "#ff9800",
  orangeActive: "#ed8a00",
  orangeBackground: "#FAE5D3",
};

export const screenSize = () => {
  let size = {
    width: window.innerWidth,
    height: window.innerHeight
  }

  return size;
}
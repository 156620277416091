
export const getDataFromLocalStorage = ({key}) => {
    // get data from local storage
    const data = localStorage.getItem(key);
    
    // check if data is an object
    if (typeof data === "object") {
        return JSON.parse(data);
    }
    return data;
}
export const setDataToLocalStorage = ({key, value}) => {
    // check if value is an object
    if (typeof value === "object") {
        value = JSON.stringify(value);
    }
    // set data to local storage
    localStorage.setItem(key, value);
}
import React, { useEffect, useState } from 'react'
import CustomNavbar from '../navbar/Navbar'
import { USER_LOCAL_DATA, appColor, screenSize } from '../../constants'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api';
import { Button, Image, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faRankingStar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material'
import IDImageTemplate from '../../images/card-template/ID-Image.png'
import FrontImageTemplate from '../../images/card-template/ID-Card1.png'
import BackImageTemplate from '../../images/card-template/ID-Card2.png'
import SelfieImageTemplate from '../../images/card-template/ID-Card3.png'
import LoadingPage from '../../common/loadingPage';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function RequestVip() {
    const routeNavigate = useNavigate();
    const { t } = useTranslation();
    const [requestFail, setRequestFail] = useState("");
    const [realFirstName, setRealFirstName] = useState();
    const [realLastName, setRealLastName] = useState("");
    const [image, setImage] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [birtday, setBirtday] = useState("");
    const [cardType, setCardType] = useState("SELECT");
    const [cardNo, setCardNo] = useState("");
    const [cardOpenDate, setCardOpenDate] = useState("");
    const [cardEndDate, setCardEndDate] = useState("");
    const [cardImageFront, setCardImageFront] = useState("");
    const [cardImageBack, setCardImageBack] = useState("");
    const [cardImagePerson, setCardImagePerson] = useState("");
    const [userData, setUserData] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (parseData["user"] && parseData["user"]["_id"]) {
                        getUserData(parseData["user"]["_id"]);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);

    const getUserData = async (userId) => {
        try {
            setIsLoading(true)
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                let _fullname = userHandle.data?.user?.fullName?.split(" ") ?? ""
                setUserData(userHandle.data?.user);
                setRealFirstName(userHandle.data?.user?.realFirstName ?? (_fullname[0] ?? ""))
                setRealLastName(userHandle.data?.user?.realLastName ?? (_fullname?.length > 1 ? _fullname[1] : ""))
                setImage(userHandle.data?.user?.image ?? "")
                setPhone(userHandle.data?.user?.phone ?? "")
                setEmail(userHandle.data?.user?.email ?? (userHandle.data?.user?.userName ?? ""))
                setBirtday(userHandle.data?.user?.birtday ? moment(userHandle.data?.user?.birtday).format("YYYY-MM-DD") : "")
                setCardType(userHandle.data?.user?.cardType ?? `---${t("DocumentTpye")}---`)
                setCardNo(userHandle.data?.user?.cardNo ?? "")
                setCardOpenDate(userHandle.data?.user?.cardOpenDate ? moment(userHandle.data?.user?.cardOpenDate).format("YYYY-MM-DD") : "")
                setCardEndDate(userHandle.data?.user?.cardEndDate ? moment(userHandle.data?.user?.cardEndDate).format("YYYY-MM-DD") : "")
                setCardImageFront(userHandle.data?.user?.cardImageFront ?? "")
                setCardImageBack(userHandle.data?.user?.cardImageBack ?? "")
                setCardImagePerson(userHandle.data?.user?.cardImagePerson ?? "")
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    };

    const requestPresignedUrlAndUploadImageToS3 = async (event, cardCategory) => {
        try {
            const fileData = event?.target?.files[0];

            if (fileData) {
                let fileName = fileData.name.replaceAll(" ", "")
                // TODO: Request Presigned Url
                var config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${END_POINT_SEVER}/api/file/pre-signed-url`,
                    headers: { "Content-Type": "application/json" },
                    data: JSON.stringify({ fileName: fileName, folder: "images" }),
                };
                const presignedUrl = await axios(config);

                // TODO: Upload to S3
                if (presignedUrl?.data?.url) {
                    let uploadfile = await axios({
                        method: "PUT",
                        url: presignedUrl?.data?.url,
                        data: fileData,
                        headers: {
                            "Content-Type": " file/*; image/*",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    });

                    // TODO: Set image name to state
                    if (uploadfile) {
                        if (cardCategory == "image") setImage(fileName);
                        if (cardCategory == "cardImageFront") setCardImageFront(fileName);
                        if (cardCategory == "cardImageBack") setCardImageBack(fileName);
                        if (cardCategory == "cardImagePerson") setCardImagePerson(fileName);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSave = async () => {
        try {
            if (
                !realFirstName ||
                !realLastName ||
                !image ||
                !phone ||
                !email ||
                !birtday ||
                !cardType ||
                cardType == "SELECT" ||
                !cardNo ||
                !cardOpenDate ||
                !cardEndDate ||
                !cardImageFront ||
                !cardImageBack ||
                !cardImagePerson) {
                setRequestFail(t("please-enter-the-information-completely"))
                return
            }
            setIsEdit(true)

            let userCode = `S${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}${(Math.random() * 1000).toFixed(0)}`;
            let data = {
                fullName: `${realFirstName} ${realLastName}`,
                realFirstName: realFirstName,
                realLastName: realLastName,
                image: image,
                phone: phone,
                email: email,
                birtday: birtday,
                cardType: cardType,
                cardNo: cardNo,
                cardOpenDate: cardOpenDate,
                cardEndDate: cardEndDate,
                cardImageFront: cardImageFront,
                cardImageBack: cardImageBack,
                cardImagePerson: cardImagePerson,
                vipStatus: "REQUESTING",
                vipUser: false,
                vipCode: userData?.vipCode ?? userCode,
            };

            var config = {
                method: "put",
                url: `${END_POINT_SEVER}/api/users/${userData["_id"]}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(data),
            };
            const updateHandle = await axios(config);
            if (updateHandle.data) {
                setIsEdit(false);
                routeNavigate(-1)
            }
            setIsEdit(false);
        } catch (error) {
            console.log(error);
        }
    };


    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize])

    const isIPadSize = useMediaQuery(theme.breakpoints.down('md'));
    const [isIPad, setIsIPad] = useState(false);
    useEffect(() => {
        setIsIPad(isIPadSize)
    }, [isIPadSize])

    // handleChangeDocType
    const handleChangeDocType = (event) => {
        setCardType(event.target.value);
    }

    return (
        isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
                <CustomNavbar />
                <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />
                <h4 style={{ color: "#fff" }}>
                    <b>{t("register")} VIP Member</b>
                </h4>
                <div
                    style={{
                        paddingTop: isMobile ? "0px" : isIPad ? "4px" : "10px",
                        paddingLeft: isMobile ? "0px" : isIPad ? "180px" : "380px",
                        paddingRight: isMobile ? "0px" : isIPad ? "180px" : "380px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            fontSize: 14,
                            backgroundColor: appColor.bgDark,
                            padding: isMobile ? "10px" : isIPad ? "20px" : "30px",

                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                            <p>{t("Image")} <span style={{ color: "red" }}>*</span></p>
                            {image ?
                                <Image src={image.slice(0, 8) == "https://" ? image : `${S3_BUCKET_END_POINT}/images/${image}`} style={{ width: 130, height: 140, marginBottom: 8 }} />
                                :
                                <div >
                                    {/* style={{ display: "flex", height: 140, width: 130, borderRadius: 5, backgroundColor: "#e6e6e6", justifyContent: "center", alignItems: "center" }} */}
                                    {/* <FontAwesomeIcon icon={faImage} style={{ color: "#bebebe", fontSize: 40 }} /> */}
                                    <img src={IDImageTemplate} alt="FrontImageTemplate" style={{
                                        width: isMobile ? 80 : isIPad ? 100 : 130
                                    }} />
                                </div>

                            }
                            <div style={{ height: 10 }} />
                            <label htmlFor="upload-photo-image"
                                style={{
                                    color: appColor.textWhite,
                                    backgroundColor: appColor.orange,
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    paddingTop: 4,
                                    paddingBottom: 4,
                                    cursor: "pointer",
                                    borderRadius: 30,
                                }}>

                                <input
                                    style={{ display: 'none' }}
                                    id="upload-photo-image"
                                    name="upload-photo-image"
                                    type="file"
                                    // defaultValue={}
                                    onChange={(event) => requestPresignedUrlAndUploadImageToS3(event, "image")}
                                />

                                {t("ChooseFile")}
                                <br />
                            </label>
                        </div>
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("FirstName")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                            type="text"
                            placeholder={`${t("Enter")} ${t("FirstName")}`}
                            value={realFirstName}
                            onChange={(event) => setRealFirstName(event?.target?.value)}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && !realFirstName ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("LastName")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                            type="text"
                            placeholder={`${t("Enter")} ${t("LastName")}`}
                            value={realLastName}
                            onChange={(event) => setRealLastName(event?.target?.value)}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && !realLastName ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("Phone")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                            type="text"
                            placeholder={`${t("Enter")} ${t("Phone")}`}
                            value={phone}
                            onChange={(event) => setPhone(event?.target?.value)}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && !phone ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("Email")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                            type="text"
                            placeholder={`${t("Enter")} ${t("Email")}`}
                            value={email}
                            onChange={(event) => setEmail(event?.target?.value)}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && !email ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("BirthDay")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                            type="date"
                            placeholder={`${t("Enter")} ${t("BirthDay")}`}
                            value={birtday}
                            onChange={(event) => setBirtday(event?.target?.value)}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && !birtday ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("DocumentTpye")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <FormControl fullWidth sx={{
                            m: 1, minWidth: 120,
                            border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && (!cardType || cardType == "SELECT") ? "red" : "#fff"),
                            borderRadius: 1,
                        }}

                            size="small">
                            <Select
                                value={cardType}
                                onChange={handleChangeDocType}
                                label={`---${t("DocumentTpye")}---`}
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{
                                    textAlign: "left",
                                }}
                            >
                                {/* <MenuItem value={"SELECT"}>---{t("DocumentTpye")}---</MenuItem> */}
                                <MenuItem value={"PERSONAL_CARD"}>{t("IDCard")}</MenuItem>
                                <MenuItem value={"PASSPORT_BOOK"}>{t("Passport")}</MenuItem>
                                <MenuItem value={"FAMILY_BOOK"}>{t("FamilyBook")}</MenuItem>
                                <MenuItem value={"DRIVER_LICENSE"}>{t("DriverLicense")}</MenuItem>
                            </Select>
                        </FormControl>

                        {/* <select
                            onChange={(e) => setCardType(e.target.value)}
                            value={cardType}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && (!cardType || cardType == "SELECT") ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        >
                            <option value={"SELECT"}>---{t("DocumentTpye")}---</option>
                            <option value={"PERSONAL_CARD"}>{t("IDCard")}</option>
                            <option value={"PASSPORT_BOOK"}>{t("Passport")}</option>
                            <option value={"FAMILY_BOOK"}>{t("FamilyBook")}</option>
                            <option value={"DRIVER_LICENSE"}>{t("DriverLicense")}</option>
                        </select> */}
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("CardNumber")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                            type="text"
                            placeholder={`${t("Enter")} ${t("CardNumber")}`}
                            value={cardNo}
                            onChange={(event) => setCardNo(event?.target?.value)}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && !cardNo ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("CardOpenDate")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                            type="date"
                            placeholder={`${t("Enter")} ${t("CardOpenDate")}`}
                            value={cardOpenDate}
                            onChange={(event) => setCardOpenDate(event?.target?.value)}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && !cardOpenDate ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <div style={{ height: 10 }} />
                        <p style={{ color: "#fff", fontSize: 14 }}>
                            {t("CardExpireDate")} <span style={{ color: "red" }}>*</span>
                        </p>
                        <input
                            type="date"
                            placeholder={`${t("Enter")} ${t("CardExpireDate")}`}
                            value={cardEndDate}
                            onChange={(event) => setCardEndDate(event?.target?.value)}
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid " + (requestFail == t("please-enter-the-information-completely") && !cardEndDate ? "red" : "#fff"),
                                borderRadius: 4,
                                height: 40,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <div style={{ height: 20 }} />
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "31%", alignItems: "center" }}>
                                <p>{t("FrontCard")} <span style={{ color: "red" }}>*</span></p>
                                {cardImageFront ?
                                    <Image src={`${S3_BUCKET_END_POINT}/images/${cardImageFront}`} style={{ width: "100%", height: 110, marginBottom: 8 }} />
                                    :
                                    <div >
                                        <img src={FrontImageTemplate} alt="FrontImageTemplate" style={{
                                            width: isMobile ? 100 : isIPad ? 120 : 130
                                        }} />
                                    </div>
                                }
                                <div style={{ height: 10 }} />
                                <label htmlFor="upload-photo-cardImageFront"
                                    style={{
                                        color: appColor.textWhite,
                                        backgroundColor: appColor.orange,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingTop: 4,
                                        paddingBottom: 4,
                                        cursor: "pointer",
                                        borderRadius: 20,
                                        fontSize: 12
                                    }}>
                                    <input
                                        style={{ display: 'none' }}
                                        id="upload-photo-cardImageFront"
                                        name="upload-photo-cardImageFront"
                                        type="file"
                                        onChange={(event) => requestPresignedUrlAndUploadImageToS3(event, "cardImageFront")}
                                    />

                                    {t("ChooseFile")}
                                    <br />
                                </label>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "31%", alignItems: "center" }}>
                                <p>{t("BackCard")} <span style={{ color: "red" }}>*</span></p>
                                {cardImageBack ?
                                    <Image src={`${S3_BUCKET_END_POINT}/images/${cardImageBack}`} style={{ width: "100%", height: 110, marginBottom: 8 }} />
                                    :
                                    <div >
                                        <img src={BackImageTemplate} alt="BackImageTemplate" style={{
                                            width: isMobile ? 100 : isIPad ? 120 : 130
                                        }} />
                                    </div>
                                }
                                <div style={{ height: 10 }} />
                                <label htmlFor="upload-photo-cardImageBack"
                                    style={{
                                        color: appColor.textWhite,
                                        backgroundColor: appColor.orange,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingTop: 4,
                                        paddingBottom: 4,
                                        cursor: "pointer",
                                        borderRadius: 20,
                                        fontSize: 12
                                    }}>
                                    <input
                                        style={{ display: 'none' }}
                                        id="upload-photo-cardImageBack"
                                        name="upload-photo-cardImageBack"
                                        type="file"
                                        onChange={(event) => requestPresignedUrlAndUploadImageToS3(event, "cardImageBack")}
                                    />

                                    {t("ChooseFile")}
                                    <br />
                                </label>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "31%", alignItems: "center" }}>
                                <p>{t("Selfie")} <span style={{ color: "red" }}>*</span></p>
                                {cardImagePerson ?
                                    <Image src={`${S3_BUCKET_END_POINT}/images/${cardImagePerson}`} style={{ width: "100%", height: 110, marginBottom: 8 }} />
                                    :
                                    <div >
                                        <img src={SelfieImageTemplate} alt="SelfieImageTemplate" style={{
                                            width: isMobile ? 100 : isIPad ? 120 : 130
                                        }} />
                                    </div>
                                }
                                <div style={{ height: 10 }} />
                                <label htmlFor="upload-photo-cardImagePerson"
                                    style={{
                                        color: appColor.textWhite,
                                        backgroundColor: appColor.orange,
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        paddingTop: 4,
                                        paddingBottom: 4,
                                        cursor: "pointer",
                                        borderRadius: 20,
                                        fontSize: 12
                                    }}>
                                    <input
                                        style={{ display: 'none' }}
                                        id="upload-photo-cardImagePerson"
                                        name="upload-photo-cardImagePerson"
                                        type="file"
                                        onChange={(event) => requestPresignedUrlAndUploadImageToS3(event, "cardImagePerson")}
                                    />

                                    {t("ChooseFile")}
                                    <br />
                                </label>
                            </div>
                        </div>
                        <div style={{ height: 50 }} />
                        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                            <Button
                                onClick={() => onSave()}
                                style={{
                                    borderRadius: 28,
                                    borderWidth: 0,
                                    color: "white",
                                    width: "90%",
                                    height: 45,
                                    backgroundColor: appColor.yellow,
                                    color: appColor.primary,
                                }}
                            >
                                <div>
                                    {isEdit ? (
                                        <Spinner animation="border" role="status"></Spinner>
                                    ) : (
                                        <div><FontAwesomeIcon icon={faRankingStar} style={{ color: appColor.primary, }} />{" "} {t("register")} VIP Member</div>
                                    )}
                                </div>
                            </Button>
                        </div>
                        <div style={{ height: 30 }} />
                    </div>
                </div>

            </div>
    )
}

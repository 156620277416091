import { gql } from "@apollo/client";

export const ADMIN_REVIEW_ACTION_P2P_GQL = gql`
mutation AdminReviewP2PTransaction($data: adminReviewP2PInput!) {
  adminReviewP2PTransaction(data: $data) {
    data {
      _id
      adv
      tradeType
      assetType
      fiatUnitType
      merchant
      merchantConfirmType
      customer
      customerConfirmType
      price
      amount
      fiatAmount
      transectionStatus
      remark
      note
      createdAt
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;

export const ADMIN_GET_ALL_P2P_GQL = gql`
query AdminGetAllP2PTransaction($query: adminFilterP2PTransaction) {
  adminGetAllP2PTransaction(query: $query) {
    data {
      _id
      adv
      tradeType
      assetType
      fiatUnitType
      merchantConfirmType
      customerConfirmType
      price
      amount
      fiatAmount
      transectionStatus
      remark
      note
      createdAt
      merchant {
        fullName
        image
        phone
        merchantName
        verificationStatus
        isActive
      }
      customer {
        fullName
        image
        phone
        userName
        loginType
      }
    }
    meta {
      countMCTransPending
      countCusTransPending
      totalDocs
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;

export const GET_MY_MERCHANT_TRADE_INFO_GQL = gql`
query GetMyMerchantTradeInfo($userId: String!) {
  getMyMerchantTradeInfo(userID: $userId) {
    data {
      totalAllTrade
      totalCompletedRate
      avgResponseTime
      avgTradeTime
      totalFeedbackPercent
      totalPositiveFeedback
      totalNegativeFeedback
      registeredDate
    }
  }
}
`;
export const CUS_REVIEW_P2P_TRANSACTION_GQL = gql`
mutation CustomerReviewP2PTransaction($data: customerReviewP2PInput!) {
  customerReviewP2PTransaction(data: $data) {
    data {
      _id
      adv
      tradeType
      assetType
      fiatUnitType
      merchant
      merchantConfirmType
      customer
      customerConfirmType
      price
      amount
      fiatAmount
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;
export const MC_REVIEW_P2P_TRANSACTION_GQL = gql`
mutation MerchantReviewP2PTransaction($data: merchantReviewP2PInput!) {
  merchantReviewP2PTransaction(data: $data) {
    data {
      _id
      adv
      tradeType
      assetType
      fiatUnitType
      merchant
      merchantConfirmType
      customer
      customerConfirmType
      price
      amount
      fiatAmount
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;
export const GET_P2P_TRANSACTION_GQL = gql`
query GetP2PTransaction($query: filterP2PTransaction) {
  getP2PTransaction(query: $query) {
    data {
      _id
      adv
      tradeType
      assetType
      fiatUnitType
      merchant
      merchantConfirmType
      customer
      customerConfirmType
      price
      amount
      fiatAmount
      createdAt
    }
    meta{
      countMCTransPending
      countCusTransPending
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;
export const GET_P2P_TRANSACTION_BY_ID = gql`
query GetP2PTransactionByID($id: String) {
  getP2PTransactionByID(_id: $id) {
    _id
    adv
    tradeType
    assetType
    fiatUnitType
    merchantConfirmType
    customerConfirmType
    price
    amount
    fiatAmount
    transectionStatus
    remark
    note
    createdAt
    customer
    merchant
  }
}
`;
export const REQUEST_P2P_TRANSACTION_GQL = gql`
mutation RequestP2PTransaction($data: requestP2PInput!) {
  requestP2PTransaction(data: $data) {
    data {
      _id
      adv
      tradeType
      assetType
      fiatUnitType
      merchant
      merchantConfirmType
      customer
      customerConfirmType
      price
      amount
      fiatAmount
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}

`;
export const UPDATE_AVS_GQL = gql`
mutation UpdateAdvertisement($data: updateAdvertisementInput!) {
  updateAdvertisement(data: $data) {
    data {
      _id
      merchantName
      mcMCDetail
      verificationStatus
      userName
      fullName
      phone
      tradeType
      assetType
      fiatUnitType
      price
      minSingleTransQuantity
      maxSingleTransQuantity
      tradableQuantity
      user
      loginType
      isPublish
      createdAt
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;
export const CREATE_AVS_GQL = gql`
mutation CreateAdvertisement($data: createAdvertisementInput!) {
  createAdvertisement(data: $data) {
    data {
      tradeType
      assetType
      fiatUnitType
      price
      maxSingleTransQuantity
      minSingleTransQuantity
      tradableQuantity
      user
      merchantName
      mcMCDetail
      verificationStatus
      userName
      fullName
      phone
      loginType
      isPublish
      createdAt
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;

export const FETCH_NEW_TRADING = gql`
mutation GetSummaryTrading {
  getSummaryTrading {
    status
  }
}
`;

export const GET_AVS_GQL = gql`
  query GetAdvertisement($query: filterAdvertisementInput!) {
  getAdvertisement(query: $query) {
    data {
      merchantName
      mcMCDetail
      verificationStatus
      userName
      fullName
      phone
      tradeType
      assetType
      fiatUnitType
      price
      minSingleTransQuantity
      maxSingleTransQuantity
      tradableQuantity
      user
      isPublish
      _id
      createdAt
      mcMCDetail
      userImage
      merchantActive
      loginType
      meta {
        totalAllTrade
        totalCompletedRate
        avgResponseTime
        avgTradeTime
        totalFeedbackPercent
        totalPositiveFeedback
        totalNegativeFeedback
        registeredDate
      }
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;

export const GET_MC_AVS_GQL = gql`
  query GetMCAdvertisement($query: filterMCAdvertisementInput!) {
  getMCAdvertisement(query: $query) {
    data {
      _id
      merchantName
      mcMCDetail
      verificationStatus
      userName
      fullName
      phone
      tradeType
      assetType
      fiatUnitType
      price
      minSingleTransQuantity
      maxSingleTransQuantity
      tradableQuantity
      user
      loginType
      isPublish
      createdAt
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;
export const CREATE_MC_GQL = gql`
  mutation CreateMerchant($data: createMerchantInput!) {
  createMerchant(data: $data) {
    data {
      _id
      merchantName
      verificationStatus
      user
      bankName
      bankAccountName
      bankAccountNumber
      bankAccountQR
      mcMCDetail
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;

export const GET_MC_GQL = gql`
query GetMyMerchant($userId: String!) {
  getMyMerchant(userID: $userId) {
    data {
      _id
      merchantName
      verificationStatus
      isActive
      user
      bankName
      bankAccountName
      bankAccountNumber
      bankAccountQR
      mcMCDetail
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;
export const UPDATE_MC = gql`
mutation UpdateMerchant($data: createMerchantInput!, $mcId: String) {
  updateMerchant(data: $data, mcId: $mcId) {
    data {
      merchantName
      verificationStatus
      user
      bankName
      bankAccountName
      bankAccountNumber
      bankAccountQR
      mcMCDetail
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;

export const GET_MC_SETTING_GQL = gql`
 query GetMCSetting {
  getMCSetting {
    data {
      minimumBalance
      user
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;
export const MC_SETTING_GQL = gql`
 mutation CreateMCSetting($data: createMCSettingInput!) {
  createMCSetting(data: $data) {
    data {
      minimumBalance
      user
    }
    error {
      error
      errorCode
      errorMsg
    }
  }
}
`;

export const CRYPTO_QUERY_BTC_30s = gql`
  subscription ReceiveBTCGraph30s {
    receiveBTCGraph1s {
      id
      binary
      open
      low
      high
      close
      volume
      timestamp
      turnover
      createdAt
      updatedAt
    }
  }
`;

export const RECEIVE_GRAPH1S = gql`
 subscription ReceiveGraph1s($binary: String) {
  receiveGraph1s(binary: $binary) {
    id
    binary
    open
    low
    high
    close
    volume
    timestamp
    turnover
    createdAt
    updatedAt
  }
}
`;

export const CRYPTO_HISTORY = gql`
  query GetGraph30s($binary: String, $limit: Int) {
    getGraph30s(binary: $binary, limit: $limit) {
      id
      binary
      open
      low
      high
      close
      volume
      timestamp
      turnover
      createdAt
      updatedAt
    }
  }
`;

export const CRYPTO_HISTORY_1M = gql`
  query GetGraph1m($binary: String, $limit: Int) {
    getGraph1m(binary: $binary, limit: $limit) {
      id
      binary
      open
      low
      high
      close
      volume
      timestamp
      turnover
      createdAt
      updatedAt
    }
  }
`;

export const CRYPTO_PRICE_CTRL = gql`
  mutation GraphControl(
    $binaryId: String!
    $binary: String!
    $numberControl: Int!
    $enable: Boolean!
    $userID: String!
  ) {
    graphControl(
      binaryId: $binaryId
      binary: $binary
      numberControl: $numberControl
      enable: $enable
      userID: $userID
    ) {
      id
      binary
      numberControl
      enable
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CREDIT_HISTORY = gql`
  mutation UpdateCreditHistory($status: CreditHistoryStatus!, $user: String!, $updateCreditHistoryId: String! , $bankName: String!) {
    updateCreditHistory(status: $status, user: $user, id: $updateCreditHistoryId,bankName: $bankName) {
      status
      type
      credit
      creditKip
      creditRate
      image
      adminSlip
      bankName
    }
  }
`;

export const GET_GRAPH_CONTROL = gql`
  query GetGraphControls($binary: String, $limit: Int) {
    getGraphControls(binary: $binary, limit: $limit) {
      id
      binary
      numberControl
      enable
      createdAt
      updatedAt
    }
  }
`;

export const RECEIVE_GRAPH_COUNTDOWN = gql`
  subscription ReceiveGraphCountDown($roomId: String) {
    receiveGraphCountDown(roomId: $roomId) {
      enable
      countNumber
    }
  }
`;

export const RECEIVE_TRADE = gql`
  subscription ReceiveTrade($userId: String) {
    receiveTrade(userId: $userId) {
      id
      type
      cardType
      status
      binary
      openPrice
      closePrice
      money
      profit
      isProfit
      createdAt
      updatedAt
    }
  }
`;

/*
{
  "userId": "64f771e250a6bd4afc1d8317"
}
*/

export const RECEIVE_TRADE_DETECT = gql`
  subscription ReceiveTradeDetect($detectId: String) {
    receiveTradeDetect(detectId: $detectId) {
      calculateBuy
      calculateSell
      minBTCPrice
      maxBTCPrice
      minETHPrice
      maxETHPrice
      minEURPrice
      maxEURPrice
      tradeDetect {
        id
        money
        count
        profit
        type
        cardType
        userName
        calculate
        openPrice
      }
    }
  }
`;

export const RECEIVE_NOTIFICATION = gql`
  subscription ReceiveNotification($userId: String) {
  receiveNotification(userId: $userId) {
    senderId
    senderName
    receiveId
    receiveName
    category
    type
    title
    detail
  }
}
`;
export const CREATE_CREDIT_HISTORY = gql`
mutation CreateCreditHistory($data: CreditHistoryInput!) {
  createCreditHistory(data: $data) {
    status
    type
    credit
    creditKip
    creditRate
    image
    adminSlip
    bankName
    binanceEmailOrBinanceID
    usedBonus
    realMoney
    error
  }
}
`;

export const GET_NOTIFICATION_HISTORY = gql`
query GetNotifications($receiveId: String) {
  getNotifications(receiveId: $receiveId) {
    senderId
    senderName
    receiveId
    receiveName
    category
    type
    title
    detail
  }
}
`;

export const GET_UNREAD_NOTIFICATIONS = gql`
  query GetUnreadNotifications($receiveId: String!) {
    getUnreadNotifications(receiveId: $receiveId) {
      amount
    }
  }
`;

export const UPDATE_UNREAD_NOTIFICATIONS = gql`
  mutation UpdateReadNotification($receiveId: String!) {
    updateReadNotification(receiveId: $receiveId) {
      status
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation Chat($data: ChatInput) {
    chat(data: $data) {
      id
    }
  }
`;

export const GET_CHAT_LIST = gql`
  query GetChats($room: String!, $limit: Int, $skip: Int) {
    getChats(room: $room, limit: $limit, skip: $skip) {
      id
      room
      message
      type
      read
      sender {
        id
        fullName
        phone
        userName
        role
        image
        realFirstName
        realLastName
        vipUser
        loginType
      }
      createdAt
      updatedAt
    }
  }
`;

export const SUB_CHAT_MESSAGE = gql`
  subscription Chat($room: String) {
    chat(room: $room) {
      id
      room
      message
      type
      read
      sender {
        id
        fullName
        phone
        userName
        role
        image
        realFirstName
        realLastName
        vipUser
        loginType
      }
      createdAt
      updatedAt
    }
  }
`;
export const P2P_UPDATE_NOTI = gql`
  subscription P2pUpdateNoti($room: String) {
    p2pUpdateNoti(room: $room) {
      room
      merchantConfirmType
      customerConfirmType
    }
  }
`;
export const CHAT_NOTIFICATION = gql`
  subscription ChatNotification($notiId: String) {
    chatNotification(notiId: $notiId) {
      room
      title
      message
    }
  }
`;
export const STOP_P2P_COUNTDOWN = gql`
  mutation Mutation($roomId: String) {
    stopP2PCountDown(roomId: $roomId) {
      status
    }
  }
`;
export const START_P2P_COUNTDOWN = gql`
  mutation Mutation($roomId: String, $mimute: Int) {
    startP2PCountDown(roomId: $roomId, mimute: $mimute) {
      status
    }
  }
`;
export const RECEIVE_P2P_COUNTDOWN = gql`
  subscription ReceiveP2PCountDown($roomId: String) {
    receiveP2PCountDown(roomId: $roomId) {
      time
    }
  }
`;
import React, { useState, useEffect, useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Spinner from "react-bootstrap/Spinner";
import { USER_LOCAL_DATA, appColor, screenSize } from "../../constants";
import {
  Nav,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LeaderboardOutlined,
  WalletOutlined,
  PersonOutline,
  History,
  AttachMoney,
  LogoutOutlined,
  SettingsOutlined,
  NotificationsOutlined,
  Add,
  Remove,
  TrendingUp,
  TrendingDown,
  ArrowDropDown,
  ExitToApp,
  Percent,
  Language,
  NotificationsOff,
  VolumeUpTwoTone,
  VolumeOffOutlined,
  Cached,
  Person,
  ListAlt,
  Menu,
  SwapHoriz,
  DragIndicator,
  DragIndicatorOutlined,
  CandlestickChart,
  ShowChart,
  SwapHorizOutlined,
  ArrowBack,
  MultipleStop,

} from "@mui/icons-material";
import {
  faCreditCard,
  faDoorOpen,
  faIdCardClip,
  faCoins,
  faUser,
  faRotateRight,
  faPlus,
  faMoneyBillTrendUp,
  faClockRotateLeft,
  faPercent,
  faDollar,
  faGear,
  faBell,
  faRankingStar,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { END_POINT_SEVER } from "../../api";
import axios from "axios";
import {
  Box,
  Paper,
  Divider,
  Avatar,
  Button,
  Typography,
  TextField,
  Modal,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Container,
  Drawer,
  IconButton,
  Badge,
  useTheme,
} from "@mui/material";
import AppPopup from "../../common/popup";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import laoFlag from '../../images/flag/lao-flag.png';
import englishFlag from '../../images/flag/english-flag.png';
import thailandFlag from '../../images/flag/thailand-flag.png';
import khrFlag from '../../images/flag/khr-flag.png';
import AppContext from '../../appProvider/context';
import ChangeLanguage from "../../components/ChangeLanguage";
import { CHAT_NOTIFICATION, GET_UNREAD_NOTIFICATIONS, RECEIVE_NOTIFICATION, UPDATE_UNREAD_NOTIFICATIONS } from "../../apollo/crypto";
import { useLazyQuery, useMutation, useSubscription } from "@apollo/client";
import { toast } from "react-toastify";
import { RiP2PFill } from "react-icons/ri";
import ProfileModal from "../../components/profile/profileModal";
import CreditTransfer from "../transection/creditTransfer";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  background: appColor.bgDark,
  color: appColor.white,
};
function CustomNavbar() {
  const routeNavigate = useNavigate();
  const { t } = useTranslation();

  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [localData, setLocalData] = useState();
  const [showProfile, setShowProfile] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [backdrop, setBackdrop] = useState(true);
  const [appLanguages, setAppLanguages] = useState("lao");

  const [showCreditCard, setShowCreditCard] = useState(false);
  const [userData, setUserData] = useState();
  const [userID, setUserID] = useState();
  const [demoCreditCardData, setDemoCreditCardData] = useState();
  const [realCreditCardData, setRealCreditCardData] = useState();
  const [activeCard, setActiveCard] = useState("DEMO");
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showModalLanguages, setShowModalLanguages] = useState(false);
  const { setStateLanguage } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  const [amountNotification, setAmountNotification] = useState(0)
  const [getUnreadNotifications, { data: getUnreadNotificationData }] = useLazyQuery(GET_UNREAD_NOTIFICATIONS, { fetchPolicy: "network-only" })
  const [updateReadNotification] = useMutation(UPDATE_UNREAD_NOTIFICATIONS)

  const [scrolledDown, setScrolledDown] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;

      // If scrolled down more than 50px, set scrolledDown to true
      if (position >= 50 && !scrolledDown) {
        setScrolledDown(true);
        console.log('Scrolled down to 50px or more');
      }

      // If scrolled back to top, set scrolledDown to false
      if (position < 50 && scrolledDown) {
        setScrolledDown(false);
        console.log('Scrolled back to top');
      }
    };

    // Add the scroll event listener to window
    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolledDown]);

  const handleShowCreditCard = () => {
    setShowCreditCard(!showCreditCard);
  };

  const handleProfile = () => {
    setShowProfile(false);
    setIsEdit(false);
    setBackdrop(true);
  };

  const [logoutDialog, setLogoutDialog] = useState(false);


  const handleLogoutConfirm = () => {
    // localStorage.clear();
    localStorage.removeItem('CLOSE_POPUP');
    localStorage.removeItem('CLOSE_POPUP_IMAGE');
    localStorage.removeItem(USER_LOCAL_DATA);
    routeNavigate("/");
  };

  useEffect(() => {
    const getLoalData = async () => {
      try {
        setIsLoading(true)
        const appLanguage = await localStorage.getItem('appLanguage')
        if (appLanguage) {
          setAppLanguages(appLanguage)
          setStateLanguage(appLanguage)
        } else {
          setAppLanguages("lao")
          setStateLanguage("lao")
        }

        const _localData = await localStorage.getItem(USER_LOCAL_DATA);
        // console.log('_localData :>> ', _localData);
        if (_localData) {
          let parseData = JSON.parse(_localData);
          setLocalData(parseData);
          setUserData(_localData)
          if (parseData["user"] && parseData["user"]["_id"]) {
            getUserData(parseData["user"]["_id"]);
            setUserID(parseData["user"]["_id"]);
            getCreditData(parseData["user"]["_id"]);
          }
        }
        setIsLoading(false)
      } catch (error) {
        console.log(error);
        setIsLoading(false)
      }
    };
    getLoalData();
  }, []);

  const getUserData = async (userId) => {
    try {
      setIsLoading(true)
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/users/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const userHandle = await axios(config);

      if (userHandle?.data) {
        setUserData(userHandle.data?.user);
        setFullname(userHandle?.data?.user?.fullName);
        setUsername(userHandle?.data?.user?.userName);
        setPhone(userHandle?.data?.user?.phone);
        getUnreadNotifications({
          variables: {
            receiveId: userHandle.data?.user?.role === "ADMIN" ? "ADMIN" : userId,
          }
        })
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  };

  const getCreditData = async (userId) => {
    try {
      setIsLoading(true)
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const creditHandle = await axios(config);
      if (creditHandle?.data) {
        for (var i = 0; i < creditHandle.data?.credit.length; i++) {
          if (creditHandle.data?.credit[i].type == "DEMO")
            setDemoCreditCardData(creditHandle.data?.credit[i]);
          if (creditHandle.data?.credit[i].type == "REAL")
            setRealCreditCardData(creditHandle.data?.credit[i]);
          if (creditHandle.data?.credit[i].isUse == true)
            setActiveCard(creditHandle.data?.credit[i].type);
        }
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
      setIsLoading(false)
    }
  };

  const toEdit = () => {
    setIsEdit(true);
    setBackdrop("static");
  };

  const onEdit = async () => {
    try {
      let data = {
        fullName: fullname,
        phone: phone,
        userName: username,
      };
      if (password && password != "") data = { ...data, password: password };

      var config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/users/${userData["_id"]}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
      const updateHandle = await axios(config);
      console.log("updateHandle: ", updateHandle);
      if (updateHandle.data) {
        setIsEdit(false);
        setBackdrop(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function currencyFormat(num) {
    return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const resetDemoCard = async (cardId) => {
    try {
      var config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/credit/${cardId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ credit: 10000 }),
      };
      const updateHandle = await axios(config);
      if (updateHandle.data) {
        getCreditData(userData["_id"]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSave = async () => {
    try {
      setIsSaveLoading(true);
      var config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/credit/${activeCard == "DEMO"
          ? demoCreditCardData["_id"]
          : realCreditCardData["_id"]
          }`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ isUse: true }),
      };
      const updateHandle = await axios(config);
      var configFalse = {
        method: "put",
        url: `${END_POINT_SEVER}/api/credit/${activeCard == "REAL"
          ? demoCreditCardData["_id"]
          : realCreditCardData["_id"]
          }`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ isUse: false }),
      };
      await axios(configFalse);

      if (updateHandle.data) {
        getCreditData(userData["_id"]);
        setShowCreditCard(false);
      }
      setIsSaveLoading(false);
    } catch (error) {
      console.log(error);
      setIsSaveLoading(false);
    }
  };

  const onChangeLanguage = async (language) => {
    setAppLanguages(language)
    i18n.changeLanguage(language);
    setStateLanguage(language)
    localStorage.setItem('appLanguage', language)
  }

  // START RECEIVE NOTIFICATION
  const { data: receiveNotification } = useSubscription(RECEIVE_NOTIFICATION, {
    variables: {
      userId: userData?.role === "ADMIN" ? "ADMIN" : userID,
    },
  });

  useEffect(() => {
    if (userData) {
      getUnreadNotifications({
        variables: {
          receiveId: userData?.role === "ADMIN" ? "ADMIN" : userID,
        }
      })
      toast(receiveNotification?.receiveNotification?.detail, { type: receiveNotification?.receiveNotification?.type === "APPROVED" ? "sucess" : "error" });
    }
  }, [receiveNotification]);

  useEffect(() => {
    if (getUnreadNotificationData?.getUnreadNotifications) {
      setAmountNotification(getUnreadNotificationData?.getUnreadNotifications?.amount ?? 0)
    }
  }, [getUnreadNotificationData]);

  const onUpdateReadNotification = async () => {
    try {
      setIsLoading(true)
      await updateReadNotification({
        variables: {
          receiveId: userData?.role === "ADMIN" ? "ADMIN" : userID,
        }
      })
      await getUnreadNotifications({
        variables: {
          receiveId: userData?.role === "ADMIN" ? "ADMIN" : userID,
        }
      })
      setAmountNotification(0)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  // END RECEIVE NOTIFICATION

  const [isMobile, setIsMobile] = useState(false);
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  // check device type desktop or mobile

  useEffect(() => {
    setIsMobile(isMobileSize)
  }, [isMobileSize, isMobile]);

  const [expanded, setexpanded] = useState('');
  const toggerNavBar = () => {
    toggleDrawer('left');
    // setexpanded("expanded");
    // setexpanded(expanded === 'expanded' ? '' : 'expanded');
    if (showProfile == true) handleProfile(false);
  }


  // LANGUAGE
  const currentLanguage = i18n.language;
  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    setStateLanguage(event.target.value)
    localStorage.setItem('appLanguage', event.target.value)
  };

  const { data: chatNotification } = useSubscription(CHAT_NOTIFICATION, {
    variables: { notiId: userID }
  });

  useEffect(() => {
    if (chatNotification) {
      toast(chatNotification?.chatNotification?.title, { type: "warning" });
    }
  }, [chatNotification]);
  const [drawerType, setDrawerType] = useState("left");
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const toggleDrawer = (clickDrawerType) => {
    setDrawerType(clickDrawerType)
    setisDrawerOpen(!isDrawerOpen);
    // setisDrawerIndicatorOpen(!isDrawerIndicatorOpen);
    // if (isDrawerHistory) setIsDrawerHistory(!isDrawerHistory);
  }

  // PopupTransfer
  const [isShowPopupTransfer, setIsShowPopupTransfer] = useState(false);
  const [creditList, setCreditList] = useState([]);
  const handlePopupTransfer = () => {
    setIsShowPopupTransfer(!isShowPopupTransfer);
  }

  // LOGOUT
  const [showModalLogou, setShowModalLogout] = useState(false);

  const handleModelLogout = () => {
    setShowModalLogout(!showModalLogou);
  };
  const handleLogout = () => {
    localStorage.removeItem('CLOSE_POPUP');
    localStorage.removeItem('CLOSE_POPUP_IMAGE');
    localStorage.removeItem(USER_LOCAL_DATA);
    routeNavigate("/");
  };
  return (
    isLoading ? <></> : <>
      <Navbar
        collapseOnSelect
        expanded={expanded}
        expand={"lg"}
        variant="dark"
        className="color-nav"
        style={{
          padding: 8,
          zIndex: 100,
          backgroundColor: appColor.background,
          width: "100vw"
        }}
      >

        <Drawer
          anchor={drawerType}
          open={isDrawerOpen}
          onClose={() => toggleDrawer(drawerType)}
        >

          <Box display="flex"
            sx={{

              height: drawerType !== "left" && isMobile ? "45vh" : "100vh",
              width: 1,
            }}
          >
            <Paper
              elevation={0}
              sx={{
                backgroundColor: appColor.bgDark,
                color: appColor.textGrey,
                width: 1,
              }}
            >
              {drawerType == "left" ?
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{
                    p: 2,
                    justifyItems: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  height={`calc(100%)`}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      gap: 2,
                      justifyItems: "center",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        cursor: "pointer",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                        gap: 1
                      }}
                      onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                    >

                      <img src="/coin-logo.png" style={{ height: 54 }} />
                    </Box>
                    {/* </a> */}
                    <Box
                      height={4}
                    />
                    {/* <a href="/signal/BTCOTCUSDT"> */}
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        cursor: "pointer",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                    >
                      <LeaderboardOutlined style={{ fontSize: 30 }} />
                      <p style={{ fontSize: 12 }}>{t("trading")}
                      </p>
                    </Box>
                    {/* </a> */}
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        cursor: "pointer",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => routeNavigate("/trade-histories")}
                    >
                      <History style={{ fontSize: 30 }} />
                      <p style={{ fontSize: 12 }}>{t("history")}</p>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        cursor: "pointer",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => routeNavigate("/transection-list")}
                    >
                      <WalletOutlined style={{ fontSize: 30 }} />
                      <p style={{ fontSize: 12 }}>{t("wallet")}</p>
                    </Box>                                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        cursor: "pointer",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => routeNavigate("/p2p-advertisement")}
                    >
                      <RiP2PFill size={32} />{" "}
                      <p style={{ fontSize: 12 }}>{t("P2P")}</p>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        cursor: "pointer",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      // select-bank-option
                      onClick={() => {
                        handlePopupTransfer();
                        toggleDrawer(drawerType);
                      }}
                    >
                      <SwapHorizOutlined style={{ fontSize: 30 }} />
                      <p style={{ fontSize: 12 }}>{t("transfer")}</p>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        cursor: "pointer",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => routeNavigate(userData?.role === "ADMIN" ? "/admin-vip-page" : "/vip-page")}
                    >
                      <FontAwesomeIcon
                        icon={faRankingStar}
                        style={{ fontSize: 30 }}
                      />
                      <p style={{ fontSize: 11 }}>VIP Member</p>
                    </Box>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        cursor: "pointer",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setShowProfile(true)}
                    >
                      <PersonOutline style={{ fontSize: 30 }} />
                      <p style={{ fontSize: 12 }}>{t("profile")}</p>
                    </Box>
                    {userData?.role === "ADMIN" ? (
                      <>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          sx={{
                            cursor: "pointer",
                            justifyItems: "center",
                            alignItems: "center",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => routeNavigate("/cost-page")}
                        >
                          <AttachMoney style={{ fontSize: 30 }} />
                          <p style={{ fontSize: 12 }}>{t("capital-cost")}</p>
                        </Box>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          sx={{
                            cursor: "pointer",
                            justifyItems: "center",
                            alignItems: "center",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => routeNavigate("/profit-page")}
                        >
                          <Percent style={{ fontSize: 30 }} />
                          <p style={{ fontSize: 12 }}>Profit</p>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      cursor: "pointer",
                      justifyItems: "center",
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                    }}
                    onClick={handleLogout}
                  >
                    <LogoutOutlined style={{ fontSize: 30 }} />
                    <p style={{ fontSize: 12 }}>Logout</p>
                  </Box>
                </Box> :
                <></>
              }

            </Paper>
          </Box>
        </Drawer>
        {screenSize().width > 700 ? <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: "16px",
          paddingRight: "16px",
          width: "100vw"
        }}>

          {/* <a href="/signal/BTCOTCUSDT"> */}
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
          >
            <Navbar.Brand>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: 50,
                  alignItems: "center",
                }}
              >
                <img src="/coin-logo-text.png" style={{ height: 45 }} />
              </div>
            </Navbar.Brand>
          </Box>
          {/* </a> */}
          <Box sx={{
            width: "100%",
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: "space-between"
          }}>
            <Box></Box>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"
              onClick={toggerNavBar}
            />
          </Box>


          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            {/* <Typography variant="body1" color="white">No Nav</Typography> */}
            {userData ? (

              <Nav>
                {/* <Typography variant="body1" color="white">Nav Nav</Typography> */}
                {/* <a href="/signal/BTCOTCUSDT"> */}
                <div
                  onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMoneyBillTrendUp}
                    style={{ fontSize: 16, color: "#fff" }}
                  />
                </div>
                {/* </a> */}
                <div style={{ width: 30 }} />
                <div
                  onClick={() =>
                    userData?.role == "ADMIN"
                      ? routeNavigate("/transection-list-admin")
                      : routeNavigate("/transection-list")
                  }
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCoins}
                    style={{ fontSize: 16, color: "#fff" }}
                  />
                </div>

                <div style={{ width: 30 }} />
                <div
                  onClick={() => routeNavigate("/trade-histories")}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClockRotateLeft}
                    style={{ fontSize: 16, color: "#fff" }}
                  />
                </div>
                <div style={{ width: 30 }} />
                {userData?.role === "ADMIN" ? (
                  <>
                    <div
                      onClick={() => routeNavigate("/cost-page")}
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faDollar}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 30 }} />
                    <div
                      onClick={() => routeNavigate("/profit-page")}
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faPercent}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 30 }} />
                    <div
                      onClick={() => routeNavigate("/setting-page")}
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faGear}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 30 }} />
                  </>
                ) : (
                  ""
                )}
                <div
                  onClick={() => routeNavigate(userData?.role === "ADMIN" ? "/admin-vip-page" : "/vip-page")}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRankingStar}
                    style={{ fontSize: 16, color: "#fff" }}
                  />
                </div>
                <div style={{ width: 30 }} />
                <div
                  onClick={() => {
                    routeNavigate("/notificaiton")
                    onUpdateReadNotification()
                  }}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    style={{ fontSize: 16, color: "#fff" }}
                  />
                  <div style={{ backgroundColor: "red", height: 14, borderRadius: 14, position: "relative", marginTop: -10, fontSize: 10, paddingLeft: 4, paddingRight: 4, color: "#fff" }}>{amountNotification > 9 ? `9+` : amountNotification}</div>
                </div>
                <div style={{ width: 30 }} />
                <div
                  onClick={() => {
                    routeNavigate("/p2p-advertisement")
                    onUpdateReadNotification()
                  }}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    cursor: "pointer",
                  }}
                >
                  <RiP2PFill size={20} color={appColor.white} />{" "}
                </div>
                <div style={{ width: 30 }} />
                <div
                  style={{
                    height: 45,
                    borderRightWidth: 1,
                    borderRightColor: "#b6b6b6",
                    borderRightStyle: "solid",
                  }}
                />
                <div style={{ width: 10 }} />
                <div
                  style={{

                    fontWeight: "bold",
                    height: 45,
                    borderRadius: 4,
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #b6b6b6",
                    paddingLeft: 20,
                    paddingRight: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => setShowProfile(true)}
                >
                  <FontAwesomeIcon icon={faUser} style={{ fontSize: 24 }} />
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: '4rem',
                    }}>
                    <Typography
                      noWrap
                      sx={{
                        textDecoration: "none",
                      }}>
                      {userData?.fullName?.split(" ")[0] ?? "-"}
                    </Typography>
                  </div>
                </div>
                <div style={{ width: 10 }} />
                <div
                  onClick={handleLogout}
                  style={{
                    width: 45,
                    height: 45,
                    borderRadius: 45,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faDoorOpen}
                    style={{ fontSize: 16, color: "#fa6969" }}
                  />
                </div>
                <div style={{ width: 20 }} />
                <Box
                  sx={{
                    px: 0.5
                  }}
                >
                  <FormControl fullWidth>
                    <Select
                      labelId="binary-simple-select-label"
                      id="binary-simple-select"
                      sx={{
                        backgroundColor: appColor.background,
                        padding: 0,
                        color: "#fff",
                        height: 50,
                        width: 110,
                        border: `0.5px solid ${appColor.bgDark}`,
                        textAlign: "start",
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                        {
                          color: appColor.white,
                        },
                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                          color: appColor.white,
                        },
                      }}
                      value={currentLanguage}
                      onChange={handleChangeLanguage}
                    >
                      <MenuItem value={"english"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={englishFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; EN
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={"thailand"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={thailandFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; TH
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={"khr"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={khrFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; KHR
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {/* <FormControl >
                    <Select
                      labelId="binary-simple-select-label"
                      id="binary-simple-select"
                      sx={{
                        color: appColor.bgDark,
                        backgroundColor: appColor.background,
                        padding: 0,
                        color: "#FFF",
                        height: 40,
                        // width: 150,
                        textAlign: "start",
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                        {
                          color: 'white',
                        },
                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                          color: 'white',
                        },
                      }}
                      value={currentLanguage}
                      onChange={handleChangeLanguage}
                    >
                      <MenuItem value={"english"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={englishFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; EN
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={"thailand"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={thailandFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; TH
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={"khr"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={khrFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; KHR
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl> */}
              </Nav>

            ) : (
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  justifyContent: "end",
                  alignItems: "center",
                  paddingTop: 10
                }}
              >
                <FormControl >
                  <Select
                    labelId="binary-simple-select-label"
                    id="binary-simple-select"
                    sx={{
                      padding: 0,
                      color: "#FFF",
                      height: 40,
                      width: 150,
                      textAlign: "start",
                      '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                      {
                        color: 'white',
                      },
                      '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                        color: 'white',
                      },
                    }}
                    value={currentLanguage}
                    onChange={handleChangeLanguage}
                  >
                    <MenuItem value={"english"}>
                      <Box
                        display={"flex"}
                        sx={{
                          gap: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body1" fontWeight={"bold"}>
                          <img src={englishFlag} style={{ cursor: "pointer" }} width={30}
                            height={20} />&nbsp;&nbsp; EN
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={"thailand"}>
                      <Box
                        display={"flex"}
                        sx={{
                          gap: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body1" fontWeight={"bold"}>
                          <img src={thailandFlag} style={{ cursor: "pointer" }} width={30}
                            height={20} />&nbsp;&nbsp; TH
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value={"khr"}>
                      <Box
                        display={"flex"}
                        sx={{
                          gap: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="body1" fontWeight={"bold"}>
                          <img src={khrFlag} style={{ cursor: "pointer" }} width={30}
                            height={20} />&nbsp;&nbsp; KHR
                        </Typography>
                      </Box>
                    </MenuItem>
                  </Select>
                </FormControl>
                <div style={{ width: 20 }} />
                {!isLoading && <button
                  onClick={() => routeNavigate("/auth")}
                  style={{
                    backgroundColor: "rgb(69,75,91)",
                    color: "#fff",
                    borderWidth: 0,
                    borderRadius: 4,
                    height: 40,
                    width: 120,
                  }}
                >
                  {t("login")}
                </button>}
                <div style={{ width: 20 }} />
                {!isLoading && <button
                  onClick={() => routeNavigate("/auth")}
                  style={{
                    backgroundColor: "rgb(95,182,120)",
                    color: "#fff",
                    borderWidth: 0,
                    borderRadius: 4,
                    height: 40,
                    width: 120,
                  }}
                >
                  {t("register")}
                </button>}
              </div>
            )}
          </Navbar.Collapse>
        </Box> : <>
          {userData &&
            <Navbar.Toggle aria-controls="responsive-navbar-nav"
              onClick={toggerNavBar}
            />
          }
          <Box sx={{ cursor: "pointer", paddingTop: "8px" }}>
            <Navbar.Brand>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: 35,
                  alignItems: "end",
                }}
              >
                {userData && <div style={{ paddingTop: 20 }}>
                  <div style={{ display: "flex", width: "80vw", justifyContent: "flex-end", alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                      }}>
                      <div
                        style={{
                          fontWeight: "bold",
                          height: 40,
                          width: "40vw",
                          borderRadius: 4,
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #b6b6b6",
                          paddingLeft: 2,
                          paddingRight: 2,
                          cursor: "pointer",
                          fontSize: 16
                        }}
                        onClick={() => setShowProfile(true)}
                      >
                        <FontAwesomeIcon icon={faUser} style={{ fontSize: 12 }} />
                        <div style={{ width: 10 }} />
                        <div
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: '4rem',
                          }}>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: '4rem',
                            }}>
                            <Typography
                              noWrap
                              sx={{
                                textDecoration: "none",
                              }}>
                              {userData?.fullName?.split(" ")[0] ?? "-"}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: 10 }} />
                      <FormControl >
                        <Select
                          labelId="binary-simple-select-label"
                          id="binary-simple-select"
                          sx={{
                            padding: 0,
                            color: "#FFF",
                            height: 40,
                            width: 100,
                            textAlign: "start",
                            '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                            {
                              color: 'white',
                            },
                            '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                              color: 'white',
                            },
                          }}
                          value={currentLanguage}
                          onChange={handleChangeLanguage}
                        >
                          <MenuItem value={"english"}>
                            <Box
                              display={"flex"}
                              sx={{
                                gap: 1,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body1" fontWeight={"bold"}>
                                <img src={englishFlag} style={{ cursor: "pointer" }} width={30}
                                  height={20} />&nbsp;&nbsp; EN
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem value={"thailand"}>
                            <Box
                              display={"flex"}
                              sx={{
                                gap: 1,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body1" fontWeight={"bold"}>
                                <img src={thailandFlag} style={{ cursor: "pointer" }} width={30}
                                  height={20} />&nbsp;&nbsp; TH
                              </Typography>
                            </Box>
                          </MenuItem>
                          <MenuItem value={"khr"}>
                            <Box
                              display={"flex"}
                              sx={{
                                gap: 1,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Typography variant="body1" fontWeight={"bold"}>
                                <img src={khrFlag} style={{ cursor: "pointer" }} width={30}
                                  height={20} />&nbsp;&nbsp; KHR
                              </Typography>
                            </Box>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ width: 10 }} />

                    <FontAwesomeIcon
                      onClick={() => {
                        routeNavigate("/notificaiton");
                        onUpdateReadNotification();
                      }}
                      icon={faBell}
                      style={{ fontSize: 20, color: "#fff" }}
                    />
                    <div style={{ width: 10 }} />
                  </div>
                </div>}
              </div>
            </Navbar.Brand>
          </Box>
          {
            userData ? (
              <Navbar.Collapse id="responsive-navbar-nav" >
                <Nav className="me-auto"></Nav>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    backgroundColor: appColor.grayOpacity,
                    padding: 20,
                    borderRadius: 6,

                  }}
                >
                  <div onClick={toggerNavBar} style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>

                    <div
                      onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMoneyBillTrendUp}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 10 }} />
                    <h6
                      // onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                      style={{ color: "#fff" }}>{t("trading")}</h6>
                  </div>
                  <div style={{ height: 10 }} />
                  <div onClick={() => {
                    setShowProfile(true)
                    toggerNavBar();

                  }} style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faIdCardClip}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 10 }} />
                    <h6 style={{ color: "#fff" }}>{t("profile")}</h6>
                  </div>
                  <div style={{ height: 10 }} />
                  <div onClick={() =>
                    userData?.role == "ADMIN"
                      ? routeNavigate("/transection-list-admin")
                      : routeNavigate("/transection-list")
                  } style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCoins}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 10 }} />
                    <h6 style={{ color: "#fff" }}>{t("deposit")} / {t("withdraw")}</h6>
                  </div>
                  <div style={{ height: 10 }} />
                  <div onClick={() => routeNavigate("/trade-histories")} style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faClockRotateLeft}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 10 }} />
                    <h6 style={{ color: "#fff" }}>{t("history")}</h6>
                  </div>
                  <div style={{ height: 10 }} />
                  <div
                    onClick={() => routeNavigate(userData?.role === "ADMIN" ? "/admin-vip-page" : "/vip-page")}
                    style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faRankingStar}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 10 }} />
                    <h6 style={{ color: "#fff" }}>VIP Member</h6>
                  </div>
                  <div style={{ height: 10 }} />
                  <div onClick={() => {
                    routeNavigate("/notificaiton")
                    onUpdateReadNotification()
                    toggerNavBar()
                  }} style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBell}
                        style={{ fontSize: 16, color: "#fff" }}
                      />
                    </div>
                    <div style={{ width: 10 }} />
                    <h6 style={{ color: "#fff" }}>{t("Notification")}{" "}<span style={{ backgroundColor: "red", height: 20, borderRadius: 20, position: "relative", fontSize: 14, paddingLeft: 8, paddingRight: 8, color: "#fff" }}>{amountNotification > 9 ? `9+` : amountNotification}</span></h6>
                  </div>
                  <div style={{ height: 10 }} />
                  {
                    userData?.role === "ADMIN" && <>
                      <div onClick={() => routeNavigate("/cost-page")} style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 45,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255,255,255,0.1)",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faDollar}
                            style={{ fontSize: 16, color: "#fff" }}
                          />
                        </div>
                        <div style={{ width: 10 }} />
                        <h6 style={{ color: "#fff" }}>{t("capital-cost")}</h6>
                      </div>
                      <div style={{ height: 10 }} />
                      <div onClick={() => routeNavigate("/profit-page")} style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 45,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255,255,255,0.1)",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPercent}
                            style={{ fontSize: 16, color: "#fff" }}
                          />
                        </div>
                        <div style={{ width: 10 }} />
                        <h6 style={{ color: "#fff" }}>Profit</h6>
                      </div>
                      <div style={{ height: 10 }} />
                      <div onClick={() => routeNavigate("/setting-page")} style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                        <div
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: 45,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(255,255,255,0.1)",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faGear}
                            style={{ fontSize: 16, color: "#fff" }}
                          />
                        </div>
                        <div style={{ width: 10 }} />
                        <h6 style={{ color: "#fff" }}>Setting</h6>
                      </div>
                    </>
                  }
                  <div style={{ height: 20 }} />
                  <div onClick={() => {
                    handleLogout();
                    toggerNavBar();
                  }} style={{ display: "flex", width: "100%", justifyContent: "start", alignItems: "center" }}>
                    <div
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(255,255,255,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faDoorOpen}
                        style={{ fontSize: 16, color: "#fa6969" }}
                      />
                    </div>
                    <div style={{ width: 10 }} />
                    <h6 style={{ color: "#fff" }}>Logout</h6>
                  </div>
                </div>
              </Navbar.Collapse>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: 35,
                    alignItems: "end",
                  }}
                >
                  <img src="/coin-logo.png" style={{ height: 35 }} />
                </div>
                {!isLoading && <div style={{ display: "flex", paddingTop: 5, justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
                  <button
                    onClick={() => routeNavigate("/auth")}
                    style={{
                      backgroundColor: "rgb(69,75,91)",
                      color: "#fff",
                      borderWidth: 0,
                      borderRadius: 4,
                      height: 40,
                      // width: 90,
                    }}
                  >
                    {t("login")}
                  </button>
                  <div style={{ width: 4 }} />
                  <button
                    onClick={() => routeNavigate("/auth")}
                    style={{
                      backgroundColor: "rgb(95,182,120)",
                      color: "#fff",
                      borderWidth: 0,
                      borderRadius: 4,
                      height: 40,
                    }}
                  >
                    {t("register")}
                  </button>
                  <div style={{ width: 10 }} />
                  <FormControl >
                    <Select
                      labelId="binary-simple-select-label"
                      id="binary-simple-select"
                      sx={{
                        padding: 0,
                        color: "#FFF",
                        height: 40,
                        width: 100,
                        textAlign: "start",
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                        {
                          color: 'white',
                        },
                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                          color: 'white',
                        },
                      }}
                      value={currentLanguage}
                      onChange={handleChangeLanguage}
                    >
                      <MenuItem value={"english"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={englishFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; EN
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={"thailand"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={thailandFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; TH
                          </Typography>
                        </Box>
                      </MenuItem>
                      <MenuItem value={"khr"}>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: 1,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>
                            <img src={khrFlag} style={{ cursor: "pointer" }} width={30}
                              height={20} />&nbsp;&nbsp; KHR
                          </Typography>
                        </Box>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>}
              </div>
            )
          }
        </>}
      </Navbar >

      <ProfileModal
        isEdit={isEdit}
        toEdit={toEdit}
        showProfile={showProfile}
        handleCloseProfile={handleProfile}
        userData={userData}
        getUserData={getUserData}
      />
      <AppPopup
        width={screenSize().width > 700 ? 800 : "95%"} height={screenSize().width > 700 ? 600 : "60vh"}
        isShowPopup={showCreditCard}
        handdlePopup={handleShowCreditCard}
      >
        <h3>{t("credit-trade-detail")}</h3>
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <div
              onClick={() => setActiveCard("DEMO")}
              style={{
                border: `1px solid ${activeCard == "DEMO" ? "orange" : "#bebebe"}`,
                borderRadius: 8,
                padding: 20,
                paddingLeft: 32,
                paddingRight: 32,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                icon={faCreditCard}
                style={{
                  fontSize: 60,
                  color: activeCard == "DEMO" ? "orange" : "#bebebe",
                }}
              />
              <div style={{ width: 16 }} />
              <div>
                <h4
                  style={{
                    color: activeCard == "DEMO" ? "orange" : "#bebebe",
                  }}
                >
                  {t("demo-credit")}
                </h4>
                <p style={{ color: activeCard == "DEMO" ? "#000" : "#bebebe" }}>
                  {demoCreditCardData?.credit ? currencyFormat(demoCreditCardData?.credit) : 0}
                </p>
                <Button
                  onClick={() => resetDemoCard(demoCreditCardData["_id"])}
                  variant="outline-secondary"
                  style={{ width: 30, height: 30, padding: 0 }}
                >
                  <FontAwesomeIcon
                    icon={faRotateRight}
                    style={{ fontSize: 16 }}
                  />
                </Button>
              </div>
            </div>
            <div
              onClick={() => setActiveCard("REAL")}
              style={{
                border: `1px solid ${activeCard == "REAL" ? "#f9de5d" : "#bebebe"
                  }`,
                borderRadius: 8,
                padding: 20,
                paddingLeft: 32,
                paddingRight: 32,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                icon={faCreditCard}
                style={{
                  fontSize: 60,
                  color: activeCard == "REAL" ? "#f9de5d" : "#bebebe",
                }}
              />
              <div style={{ width: 16 }} />
              <div>
                <h4
                  style={{
                    color: activeCard == "REAL" ? "#f9de5d" : "#bebebe",
                  }}
                >
                  {t("real-credit")}
                </h4>
                <p
                  style={{
                    color: activeCard == "REAL" ? "#000" : "#bebebe",
                  }}
                >
                  {realCreditCardData?.credit
                    ? currencyFormat(realCreditCardData?.credit)
                    : 0}
                </p>
                <Button
                  variant="outline-success"
                  onClick={() => routeNavigate("/transection-list")}
                  style={{ width: 90, height: 30, padding: 0 }}
                >
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />{" "}
                  {t("deposit")}
                </Button>
              </div>
            </div>
          </div>
        </Container>
        <Button
          onClick={() => onSave()}
          style={{
            borderRadius: 28,
            color: "white",
            minWidth: "170px",
            height: 45,
            borderWidth: 0,
            backgroundColor: "rgba(91, 97, 138)",
            width: "100%",
          }}
        >
          <div>
            {isSaveLoading ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              t("save")
            )}
          </div>
        </Button>
      </AppPopup>
      <AppPopup width={370} height={250} isShowPopup={logoutDialog} handdlePopup={handleLogout}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("do-you-really-want-to-logout")}
          </Typography>
        </Box>
        <Box>
          <ExitToApp
            color="warning"
            sx={{
              width: 60,
              height: 60,
            }}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            gap: 2,
          }}
        >
          <br />
          <Button
            onClick={handleLogoutConfirm}
            style={{
              borderRadius: 28,
              color: "white",
              minWidth: "170px",
              height: 40,
              borderWidth: 0,
              background: appColor.warning,
              width: "100%",
            }}
          >
            {t("confirm-logout")}
          </Button>
        </Box>
      </AppPopup>

      <ChangeLanguage
        appLanguages={appLanguages}
        showModalLanguages={showModalLanguages}
        setShowModalLanguages={setShowModalLanguages}
        onChangeLanguage={onChangeLanguage}
        width={screenSize().width > 700 ? 400 : "95%"}
      />

      <Modal
        open={isShowPopupTransfer}
        onClose={handlePopupTransfer}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{
          ...style, width: 600,
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            sx={{
              justifyContent: "space-between",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontFamily: currentLanguage == "lao" ? "Phetsarath_OT" : "Myriad_Pro"
              }}
            >
              {t("transfer")}
            </Typography>
          </Box>
          <CreditTransfer creditList={creditList} userID={userID} handlePopupTransfer={handlePopupTransfer} getCreditData={getCreditData} />
        </Box>
      </Modal>
    </>
  );
}

export default CustomNavbar;

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { END_POINT_SEVER } from "../../api";
import axios from "axios";
import { Button } from "react-bootstrap";
import { appColor } from "../../constants";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next'

export default function ExchangeRate({ onCloseExchangeRatePopup, isShowPopup }) {
  const { t } = useTranslation();
  const [rate, setRate] = useState(1);
  const [rateWithdraw, setRateWithdraw] = useState(1);
  const [bahtRate, setBahtRate] = useState(1);
  const [bahtRateWithdraw, setBahtRateWithdraw] = useState(1);

  useEffect(() => {
    getRate();
  }, []);

  const getRate = async () => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/rate/?limit=1`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const rateDate = await axios(config);
      if (rateDate?.data?.rate?.length > 0) {
        setRate(rateDate?.data?.rate[0]?.usd);
        setRateWithdraw(rateDate?.data?.rate[0]?.usdWithdraw);
        setBahtRate(rateDate?.data?.rate[0]?.baht ?? 0);
        setBahtRateWithdraw(rateDate?.data?.rate[0]?.bahtWithdraw ?? 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSave = async () => {
    try {
      var config = {
        method: "post",
        url: `${END_POINT_SEVER}/api/rate/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ usd: rate, usdWithdraw: rateWithdraw, baht: bahtRate, bahtWithdraw: bahtRateWithdraw }),
      };
      const adminBank = await axios(config);
      if (adminBank?.data) {
        getRate();
        onCloseExchangeRatePopup();
        toast(t("corrected-successfully"), { type: "success" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0.7)",
        display: isShowPopup ? "flex" : "none",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        top: 0,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: 600,
          backgroundColor: appColor.darkColor,
          borderRadius: 8,
          padding: 20,
          color: "#fff"
        }}
      >
        <h4>{t("exchange-rate")}</h4>
        <div style={{ height: 20 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ width: 150 }}>{t("dollar-exchange-rate")} ({t("deposit")}):</div>
            <input
              type="number"
              style={{ width: 400, textAlign: "center" }}
              value={rate}
              min={0}
              onChange={(e) => {
                let rateNumber = parseInt(e.target.value);
                if (rateNumber < 0) {
                  rateNumber = 0;
                } else {
                  setRate(e.target.value);
                }
              }}
            />
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: 150 }}>{t("dollar-exchange-rate")} ({t("withdraw")}):</div>
            <input
              type="number"
              style={{ width: 400, textAlign: "center" }}
              value={rateWithdraw}
              min={0}
              onChange={(e) => {
                let rateNumber = parseInt(e.target.value);
                if (rateNumber < 0) {
                  rateNumber = 0;
                } else {
                  setRateWithdraw(e.target.value);
                }
              }}
            />
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: 150 }}>{t("baht-exchange-rate")} ({t("deposit")}):</div>
            <input
              type="number"
              style={{ width: 400, textAlign: "center" }}
              value={bahtRate}
              min={0}
              onChange={(e) => {
                let rateNumber = parseInt(e.target.value);
                if (rateNumber < 0) {
                  rateNumber = 0;
                } else {
                  setBahtRate(e.target.value);
                }
              }}
            />
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <div style={{ width: 150 }}>{t("baht-exchange-rate")} ({t("withdraw")}):</div>
            <input
              type="number"
              style={{ width: 400, textAlign: "center" }}
              value={bahtRateWithdraw}
              min={0}
              onChange={(e) => {
                let rateNumber = parseInt(e.target.value);
                if (rateNumber < 0) {
                  rateNumber = 0;
                } else {
                  setBahtRateWithdraw(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div style={{ height: 30 }} />
        <Button
          onClick={() => onSave()}
          style={{
            borderRadius: 28,
            color: "white",
            height: 45,
            borderWidth: 0,
            backgroundColor: appColor.green,
            width: "100%",
          }}
        >
          <div>{t("save")}</div>
        </Button>
      </div>
      <div style={{ height: 10 }} />
      <div
        onClick={() => onCloseExchangeRatePopup(false)}
        style={{
          width: 50,
          height: 50,
          borderRadius: 50,
          backgroundColor: "rgba(255,255,255,0.2)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon
          icon={faClose}
          style={{ color: "#fff", fontSize: 22 }}
        />
      </div>
    </div>
  );
}

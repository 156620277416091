import React, { useState, useEffect } from "react";
import {
  faCircleCheck,
  faCircleXmark,
  faClock,
  faCoins,
  faDownload,
  faEnvelopeOpenText,
  faFileExcel,
  faImage,
  faMinus,
  faMoneyBillTransfer,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomNavbar from "../navbar/Navbar";
import {
  Button,
  Container,
  Form,
  Image,
  Modal,
  Nav,
  Spinner,
  Table,
} from "react-bootstrap";
import { Box, Grid } from "@mui/material";
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from "../../api";
import axios from "axios";
import { appColor, USER_LOCAL_DATA } from "../../constants";
import moment from "moment";
import XLSX from "sheetjs-style";
import AppPopup from "../../common/popup";
import { useTranslation } from 'react-i18next'
import { UPDATE_CREDIT_HISTORY, UPDATE_UNREAD_NOTIFICATIONS } from "../../apollo/crypto";
import { useMutation } from "@apollo/client";
import LoadingPage from "../../common/loadingPage";

export default function TransectionListAdmin() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateCredit, setShowUpdateCredit] = useState(false);
  const [userData, setUserData] = useState();
  const [adminSlipImage, setAdminSlipImage] = useState("");
  const [admin, setAdmin] = useState();
  const [creditHistoryData, setCreditHistoryData] = useState([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [updateCreditStatus, setUpdateCreditStatus] = useState("");
  const [updateCreditDetail, setUpdateCreditDetail] = useState();
  const [dataType, setDataType] = useState("ADD");
  const [totalApproved, setTotalApproved] = useState(0);
  const [totalRejected, setTotalRejected] = useState(0);
  const [totalWaitting, setTotalWaitting] = useState(0);
  const [status, setStatus] = useState("ALL");
  const [startDate, setStartDate] = useState(
    moment(moment().add(-1, "month")).format("yyyy-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("yyyy-MM-DD"));

  const [updateReadNotification] = useMutation(UPDATE_UNREAD_NOTIFICATIONS)

  useEffect(() => {
    const getLoalData = async () => {
      try {
        const _localData = await localStorage.getItem(USER_LOCAL_DATA);
        if (_localData) {
          let parseData = JSON.parse(_localData);
          if (parseData["user"] && parseData["user"]["_id"]) {
            getUserData(parseData["user"]["_id"]);
            getCreditHistoryData();

            await updateReadNotification({
              variables: {
                receiveId: parseData?.user?.role === "ADMIN" ? "ADMIN" : parseData["user"]["_id"],
              }
            })
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLoalData();
    getAdminBank();
  }, []);

  useEffect(() => {
    getCreditHistoryData();
  }, [dataType, startDate, endDate, status]);

  const getUserData = async (userId) => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/users/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const userHandle = await axios(config);
      if (userHandle?.data) {
        setUserData(userHandle.data?.user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAdminBank = async () => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/bank/`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const adminBank = await axios(config);
      if (adminBank?.adminBank) {
        setAdmin(adminBank?.data?.adminBank[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCreditHistoryData = async () => {
    try {
      setIsLoading(true);
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/credit/histories?type=${dataType}&startDate=${startDate}&endDate=${moment(
          moment(endDate).add(1, "day")
        ).format("yyyy-MM-DD")}${status != "ALL" ? `&status=${status}` : ""}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const creditHistoryHandle = await axios(config);
      if (creditHistoryHandle?.data.credit) {
        let _totalApproved = 0;
        let _totalRejected = 0;
        let _totalWaitting = 0;
        for (var i = 0; i < creditHistoryHandle.data.credit.length; i++) {
          if (creditHistoryHandle.data.credit[i].status == "WAITTING")
            _totalWaitting += creditHistoryHandle.data.credit[i].credit;
          if (creditHistoryHandle.data.credit[i].status == "APPROVED")
            _totalApproved += creditHistoryHandle.data.credit[i].credit;
          if (creditHistoryHandle.data.credit[i].status == "REJECTED")
            _totalRejected += creditHistoryHandle.data.credit[i].credit;
        }

        setTotalApproved(_totalApproved);
        setTotalRejected(_totalRejected);
        setTotalWaitting(_totalWaitting);
        setCreditHistoryData(creditHistoryHandle.data?.credit);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  // START UPDATE_CREDIT_HISTORY
  const [
    mutationUpdateCreditStatusHandle,
    { data: updateRes, error: controlError, loading: controlLoading },
  ] = useMutation(UPDATE_CREDIT_HISTORY);

  // END UPDATE_CREDIT_HISTORY
  const onUpdateCredit = async () => {
    try {
      setIsSaveLoading(true);
      let data = {
        status: updateCreditStatus,
        user: updateCreditDetail.user._id,
      };
      if (adminSlipImage) data = { ...data, adminSlip: adminSlipImage };

      var config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/credit/update-credit-history/${updateCreditDetail._id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
      const updateHandle = await axios(config);
      if (updateHandle.data) {
        getCreditHistoryData();

        // UPDATE_CREDIT_HISTORY
        await mutationUpdateCreditStatusHandle(
          {
            variables: {
              status: updateCreditStatus,
              user: updateCreditDetail.user._id,
              updateCreditHistoryId: updateHandle.data.credit._id,
              bankName: updateCreditDetail.bankName
            },
          }
        )

      }
      setIsSaveLoading(false);
      setShowUpdateCredit(false);
    } catch (error) {
      console.log(error);
      setIsSaveLoading(false);
    }
  };


  const requestPresignedUrlAndUploadImageToS3 = async (event) => {
    try {
      const fileData = event?.target?.files[0];

      if (fileData) {
        // TODO: Request Presigned Url
        var config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${END_POINT_SEVER}/api/file/pre-signed-url`,
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify({ fileName: fileData.name, folder: "images" }),
        };
        const presignedUrl = await axios(config);

        // TODO: Upload to S3
        if (presignedUrl?.data?.url) {
          let uploadfile = await axios({
            method: "PUT",
            url: presignedUrl?.data?.url,
            data: fileData,
            headers: {
              "Content-Type": " file/*; image/*",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          });

          // TODO: Set image name to state
          if (uploadfile) {
            setAdminSlipImage(fileData.name);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  function currencyFormat(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async () => {
    try {
      setIsLoading(true);
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/credit/histories?limit=1000&type=${dataType}&startDate=${startDate}&endDate=${moment(
          moment(endDate).add(1, "day")
        ).format("yyyy-MM-DD")}${status != "ALL" ? `&status=${status}` : ""}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const creditHistoryHandle = await axios(config);
      if (creditHistoryHandle.data?.credit?.length > 0) {
        const ws = XLSX.utils.json_to_sheet(creditHistoryHandle.data?.credit);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          "selectoption-" + moment().format("DD-MM-yyyyHH:mm") + fileExtension;
        a.click();
        window.URL.revokeObjectURL(url);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handdlePopup = () => {
    setShowUpdateCredit(!showUpdateCredit);
  };

  return (
    isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
      <LoadingPage />
    </div>
      :
      <div
        className="color-body"
        style={{
          width: "100%",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomNavbar />
        <div style={{ height: 100 }} />
        <AppPopup style={{ position: "absolute", zIndex: 1000,  }} isShowPopup={showUpdateCredit} handdlePopup={handdlePopup} height={updateCreditStatus == "APPROVED" ? 750 : 600}>
          <h3>{t("check-the-request")}</h3>
          <Container>
            <div style={{ display: "flex" }}>
              <Grid container>
                <p>{t("account-type")}</p>
                <input
                  disabled
                  type="text"
                  value={updateCreditDetail?.bankName}
                  style={{ width: "100%", height: 40 }}
                />
                <p>
                  {t("money-that-needs-to")}
                  {updateCreditDetail?.type == "WITHDRAW" ? t("withdraw-1") : t("deposit-1")}
                </p>
                <input
                  disabled
                  type="text"
                  value={updateCreditDetail?.credit ? (updateCreditDetail?.usedBonus ? `$${updateCreditDetail?.credit} (Real Money: $${updateCreditDetail?.realMoney})` : `$${updateCreditDetail?.credit}`) : 0}
                  style={{ width: "100%", height: 40 }}
                />
                <div style={{ height: 14 }} />
                <p>{t("type")}</p>
                <input
                  disabled
                  type="text"
                  value={
                    updateCreditDetail?.type == "ADD"
                      ? t("deposit-request")
                      : t("withdraw-request")
                  }
                  style={{ width: "100%", height: 40 }}
                />
                <div style={{ height: 14 }} />
                <p>{t("status")}</p>
                <input
                  disabled
                  type="text"
                  value={"ລໍຖ້າອະນຸມັດ"}
                  style={{ width: "100%", height: 40 }}
                />
                <p>{t("requester-s-name")}</p>
                <input
                  disabled
                  type="text"
                  value={updateCreditDetail?.user?.fullName ?? "-"}
                  style={{ width: "100%", height: 40 }}
                />
                <p>{t("requester-s-time")}</p>
                <input
                  disabled
                  type="text"
                  value={moment(updateCreditDetail?.createdAt).format(
                    "yyyy/MM/DD HH:mm"
                  )}
                  style={{ width: "100%", height: 40 }}
                />
              </Grid>
              <div style={{ width: 30 }} />
              {updateCreditDetail?.type == "ADD" && (
                <Grid container>
                  <div>
                    <p>{t("picture-of-money-transfer")}</p>
                    <Image
                      src={`${S3_BUCKET_END_POINT}/images/${updateCreditDetail?.image}`}
                      style={{ height: "auto", width: 280, borderRadius: 8 }}
                    />
                  </div>
                </Grid>
              )}
              {updateCreditDetail?.type == "WITHDRAW" && (
                <Grid container>
                  <div>
                    <p style={{ textAlign: "left" }}>Payment Type</p>
                    <input
                      disabled
                      type="text"
                      value={updateCreditDetail?.bankName ?? "-"}
                      style={{ width: "100%", minWidth: 250, height: 40 }}
                    />
                    <p style={{ textAlign: "left" }}>Binance ID or Binance Email</p>
                    <input
                      disabled
                      type="text"
                      value={updateCreditDetail?.binanceEmailOrBinanceID ?? "-"}
                      style={{ width: "100%", minWidth: 250, height: 40 }}
                    />
                  </div>
                </Grid>
              )}
            </div>
            <Box height={40} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <div
                onClick={() => setUpdateCreditStatus("APPROVED")}
                style={{
                  display: "flex",
                  width: 200,
                  border: `2px solid ${updateCreditStatus == "APPROVED" ? "#35a947" : "#b6b6b6"
                    }`,
                  borderRadius: 8,
                  height: 50,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  style={{
                    color:
                      updateCreditStatus == "APPROVED" ? "#35a947" : "#b6b6b6",
                    paddingRight: 10,
                  }}
                />{" "}
                {t("approve")}
              </div>
              <div
                onClick={() => setUpdateCreditStatus("REJECTED")}
                style={{
                  display: "flex",
                  width: 200,
                  border: `2px solid ${updateCreditStatus == "REJECTED" ? "#f7052d" : "#b6b6b6"
                    }`,
                  borderRadius: 8,
                  height: 50,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  style={{
                    color:
                      updateCreditStatus == "REJECTED" ? "#f7052d" : "#b6b6b6",
                    paddingRight: 10,
                  }}
                />{" "}
                {t("reject")}
              </div>
            </div>
            {updateCreditDetail?.type == "WITHDRAW" &&
              updateCreditStatus == "APPROVED" && (
                <div>
                  <p>{t("picture-of-money-transfer")}</p>
                  {adminSlipImage ? (
                    <Image
                      src={`${S3_BUCKET_END_POINT}/images/${adminSlipImage}`}
                      style={{ height: "auto", width: 170, marginBottom: 8 }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        height: 130,
                        width: 100,
                        borderRadius: 5,
                        backgroundColor: "#e6e6e6",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faImage}
                        style={{ color: "#bebebe", fontSize: 40 }}
                      />
                    </div>
                  )}
                  <Box height={8} />
                  <Form.Control
                    type="file"
                    onChange={(event) =>
                      requestPresignedUrlAndUploadImageToS3(event)
                    }
                  />
                </div>
              )}
            <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
              <br />
              <Button
                onClick={() => onUpdateCredit()}
                style={{
                  borderRadius: 28,
                  color: "white",
                  height: 45,
                  borderColor: "#fff",
                  background:
                    "linear-gradient(90deg, rgba(91, 97, 138) 22%, rgba(199, 169, 204) 79%)",
                  width: "100%",
                }}
              >
                <div>
                  {isSaveLoading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    t("confirm")
                  )}
                </div>
              </Button>
            </Grid>
            <Box height={10} />
          </Container>
        </AppPopup>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", paddingLeft: 30 }}>
            <FontAwesomeIcon
              icon={faCoins}
              style={{ fontSize: 40, color: "#f7b305" }}
            />
            <div style={{ width: 20 }} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <h4 style={{ color: "#b6b6b6" }}>
                {t("list-of-deposit-and-withdrawals")}
              </h4>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Button variant="success" onClick={() => exportToExcel()}>
              <FontAwesomeIcon
                icon={faFileExcel}
                style={{ fontSize: 20, color: "#fff" }}
              />{" "}
              Export to Excel
            </Button>
            <div style={{ width: 20 }} />
            <select
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              style={{
                borderRadius: 8,
                backgroundColor: appColor.bgDark,
                color: appColor.textWhite,
              }}
            >
              <option value={"ALL"}>{t("show-all")}</option>
              <option value={"WAITTING"}>{t("waiting-for-approval")}</option>
              <option value={"APPROVED"}>{t("approved")}</option>
              <option value={"REJECTED"}>{t("rejected")}</option>
            </select>
            <div style={{ width: 20 }} />
            <input
              style={{
                borderRadius: 8,
                backgroundColor: appColor.bgDark,
                color: appColor.textWhite,
              }}
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <div style={{ color: "#fff", padding: 10 }}>
              <FontAwesomeIcon icon={faMinus} />
            </div>
            <input
              style={{
                borderRadius: 8,
                backgroundColor: appColor.bgDark,
                color: appColor.textWhite,
              }}
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <br />
        <Nav justify variant="tabs" defaultActiveKey="ADD">
          <Nav.Item>
            <Nav.Link
              eventKey={"ADD"}
              style={{ color: "#fff", backgroundColor: "#35a947" }}
              onClick={() => setDataType("ADD")}
            >
              {t("deposit-list")}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey={"WITHDRAW"}
              style={{ color: "#fff", backgroundColor: "#f7052d" }}
              onClick={() => setDataType("WITHDRAW")}
            >
              {t("withdraw-list")}
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div style={{ padding: 20 }}>
          <div style={{ display: "flex", color: "#fff" }}>
            <div>{t("all-waiting")}: ${currencyFormat(totalWaitting) ?? 0} </div>
            <div style={{ paddingLeft: 20 }}>
              {t("all-approved")}: ${currencyFormat(totalApproved) ?? 0}{" "}
            </div>
            <div style={{ paddingLeft: 20 }}>
              {t("all-rejected")}: ${currencyFormat(totalRejected) ?? 0}
            </div>
          </div>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>#</th>
                <th>{t("requester-s-name")}</th>
                <th>{t("account-type")}</th>
                <th>{t("amount-of-money")}</th>
                <th>{t("type")}</th>
                <th>{t("picture-of-money-transfer")}</th>
                <th>{t("status")}</th>
                <th>{t("time-to-create")}</th>
                <th>{t("check")}</th>
              </tr>
            </thead>
            <tbody>
              {creditHistoryData &&
                creditHistoryData.map((value, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{value?.user?.fullName ?? `-- ${t("name-not-found")} --`}</td>
                    <td>{value.bankName ?? <span style={{ color: "yellow" }}>Trade Bonus</span>}</td>
                    <td>${value?.credit ? currencyFormat(value?.credit) : 0}</td>
                    <td>{value?.type == "ADD" ? t("deposit") : t("withdraw")}</td>
                    <td>
                      {value?.type == "ADD" ? (
                        <a
                          href={`${S3_BUCKET_END_POINT}/images/${value?.image}`}
                          download={value?.image}
                          target="_blank"
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      ) : value?.adminSlip ? (
                        <a
                          href={`${S3_BUCKET_END_POINT}/images/${value?.adminSlip}`}
                          download={value?.adminSlip}
                          target="_blank"
                          style={{ cursor: "pointer" }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      {value?.status == "WAITTING" ? (
                        <span>
                          <FontAwesomeIcon
                            icon={faClock}
                            style={{ color: "orange" }}
                          />{" "}
                          {t("waiting-for-approval")}
                        </span>
                      ) : value?.status == "APPROVED" ? (
                        <span>
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{ color: "#35a947" }}
                          />{" "}
                          {t("approved")}
                        </span>
                      ) : (
                        <span>
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{ color: "#f7052d" }}
                          />{" "}
                          {t("rejected")}
                        </span>
                      )}
                    </td>
                    <td>{moment(value?.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                    <td>
                      {value?.status == "WAITTING" ? (
                        <FontAwesomeIcon
                          icon={faEnvelopeOpenText}
                          style={{ color: "#fff", cursor: "pointer" }}
                          onClick={() => {
                            setUpdateCreditDetail(value);
                            setShowUpdateCredit(true);
                          }}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div
          style={{
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: isLoading ? "flex" : "none",
            flexDirection: "column",
            width: "100vw",
            height: "100vh",
            top: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 120,
              height: 120,
              borderRadius: 8,
            }}
          >
            <Spinner animation="border" variant="success" />
          </div>
        </div>
      </div>
  );
}

import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CustomNavbar from "../navbar/Navbar";
import { SEARCH_GOOGLE_IMAGE, USER_LOCAL_DATA, appColor } from "../../constants";
import { BINANCE_URL, END_POINT_SEVER, S3_BUCKET_END_POINT } from "../../api";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  Box,
  Grid,
  Paper,
  Divider,
  Avatar,
  Button,
  Typography,
  TextField,
  Modal,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { Progress } from "antd";
import {
  Menu,
  LeaderboardOutlined,
  WalletOutlined,
  PersonOutline,
  History,
  AttachMoney,
  FlutterDashOutlined,
  GroupOutlined,
  FaceOutlined,
  LogoutOutlined,
  SettingsOutlined,
  NotificationsOutlined,
  Add,
  Remove,
  TrendingUp,
  TrendingDown,
  ArrowDropDown,
  Done,
  ExitToApp,
  Percent,
} from "@mui/icons-material";
import {
  faArrowTrendDown,
  faArrowTrendUp,
  faCircleCheck,
  faCreditCard,
  faMoneyBillTransfer,
  faPlus,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import { FaBtc, FaEthereum, FaEuroSign } from "react-icons/fa";
import { AiOutlineGold } from "react-icons/ai";
import { init, dispose, registerOverlay } from "klinecharts";
import Layout from "../../components/Layout";
import moment, { min } from "moment";
import { toast } from "react-toastify";
import ReactAudioPlayer from "react-audio-player";
import { MODAL_STYLE, appProfitCalculator } from "../../utils";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import {
  CRYPTO_HISTORY,
  CRYPTO_HISTORY_1M,
  CRYPTO_PRICE_CTRL,
  CRYPTO_QUERY_BTC_30s,
  GET_GRAPH_CONTROL,
  RECEIVE_GRAPH1S,
  RECEIVE_GRAPH_COUNTDOWN,
  RECEIVE_NOTIFICATION,
  RECEIVE_TRADE,
  RECEIVE_TRADE_DETECT,
} from "../../apollo/crypto";
import { useTranslation } from 'react-i18next';
import AppContext from "../../appProvider/context";
import laoFlag from '../../images/flag/lao-flag.png';
import englishFlag from '../../images/flag/english-flag.png';
import thailandFlag from '../../images/flag/thailand-flag.png';
import { useTheme } from "@emotion/react";
import { faArrowAltCircleUp, faRankingStar } from "@fortawesome/free-solid-svg-icons";

let tvScriptLoadingPromise;

export default function AdminCtrl() {
  const routeNavigate = useNavigate();
  const { t, i18n } = useTranslation();
  const routeParams = useParams();
  const [binary, setBinary] = useState();
  const [binaryID, setBinaryID] = useState();

  const [timeOfTank, setTimeOfTank] = useState(30000);
  const [beforeData, setBeforeData] = useState(0);
  const [lastData, setLastData] = useState(0);
  const [beforeData1, setBeforeData1] = useState(0);
  const [lastData1, setLastData1] = useState(0);
  const [dataPrice, setDataPrice] = useState([]);
  const [dataPriceRealTime, setDataPriceRealTime] = useState([]);

  const [startPrice2Tank, setStartPrice2Tank] = useState(0);
  const [endPrice2Tank, setEndPrice2Tank] = useState(0);
  const [startPrice3Tank, setStartPrice3Tank] = useState(0);
  const [endPrice3Tank, setEndPrice3Tank] = useState(0);

  const [countTrue2, setCountTrue2] = useState(0);
  const [countTrue3, setCountTrue3] = useState(0);
  const [countFail2, setCountFail2] = useState(0);
  const [countFail3, setCountFail3] = useState(0);
  const [selectTankType, setSelectTankType] = useState("TWO_TANK");

  const [noti2Tank, setNoti2Tank] = useState("");
  const [noti3Tank, setNoti3Tank] = useState("");

  const [tradeType, setTradeType] = useState("MANNUAL");
  const [showCreditCard, setShowCreditCard] = useState(false);
  const [userData, setUserData] = useState();
  const [userID, setUserID] = useState();
  const [demoCreditCardData, setDemoCreditCardData] = useState();
  const [realCreditCardData, setRealCreditCardData] = useState();
  const [usdtWalletData, setUsdtWalletData] = useState();
  const [activeCard, setActiveCard] = useState("DEMO");
  const [draffActiveCard, setDraffActiveCard] = useState("DEMO");
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const [tradingType, setTradingType] = useState("");
  const [tradingID, setTradingID] = useState("");
  const [openPrice, setOpenPrice] = useState(0);
  const [closePrice, setClosePrice] = useState(0);
  const [tradeMoney, setTradeMoney] = useState(10);
  const [countTrade, setCountTrade] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const [profit, setProfit] = useState("$0");
  const [isProfit, setIsProfit] = useState(true);
  const soundWinPlayer = useRef();
  const soundLossPlayer = useRef();
  const kLineChart = useRef();

  const [countfetch, setCountFetch] = useState(timeOfTank / 1000);
  const [profitPercent, setProfitPercent] = useState();

  const { setStateLanguage } = useContext(AppContext);

  useEffect(() => {
    if (tradeMoney && profitPercent) profitCalculate();
  }, [tradeMoney, profitPercent]);

  const profitCalculate = () => {
    setProfit(
      "$ " + currencyFormat(appProfitCalculator(tradeMoney, profitPercent))
    );
  };

  // END PROFIT CALCULATE
  //START CHECK BALANCE ENOUGH OR NOT
  const [balanceIsEnough, setbalanceIsEnough] = useState(false);

  useEffect(() => {
    // ? setTradeMoney(realCreditCardData?.credit ?? 0)
    // : setTradeMoney(demoCreditCardData?.credit ?? 0);
    if (draffActiveCard == "REAL") {
      if (realCreditCardData?.credit > 0) {
        setbalanceIsEnough(true);
      } else {
        setbalanceIsEnough(false);
      }
    } else {
      if (demoCreditCardData?.credit > 0) {
        setbalanceIsEnough(true);
      } else {
        setbalanceIsEnough(false);
      }
    }
    // setbalanceIsEnough(true);
  }, [draffActiveCard, realCreditCardData, demoCreditCardData]);
  //START CHECK BALANCE ENOUGH OR NOT
  // COUNTDOWN
  const [isCounting, setIsCounting] = useState(false);
  const duration = 30;

  const timerProps = {
    isPlaying: isCounting,
    size: 120,
    strokeWidth: 6,
  };

  // const startCountdown = () => {
  //   setIsCounting(true);
  // };

  // const container = useRef();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    background: appColor.bgDark,
    color: appColor.white,
  };

  const styleTrade = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#030711",
    opacity: 0.8,
    // border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleCloseProfile = () => {
    setShowProfile(false);
    setIsEdit(false);
    setBackdrop(true);
  };
  // PROFILE
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [userImage, setUserImage] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const [backdrop, setBackdrop] = useState(true);
  const toEdit = () => {
    setIsEdit(true);
    setBackdrop("static");
  };

  const onEdit = async () => {
    try {
      let data = {
        fullName: fullname,
        phone: phone,
        userName: username,
      };
      if (password && password != "") data = { ...data, password: password };

      var config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/users/${userData["_id"]}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
      const updateHandle = await axios(config);
      // console.log("updateHandle: ", updateHandle);
      if (updateHandle.data) {
        setIsEdit(false);
        setBackdrop(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (routeParams?.tradeType) {
      setBinary(routeParams.tradeType);
      fetchController(routeParams.tradeType)
    }
    const getLoalData = async () => {
      try {
        const _localData = await localStorage.getItem(USER_LOCAL_DATA);
        if (_localData) {
          let parseData = JSON.parse(_localData);
          if (
            parseData["user"] &&
            parseData["user"] &&
            parseData["user"]["_id"]
          ) {
            getUserData(parseData["user"]["_id"]);
            setUserID(parseData["user"]["_id"]);
            getCreditData(parseData["user"]["_id"]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLoalData();
  }, []);

  useEffect(() => {
    if (userData) {
      getTradeHistoryData(userData["_id"]);
    }
  }, [activeCard]);

  useEffect(() => {
    if (userData) {
      getTradeHistoryData(userData["_id"]);
    }
  }, [realCreditCardData]);

  useEffect(() => {
    if (userData) {
      getTradeHistoryData(userData["_id"]);
    }
  }, [demoCreditCardData]);

  // START INIT NEW CHART

  const currentInterval = "1m";
  const currentIntervalGraph = "1m";
  const _limit = 1000;
  const fetchCryptoData = async () => {
    if (binary) {
      try {
        const responseBTCUSDT = await axios.get(BINANCE_URL, {
          params: {
            symbol: binary,
            interval: currentInterval,
            limit: _limit,
          },
        });
        if (responseBTCUSDT.status === 200) {
          // console.log("responseBTCUSDT.data: ", responseBTCUSDT.data);
          responseBTCUSDT.data.forEach((item, index) => {
            // console.log("api item", item);
            let newData = {
              open: parseFloat(item[1]),
              low: parseFloat(item[2]),
              high: parseFloat(item[3]),
              close: parseFloat(item[4]),
              volume: parseFloat(item[5]),
              timestamp: item[6],
              turnover: parseFloat(item[7]),
            };
            // console.log("api item", JSON.stringify(newData));
            // setBtcUSDT((prevData) => [...prevData, newData]);
            setDataPriceRealTime((prevData) => {
              let _draftPrevData = [...prevData, newData];
              let _newPrevData = limitArrayLength(_draftPrevData, _limit);
              return _newPrevData;
            });
            // setStartStream(true);
          });
          // console.log("api item", JSON.stringify(dataPriceRealTime));
          // console.log("btcUSDT", btcUSDT);
        }
      } catch (error) {
        console.log("error fetch Crypto", error);
      }
    }
  };
  useEffect(() => {
    handleGetDelayOrder(binary);
  }, [binary]);

  const [newStreamingData, setNewStreamingData] = useState();
  const [countTime, setcountTime] = useState(0);
  const [isCtrol, setisCtrol] = useState(false);
  const [fixCurrentClosePrice, setfixCurrentClosePrice] = useState(0);

  const [latestCandle, setLatestCandle] = useState();

  useEffect(() => {
    if (dataPriceRealTime.length > 0 && newStreamingData) {
      const dataList = kLineChart.current?.getDataList();
      const lastData = dataList[dataList.length - 1];
      const currentData = {
        open: parseFloat(newStreamingData.open),
        low: parseFloat(newStreamingData.low),
        high: parseFloat(newStreamingData.high),
        close: parseFloat(newStreamingData.close),
        volume: parseFloat(newStreamingData.volume),
        timestamp: newStreamingData.timestamp,
        turnover: parseFloat(newStreamingData.turnover),
      };
      kLineChart.current?.updateData(currentData);
    }

  }, [newStreamingData]);


  const updateDataChart = () => {
    const ws = new WebSocket(
      `wss://stream.binance.com:9443/ws/${binary.toLowerCase()}@kline_${currentIntervalGraph}`
    );
    ws.onopen = () => {
      console.log("WebSocket connection opened");
    };
    ws.onmessage = (event) => {
      const trade = JSON.parse(event.data);
      let newData = {
        open: parseFloat(trade.k.o),
        low: parseFloat(trade.k.l),
        high: parseFloat(trade.k.h),
        close: parseFloat(trade.k.c),
        volume: parseFloat(trade.k.v),
        timestamp: trade.k.T,
        turnover: parseFloat(trade.k.q),
      };
      setNewStreamingData(newData);
    };
    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };
    return () => {
      ws.close();
    };
  };


  const [device, setDevice] = useState('desktop');

  const checkDevice = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      setDevice('mobile');
    } else if (screenWidth >= 768 && screenWidth < 1024) {
      setDevice('iPad');
    } else {
      setDevice('desktop');
    }
  };

  useEffect(() => {
    checkDevice();
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  // check device type desktop or mobile

  useEffect(() => {
    // console.log('isMobileSize :>> ', isMobileSize);
    setIsMobile(isMobileSize)
    // if (isMobile==true) {
    //   console.log("isMobile");
    //   kLineChart.current?.setStyles(
    //     {
    //       candle: {
    //         tooltip: {
    //           showRule: "none",
    //         }
    //       }
    //     });
    // } else {
    //   console.log("isDesktop");
    //   kLineChart.current?.setStyles(
    //     {
    //       candle: {
    //         tooltip: {
    //           showRule: "none",
    //         }
    //       }
    //     });
    // }
  }, [isMobileSize, isMobile]);
  var chartCfg = {
    grid: {
      show: true,
      horizontal: {
        show: true,
        size: 1,
        color: '#343F59',
        style: 'dashed',
        dashedValue: [2, 2]
      },
      vertical: {
        show: true,
        size: 1,
        color: '#343F59',
        style: 'dashed',
        dashedValue: [2, 2]
      }
    },
    candle: {
      // 'candle_solid'|'candle_stroke'|'candle_up_stroke'|'candle_down_stroke'|'ohlc'|'area'
      type: 'candle_solid',
      bar: {
        upColor: appColor.green,
        downColor: appColor.red,
        noChangeColor: '#888888',
        upBorderColor: appColor.green,
        downBorderColor: appColor.red,
        noChangeBorderColor: '#888888',
        upWickColor: appColor.green,
        downWickColor: appColor.red,
        noChangeWickColor: '#888888'
      },
      area: {
        lineSize: 2,
        lineColor: '#2196F3',
        value: 'close',
        backgroundColor: [{
          offset: 0,
          color: 'rgba(33, 150, 243, 0.01)'
        }, {
          offset: 1,
          color: 'rgba(33, 150, 243, 0.2)'
        }],
        point: {
          show: true,
          color: appColor.info,
          radius: 4,
          // rippleColor: getAlphaBlue(0.3),
          rippleColor: appColor.infoActive,
          rippleRadius: 8,
          animation: true,
          animationDuration: 2000
        }
      },
      priceMark: {
        show: true,
        high: {
          show: true,
          color: '#D9D9D9',
          textMargin: 5,
          textSize: 10,
          textFamily: 'Helvetica Neue',
          textWeight: 'normal'
        },
        low: {
          show: true,
          color: '#D9D9D9',
          textMargin: 5,
          textSize: 10,
          textFamily: 'Helvetica Neue',
          textWeight: 'normal',
        },
        last: {
          show: true,
          upColor: appColor.green,
          downColor: appColor.red,
          noChangeColor: '#888888',
          line: {
            show: true,
            // 'solid' | 'dashed'
            style: 'dashed',
            dashedValue: [4, 4],
            size: 1
          },
          text: {
            show: true,
            // 'fill' | 'stroke' | 'stroke_fill'
            style: 'fill',
            size: 12,
            paddingLeft: 4,
            paddingTop: 4,
            paddingRight: 4,
            paddingBottom: 4,
            // 'solid' | 'dashed'
            borderStyle: 'solid',
            borderSize: 1,
            borderDashedValue: [2, 2],
            color: '#FFFFFF',
            family: 'Helvetica Neue',
            weight: 'normal',
            borderRadius: 2
          }
        }
      },
      tooltip: {
        // 'always' | 'follow_cross' | 'none'
        showRule: isMobile ? 'none' : 'always',
        // 'standard' | 'rect'
        showType: 'standard',
        // Custom display, it can be a callback method or an array, when it is a method, it needs to return an array
        // The child item type of the array is { title, value }
        // title and value can be strings or objects, and the object type is { text, color }
        // title or title.text can be an internationalized key,
        // value or value.text supports string templates
        // For example: want to display time, opening and closing, configure [{ title: 'time', value: '{time}' }, { title: 'open', value: '{open}' }, { title: ' close', value: '{close}' }]
        custom: null,
        defaultValue: 'n/a',
        rect: {
          // 'fixed' | 'pointer'
          position: 'pointer',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 6,
          offsetLeft: 10,
          offsetTop: 8,
          offsetRight: 10,
          offsetBottom: 8,
          borderRadius: 4,
          borderSize: 1,
          borderColor: '#f2f3f5',
          color: '#FEFEFE'
        },
        text: {
          size: 12,
          family: 'Helvetica Neue',
          weight: 'normal',
          color: '#D9D9D9',
          marginLeft: 10,
          marginTop: 8,
          marginRight: 6,
          marginBottom: 0
        },
        // sample:
        // [{
        //   id: 'icon_id',
        //   position: 'left', // types include 'left', 'middle', 'right'
        //   marginLeft: 8,
        //   marginTop: 6,
        //   marginRight: 0,
        //   marginBottom: 0,
        //   paddingLeft: 1,
        //   paddingTop: 1,
        //   paddingRight: 1,
        //   paddingBottom: 1,
        //   icon: '\ue900',
        //   fontFamily: 'iconfont',
        //   size: 12,
        //   color: '#76808F',
        //   backgroundColor: 'rgba(33, 150, 243, 0.2)',
        //   activeBackgroundColor: 'rgba(33, 150, 243, 0.4)'
        // }]
        icons: [<FontAwesomeIcon icon={faArrowAltCircleUp} style={{ fontSize: 20 }} />]
      }
    },
    indicator: {
      ohlc: {
        upColor: 'rgba(45, 192, 142, .7)',
        downColor: 'rgba(249, 40, 85, .7)',
        noChangeColor: '#888888'
      },
      bars: [{
        // 'fill' | 'stroke' | 'stroke_fill'
        style: 'fill',
        // 'solid' | 'dashed'
        borderStyle: 'solid',
        borderSize: 1,
        borderDashedValue: [2, 2],
        upColor: 'rgba(45, 192, 142, .7)',
        downColor: 'rgba(249, 40, 85, .7)',
        noChangeColor: '#888888'
      }],
      lines: [
        {
          // 'solid' | 'dashed'
          style: 'solid',
          smooth: false,
          size: 1,
          dashedValue: [2, 2],
          color: '#FF9600'
        }, {
          style: 'solid',
          smooth: false,
          size: 1,
          dashedValue: [2, 2],
          color: '#935EBD'
        }, {
          style: 'solid',
          smooth: false,
          size: 1,
          dashedValue: [2, 2],
          color: '#2196F3'
        }, {
          style: 'solid',
          smooth: false,
          size: 1,
          dashedValue: [2, 2],
          color: '#E11D74'
        }, {
          style: 'solid',
          smooth: false,
          size: 1,
          dashedValue: [2, 2],
          color: '#01C5C4'
        }
      ],
      circles: [{
        // 'fill' | 'stroke' | 'stroke_fill'
        style: 'fill',
        // 'solid' | 'dashed'
        borderStyle: 'solid',
        borderSize: 1,
        borderDashedValue: [2, 2],
        upColor: 'rgba(45, 192, 142, .7)',
        downColor: 'rgba(249, 40, 85, .7)',
        noChangeColor: '#888888'
      }],
      lastValueMark: {
        show: false,
        text: {
          show: false,
          // 'fill' | 'stroke' | 'stroke_fill'
          style: 'fill',
          color: '#FFFFFF',
          size: 12,
          family: 'Helvetica Neue',
          weight: 'normal',
          // 'solid' | 'dashed'
          borderStyle: 'solid',
          borderSize: 1,
          borderDashedValue: [2, 2],
          paddingLeft: 4,
          paddingTop: 4,
          paddingRight: 4,
          paddingBottom: 4,
          borderRadius: 2
        }
      },
      tooltip: {
        // 'always' | 'follow_cross' | 'none'
        showRule: isMobile ? 'none' : 'always',
        // 'standard' | 'rect'
        showType: 'standard',
        showName: true,
        showParams: true,
        defaultValue: 'n/a',
        text: {
          size: 12,
          family: 'Helvetica Neue',
          weight: 'normal',
          color: '#D9D9D9',
          marginTop: 8,
          marginRight: 6,
          marginBottom: 0,
          marginLeft: 10
        },
        // sample:
        // [{
        //   id: 'icon_id',
        //   position: 'left', // types include 'left', 'middle', 'right'
        //   marginLeft: 8,
        //   marginTop: 6,
        //   marginRight: 0,
        //   marginBottom: 0,
        //   paddingLeft: 1,
        //   paddingTop: 1,
        //   paddingRight: 1,
        //   paddingBottom: 1,
        //   icon: '\ue900',
        //   fontFamily: 'iconfont',
        //   size: 12,
        //   color: '#76808F',
        //   backgroundColor: 'rgba(33, 150, 243, 0.2)',
        //   activeBackgroundColor: 'rgba(33, 150, 243, 0.4)'
        // }]
        icons: [<FontAwesomeIcon icon={faArrowAltCircleUp} style={{ fontSize: 20 }} />]
      }
    },
    xAxis: {
      show: true,
      size: 'auto',
      axisLine: {
        show: false,
        color: '#888888',
        size: 1
      },
      tickText: {
        show: true,
        color: '#D9D9D9',
        family: 'Helvetica Neue',
        weight: 'normal',
        size: 12,
        marginStrat: 4,
        marginBottom: 4
      },
      tickLine: {
        show: true,
        size: 1,
        length: 3,
        color: '#888888'
      }
    },
    yAxis: {
      show: true,
      size: 'auto',
      // 'left' | 'right'
      position: 'right',
      // 'normal' | 'percentage' | 'log'
      type: 'normal',
      inside: false,
      reverse: false,
      axisLine: {
        show: false,
        color: '#888888',
        size: 1
      },
      tickText: {
        show: true,
        color: '#D9D9D9',
        family: 'Helvetica Neue',
        weight: 'normal',
        size: 12,
        marginStrat: 4,
        marginBottom: 4
      },
      tickLine: {
        show: true,
        size: 1,
        length: 3,
        color: '#888888'
      }
    },
    separator: {
      size: 1,
      color: '#888888',
      fill: true,
      activeBackgroundColor: 'rgba(230, 230, 230, .15)'
    },
    crosshair: {
      show: true,
      horizontal: {
        show: true,
        line: {
          show: true,
          // 'solid'|'dashed'
          style: 'dashed',
          dashedValue: [4, 2],
          size: 1,
          color: '#888888'
        },
        text: {
          show: true,
          // 'fill' | 'stroke' | 'stroke_fill'
          style: 'fill',
          color: '#FFFFFF',
          size: 12,
          family: 'Helvetica Neue',
          weight: 'normal',
          // 'solid' | 'dashed'
          borderStyle: 'solid',
          borderDashedValue: [2, 2],
          borderSize: 1,
          borderColor: '#686D76',
          borderRadius: 2,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: '#686D76'
        }
      },
      vertical: {
        show: true,
        line: {
          show: true,
          // 'solid'|'dashed'
          style: 'dashed',
          dashedValue: [4, 2],
          size: 1,
          color: '#888888'
        },
        text: {
          show: true,
          // 'fill' | 'stroke' | 'stroke_fill'
          style: 'fill',
          color: '#FFFFFF',
          size: 12,
          family: 'Helvetica Neue',
          weight: 'normal',
          // 'solid' | 'dashed'
          borderStyle: 'solid',
          borderDashedValue: [2, 2],
          borderSize: 1,
          borderColor: '#686D76',
          borderRadius: 2,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
          paddingBottom: 4,
          backgroundColor: '#686D76'
        }
      }
    },
    overlay: {
      point: {
        color: '#1677FF',
        borderColor: 'rgba(22, 119, 255, 0.35)',
        borderSize: 1,
        radius: 5,
        activeColor: '#1677FF',
        activeBorderColor: 'rgba(22, 119, 255, 0.35)',
        activeBorderSize: 3,
        activeRadius: 5
      },
      line: {
        // 'solid' | 'dashed'
        style: 'solid',
        smooth: false,
        color: '#FFFFFF',
        size: 1,
        dashedValue: [2, 2]
      },
      rect: {
        // 'fill' | 'stroke' | 'stroke_fill'
        style: 'fill',
        color: 'rgba(22, 119, 255, 0.25)',
        borderColor: '#1677FF',
        borderSize: 1,
        borderRadius: 0,
        // 'solid' | 'dashed'
        borderStyle: 'solid',
        borderDashedValue: [2, 2]
      },
      polygon: {
        // 'fill' | 'stroke' | 'stroke_fill'
        style: 'fill',
        color: '#1677FF',
        borderColor: '#1677FF',
        borderSize: 1,
        // 'solid' | 'dashed'
        borderStyle: 'solid',
        borderDashedValue: [2, 2]
      },
      circle: {
        // 'fill' | 'stroke' | 'stroke_fill'
        style: 'fill',
        color: 'rgba(22, 119, 255, 0.25)',
        borderColor: '#1677FF',
        borderSize: 1,
        // 'solid' | 'dashed'
        borderStyle: 'solid',
        borderDashedValue: [2, 2]
      },
      arc: {
        // 'solid' | 'dashed'
        style: 'solid',
        color: '#1677FF',
        size: 1,
        dashedValue: [2, 2]
      },
      text: {
        color: '#FFFFFF',
        size: 12,
        family: 'Helvetica Neue',
        weight: 'normal'
      },
      rectText: {
        // 'fill' | 'stroke' | 'stroke_fill'
        style: 'fill',
        color: '#FFFFFF',
        size: 12,
        family: 'Helvetica Neue',
        weight: 'normal',
        // 'solid' | 'dashed'
        borderStyle: 'solid',
        borderDashedValue: [2, 2],
        borderSize: 1,
        borderRadius: 2,
        borderColor: '#1677FF',
        paddingLeft: 4,
        paddingRight: 4,
        paddingTop: 4,
        paddingBottom: 4,
        backgroundColor: '#1677FF'
      }
    }
  };

  registerOverlay({
    name: 'circle',
    needDefaultPointFigure: true,
    needDefaultXAxisFigure: true,
    needDefaultYAxisFigure: true,
    totalStep: 3,
    createPointFigures: function ({ overlay, coordinates }) {
      if (coordinates.length === 2) {
        const xDis = Math.abs(coordinates[0].x - coordinates[1].x)
        const yDis = Math.abs(coordinates[0].y - coordinates[1].y)
        const radius = Math.sqrt(xDis * xDis + yDis * yDis)
        return {
          key: 'circle',
          type: 'circle',
          attrs: {
            ...coordinates[0],
            r: radius
          },
          styles: {
            style: 'stroke_fill'
          }
        }
      }
      return []
    }
  })

  useEffect(() => {
    if (dataPriceRealTime.length > 0) {
      // let kLine = init("update-k-line");
      // kLine.removeIndicator()

      kLineChart.current = init("update-k-line");
      kLineChart.current?.applyNewData(dataPriceRealTime);
      kLineChart.current?.setStyles(chartCfg);

      // // console.log('newData :>> ', newData);
      setNewStreamingData(dataPriceRealTime[0]);

      // updateDataChart();
      // kLineChart.current?.setStyles(candleTooltip)
    }
    return () => {
      dispose("update-k-line");
    };
  }, [dataPriceRealTime]);

  const toastAlert = (msg, type) => {
    toast(msg, { type: type == "win" ? "success" : "error" });
  };

  const [creditHistoryData, setTradeHistoryData] = useState([]);
  // const historyDataOpen = creditHistoryData.filter(
  //   (item) => item.status == "OPEN"
  // );
  // const historyDataClose = creditHistoryData.filter(
  //   (item) => item.status == "CLOSE"
  // );
  const getTradeHistoryData = async (userId) => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/trade/?user=${userId}&status=OPEN&card=${activeCard == "DEMO"
          ? demoCreditCardData["_id"]
          : realCreditCardData["_id"]
          }`,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const tradeHistoryHandle = await axios(config);
      if (tradeHistoryHandle?.data) {
        setTradeHistoryData(tradeHistoryHandle.data?.trade);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // END TRADE HISTORY

  // useEffect(() => {
  //   if (binary) {
  //     const fetchTickerData = async () => {
  //       try {
  //         if (binary == "GOLDUSDT") {
  //           const response = await axios.get(
  //             "https://data-asg.goldprice.org/dbXRates/USD"
  //           );
  //           setDataPrice((prevData) => {
  //             let _draftPrevData = [
  //               ...prevData,
  //               response?.data?.items[0].xauPrice,
  //             ];
  //             let _newPrevData = limitArrayLength(_draftPrevData);
  //             return _newPrevData;
  //           });
  //         } else {
  //           const response = await axios.get(
  //             "https://api.binance.com/api/v3/ticker/price",
  //             { params: { symbol: binary } }
  //           );
  //           setDataPrice((prevData) => {
  //             let _draftPrevData = [...prevData, response?.data?.price];
  //             let _newPrevData = limitArrayLength(_draftPrevData);
  //             return _newPrevData;
  //           });
  //         }
  //       } catch (error) {
  //         console.error("Error fetching ticker data:", error);
  //       }
  //     };

  //     const interval = setInterval(fetchTickerData, timeOfTank);

  //     return () => clearInterval(interval);
  //   }
  // }, [timeOfTank, binary]);

  const onLoadScriptRef = useRef();
  useEffect(() => {
    // console.log("binary: ", binary);
    if (binary) {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement("script");
          script.id = "tradingview-widget-loading-script";
          script.src = "https://s3.tradingview.com/tv.js";
          script.type = "text/javascript";
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(
        () => onLoadScriptRef.current && onLoadScriptRef.current()
      );

      return () => (onLoadScriptRef.current = null);

      function createWidget() {
        if (
          document.getElementById("tradingview_22cb2") &&
          "TradingView" in window
        ) {
          new window.TradingView.widget({
            width: window.innerWidth,
            height: 400,
            symbol:
              binary == "GOLDUSDT" ? "FOREXCOM:XAUUSD" : `BINANCE:${binary}`,
            interval: "1",
            timezone: "Asia/Bangkok",
            theme: "dark",
            style: "1",
            locale: "en",
            enable_publishing: false,
            save_image: false,
            container_id: "tradingview_22cb2",
          });
        }
      }
    }
  }, [binary]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countfetch > 0) {
        if (countfetch - 1 == 0) {
          setCountFetch(timeOfTank / 1000 + 1);
          setcountTime((prevCountdown) => prevCountdown + 1);
        }
        setCountFetch((prevCountdown) => prevCountdown - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countfetch]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (countTrade > 0) {
  //       setClosePrice(newStreamingData.close);
  //       if (countTrade - 1 == 0) {
  //         onUpdateBuyAndSell();
  //       }
  //       setCountTrade((prevCountdown) => prevCountdown - 1);
  //     }
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, [countTrade]);

  const [countfetch2, setCountFetch2] = useState(timeOfTank / 1000);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countfetch2 > 0) {
        if (countfetch2 - 1 == 0) {
          setCountFetch2(timeOfTank / 1000 + 1);
        }
        setCountFetch2((prevCountdown) => prevCountdown - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countfetch2]);

  const [countSignal2, setCountSignal2] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countSignal2 > 0) {
        setCountSignal2((prevCountdown) => prevCountdown - 1);
      } else {
        setEndPrice3Tank(lastData);
        if (startPrice3Tank && lastData) {
          if (noti3Tank == "BUY" && lastData > startPrice3Tank)
            setCountTrue3(countTrue3 + 1);
          if (noti3Tank == "BUY" && lastData < startPrice3Tank)
            setCountFail3(countFail3 + 1);
          if (noti3Tank == "SELL" && lastData < startPrice3Tank)
            setCountTrue3(countTrue3 + 1);
          if (noti3Tank == "SELL" && lastData > startPrice3Tank)
            setCountFail3(countFail3 + 1);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countSignal2]);

  const [countSignal22, setCountSignal22] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (countSignal22 > 0) {
        setCountSignal22((prevCountdown) => prevCountdown - 1);
      } else {
        setEndPrice2Tank(lastData);
        if (startPrice2Tank && lastData) {
          if (noti2Tank == "BUY" && lastData > startPrice2Tank)
            setCountTrue2(countTrue2 + 1);
          if (noti2Tank == "BUY" && lastData < startPrice2Tank)
            setCountFail2(countFail2 + 1);
          if (noti2Tank == "SELL" && lastData < startPrice2Tank)
            setCountTrue2(countTrue2 + 1);
          if (noti2Tank == "SELL" && lastData > startPrice2Tank)
            setCountFail2(countFail2 + 1);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countSignal22]);

  const [countSignal, setCountSignal] = useState(0);
  const [checkSignal, setCheckSignal] = useState("_____");
  useEffect(() => {
    const interval = setInterval(() => {
      if (countSignal > 0) {
        setCountSignal((prevCountdown) => prevCountdown - 1);
      } else {
        setCheckSignal("_____");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countSignal]);

  const [countSignal2Tank, setCountSignal2Tank] = useState(0);
  const [checkSignal22, setCheckSignal22] = useState("_____");
  useEffect(() => {
    const interval = setInterval(() => {
      if (countSignal2Tank > 0) {
        setCountSignal2Tank((prevCountdown) => prevCountdown - 1);
      } else {
        setCheckSignal22("_____");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [countSignal2Tank]);

  useEffect(() => {
    if (dataPrice.length > 0) {
      if (dataPrice.length > 1) setBeforeData(dataPrice.slice(-2)[0]);
      setLastData(dataPrice.slice(-1)[0]);

      // Signal 2 tank
      if (
        dataPrice.slice(-1)[0] > dataPrice.slice(-2)[0] &&
        dataPrice.slice(-2)[0] > dataPrice.slice(-3)[0] &&
        countSignal2Tank <= 0 &&
        countSignal22 <= 0
      ) {
        setStartPrice2Tank(0);
        setEndPrice2Tank(0);
        setStartPrice2Tank(dataPrice.slice(-1)[0]);
        setCheckSignal22("BUY");
        setNoti2Tank("BUY");
        setCountSignal2Tank(timeOfTank / 1000);
        setCountFetch2(timeOfTank / 1000);
        setCountSignal22(timeOfTank / 1000);

        if (tradeType == "AUTO" && selectTankType == "TWO_TANK")
          onCreateBuyAndSell("BUY");
      }
      if (
        dataPrice.slice(-1)[0] < dataPrice.slice(-2)[0] &&
        dataPrice.slice(-2)[0] < dataPrice.slice(-3)[0] &&
        countSignal2Tank <= 0 &&
        countSignal22 <= 0
      ) {
        setStartPrice2Tank(0);
        setEndPrice2Tank(0);
        setStartPrice2Tank(dataPrice.slice(-1)[0]);
        setCheckSignal22("SELL");
        setNoti2Tank("SELL");
        setCountSignal2Tank(timeOfTank / 1000);
        setCountFetch2(timeOfTank / 1000);
        setCountSignal22(timeOfTank / 1000);

        if (tradeType == "AUTO" && selectTankType == "TWO_TANK")
          onCreateBuyAndSell("SELL");
      }

      // Signal 3 tank
      if (
        dataPrice.slice(-1)[0] > dataPrice.slice(-2)[0] &&
        dataPrice.slice(-2)[0] > dataPrice.slice(-3)[0] &&
        dataPrice.slice(-3)[0] > dataPrice.slice(-4)[0] &&
        countSignal <= 0 &&
        countSignal2 <= 0
      ) {
        setStartPrice3Tank(0);
        setEndPrice3Tank(0);
        setStartPrice3Tank(dataPrice.slice(-1)[0]);
        setCheckSignal("BUY");
        setNoti3Tank("BUY");
        setCountSignal(timeOfTank / 1000);
        setCountFetch(timeOfTank / 1000);
        setCountSignal2(timeOfTank / 1000);

        if (tradeType == "AUTO" && selectTankType == "THREE_TANK")
          onCreateBuyAndSell("BUY");
      }
      if (
        dataPrice.slice(-1)[0] < dataPrice.slice(-2)[0] &&
        dataPrice.slice(-2)[0] < dataPrice.slice(-3)[0] &&
        dataPrice.slice(-3)[0] < dataPrice.slice(-4)[0] &&
        countSignal <= 0 &&
        countSignal2 <= 0
      ) {
        setStartPrice3Tank(0);
        setEndPrice3Tank(0);
        setStartPrice3Tank(dataPrice.slice(-1)[0]);
        setCheckSignal("SELL");
        setNoti3Tank("SELL");
        setCountSignal(timeOfTank / 1000);
        setCountFetch(timeOfTank / 1000);
        setCountSignal2(timeOfTank / 1000);

        if (tradeType == "AUTO" && selectTankType == "THREE_TANK")
          onCreateBuyAndSell("SELL");
      }
    }
  }, [dataPrice]);

  function limitArrayLength(arr, max) {
    if (arr.length > max ?? 10) {
      return arr.slice(arr.length - max ?? 10);
    }
    return arr.slice();
  }

  const getUserData = async (userId) => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/users/${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const userHandle = await axios(config);
      if (userHandle?.data) {
        setUserData(userHandle.data?.user);
        setFullname(userHandle.data?.user?.fullName ?? "");
        setUsername(userHandle.data?.user?.userName ?? "");
        setPhone(userHandle.data?.user?.phone ?? "");
        setUserImage(userHandle.data?.user?.image ?? "");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCreditData = async (userId) => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const creditHandle = await axios(config);
      if (creditHandle?.data) {
        for (var i = 0; i < creditHandle.data?.credit.length; i++) {
          if (creditHandle.data?.credit[i].type == "DEMO")
            setDemoCreditCardData(creditHandle.data?.credit[i]);
          if (creditHandle.data?.credit[i].type == "REAL")
            setRealCreditCardData(creditHandle.data?.credit[i]);
          if (creditHandle.data?.credit[i].type == "USDT")
            setUsdtWalletData(creditHandle.data?.credit[i]);
          if (creditHandle.data?.credit[i].isUse == true) {
            setActiveCard(creditHandle.data?.credit[i].type);
            setDraffActiveCard(creditHandle.data?.credit[i].type);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeCreditCard = async (card) => {
    try {
      setIsSaveLoading(true);
      var config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/credit/${card == "DEMO"
          ? demoCreditCardData["_id"]
          : realCreditCardData["_id"]
          }`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ isUse: true }),
      };
      const updateHandle = await axios(config);
      var configFalse = {
        method: "put",
        url: `${END_POINT_SEVER}/api/credit/${card == "REAL"
          ? demoCreditCardData["_id"]
          : realCreditCardData["_id"]
          }`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ isUse: false }),
      };
      await axios(configFalse);

      if (updateHandle.data) {
        getCreditData(userData["_id"]);
        setShowCreditCard(false);
      }
      setIsSaveLoading(false);
    } catch (error) {
      console.log(error);
      setIsSaveLoading(false);
    }
  };

  const resetDemoCard = async (cardId) => {
    try {
      var config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/credit/${cardId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ credit: 10000 }),
      };
      const updateHandle = await axios(config);
      if (updateHandle.data) {
        getCreditData(userData["_id"]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function currencyFormat(num) {
    return num != undefined
      ? num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      : 0;
  }

  const onCreateBuyAndSell = async (type) => {
    try {
      setTradingType(type);
      if (isCtrol) {
        console.log("fixCurrentClosePrice: ", fixCurrentClosePrice);
        setOpenPrice(fixCurrentClosePrice);
      } else {
        // console.log("newStreamingData.open ", newStreamingData.open);
        setOpenPrice(newStreamingData?.open);
      }

      var config = {
        method: "post",
        url: `${END_POINT_SEVER}/api/trade`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          type: type,
          cardType: draffActiveCard,
          card:
            draffActiveCard == "DEMO"
              ? demoCreditCardData["_id"]
              : realCreditCardData["_id"],
          status: "OPEN",
          binary: binary,
          openPrice: newStreamingData?.open,
          money: tradeMoney,
          user: userData["_id"],
        }),
      };
      const createHandle = await axios(config);
      if (createHandle.data) {
        setTradingID(createHandle.data.trade._id);
        setCountTrade(duration);
        getCreditData(userData["_id"]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isTradeStatus, setisTradeStatus] = useState(false);

  const [showModalLogou, setShowModalLogout] = useState(false);

  const handleModelLogout = () => {
    setShowModalLogout(!showModalLogou);
  };
  const handleLogout = () => {
    // localStorage.clear();
    localStorage.removeItem('CLOSE_POPUP');
    localStorage.removeItem('CLOSE_POPUP_IMAGE');
    localStorage.removeItem(USER_LOCAL_DATA);
    routeNavigate("/");
  };

  const [profits, setProfits] = useState([]);
  useEffect(() => {
    fetchProfitList();
    fetchController();
  }, []);

  useEffect(() => {
    switch (binary) {
      case profits[0]?.binary:
        setProfitPercent(profits[0]?.percent);
        break;
      case profits[1]?.binary:
        setProfitPercent(profits[1]?.percent);
        break;
      case profits[2]?.binary:
        setProfitPercent(profits[2]?.percent);
        break;
    }
  }, [profits, binary]);

  const fetchProfitList = async () => {
    try {
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/profit?status=OPENING`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const tradeHistoryHandle = await axios(config);
      if (tradeHistoryHandle?.data) {
        setProfits(tradeHistoryHandle.data?.profit);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // START DATA FROM GRAPHQL
  const [getGraphData1m] = useLazyQuery(CRYPTO_HISTORY_1M, {
    variables: { binary: binary, limit: 1 },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getGraphData();
  }, [binary]);

  const [getGraphData, { data: graphData }] = useLazyQuery(CRYPTO_HISTORY_1M, {
    variables: { binary: binary, limit: _limit },
    fetchPolicy: 'network-only',

  });

  useEffect(() => {
    if (graphData?.getGraph1m) {
      // DISABLE GRAPHQL SUBSCRIPTION
      setDataPriceRealTime(graphData?.getGraph1m);
    }
  }, [graphData]);

  const { data: receiveGraph1s } = useSubscription(RECEIVE_GRAPH1S, {
    variables: {
      binary: binary
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (receiveGraph1s?.receiveGraph1s) {
      setNewStreamingData(receiveGraph1s?.receiveGraph1s);
    }

  }, [receiveGraph1s]);
  // END DATA FROM GRAPHQL

  // control
  const [isPriceUp, setisPriceUp] = useState(false);
  const [priceControl, setpriceControl] = useState(0);
  // get graph control
  const [graphControllData, setGraphControllData] = useState([]);

  const [getGraphControl, { data: graphControlData }] = useLazyQuery(
    GET_GRAPH_CONTROL,
    {
      variables: { binary: binary, limit: 3 },
    }
  );
  useEffect(() => {
    getGraphControl();
  }, []);

  useEffect(() => {
    if (graphControlData?.getGraphControls) {
      setGraphControllData(graphControlData?.getGraphControls);
    }
  }, [graphControlData]);
  useEffect(() => {
    switch (binary) {
      case graphControllData[0]?.binary:
        setBinaryID(graphControllData[0]?.id);
        setisPriceUp(graphControllData[0]?.enable);
        break;
      case graphControllData[1]?.binary:
        setBinaryID(graphControllData[1]?.id);
        setisPriceUp(graphControllData[1]?.enable);
        break;
      case graphControllData[2]?.binary:
        setBinaryID(graphControllData[2]?.id);
        setisPriceUp(graphControllData[2]?.enable);
        break;
    }
  }, [binary, graphControllData]);

  const [mutationControlHandle, { data: ctrolRes, loading: controlLoading }] = useMutation(CRYPTO_PRICE_CTRL);
  const priceControlHandle = (type) => {
    try {
      mutationControlHandle({
        variables: {
          binaryId: binaryID,
          binary: binary,
          numberControl: priceControl,
          enable: type == "UP" ? true : false,
          userID: userData["_id"],
        }
      })
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (ctrolRes?.graphControl?.binary) toastAlert(t("success"), "win");
  }, [controlLoading, ctrolRes]);

  const [countdownUpdate, setCountdownUpdate] = useState(0);
  const [enableTrading, setEnableTrading] = useState(false);

  const { data: countdownRes } = useSubscription(RECEIVE_GRAPH_COUNTDOWN, {
    variables: {
      roomId: "GraphCountDown",
    },
  });

  useEffect(() => {
    if (countdownRes?.receiveGraphCountDown) {
      setCountdownUpdate(countdownRes?.receiveGraphCountDown?.countNumber);
      setEnableTrading(countdownRes?.receiveGraphCountDown?.enable);
    }
  }, [countdownRes]);
  // END SUBSCRIPTION

  const handleChange = (event) => {
    setBinary(event.target.value);
  };

  // START RECEIVE_TRADE_DETECT;
  const [receiveDataTradingData, setReceiveDataTradingData] = useState({
    calculateBuy: 0,
    calculateSell: 0,
  });

  const { data: receiveDataTrading } = useSubscription(RECEIVE_TRADE_DETECT, {
    variables: {
      detectId: "GraphDetect",
    },
  });
  useEffect(() => {
    setReceiveDataTradingData(receiveDataTrading?.receiveTradeDetect);
  }, [receiveDataTrading]);
  // END RECEIVE_TRADE_DETECT;

  // START RECEIVE NOTIFICATION
  const { data: receiveNotification } = useSubscription(RECEIVE_NOTIFICATION, {
    variables: {
      userId: "ADMIN",
    },
  });
  useEffect(() => {
    toast(receiveNotification?.receiveNotification?.detail, { type: receiveNotification?.receiveNotification.type === "APPROVED" ? "sucess" : "error" });
  }, [receiveNotification]);

  // END RECEIVE NOTIFICATION

  const tooltip = (
    <Tooltip id="tooltip">
      <div style={{ fontFamily: "Phetsarath_OT" }}>ກຳລັງພັດທະນາ</div>
    </Tooltip>
  );

  const currentLanguage = i18n.language;
  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    setStateLanguage(event.target.value)
    localStorage.setItem('appLanguage', event.target.value)
  };

  const handleChangeCard = (event) => {
    if (event.target.value != "USDT") {
      setDraffActiveCard(event.target.value);
      onChangeCreditCard(event.target.value)
    } else {
      onChangeCreditCard(draffActiveCard)
    }
  };

  const binaryFormat = (num) => {
    return num != undefined
      ? num.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      : 0;
  };

  const [controlStatus, setControlStatus] = useState(false);
  const [delayStatus, setDelayStatus] = useState(false);
  const [priceControlAuto, setpriceControlAuto] = useState(0);
  const handleChangeSwitchControl = (event) => {
    if (!event.target.checked) {
      setControlStatus(event.target.checked)
      updateController(false)
    } else if (priceControlAuto > 0) {
      setControlStatus(event.target.checked)
      updateController(true)
    }
  }
  const handleChangeSwitchDelay = async () => {
    try {
      const _delayStatus = !delayStatus
      setDelayStatus(_delayStatus)
      const config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/delay-order/${binary}`,
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify({ status: _delayStatus })
      };
      const delayOrderHandle = await axios(config);
      if (delayOrderHandle?.data) {
        console.log("delayOrderHandle.data?.delayOrder: ", delayOrderHandle.data?.delayOrder)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleGetDelayOrder = async (binary) => {
    try {
      const config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/delay-order/${binary}`,
        headers: { "Content-Type": "application/json" }
      };
      const delayOrderHandle = await axios(config);
      if (delayOrderHandle?.data) {
        setDelayStatus(delayOrderHandle.data?.delayOrder?.status)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const [selectedControlOption, setSelectedControlOption] = useState('UP');
  const fetchController = async (reqBinary) => {
    try {
      if (reqBinary || binary) {
        var config = {
          method: "get",
          url: `${END_POINT_SEVER}/api/controller/${reqBinary || binary}`,
          headers: {
            "Content-Type": "application/json",
          },
        };
        const controllerHandle = await axios(config);
        if (controllerHandle?.data) {
          setControlStatus(controllerHandle.data?.control?.status);
          setpriceControlAuto(controllerHandle.data?.control?.number);
          setSelectedControlOption(controllerHandle.data?.control?.type);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateController = async (updateStatus) => {
    try {
      var config = {
        method: "put",
        url: `${END_POINT_SEVER}/api/controller/${binary}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          status: updateStatus,
          number: priceControlAuto,
          type: selectedControlOption
        }),
      };
      await axios(config);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="color-body"
      style={{
        width: "100%",
        minHeight: "100vh",
        color: "white",
        padding: 10,
      }}
    >
      <ReactAudioPlayer
        src="/sound/win.mpeg"
        type={"audio/mpeg"}
        ref={soundWinPlayer}
        autoplay={false}
      />
      <ReactAudioPlayer
        src="/sound/loss.mpeg"
        type={"audio/mpeg"}
        ref={soundLossPlayer}
        autoplay={false}
      />
      <Box
        display="flex"
        flexDirection={"column"}
      >
        <Box
          display="flex"
          flexDirection={"row"}
          sx={{
            justifyContent: "space-between",
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Box
            display={"flex"}
            sx={{ alignItems: "center", cursor: "pointer" }}
          >
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
              onClick={() => userData?.userName != "ethusdtotc" ? routeNavigate("/signal/BTCOTCUSDT") : routeNavigate("/signal/ETHOTCUSDT")}>
              <img src="/coin-logo-text.png" style={{ height: 45 }} />
            </div>
          </Box>
          <Box
            display={"flex"}
            sx={{
              justifyContent: "center",
              itemsAlign: "center",
              alignContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            {(userData?.userName == "august2002" || userData?.userName == "ethusdtotc") && <div
              labelId="binary-simple-select-label"
              id="binary-simple-select"
              style={{ border: "1px solid #FFFFFF", borderRadius: 5, padding: 5, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10, paddingLeft: 10, paddingRight: 10 }}
            >
              <div style={{ borderRight: "1px solid #FFFFFF", paddingRight: 10 }}>
                <TextField
                  id="outlined-basic"
                  variant="standard"
                  inputProps={{
                    min: 0,
                    disableUnderline: true,
                    style: {
                      textAlign: "center",
                      color: "#FFFFFF ",
                      fontWeight: "bold",
                      fontSize: "20px",
                      width: "120px",
                      height: "20px",
                      backgroundColor: appColor.background,
                    },
                  }}
                  value={priceControlAuto}
                  disabled={controlStatus}
                  onChange={(event) => {
                    if (event.target.value > 0) {
                      setpriceControlAuto(parseInt(event.target.value));
                    } else {
                      setpriceControlAuto(0);
                    }
                  }}
                />
              </div>
              <div>
                <div>Auto Controller</div>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={{ fontSize: "14px" }}>{t('Close')}</Typography>
                  <Switch
                    size='medium'
                    checked={controlStatus}
                    value={true}
                    onChange={handleChangeSwitchControl} />
                  <Typography sx={{ fontSize: "14px" }}>{t('Open')}</Typography>
                </Stack>
              </div>
            </div>}
            {(userData?.userName == "august2002" || userData?.userName == "alldelayuser") && <div
              labelId="binary-simple-select-label"
              id="binary-simple-select"
              style={{ border: "1px solid #FFFFFF", borderRadius: 5, padding: 5, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10, paddingLeft: 10, paddingRight: 10 }}
            >
              <div>
                <div>Delay</div>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography sx={{ fontSize: "14px" }}>{t('Close')}</Typography>
                  <Switch
                    size='medium'
                    defaultChecked={delayStatus}
                    checked={delayStatus}
                    onChange={handleChangeSwitchDelay} />
                  <Typography sx={{ fontSize: "14px" }}>{t('Open')}</Typography>
                </Stack>
              </div>
            </div>}
            <Select
              labelId="binary-simple-select-label"
              id="binary-simple-select"
              sx={{
                color: "#FFF",
                height: 50,
                textAlign: "start",
                '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                {
                  color: 'white',
                },
                '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
              value={currentLanguage}
              onChange={handleChangeLanguage}
            >
              <MenuItem value={"english"}>
                <Box
                  display={"flex"}
                  sx={{
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight={"bold"}>
                    <img src={englishFlag} style={{ cursor: "pointer" }} width={30} height={20} />&nbsp;&nbsp; EN
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem value={"thailand"}>
                <Box
                  display={"flex"}
                  sx={{
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight={"bold"}>
                    <img src={thailandFlag} style={{ cursor: "pointer" }} width={30} height={20} />&nbsp;&nbsp; TH
                  </Typography>
                </Box>
              </MenuItem>
            </Select>
            <Select
              labelId="binary-simple-select-label"
              id="binary-simple-select"
              sx={{
                color: "#FFF", backgroundColor: "rgba(255,255,255,0.1)",
                height: 50,
                textAlign: "start",
                '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                {
                  color: 'white',
                },
                '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                  color: 'white',
                },
              }}
              value={draffActiveCard}
              onChange={handleChangeCard}
            >

              <MenuItem value={"DEMO"}>
                <Box
                  display={"flex"}
                  sx={{
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight={"bold"}>
                    <div style={{ color: appColor.textWhite, fontSize: 14 }}>
                      Demo Account
                    </div>
                    <div style={{ fontSize: 18, color: appColor.textWhite }}>
                      $
                      {demoCreditCardData?.credit
                        ? currencyFormat(demoCreditCardData?.credit)
                        : 0}{" "}
                    </div>
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem value={"REAL"}>
                <Box
                  display={"flex"}
                  sx={{
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight={"bold"}>
                    <div style={{ color: appColor.greenActive, fontSize: 14 }}>
                      Real Account
                    </div>
                    <div style={{ fontSize: 18, color: appColor.greenActive }}>
                      {`$  ${realCreditCardData?.credit
                        ? currencyFormat(realCreditCardData?.credit)
                        : 0}`}
                    </div>
                  </Typography>
                </Box>
              </MenuItem>
            </Select>
            {userData?.userName == "august2002" && <Box
              display={"flex"}
              sx={{
                alignItems: "center",
                gap: 2,
              }}
            >
              <Button
                variant="contained"
                sx={{
                  fontStyle: "bold",
                  "&:hover": {
                    backgroundColor: appColor.info,
                  },
                  height: 48,
                  width: 200
                }}
                onClick={() => routeNavigate("/transection-list-admin")}
              >
                +Deposit & Withdraw
              </Button>
              <SettingsOutlined onClick={() => routeNavigate("/setting-page")} sx={{
                width: 24,
                height: 24,
                cursor: "pointer"
              }} />
              <NotificationsOutlined
                onClick={() => routeNavigate("/notificaiton")}
                sx={{
                  width: 24,
                  height: 24,
                  cursor: "pointer"
                }} />
            </Box>}
          </Box>
        </Box>

        <Box display="flex">
          <Paper
            elevation={0}
            sx={{
              backgroundColor: appColor.background,
              color: "#fff",
              paddingY: 3,
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                p: 2,
                justifyItems: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              height={`calc(95% - 50px)`}
            >
              {userData?.userName == "august2002" && <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                >
                  <LeaderboardOutlined />
                  <p style={{ fontSize: 12 }}>Trading</p>
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => routeNavigate("/trade-histories")}
                >
                  <History />
                  <p style={{ fontSize: 12 }}>History</p>
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => routeNavigate("/transection-list-admin")}
                >
                  <WalletOutlined />
                  <p style={{ fontSize: 12 }}>Wallet</p>
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => setShowProfile(true)}
                >
                  <PersonOutline />
                  <p style={{ fontSize: 12 }}>Profile</p>
                </Box>
                <Box
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => routeNavigate(userData?.role === "ADMIN" ? "/admin-vip-page" : "/vip-page")}
                >
                  <FontAwesomeIcon
                    icon={faRankingStar}
                    style={{ fontSize: 20, color: "#fff" }}
                  />
                  <p style={{ fontSize: 12 }}>{t("VIP")}</p>
                </Box>
                {userData?.role === "ADMIN" ? (
                  <>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => routeNavigate("/cost-page")}
                    >
                      <AttachMoney />
                      <p style={{ fontSize: 12 }}>Cost</p>
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => routeNavigate("/profit-page")}
                    >
                      <Percent />
                      <p style={{ fontSize: 12 }}>Profit</p>
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </Box>}
              <Box
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleModelLogout}
              >
                <LogoutOutlined />
                <p style={{ fontSize: 12 }}>Logout </p>
              </Box>
            </Box>
          </Paper>
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
              width: "100%",
              alignContent: "start",
              justifyContent: "start",
              alignItems: "start",
              p: 1,
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              sx={{
                alignItems: "center",
                cursor: "pointer",
                position: "absolute",
                zIndex: 1,
                pl: 1,
                pt: 3
              }}
            >
              <FormControl fullWidth>
                <Select
                  key={binary}
                  labelId="binary-simple-select-label"
                  id="binary-simple-select"
                  sx={{
                    color: "#FFF",
                    backgroundColor: appColor.background,
                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                    {
                      color: 'white',
                    },
                    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                      color: 'white',
                    },
                  }}
                  value={binary}
                  onChange={handleChange}
                  defaultValue={binary}
                >
                  {profits &&
                    profits.map((item, index) => {
                      return (
                        userData?.userName != "ethusdtotc" ? <MenuItem
                          key={index}
                          value={item?.binary}
                          onClick={() => {
                            routeNavigate(`/signal/${item?.binary}`);
                            setBinary(item?.binary);
                          }}
                        >
                          <Box
                            display={"flex"}
                            sx={{
                              gap: 1,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={item?.logo} style={{ width: 24, height: 24, borderRadius: 24 }} />
                            <Typography variant="body1" fontWeight={"bold"}>
                              <span>{item?.displayName}</span>{" "}
                              <span style={{ color: "#F6931A" }}>
                                {item?.percent} %
                              </span>{" "}
                            </Typography>
                          </Box>
                        </MenuItem> : item?.binary == "ETHOTCUSDT" && <MenuItem
                          key={index}
                          value={item?.binary}
                          onClick={() => {
                            routeNavigate(`/signal/${item?.binary}`);
                            setBinary(item?.binary);
                          }}
                        >
                          <Box
                            display={"flex"}
                            sx={{
                              gap: 1,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={item?.logo} style={{ width: 24, height: 24, borderRadius: 24 }} />
                            <Typography variant="body1" fontWeight={"bold"}>
                              <span>{item?.displayName}</span>{" "}
                              <span style={{ color: "#F6931A" }}>
                                {item?.percent} %
                              </span>{" "}
                            </Typography>
                          </Box>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{
              width: "100%",
              zIndex: 0
            }}>
              <Layout height={`${device == "mobile" ? window.innerHeight - 300 : window.innerHeight - 140}px`}>
                <div
                  id="update-k-line"
                  className="k-line-chart"
                  style={{
                    grid: {
                      show: false,
                      horizontal: {
                        show: true,
                        size: 1,
                        color: "#FFD700",
                        style: "dashed",
                        dashedValue: [2, 2],
                      },
                      vertical: {
                        show: true,
                        size: 1,
                        color: "#FFD700",
                        style: "dashed",
                        dashedValue: [2, 2],
                      },
                    },
                  }}
                />
              </Layout>
            </Box>
          </Box>

          <Paper
            elevation={0}
            sx={{
              backgroundColor: appColor.background,
              width: "300px",
              color: "#fff",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={{
                justifyItems: "center",
                alignItems: "center",
                gap: 2,
                pt: 1,
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  gap: 1,
                  backgroundColor: appColor.bgDark,
                  py: 2,
                  px: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* START CIRCLE COUNTDOWN */}
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    cursor: "pointer",
                    color: appColor.textWhite,
                    backgroundColor: `${enableTrading ? appColor.greenActive : appColor.bgDark}`,
                    "&:hover": {
                      backgroundColor: `${enableTrading ? appColor.green : appColor.background
                        }`,
                    },
                    border: 1,
                  }}
                >
                  <Typography variant="h5">{countdownUpdate} s</Typography>
                </Avatar>
                {/* END CIRCLE COUNTDOWN */}
                {/* START MAX */}
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{
                    width: "100%",
                    alignItems: "stretch",
                    gap: 1,
                  }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      backgroundColor: appColor.infoActive,
                      color: "#FFFFFF",
                      px: 1,
                      borderRadius: "10px",
                    }}
                  >
                    Current: {newStreamingData?.close ? binaryFormat(newStreamingData?.close) : 0}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      backgroundColor: appColor.green,
                      color: "#FFFFFF",
                      px: 1,
                      borderRadius: "10px",
                    }}
                  >
                    Hight: {binary === "BTCUSDT" ? receiveDataTradingData?.maxBTCPrice : binary === "ETHUSDT" ? receiveDataTradingData?.maxETHPrice : binary === "EURUSDT" ? receiveDataTradingData?.maxEURPrice : 0}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      backgroundColor: appColor.red,
                      color: "#FFFFFF",
                      px: 1,
                      borderRadius: "10px",
                    }}
                  >
                    Low: {binary === "BTCUSDT" ? receiveDataTradingData?.minBTCPrice : binary === "ETHUSDT" ? receiveDataTradingData?.minETHPrice : binary === "EURUSDT" ? receiveDataTradingData?.minEURPrice : 0}
                  </Typography>
                </Box>
                {userData?.userName != "alldelayuser" && <TextField
                  id="outlined-basic"
                  variant="standard"
                  inputProps={{
                    min: 0,
                    style: {
                      textAlign: "center",
                      color: "#FFFFFF ",
                      fontWeight: "bold",
                      fontSize: "20px",
                      backgroundColor: appColor.background,
                    },
                  }}
                  value={priceControl}
                  disabled={controlStatus}
                  onChange={(event) => {
                    if (event.target.value > 0) {
                      setpriceControl(parseInt(event.target.value));
                    } else {
                      setpriceControl(0);
                    }
                  }}
                />}
                {userData?.userName != "alldelayuser" && <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={controlStatus}
                    sx={{
                      background: `${appColor.background}`,
                      color: "#FFF",
                      fontWeight: "bold",
                      width: "32%",
                      "&:hover": {
                        background: appColor.background,
                      },
                    }}
                    onClick={() => setpriceControl(10)}
                  >
                    10
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={controlStatus}
                    sx={{
                      background: `${appColor.background}`,
                      color: "#FFF",
                      fontWeight: "bold",
                      width: "32%",
                      "&:hover": {
                        background: appColor.background,
                      },
                    }}
                    onClick={() => setpriceControl(20)}
                  >
                    20
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={controlStatus}
                    sx={{
                      background: `${appColor.background}`,
                      color: "#FFF",
                      fontWeight: "bold",
                      width: "32%",
                      "&:hover": {
                        background: appColor.background,
                      },
                    }}
                    onClick={() => setpriceControl(50)}
                  >
                    50
                  </Button>
                </div>}
                {userData?.userName != "alldelayuser" && <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={controlStatus}
                    sx={{
                      background: `${appColor.background}`,
                      color: "#FFF",
                      fontWeight: "bold",
                      width: "32%",
                      "&:hover": {
                        background: appColor.background,
                      },
                    }}
                    onClick={() => setpriceControl(100)}
                  >
                    100
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={controlStatus}
                    sx={{
                      background: `${appColor.background}`,
                      color: "#FFF",
                      fontWeight: "bold",
                      width: "32%",
                      "&:hover": {
                        background: appColor.background,
                      },
                    }}
                    onClick={() => setpriceControl(200)}
                  >
                    200
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={controlStatus}
                    sx={{
                      background: `${appColor.background}`,
                      color: "#FFF",
                      fontWeight: "bold",
                      width: "32%",
                      "&:hover": {
                        background: appColor.background,
                      },
                    }}
                    onClick={() => setpriceControl(300)}
                  >
                    300
                  </Button>
                </div>}
                {userData?.userName != "alldelayuser" && <Button
                  variant="contained"
                  fullWidth
                  disabled={controlStatus}
                  sx={{
                    background: `${(enableTrading || countdownUpdate > 20) ? appColor.background : appColor.greenTrade}`,
                    color: "#FFF",
                    fontWeight: "bold",
                    "&:hover": {
                      background: `${(enableTrading || countdownUpdate > 20) ? appColor.background : appColor.greenTrade}`,
                    },
                  }}
                  onClick={() => {
                    if (enableTrading || countdownUpdate > 20) console.log("disable")
                    else priceControlHandle("UP")
                  }}
                >
                  Up
                </Button>}
                {userData?.userName != "alldelayuser" && <Button
                  variant="contained"
                  fullWidth
                  disabled={controlStatus}
                  sx={{
                    background: `${(enableTrading || countdownUpdate > 20) ? appColor.background : appColor.redActive}`,
                    color: "#FFF",
                    fontWeight: "bold",
                    "&:hover": {
                      background: `${(enableTrading || countdownUpdate > 20) ? appColor.background : appColor.redActive}`,
                    },
                  }}
                  onClick={() => {
                    if (enableTrading || countdownUpdate > 20) console.log("disable")
                    else priceControlHandle("DOWN")
                  }}
                >
                  Down
                </Button>}
              </Box>
              {/* START CURRENT TRADING */}
              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  gap: 1,
                  p: 2,
                  width: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  sx={{ backgroundColor: appColor.bgDark, px: 2, py: 1, gap: 1 }}
                >
                  <Typography>Total Buy:</Typography>
                  <Typography>
                    {currencyFormat(receiveDataTradingData?.calculateBuy)}
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  sx={{ backgroundColor: appColor.bgDark, px: 2, py: 1, gap: 1 }}
                >
                  <Typography>Total Sell:</Typography>
                  <Typography>
                    {currencyFormat(receiveDataTradingData?.calculateSell)}
                  </Typography>
                </Box>
                {receiveDataTradingData?.tradeDetect?.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        border: 1,
                        borderColor: "#e5e5e5",
                        backgroundColor: appColor.background,
                        borderRadius: "4px",
                        width: "200px",
                        height: "90px",
                      }}
                    >
                      <Box
                        display="flex"
                        sx={{
                          p: 1,
                          gap: 2,
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          display="flex"
                          sx={{
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Typography variant="body2">{item.id}</Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: item.type == "BUY" ? "#000" : "#FFF",
                              backgroundColor:
                                item.type == "BUY" ? "#10ff00" : "#ff0000",
                              cursor: "pointer",
                              borderRadius: "4px",
                            }}
                          >
                            {item.type} | {item.cardType}
                          </Typography>
                        </Box>
                        <Avatar
                          sx={{
                            width: 18,
                            height: 18,
                            backgroundColor: "#FFF",
                            p: 0.5,
                          }}
                        >
                          {(item.id == "BTCUSDT" || item.id == "BTCOTCUSDT") ? (
                            <img src={"https://foreign-xo-buckett.s3.ap-southeast-1.amazonaws.com/logo/btc-logo.png"} style={{ width: 18, height: 18, borderRadius: 18 }} />
                          ) : (item.id == "ETHUSDT" || item.id == "ETHOTCUSDT") ? (
                            <img src={"https://foreign-xo-buckett.s3.ap-southeast-1.amazonaws.com/logo/eth-logo.png"} style={{ width: 18, height: 18, borderRadius: 18 }} />
                          ) : item.id == "EURUSDT" ? (
                            <img src={"https://foreign-xo-buckett.s3.ap-southeast-1.amazonaws.com/logo/eur-logo.png"} style={{ width: 18, height: 18, borderRadius: 18 }} />
                          ) : item.id == "BNBUSDT" ? (
                            <img src={"https://foreign-xo-buckett.s3.ap-southeast-1.amazonaws.com/logo/bnb-logo.png"} style={{ width: 18, height: 18, borderRadius: 18 }} />
                          ) : (
                            <img src={"https://foreign-xo-buckett.s3.ap-southeast-1.amazonaws.com/logo/gold-logo.png"} style={{ width: 18, height: 18, borderRadius: 18 }} />
                          )}
                        </Avatar>
                      </Box>
                      <Box
                        display="flex"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          px: 1,
                        }}
                      >
                        {item.type == "BUY" ? (
                          <TrendingUp
                            sx={{
                              color: appColor.greenActive,
                            }}
                          />
                        ) : (
                          <TrendingDown
                            sx={{
                              color: appColor.redActive,
                            }}
                          />
                        )}
                        <Typography variant="body2">
                          money: $ {currencyFormat(item.money)}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          px: 1,
                        }}
                      >
                        <Typography variant="body2">
                          Calculate: $ {currencyFormat(item.calculate)}
                          {/* {moment(item.createdAt).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )} */}
                        </Typography>
                        <Typography variant="caption">
                          order: {item.count}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <Modal
        open={isTradeStatus}
        onClose={() => setisTradeStatus(false)}
        aria-labelledby="parent-trade-modal-title"
        aria-describedby="parent-trade-modal-description"
      >
        <Box sx={{ ...styleTrade, width: 400 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
              alignItems: "center",
            }}
          >
            {isProfit ? (
              <img src="/FinishIcon.gif" alt="" width={80} height={80} />
            ) : (
              <img src="/SadIcon.gif" alt="" width={80} height={80} />
            )}
            <Typography
              variant="h5"
              sx={{
                color: "#FFFFFF",
                fontWeight: "bold",
              }}
            >
              {isProfit ? "Congraturations" : "Loss"}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: `${isProfit ? "#f9de5d" : "#e34828"}`,
                fontWeight: "bold",
              }}
            >
              {profit}
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showCreditCard}
        onClose={() => setShowCreditCard(false)}
        aria-labelledby="parentcard-modal-title"
        aria-describedby="parentcard-modal-description"
      >
        <Box sx={{ ...style, width: 600 }}>
          <Box>
            <Box id="contained-modal-title-vcenter">{t("credit-trade-detail")}</Box>
          </Box>
          <Box>
            <Box>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-around",
                }}
              >
                <div
                  onClick={() => setDraffActiveCard("DEMO")}
                  style={{
                    border: `1px solid ${draffActiveCard == "DEMO" ? "#f9de5d" : "#bebebe"
                      }`,
                    borderRadius: 8,
                    padding: 20,
                    paddingLeft: 32,
                    paddingRight: 32,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    style={{
                      fontSize: 60,
                      color: draffActiveCard == "DEMO" ? "orange" : "#bebebe",
                    }}
                  />
                  <div style={{ width: 16 }} />
                  <div>
                    <h4
                      style={{
                        color: draffActiveCard == "DEMO" ? "orange" : "#bebebe",
                      }}
                    >
                      {t("demo-credit")}
                    </h4>
                    <p
                      style={{
                        color: draffActiveCard == "DEMO" ? "#000" : "#bebebe",
                      }}
                    >
                      {demoCreditCardData?.credit
                        ? currencyFormat(demoCreditCardData?.credit)
                        : 0}{" "}
                      USD
                    </p>
                    <Button
                      onClick={() => resetDemoCard(demoCreditCardData["_id"])}
                      variant="outline-secondary"
                      style={{ width: 30, height: 30, padding: 0 }}
                    >
                      <FontAwesomeIcon
                        icon={faRotateRight}
                        style={{ fontSize: 16 }}
                      />
                    </Button>
                  </div>
                </div>
                <div
                  onClick={() => setDraffActiveCard("REAL")}
                  style={{
                    border: `1px solid ${draffActiveCard == "REAL" ? "#f9de5d" : "#bebebe"
                      }`,
                    borderRadius: 8,
                    padding: 20,
                    paddingLeft: 32,
                    paddingRight: 32,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    style={{
                      fontSize: 60,
                      color: draffActiveCard == "REAL" ? "#f9de5d" : "#bebebe",
                    }}
                  />
                  <div style={{ width: 16 }} />
                  <div>
                    <h4
                      style={{
                        color:
                          draffActiveCard == "REAL" ? "#f9de5d" : "#bebebe",
                      }}
                    >
                      {t("real-credit")}
                    </h4>
                    <p
                      style={{
                        color: draffActiveCard == "REAL" ? "#000" : "#bebebe",
                      }}
                    >
                      {realCreditCardData?.credit
                        ? currencyFormat(realCreditCardData?.credit)
                        : 0}{" "}
                      USD
                    </p>
                    <Button
                      variant="outline-warning"
                      onClick={() => routeNavigate("/transection-list")}
                      style={{ width: 90, height: 30, padding: 0 }}
                    >
                      <FontAwesomeIcon icon={faPlus} style={{ fontSize: 16 }} />{" "}
                      {t("deposit")}
                    </Button>
                  </div>
                </div>
              </div>
              <div style={{ height: 30 }} />
              <Button
                onClick={() => onChangeCreditCard()}
                style={{
                  borderRadius: 28,
                  color: "white",
                  minWidth: "170px",
                  height: 45,
                  borderColor: "#fff",
                  background: "#f9de5d",
                  // background:
                  // "linear-gradient(90deg, rgba(255,0,213,1) 22%, rgba(0,123,255,1) 79%)",
                  width: "100%",
                }}
              >
                <div>
                  {isSaveLoading ? (
                    <Spinner animation="border" role="status"></Spinner>
                  ) : (
                    t("save")
                  )}
                </div>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={showProfile}
        onClose={handleCloseProfile}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{
          ...style, width: 400, display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          alignItems: "center",
        }}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            sx={{
              justifyContent: "space-between",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
              }}
            >
              {t("personal-information")}
            </Typography>
          </Box>
          <Box height={10} />
          {/* <Avatar
            sx={{
              width: 80,
              height: 80,
              cursor: "pointer",
              border: "4px solid #f9de5d",
            }}
          ></Avatar> */}
          <img src={userImage && userImage.includes(SEARCH_GOOGLE_IMAGE) ? userImage : `${S3_BUCKET_END_POINT}/images/${userImage}`} style={{ width: 80, height: 80, borderRadius: 40 }} />
          <Box height={10} />

          <Box sx={{ width: "100%" }}>
            <Box sx={{ width: "100%" }}>
              <Divider
                sx={{ width: "100%" }}
                color={appColor.white}
              />
              <Box height={2} />
              <Box display={"flex"} sx={{
                justifyContent: "space-between",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
              }}>

                <Box><p>{t("name-and-lastname")}:</p></Box>
                <Box>
                  <input
                    type="text"
                    disabled={!isEdit}
                    value={fullname}
                    onChange={(event) => setFullname(event?.target?.value)}
                    style={{
                      width: "100%",
                      height: 40,
                      border: "none",
                      outline: "none",
                      background: appColor.bgDark,
                      color: appColor.white
                    }}
                  />
                </Box>
              </Box>
              <Box height={2} />
              <Divider
                sx={{ width: "100%" }}
                color={appColor.white}
              />
              <Box height={2} />
              <Box display={"flex"} sx={{
                justifyContent: "space-between",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
              }}>
                <Box><p>{t("phone-number")}:</p></Box>
                <Box> <input
                  type="text"
                  disabled={!isEdit}
                  value={phone}
                  onChange={(event) => setPhone(event?.target?.value)}
                  style={{
                    width: "100%", height: 40,
                    border: "none",
                    outline: "none",
                    background: appColor.bgDark,
                    color: appColor.white
                  }}
                /></Box>
              </Box>
              <Box height={2} />
              <Divider
                sx={{ width: "100%" }}
                color={appColor.white}
              />
              <Box height={2} />
              <Box display={"flex"} sx={{
                justifyContent: "space-between",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
              }}>
                <Box><p>{t("username")}:</p></Box>
                <Box>
                  <input
                    type="text"
                    disabled={!isEdit}
                    value={username}
                    onChange={(event) => setUsername(event?.target?.value)}
                    style={{
                      width: "100%", height: 40,
                      border: "none",
                      outline: "none",
                      background: appColor.bgDark,
                      color: appColor.white
                    }}
                  />
                </Box>
              </Box>
              <Box height={2} />
              <Divider
                sx={{ width: "100%" }}
                color={appColor.white}
              />
              <Box height={2} />
              <Box display={"flex"} sx={{
                justifyContent: "space-between",
                justifyItems: "center",
                alignItems: "center",
                width: "100%",
              }}>
                <Box><p>{t("password")}:</p></Box>
                <Box>
                  <input
                    type="password"
                    disabled={!isEdit}
                    value={!isEdit ? "******************" : password}
                    onChange={(event) => setPassword(event?.target?.value)}
                    style={{
                      width: "100%", height: 40,
                      border: "none",
                      outline: "none",
                      background: appColor.bgDark,
                      color: appColor.white
                    }}
                  />
                </Box>
              </Box>
              <Box height={2} />
              <Divider
                sx={{ width: "100%" }}
                color={appColor.white}
              />
              <Box height={2} />
              <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                <br />
                <Button
                  onClick={() => (!isEdit ? toEdit() : onEdit())}
                  style={{
                    borderRadius: 28,
                    color: "white",
                    minWidth: "170px",
                    height: 45,
                    borderColor: "#fff",
                    background: "linear-gradient(90deg, rgba(91, 97, 138) 22%, rgba(91, 97, 138) 79%)",
                    width: "100%",
                  }}
                >
                  <div>{!isEdit ? t("edit") : t("save")}</div>
                </Button>
              </Grid>
              <Box height={30} />
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* START LOGOUT CONFIRM */}
      <Modal
        open={showModalLogou}
        onClose={handleModelLogout}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...MODAL_STYLE, width: 400 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
              }}
            >
              {t("do-you-really-want-to-logout")}
            </Typography>
            <ExitToApp
              color="warning"
              sx={{
                width: 100,
                height: 100,
              }}
            />
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{
              gap: 2,
            }}
          >
            <br />
            <Button
              onClick={handleLogout}
              style={{
                borderRadius: 28,
                color: "white",
                minWidth: "170px",
                height: 45,
                borderColor: "#fff",
                background: appColor.warning,
                width: "100%",
              }}
            >
              {t("confirm-logout")}
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* END LOGOUT CONFIRM */}
    </div>
  );
}

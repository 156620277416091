import React, { useEffect, useState } from 'react'
import CustomNavbar from '../navbar/Navbar'
import { Typography, useMediaQuery, Box, Button, ButtonGroup, Avatar } from '@mui/material'
import { USER_LOCAL_DATA, appColor, screenSize } from '../../constants'
import { useTheme } from '@emotion/react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { currencyFormat } from '../../utils'
import { useTranslation } from 'react-i18next'
import { Chat, CheckCircle, Close, Edit, Message, Verified, Visibility, VisibilityOff } from '@mui/icons-material';
import { useLazyQuery } from '@apollo/client'
import { GET_AVS_GQL, GET_MC_GQL, GET_P2P_TRANSACTION_GQL } from '../../apollo/crypto'
import P2PReviewPopup from '../../components/p2p/p2p-review'
import { useNavigate } from 'react-router-dom'
import P2PRequestItemContentDisplay from '../../components/p2p-request/item-content-display'
import P2PRequestItem from '../../components/p2p-request/p2p-request-item'
import LoadingPage from '../../common/loadingPage'

const P2POrderRequestPage = () => {
    const routeNavigate = useNavigate();
    const [myMC, setMyMC] = useState();
    const [user, setUser] = useState();
    const { t, i18n } = useTranslation();
    const [userID, setUserID] = useState();
    const [p2pType, setp2pType] = useState("BUY"); // BUY, SELL
    const [scrollPosition, setScrollPosition] = useState(0);
    const [p2ps, setP2ps] = useState();
    const [p2p, setP2p] = useState();

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("lg"));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize])


    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (
                        parseData
                    ) {
                        setUserID(parseData?.user?._id);
                        setUser(parseData?.user);
                    }
                }

            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);


    let queryUser = {
        "query": {
            "merchant": "",
            "customer": userID,
            "tradeType": p2pType
        }
    }
    let queryMC = {
        "query": {
            "merchant": userID,
            "customer": "",
            "tradeType": p2pType
        }
    }
    const [getAvsList, { loading }] = useLazyQuery(GET_P2P_TRANSACTION_GQL, {
        // variables: {
        //     "query": {
        //         "merchant": myMC ? userID : "",
        //         "customer": myMC ? "" : userID,
        //         "tradeType": p2pType
        //     }
        // },

        // variables: user?._id === myMC?.user ? queryMC : queryUser,
        variables: queryMC,
        fetchPolicy: 'network-only'
    })
    const getP2PList = async () => {
        // console.log('user :>> ', user?._id);
        // console.log('user?._id :>> ', user?._id);
        // console.log('myMC?.user :>> ', myMC?.user);
        const { data } = await getAvsList()
        setP2ps(data?.getP2PTransaction?.data)
    }

    const [getMyMC, { data: mcGqlData }] = useLazyQuery(GET_MC_GQL, { fetchPolicy: 'network-only' })

    useEffect(() => {
        if (userID) {
            getMyMC({
                variables: {
                    userId: userID
                }
            })
        }
    }, [userID])

    useEffect(() => {
        if (mcGqlData) {
            if (mcGqlData.getMyMerchant.error == true) {
                console.log(`getMyMC error`, mcGqlData.getMyMerchant.error);
            } else {
                setMyMC(mcGqlData?.getMyMerchant?.data)
            }
        }
    }, [mcGqlData, user])


    const getMyMCData = async () => {
        if (userID) {
            const { data } = await getMyMC({
                variables: {
                    userId: userID
                }
            })
            setMyMC(data?.getMyMerchant?.data)
        }
    }
    useEffect(() => {
        getP2PList();

        getMyMCData();

    }, [myMC, p2pType, userID, user]);

    const setP2PTypeHandle = (indexType) => {
        setp2pType(indexType)
    }

    const [reviewOpen, setReviewOpenOpen] = useState(false);
    const handleReviewOpen = () => {
        setReviewOpenOpen(true)
    }
    const handleReviewClose = () => {
        setReviewOpenOpen(false)
    }


    return (
        loading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center" }}>
                {scrollPosition === 0 ? <>
                    <CustomNavbar />
                    <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />

                </> : <></>}
                <P2PReviewPopup
                    isMobile={isMobile}
                    open={reviewOpen}
                    handleClose={handleReviewClose}
                    p2p={p2p}
                    myMC={myMC}
                    getP2PList={getP2PList}
                />

                <Box
                    sx={{
                        paddingX: isMobile ? 2 : 10,
                    }}>
                    <Box
                        sx={{
                            backgroundColor: appColor.bgDark,

                        }}>
                        <Box
                            position={'sticky'}
                            display={'flex'}
                            flexDirection={'row'}
                            sx={{
                                top: 0,
                                zIndex: 2,
                                padding: '10px',
                                boxShadow: 3,
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: appColor.background,
                                paddingX: isMobile ? 2 : 4,
                                width: '100%',
                            }}
                        >
                            <ButtonGroup
                                size='small'
                                variant="outlined"
                                aria-label="Basic button group"

                            >
                                <Button
                                    size='small'
                                    sx={{
                                        backgroundColor: p2pType == "BUY" ? appColor.green : 'transparent',
                                        border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                                        color: p2pType == "BUY" ? appColor.white : appColor.textGrey,
                                        ":hover": {
                                            backgroundColor: p2pType == "BUY" ? appColor.greenActive : 'transparent',
                                            border: `0.5px solid ${appColor.green}`,
                                        }

                                    }}
                                    onClick={() => setP2PTypeHandle("BUY")}

                                >BUY</Button>
                                <Button
                                    size='small'
                                    sx={{
                                        backgroundColor: p2pType == "SELL" ? appColor.red : 'transparent',
                                        border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                                        color: p2pType == "SELL" ? appColor.white : appColor.textGrey,
                                        ":hover": {
                                            backgroundColor: p2pType == "SELL" ? appColor.redActive : 'transparent',
                                            border: `0.5px solid ${appColor.red}`,
                                        }
                                    }}
                                    onClick={() => setP2PTypeHandle("SELL")}
                                >SELL</Button>
                            </ButtonGroup>
                            <Box sx={{
                                height: 2,
                            }} />
                            <Close sx={{
                                width: 24,
                                height: 24,
                                cursor: "pointer",
                                // padding: 2,
                                // backgroundColor: appColor.red,
                                color: appColor.textGrey
                            }}
                                onClick={() => {
                                    routeNavigate(-1)
                                }}
                            />
                            {/* <Button
                            size='small'
                            sx={{
                                backgroundColor: appColor.primary,
                                // border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                                color: appColor.white,
                                ":hover": {
                                    backgroundColor: appColor.primary,
                                    border: `0.5px solid ${appColor.primary}`,
                                }

                            }}
                        // onClick={handleMCCreateOpen}

                        >{t("Post New Ads")}</Button> */}
                        </Box>

                        <Box sx={{
                            height: 10,
                        }} />
                        {isMobile ?
                            <>
                                <Box
                                >
                                    {p2ps && p2ps?.map((p2p, index) => (
                                        <P2PRequestItem
                                            onClick={() => {
                                                handleReviewOpen()
                                                setP2p(p2p)
                                            }}
                                            key={index} p2pType={p2pType} p2p={p2p} myMC={myMC} setP2PTypeHandle={setP2PTypeHandle} />
                                    ))}
                                </Box>

                            </> :
                            <>
                                <TableContainer component={Paper}
                                    sx={{
                                        backgroundColor: appColor.bgDark,
                                    }}
                                >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >#</TableCell>
                                                {/* <TableCell>{t("ID")}</TableCell> */}
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Trade Type")}</TableCell>
                                                {/* <TableCell>{t("Trade Asset")}</TableCell> */}
                                                {/* <TableCell>{t("Fiat Unit Type")}</TableCell> */}
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Price")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Fiat amount")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Crypto amount")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("merchantConfirmType")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("customerConfirmType")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("created_at")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Action")}</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {p2ps && p2ps?.map((p2p, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                        handleReviewOpen()
                                                        setP2p(p2p)
                                                    }}
                                                >
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row">
                                            {p2p._id}
                                        </TableCell> */}
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {p2p.tradeType}
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row">
                                            {p2p.assetType}
                                        </TableCell> */}
                                                    {/* <TableCell component="th" scope="row">
                                            {p2p.fiatUnitType}
                                        </TableCell> */}
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}>
                                                        {p2p.fiatUnitType} {currencyFormat(parseFloat(p2p.price))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {p2p.fiatUnitType}  {currencyFormat(parseFloat(p2p.fiatAmount))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {p2p.assetType}  {currencyFormat(parseFloat(p2p.amount))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        <Typography variant="body2"
                                                            sx={{
                                                                color: appColor.white,
                                                                backgroundColor: p2p.merchantConfirmType == "APPROVED" ? appColor.greenActive : (p2p.merchantConfirmType == "CANCELLED" ? appColor.red : appColor.textGrey),
                                                                px: 1,
                                                                borderRadius: 2,
                                                            }}
                                                        > {p2p.merchantConfirmType}</Typography>

                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        <Typography variant="body2"
                                                            sx={{
                                                                color: appColor.white,
                                                                backgroundColor: p2p.customerConfirmType == "APPROVED" ? appColor.greenActive : (p2p.customerConfirmType == "CANCELLED" ? appColor.red : appColor.textGrey),
                                                                px: 1,
                                                                borderRadius: 2,
                                                            }}
                                                        > {p2p.customerConfirmType}</Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {p2p.createdAt}
                                                    </TableCell>
                                                    {/* <TableCell component="th" scope="row">
                                            {adv.isPublish === true ? t("Yes") : t("No")}
                                        </TableCell> */}
                                                    <TableCell component="th" scope="row">
                                                        {/* merchantConfirmType */}
                                                        {/* customerConfirmType */}
                                                        {/* PENDING */}
                                                        {/* APPROVED */}
                                                        {/* CANCELLED */}

                                                        {/* routeNavigate(`/chat/${p2p?._id}`) */}
                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                            <Avatar
                                                                sx={{
                                                                    width: 24,
                                                                    height: 24,
                                                                    cursor: "pointer",
                                                                    padding: 2,
                                                                    backgroundColor: appColor.textGrey,
                                                                    "&:hover": {
                                                                        backgroundColor: appColor.textGrey
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    routeNavigate(`/chat/${p2p?._id}`)
                                                                }}
                                                            >
                                                                {" "}
                                                                <Chat sx={{ color: appColor.white }} />
                                                            </Avatar>
                                                            <div style={{ width: 10 }} />
                                                            {myMC ?
                                                                <>
                                                                    {p2p.merchantConfirmType === "PENDING" ?
                                                                        <>
                                                                            <Avatar
                                                                                sx={{
                                                                                    width: 34,
                                                                                    height: 34,
                                                                                    cursor: "pointer",
                                                                                    backgroundColor: appColor.bgGrey,
                                                                                    "&:hover": {
                                                                                        backgroundColor: appColor.bgGrey,
                                                                                    },
                                                                                    //   backgroundColor: `${
                                                                                    //     isCounting ? "#343F59" : "#f9de5d"
                                                                                    //   }`,
                                                                                    // },
                                                                                }}
                                                                                onClick={() => {
                                                                                    handleReviewOpen()
                                                                                    setP2p(p2p)
                                                                                }}
                                                                            >
                                                                                <Visibility fontSize='10px' />
                                                                            </Avatar>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Avatar
                                                                                sx={{
                                                                                    width: 34,
                                                                                    height: 34,
                                                                                    cursor: "pointer",
                                                                                    backgroundColor: appColor.greenActive,
                                                                                    "&:hover": {
                                                                                        backgroundColor: appColor.greenActive,
                                                                                    },
                                                                                    //   backgroundColor: `${
                                                                                    //     isCounting ? "#343F59" : "#f9de5d"
                                                                                    //   }`,
                                                                                    // },
                                                                                }}
                                                                                onClick={() => {
                                                                                    // handleMCUpdateOpen()
                                                                                    // setadvDataUpdate(adv)
                                                                                }}
                                                                            >
                                                                                <CheckCircle fontSize='10px' sx={{
                                                                                    color: appColor.white,
                                                                                }} />
                                                                            </Avatar>
                                                                        </>}
                                                                </>
                                                                :
                                                                <>
                                                                    {p2p.customerConfirmType === "PENDING" ?
                                                                        <>
                                                                            <Avatar
                                                                                sx={{
                                                                                    width: 34,
                                                                                    height: 34,
                                                                                    cursor: "pointer",
                                                                                    backgroundColor: appColor.primary,
                                                                                    "&:hover": {
                                                                                        backgroundColor: appColor.primary,
                                                                                    },
                                                                                    //   backgroundColor: `${
                                                                                    //     isCounting ? "#343F59" : "#f9de5d"
                                                                                    //   }`,
                                                                                    // },
                                                                                }}
                                                                                onClick={() => {
                                                                                    handleReviewOpen()
                                                                                    setP2p(p2p)
                                                                                }}
                                                                            >
                                                                                <Visibility fontSize='10px' />
                                                                            </Avatar>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Avatar
                                                                                sx={{
                                                                                    width: 34,
                                                                                    height: 34,
                                                                                    cursor: "pointer",
                                                                                    backgroundColor: appColor.greenActive,
                                                                                    "&:hover": {
                                                                                        backgroundColor: appColor.greenActive,
                                                                                    },
                                                                                    //   backgroundColor: `${
                                                                                    //     isCounting ? "#343F59" : "#f9de5d"
                                                                                    //   }`,
                                                                                    // },
                                                                                }}
                                                                                onClick={() => {
                                                                                    // handleMCUpdateOpen()
                                                                                    // setadvDataUpdate(adv)
                                                                                }}
                                                                            >
                                                                                <CheckCircle fontSize='10px' sx={{
                                                                                    color: appColor.white,
                                                                                }} />
                                                                            </Avatar>
                                                                        </>}
                                                                </>
                                                            }
                                                        </div>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>}
                    </Box>
                </Box>



            </div>
    )
}

export default P2POrderRequestPage
import React, { useEffect, useState } from 'react'
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Slide,
    Avatar, Typography,
    Divider, TextField, Switch, Stack,

} from '@mui/material'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faExchangeAlt, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { USER_LOCAL_DATA, appColor } from '../../constants';
import { useMutation } from '@apollo/client';
import { CREATE_MC_GQL, UPDATE_MC } from '../../apollo/crypto';
import axios from 'axios';
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api'
import { useNavigate } from 'react-router-dom';
import { currencyFormat } from '../../utils';
import { Form, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from "react-toastify";
import { styled } from '@mui/system';
import { Close } from '@mui/icons-material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CreateMcPagePopup = ({
    isMobile,
    open,
    handleClose,
    adv,
    myMC,
    popupType,
    checkUserData
}) => {
    const { t } = useTranslation();
    const routeNavigate = useNavigate();
    const [myMCData, setMyMCData] = useState();
    const [userID, setUserID] = useState();
    const [mcName, setMCName] = useState();
    const [mcMCDetail, setMCDetail] = useState();
    const [mcVerifyStatus, setMCVerifyStatus] = useState(false);
    const [mcActiveStatus, setMCActiveStatus] = useState(false);
    const [userData, setUserData] = useState();
    const [usdtWalletData, setUsdtWalletData] = useState();
    const [bankName, setBankName] = useState("")
    const [bankAccount, setBankAccount] = useState("")
    const [bankNumber, setBankNumber] = useState("")
    const [bankQR, setBankQR] = useState("")


    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
    };

    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };

    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
    box-sizing: border-box;
    width: 300px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 0px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
    );

    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (parseData) {
                        setUserID(parseData?.user?._id);
                        getUserData(parseData?.user?._id)
                        getCreditData(parseData?.user?._id)
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);
    useEffect(() => {
        if (popupType && popupType == "EDIT") {
            setMyMCData(myMC)
            setMCName(myMC?.merchantName ?? "")
            setBankName(myMC?.bankName ?? "")
            setBankAccount(myMC?.bankAccountName ?? "")
            setBankNumber(myMC?.bankAccountNumber ?? "")
            setBankQR(myMC?.bankAccountQR ?? "")
            setMCDetail(myMC?.mcMCDetail ?? "")
            setMCVerifyStatus(myMC?.verificationStatus)
            setMCActiveStatus(myMC?.isActive)
        }
    }, [myMC]);

    const getUserData = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                setUserData(userHandle.data?.user);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getCreditData = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const creditHandle = await axios(config);
            if (creditHandle?.data) {
                for (var i = 0; i < creditHandle.data?.credit.length; i++) {
                    if (creditHandle.data?.credit[i].type == "USDT") setUsdtWalletData(creditHandle.data?.credit[i]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleMCName = (e) => {
        // console.log('value :>> ', e.target.value);
        setMCName(e.target.value)
    }
    const handleChangeMCDetail = (value) => {
        setMCDetail(`${value.target.value}`)
    }
    const handleMCActive = (value) => {
        // console.log('value :>> ', value.target.checked);
        setMCActiveStatus(value.target.checked)
    }

    const [mutationCreateMC] = useMutation(CREATE_MC_GQL, {
        onCompleted: (data) => {
            handleClose()
            // console.log('data :>> ', data);
            // console.log('data?.createMerchant.error :>> ', data?.createMerchant.error.error);
            if (data?.createMerchant.error.error == false) {
                alert('success', 'Create Merchant Success')
            } else {
                // console.log('data?.createMerchant.error.errorMsg :>> ', data?.createMerchant.error.errorMsg);
                alert(`ERROR: ${data?.createMerchant.error.errorMsg}`)
            }
        },
        onError: (error) => {
            alert('error', error)
        }
    })
    const [mutationUpdateMC] = useMutation(UPDATE_MC, {
        onCompleted: (data) => {
            handleClose()
            // console.log('data :>> ', data);
            // console.log('data?.createMerchant.error :>> ', data?.createMerchant.error.error);
            if (data?.updateMerchant.error.error == false) {
                toast('success', 'Update Merchant Success')
                // alert('success', 'Create Merchant Success')
            } else {
                toast('error', `ERROR: ${data?.updateMerchant.error.errorMsg}`)
                // console.log('data?.createMerchant.error.errorMsg :>> ', data?.createMerchant.error.errorMsg);
                // alert(`ERROR: ${data?.updateMerchant.error.errorMsg}`)
            }
        },
        onError: (error) => {
            alert('error', error)
        }
    })
    const handleCreateMC = async () => {
        const _checkUserData = await checkUserData()
        if (_checkUserData == true) {
            let data = {
                merchantName: mcName,
                verificationStatus: mcVerifyStatus,
                isActive: mcActiveStatus,
                user: userID,
                bankName: bankName,
                bankAccountName: bankAccount,
                bankAccountNumber: bankNumber,
                bankAccountQR: bankQR,
                mcMCDetail: mcMCDetail,
            }
            if (popupType == "EDIT") {
                await mutationUpdateMC({
                    variables: {
                        mcId: myMCData?._id,
                        data: data
                    }
                })
            } else {
                await mutationCreateMC({
                    variables: {
                        data: data
                    }
                })
            }
        }
    }

    const requestPresignedUrlAndUploadImageToS3 = async (event) => {
        try {
            const fileData = event?.target?.files[0]
            if (fileData) {
                // TODO: Request Presigned Url
                var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${END_POINT_SEVER}/api/file/pre-signed-url`,
                    headers: { 'Content-Type': 'application/json' },
                    data: JSON.stringify({ "fileName": fileData.name, "folder": "images" })
                };
                const presignedUrl = await axios(config)

                // TODO: Upload to S3
                if (presignedUrl?.data?.url) {
                    let uploadfile = await axios({
                        method: "PUT",
                        url: presignedUrl?.data?.url,
                        data: fileData,
                        headers: {
                            "Content-Type": " file/*; image/*",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })

                    // TODO: Set image name to state
                    if (uploadfile) {
                        setBankQR(fileData.name)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <React.Fragment>
            <Dialog
                maxWidth={'lg'}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{ width: '100%' }}
            >
                <DialogTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <b>{popupType == "EDIT" ? `${t('Update Merchant')}` : `${t('Create Merchant')}`}</b>
                        <Close sx={{
                            width: 24,
                            height: 24,
                            cursor: "pointer",
                            // padding: 2,
                            // backgroundColor: appColor.red,
                            color: appColor.textGrey
                        }}
                            onClick={() => {
                                handleClose()
                            }}
                        />
                    </Box>
                </DialogTitle>
                <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            paddingX: 5,
                            paddingBottom: 0,
                        }}
                    >
                        <div style={{ fontSize: 20 }}>{t('Merchant Info')}</div>
                        <div style={{ fontSize: 14 }}><u>{t('Policy')}:</u></div>
                        <div style={{ fontSize: 14 }}><FontAwesomeIcon icon={faCircleCheck} style={{ color: userData?.vipUser ? appColor.green : "#b6b6b6" }} /> {t('Verify KYC')}   {!userData?.vipUser ? <u style={{ color: "blue", cursor: "pointer" }} onClick={() => routeNavigate("/request-vip-page")}><i>Request KYC</i></u> : ""}</div>
                        <div style={{ fontSize: 14 }}><FontAwesomeIcon icon={faCircleCheck} style={{ color: usdtWalletData?.credit > 100 ? appColor.green : "#b6b6b6" }} /> {t('Minimum wallet')} $100 ({t('Current')}: ${usdtWalletData?.credit ? currencyFormat(usdtWalletData?.credit) : 0})</div>
                        <br />
                        {/* START Merchant Name */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey,
                                        fontSize: "14px"
                                    }}
                                >{t('Merchant Name')}</Typography>
                                <TextField
                                    id="outlined-basic"
                                    placeholder='Enter MC NAME'
                                    variant="standard"
                                    value={mcName}
                                    sx={{
                                        width: '100%',
                                        fontSize: "14px"
                                    }}
                                    onChange={handleMCName}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{ height: 10 }} />
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{ width: '300px' }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey,
                                        fontSize: "14px"
                                    }}
                                >{t('Merchant Status')} </Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography sx={{ fontSize: "14px" }}>{t('Inactive')}</Typography>
                                    <Switch
                                        size='medium'
                                        checked={mcActiveStatus}
                                        value={true}
                                        onChange={handleMCActive} />
                                    <Typography sx={{ fontSize: "14px" }}>{t('Active')}</Typography>
                                </Stack>
                            </Box>
                        </Box>
                        <Box sx={{ height: 10 }} />
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey,
                                        fontSize: "14px"
                                    }}
                                >{t('Merchant Detail')}</Typography>
                                <TextField
                                    id="outlined-basic"
                                    placeholder={"Enter MC Detail"}
                                    variant="standard"
                                    value={mcMCDetail}
                                    sx={{
                                        width: '100%',
                                        fontSize: "14px"
                                    }}
                                    onChange={handleChangeMCDetail}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{ height: 10 }} />
                        </Box>


                        {/* <br /> */}
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            paddingX: 5,
                            paddingBottom: 0,

                        }}
                    >
                        <div style={{ fontSize: 20 }}>{t('Bank Info')}</div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ width: "100%" }}>
                                <div style={{ height: 10 }} />
                                <div style={{ fontSize: 14 }}>{t('QR Bank Account')}</div>
                                <Image src={`${S3_BUCKET_END_POINT}/images/${bankQR}`} style={{ height: 150, width: 150 }} />
                                <Box height={8} />
                                <Form.Control type="file" style={{ height: 32, width: "100%", fontSize: 12 }} onChange={(event) => requestPresignedUrlAndUploadImageToS3(event)} />
                            </div>
                            <div style={{ height: 10 }} />
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "start", alignItems: "start" }}>
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: 130, fontSize: 14 }}>{t("name-of-the-bank")}:</div>
                                    <Form.Control type='text' style={{ height: 36, width: "100%", fontSize: 14 }} value={bankName} onChange={(e) => setBankName(e.target.value)} />
                                </div>
                                <div style={{ height: 5 }} />
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: 130, fontSize: 14 }}>{t("account-name")}:</div>
                                    <Form.Control type='text' style={{ height: 36, width: "100%", fontSize: 14 }} value={bankAccount} onChange={(e) => setBankAccount(e.target.value)} />
                                </div>
                                <div style={{ height: 5 }} />
                                <div style={{ display: "flex", width: "100%" }}>
                                    <div style={{ width: 130, fontSize: 14 }}>{t("account-number")}:</div>
                                    <Form.Control type='text' style={{ height: 36, width: "100%", fontSize: 14 }} value={bankNumber} onChange={(e) => setBankNumber(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
                <div style={{ height: 30 }} />
                <DialogActions>
                    <Button
                        disabled={(userData?.vipUser && usdtWalletData?.credit > 100) ? false : true}
                        onClick={() => (userData?.vipUser && usdtWalletData?.credit > 100) ? handleCreateMC() : console.log("Can't add merchant")}
                        sx={{
                            color: appColor.white,
                            backgroundColor: (userData?.vipUser && usdtWalletData?.credit > 100) ? appColor.green : "#b6b6b6",
                            fontWeight: 'bold',
                            paddingX: 8,
                            ":hover": {
                                backgroundColor: appColor.greenActive,
                            }
                        }
                        }>
                        <Box sx={{
                            width: 2,
                            fontWeight: 'bold'
                        }} />
                        {t('save')}
                    </Button>
                </DialogActions>

                {/* </DialogContent> */}

            </Dialog>
        </React.Fragment>
    )
}

export default CreateMcPagePopup
import React, { useEffect, useState } from "react";
import CustomNavbar from "../navbar/Navbar";
import { END_POINT_SEVER } from "../../api";
import axios from "axios";
import { Table } from "react-bootstrap";
import moment from "moment";
import { MODAL_STYLE, currencyFormat } from "../../utils";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { USER_LOCAL_DATA, appC, appColor, appColorolor } from "../../constants";
import { toast } from "react-toastify";
import { Delete, DeleteForever, Edit } from "@mui/icons-material";
import { useTranslation } from 'react-i18next'
import LoadingPage from "../../common/loadingPage";

const ProfitPage = () => {
  const { t } = useTranslation();
  const [profits, setProfits] = useState([]);
  const [profitID, setProfitID] = useState("");
  const [profitPercent, setProfitPercent] = useState(0);
  const [binaryName, setBinaryName] = useState("");
  const [binaryLogo, setBinaryLogo] = useState("");
  const [binaryStatus, setBinaryStatus] = useState("OPENING");
  const [userID, setUserID] = useState("");

  const [isUpdate, setIsUpdate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [showProfitModal, setShowProfitModal] = useState(false);
  const handleCloseProfitModal = () => {
    setShowProfitModal(!showProfitModal);
    // setIsUpdate(false);
  };

  const fetchProfitList = async () => {
    try {
      setIsLoading(true)
      var config = {
        method: "get",
        url: `${END_POINT_SEVER}/api/profit`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const profitHandle = await axios(config);
      if (profitHandle?.data) {
        setProfits(profitHandle.data?.profit);
        setBinaryName(profitHandle.data?.displayName);
        setBinaryLogo(profitHandle.data?.logo);
        setBinaryStatus(profitHandle.data?.status);
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };

  useEffect(() => {
    const getLoalData = async () => {
      try {
        const _localData = await localStorage.getItem(USER_LOCAL_DATA);
        if (_localData) {
          let parseData = JSON.parse(_localData);
          if (parseData["user"] && parseData["user"]["_id"]) {
            setUserID(parseData["user"]["_id"]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLoalData();
  }, []);

  useEffect(() => {
    fetchProfitList();
  }, []);

  const handleAddORUpdateFund = async () => {
    var profitAddData = JSON.stringify({
      percent: profitPercent,
      displayName: binaryName,
      logo: binaryLogo,
      status: binaryStatus,
      createdBy: userID
    });
    var profitUpdateData = JSON.stringify({
      percent: profitPercent,
      displayName: binaryName,
      logo: binaryLogo,
      status: binaryStatus
    });
    var urlPath = `${END_POINT_SEVER}/api/profit${isUpdate ? `/${profitID}` : ""
      }`;
    //   convert urlPath to url
    var url = new URL(urlPath);

    var config = {
      method: isUpdate ? "put" : "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: isUpdate ? profitAddData : profitUpdateData,
    };
    const resp = await axios(config);
    if (resp.status === 200) {
      fetchProfitList();

      handleCloseProfitModal();
      toast(t("success"), { type: "success" });
    }
  };
  return (
    isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
      <LoadingPage />
    </div>
      :
      <div
        className="color-body"
        style={{ width: "100%", minHeight: "100vh", color: "white", padding: 10 }}
      >
        <CustomNavbar />
        <div style={{ height: 100 }} />

        {/* Add button on the right */}
        <Typography variant="h4">{t("define-profit")}</Typography>

        <div style={{ padding: 20 }}>
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>#</th>
                <th style={{ display: "flex", justifyContent: "flex-start", paddingLeft: 20 }}>{t("coin-name")}</th>
                <th>{t("profit")} %</th>
                <th>Status</th>
                <th>{t("time-to-create")}</th>
                <th>{t("time-to-update")}</th>
                <th>{t("manage")}</th>
              </tr>
            </thead>
            <tbody>
              {profits &&
                profits.map((value, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ display: "flex", justifyContent: "flex-start", paddingLeft: 20 }}>
                      <img src={value.logo} style={{ width: 24, height: 24, borderRadius: 24 }} />
                      <span style={{ paddingLeft: 10 }}>{value?.displayName}</span>
                    </td>
                    <td>{value?.percent}</td>
                    <td>{value?.status}</td>
                    <td>{moment(value?.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                    <td>{moment(value?.updatedAt).format("yyyy/MM/DD HH:mm")}</td>
                    <td align="center">
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        sx={{
                          gap: 1,
                          justifyContent: "center",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 34,
                            height: 34,
                            cursor: "pointer",
                            backgroundColor: appColor.gray,
                            "&:hover": {
                              backgroundColor: appColor.bgGrey,
                            },
                            //   backgroundColor: `${
                            //     isCounting ? "#343F59" : "#f9de5d"
                            //   }`,
                            // },
                          }}
                          onClick={() => {
                            setProfitID(value?._id);
                            setProfitPercent(value?.percent);
                            setBinaryName(value?.displayName);
                            setBinaryLogo(value?.logo);
                            setBinaryStatus(value?.status);
                            setIsUpdate(true);
                            handleCloseProfitModal();
                          }}
                        >
                          <Edit />
                        </Avatar>
                      </Box>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* START EDIT */}
          <Modal
            open={showProfitModal}
            onClose={handleCloseProfitModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...MODAL_STYLE, width: 400, backgroundColor: appColor.darkColor, color: "#fff" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {isUpdate ? t("update-profit") : t("add-profit")}
                </Typography>
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                  gap: 2,
                }}
              >
                <Grid>
                  <p>{t("profit")} ( % )</p>
                  <input
                    type="text"
                    value={profitPercent}
                    onChange={(event) => setProfitPercent(event?.target?.value)}
                    style={{ width: "100%", height: 40 }}
                  />
                </Grid>
                <Grid>
                  <p>Display Name</p>
                  <input
                    type="text"
                    value={binaryName}
                    onChange={(event) => setBinaryName(event?.target?.value)}
                    style={{ width: "100%", height: 40 }}
                  />
                </Grid>
                <Grid>
                  <p>Logo URL</p>
                  <input
                    type="text"
                    value={binaryLogo}
                    onChange={(event) => setBinaryLogo(event?.target?.value)}
                    style={{ width: "100%", height: 40 }}
                  />
                </Grid>
                <Select
                  labelId="trade-type-select-standard-label"
                  id="trade-type-select-standard"
                  value={binaryStatus}
                  onChange={setBinaryStatus}
                  InputProps={{
                    disableUnderline: true,
                  }}
                >
                  <MenuItem value={"OPENING"}>OPENING</MenuItem>
                  <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                </Select>
                <br />
                <Button
                  onClick={handleAddORUpdateFund}
                  style={{
                    borderRadius: 28,
                    color: "white",
                    minWidth: "170px",
                    height: 45,
                    borderColor: "#fff",
                    background: appColor.green,
                    width: "100%",
                  }}
                >
                  {isUpdate ? t("edit") : t("save")}
                </Button>
              </Box>
            </Box>
          </Modal>
          {/* END EDIT */}
        </div>
      </div>
  );
};

export default ProfitPage;

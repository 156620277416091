import React, { useEffect, useState } from 'react'
import { faCheck, faClose, faCoins, faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api'
import { Button, Container, Form, Image, Spinner } from 'react-bootstrap'
import { Box, Grid, Divider } from '@mui/material'
import { appColor, screenSize } from '../../constants'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export default function TransferToBonus({
    onCloseTransferPopup,
    isShowTransferPopup,
    money,
    setMoney,
    currencyFormatKip,
    onTransferCredit,
    isSaveLoading,
    credit,
    userData
}) {
    const { t } = useTranslation();
    const [invalidMoney, setInvalidMoney] = useState(false)
    const [overWithdraw, setOverWithdraw] = useState(false)
    const [totalMoney, setTotalMoney] = useState(false)

    const checkInvalidData = () => {
        if (!money) {
            setInvalidMoney(true)
            return
        } else {
            setInvalidMoney(false)
        }
        if (parseFloat(money) > parseFloat(credit)) {
            setOverWithdraw(true)
            return
        } else {
            setOverWithdraw(false)
        }
        setInvalidMoney(false)
        onTransferCredit()
    }

    return (
        <div style={{ position: "absolute", top: 0, backgroundColor: "rgba(0,0,0,0.7)", display: isShowTransferPopup ? "flex" : "none", flexDirection: "column", width: "100vw", minHeight: "100vh", paddingTop: 120, justifyContent: "flex-start", alignItems: "center" }}>
            {screenSize().width > 700 ? <div style={{ width: 600, minHeight: 350, backgroundColor: appColor.darkColor, borderRadius: 8, padding: 20 }}>
                <Grid container>
                    <div style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "#FFD700", color: "#000", fontSize: 18 }}><b>{t("Get Bonus")}</b></div>
                </Grid>
                <div style={{ height: 10 }} />
                <div style={{ display: "flex", color: "#fff", flexDirection: "column", width: "100%", justifyContent: "start", alignItems: "flex-start" }}>
                    <p>{t("current-money")}: ${currencyFormatKip(credit ?? 0)}  <span style={{ color: appColor.greenActive }}>(Avilable: ${userData?.bonusCredit} / $500)</span></p>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                        <input
                            type='number'
                            value={parseInt(money)}
                            onChange={(event) => {
                                setMoney(parseFloat(event?.target?.value))
                                setTotalMoney(parseFloat(event?.target?.value ?? 0) + (parseFloat(event?.target?.value ?? 0) * 0.5))
                                if (parseFloat(event?.target?.value) > parseFloat(credit)) {
                                    setOverWithdraw(true)
                                    return
                                } else {
                                    setOverWithdraw(false)
                                }
                            }}
                            style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid " + (invalidMoney ? "red" : "gray"), borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                        />
                        <div style={{ width: 10 }} />
                        <Button
                            onClick={() => {
                                setMoney(credit)
                                setTotalMoney(credit + (parseFloat(credit ?? 0) * 0.5))
                            }}
                            style={{
                                color: "#101010",
                                width: "100px",
                                borderColor: "#101010",
                                backgroundColor: "#FFD700",
                                fontWeight: "bold"
                            }}
                        >
                            <div>{t("max")}</div>
                        </Button>
                    </div>
                    <p style={{ paddingTop: 5 }}><b>Total Amount: <span style={{ color: "#FFD700" }}>{totalMoney ? currencyFormatKip(totalMoney ?? 0) : 0}</span> USDT</b></p>
                    {overWithdraw && <p style={{ color: "red", fontSize: 13, paddingTop: 5 }}>{t("over-withdraw")}</p>}
                    <div style={{ height: 10 }} />
                    <div style={{ textAlign: "left", width: "94%", alignSelf: "center", backgroundColor: "#2e2e2d", borderRadius: 8, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                        <div style={{ fontSize: 14 }}>{t("get bonus 50% popup title")}<span style={{ color: "red", fontSize: 18 }}>*</span></div>
                        <div style={{ height: 6 }} />
                        <div style={{ fontSize: 12 }}><span style={{ color: "#FFD700", fontSize: 18 }}>»</span> {t("get bonus 50% popup detail 0")}</div>
                        <div style={{ height: 6 }} />
                        <div style={{ fontSize: 12 }}><span style={{ color: "#FFD700", fontSize: 18 }}>»</span> {t("get bonus 50% popup detail 1")}</div>
                        <div style={{ height: 6 }} />
                        <div style={{ fontSize: 12 }}><span style={{ color: "#FFD700", fontSize: 18 }}>»</span> {t("get bonus 50% popup detail 2")}</div>
                    </div>
                </div>
                <Box height={14} />
                <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                    <br />
                    <Button
                        onClick={() => checkInvalidData()}
                        style={{
                            borderRadius: 28,
                            color: "white",
                            height: 45,
                            borderWidth: 0,
                            backgroundColor: appColor.green,
                            width: "100%"
                        }}
                    >
                        <div>{isSaveLoading ? <Spinner animation="border" style={{ width: 20, height: 20 }} role="status"></Spinner> : t("get bonus 50% button")}</div>
                    </Button>
                </Grid>
                <Box height={20} />
            </div> : <div style={{ width: "95%", backgroundColor: appColor.darkColor, borderRadius: 8, padding: 10 }}>
                <Grid container>
                    <div style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "#FFD700", color: "#000", fontSize: 18 }}><b>{t("Get Bonus")}</b></div>
                </Grid>
                <div style={{ height: 10 }} />
                <div style={{ display: "flex", color: "#fff", flexDirection: "column", width: "100%", justifyContent: "start", alignItems: "flex-start" }}>
                    <p>{t("current-money")}: ${currencyFormatKip(credit)} <span style={{ color: appColor.greenActive }}>(Avilable: ${userData?.bonusCredit} / $500)</span></p>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                        <input
                            type='number'
                            value={parseInt(money)}
                            onChange={(event) => {
                                setMoney(parseFloat(event?.target?.value))
                                setTotalMoney(parseFloat(event?.target?.value ?? 0) + (parseFloat(event?.target?.value ?? 0) * 0.5))
                                if (parseFloat(event?.target?.value) > parseFloat(credit)) {
                                    setOverWithdraw(true)
                                    return
                                } else {
                                    setOverWithdraw(false)
                                }
                            }}
                            style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid " + (invalidMoney ? "red" : "gray"), borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                        />
                        <div style={{ width: 10 }} />
                        <Button
                            onClick={() => {
                                setMoney(credit)
                                setTotalMoney(credit + (parseFloat(credit ?? 0) * 0.5))
                            }}
                            style={{
                                color: "#101010",
                                width: "100px",
                                borderColor: "#101010",
                                backgroundColor: "#FFD700",
                                fontWeight: "bold"
                            }}
                        >
                            <div>{t("max")}</div>
                        </Button>
                    </div>
                    <p style={{ paddingTop: 5 }}><b>Total Amount: <span style={{ color: "#FFD700" }}>{totalMoney ? currencyFormatKip(totalMoney ?? 0) : 0}</span> USDT</b></p>
                    {overWithdraw && <p style={{ color: "red", fontSize: 13, paddingTop: 5 }}>{t("over-withdraw")}</p>}
                    <div style={{ height: 10 }} />
                    <div style={{ textAlign: "left", width: "94%", alignSelf: "center", backgroundColor: "#2e2e2d", borderRadius: 8, padding: 10, paddingLeft: 16, paddingRight: 16 }}>
                        <div style={{ fontSize: 14 }}>{t("get bonus 50% popup title")}<span style={{ color: "red", fontSize: 18 }}>*</span></div>
                        <div style={{ height: 4 }} />
                        <div style={{ fontSize: 12 }}><span style={{ color: "#FFD700", fontSize: 18 }}>»</span> {t("get bonus 50% popup detail 0")}</div>
                        <div style={{ height: 4 }} />
                        <div style={{ fontSize: 12 }}><span style={{ color: "#FFD700", fontSize: 18 }}>»</span> {t("get bonus 50% popup detail 1")}</div>
                        <div style={{ height: 4 }} />
                        <div style={{ fontSize: 12 }}><span style={{ color: "#FFD700", fontSize: 18 }}>»</span> {t("get bonus 50% popup detail 2")}</div>
                    </div>
                </div>
                <Box height={14} />
                <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                    <br />
                    <Button
                        onClick={() => checkInvalidData()}
                        style={{
                            borderRadius: 28,
                            color: "white",
                            height: 45,
                            borderWidth: 0,
                            backgroundColor: appColor.green,
                            width: "100%"
                        }}
                    >
                        <div>{isSaveLoading ? <Spinner animation="border" style={{ width: 20, height: 20 }} role="status"></Spinner> : t("get bonus 50% button")}</div>
                    </Button>
                </Grid>
                <Box height={20} />
            </div>}
            <div style={{ height: 10 }} />
            <div onClick={() => onCloseTransferPopup(false)} style={{ width: 50, height: 50, borderRadius: 50, backgroundColor: "rgba(255,255,255,0.2)", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}><FontAwesomeIcon icon={faClose} style={{ color: "#fff", fontSize: 22 }} /></div>
        </div>
    )
}

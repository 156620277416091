import React, { useEffect, useState } from 'react'
import CustomNavbar from '../navbar/Navbar'
import { USER_LOCAL_DATA, appColor, screenSize } from '../../constants'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography'
import { useLazyQuery } from '@apollo/client';
import { GET_AVS_GQL, GET_MC_AVS_GQL, GET_MC_GQL, GET_MY_MERCHANT_TRADE_INFO_GQL } from '../../apollo/crypto';
import { Modal, Box, Grid, ButtonGroup, Button, Avatar, AppBar, Menu, MenuItem, Container, useMediaQuery, FormControl, Select } from '@mui/material'
import { useTheme } from '@emotion/react';
import { CheckCircleRounded, Edit, VerifiedRounded } from '@mui/icons-material';
import { currencyFormat } from '../../utils';
import { useTranslation } from 'react-i18next';
import MCCreateAdsPopup from '../../components/mc/mc-create-ads';
import MCUpdateAdsPopup from '../../components/mc/mc-update-ads';
import P2PMyAdvsItem from '../../components/mc/my-p2p-item';
import MCInfoPercentSumary from '../../components/mc/mc-info-percent';
import AppModalDialog from '../../common/appModalDialog';
import TradeInfoComponent from '../../components/p2p/trade-info-component';
import moment from 'moment';
import CreateMcPagePopup from '../../components/mc/mc-page';
import LoadingPage from '../../common/loadingPage';
import loadingAnimationData from '../../images/empty-item-animation.json'
import Lottie from 'react-lottie';

// function createData(name, calories, fat, carbs, protein) {
//     return { name, calories, fat, carbs, protein };
// }


const MyAdvPage = () => {
    const { t, i18n } = useTranslation();
    const [myMC, setMyMC] = useState();
    const [userID, setUserID] = useState();
    const [p2pType, setp2pType] = useState("BUY"); // BUY, SELL
    const [advs, setAdvs] = useState();
    const [user, setUser] = useState();
    const [mcTradingInfo, setMCTradingInfo] = useState();
    const [fiatUnitType, setFiatUnitType] = useState("LAK");

    const handleChangeFiatUnitType = (event) => {
        setFiatUnitType(event.target.value);
    }

    const setP2PTypeHandle = (indexType) => {
        setp2pType(indexType)
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleChangeViewMoreModal = () => {
        setIsModalOpen(!isModalOpen)
    }
    // LOTTIE
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    // LOTTIE

    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (
                        parseData
                    ) {
                        setUserID(parseData?.user?._id);
                        setUser(parseData?.user);

                    }
                }

            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);


    const [getMyMerchantTradeInfo, { loading }] = useLazyQuery(GET_MY_MERCHANT_TRADE_INFO_GQL, {
        variables: {
            userId: userID
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // console.log(`getMyMC data`, data)
            if (data.getMyMerchantTradeInfo.error == true) {
                console.log(`getMyMC error`, data.getMyMerchantTradeInfo.error);
            } else {
                setMCTradingInfo(data.getMyMerchantTradeInfo.data)
            }
        },
        onError: (error) => {
            console.log(`getMyMerchantTradeInfo error`, error)
        }
    })

    const [getMyMC] = useLazyQuery(GET_MC_GQL, {
        variables: {
            userId: userID
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // console.log(`getMyMC data`, data)
            if (data.getMyMerchant.error == true) {
                console.log(`getMyMC error`, data.getMyMerchant.error);
            } else {
                setMyMC(data.getMyMerchant.data)
            }
        },
        onError: (error) => {
            console.log(`getMyMC error`, error)
        }
    })
    let queryUser = {
        "query": {
            "tradeType": p2pType,
            "assetType": "USDT",
            "fiatUnitType": fiatUnitType,
            "priceSort": "DESC"
        }
    }
    let queryMC = {
        "query": {
            "tradeType": p2pType,
            "assetType": "USDT",
            "fiatUnitType": fiatUnitType,
            "createSort": "DESC",
            "user": userID
        }
    }
    const [getAvsList] = useLazyQuery(user?._id === myMC?.user ? GET_MC_AVS_GQL : GET_AVS_GQL, {
        variables: user?._id === myMC?.user ? queryMC : queryUser,
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            // console.log('user?._id === myMC?.user :>> ', user?._id === myMC?.user);
            if (user?._id === myMC?.user) {
                console.log(`getMCAdv data`, data)
                if (data.getMCAdvertisement.error == true) {
                    console.log(`get getMCAdv error`, data?.getMCAdvertisement?.error);
                } else {
                    console.log('getMCAdvertisement :>> ', data?.getMCAdvertisement?.data);
                    setAdvs(data?.getMCAdvertisement?.data)
                }
            } else {
                console.log(`getadv data`, data)
                if (data.getMyMerchant.error == true) {
                    console.log(`getAdv error`, data.getMyMerchant.error);
                } else {
                    setAdvs(data.getMyMerchant.data)
                }
            }

        },
        onError: (error) => {
            console.log(`getMyMC error`, error)
        }
    })

    const getAvs = async () => {
        const { data } = await getAvsList()
        // console.log('data :>> ', JSON.stringify(data?.getAdvertisement?.data));
        // console.log('user :>> ', user?._id);
        // console.log('mc :>> ', myMC?.user);
        if (user?._id === myMC?.user) {
            setAdvs(data?.getMCAdvertisement?.data)
        } else {
            setAdvs(data?.getAdvertisement?.data)
        }
    }
    useEffect(() => {
        if (user) {
            getAvs();
            getMyMC();
            getMyMerchantTradeInfo();
        }

    }, [fiatUnitType, p2pType, user, myMC]);

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("lg"));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize])

    // const rows = [
    //     createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    //     createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    //     createData('Eclair', 262, 16.0, 24, 6.0),
    //     createData('Cupcake', 305, 3.7, 67, 4.3),
    //     createData('Gingerbread', 356, 16.0, 49, 3.9),
    // ];

    const [mcCreateOpen, setmcCreateOpen] = useState(false);
    const handleMCCreateOpen = () => {
        setmcCreateOpen(true)
    }
    const handleMCCreateClose = () => {
        setmcCreateOpen(false)
    }

    const [mcUpdateAdvOpen, setmcUpdateAdvOpen] = useState(false);
    const [advDataUpdate, setadvDataUpdate] = useState();

    const handleMCUpdateOpen = () => {
        setmcUpdateAdvOpen(true)
    }
    const handleMCUpdateClose = () => {
        setmcUpdateAdvOpen(false)
    }


    // --- mc ---
    const [isOpenMerchantUpdateModal, setIsOpenMerchantUpdateModal] = useState(false);

    const handleMerchantUpdateModal = () => {
        setIsOpenMerchantUpdateModal(!isOpenMerchantUpdateModal)
    }

    return (
        loading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center" }}>

                {scrollPosition === 0 ? <>
                    <CustomNavbar />
                    <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />

                </> : <></>}

                <MCCreateAdsPopup
                    isMobile={isMobile}
                    open={mcCreateOpen}
                    handleClose={handleMCCreateClose}
                    getAvs={getAvs}
                />
                <CreateMcPagePopup
                    isMobile={isMobile}
                    open={isOpenMerchantUpdateModal}
                    handleClose={handleMerchantUpdateModal}
                    popupType={"EDIT"}
                    myMC={myMC}
                />

                <MCUpdateAdsPopup
                    isMobile={isMobile}
                    open={mcUpdateAdvOpen}
                    handleClose={handleMCUpdateClose}
                    getAvs={getAvs}
                    advDataUpdate={advDataUpdate}
                />
                <AppModalDialog
                    isModalOpen={isModalOpen}
                    handleChangeViewMoreModal={handleChangeViewMoreModal}
                >
                    <Typography
                        id="parent-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ fontWeight: "bold" }}
                    >
                        {`${t('Trade Info')}`}
                    </Typography>
                    <TradeInfoComponent
                        title={"30d Trades"}
                        value={`${mcTradingInfo?.totalAllTrade} Time(s)`}
                    />
                    <TradeInfoComponent
                        title={"30d Completion Rate"}
                        value={`${mcTradingInfo?.totalCompletedRate} %`}
                    />
                    <TradeInfoComponent
                        title={"Registered"}
                        value={moment(mcTradingInfo?.registeredDate, "YYYY-MM-DD").fromNow()}
                    />
                </AppModalDialog>
                <Box
                    sx={{
                        paddingX: isMobile ? 2 : 10,
                    }}>
                    <Box
                        sx={{
                            backgroundColor: appColor.bgDark,
                        }}>
                        {/* START MC INFO */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingX: 2,
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    padding: 2
                                }}
                            >
                                <Avatar />
                                <Box sx={{
                                    width: 10
                                }} />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                            gap: 2
                                        }}
                                    >
                                        <Typography sx={{
                                            color: appColor.textWhite,
                                            fontWeight: 'bold'
                                        }}>
                                            {myMC?.merchantName}
                                        </Typography>
                                        <VerifiedRounded
                                            sx={{
                                                color: myMC?.verificationStatus ? appColor.greenActive : appColor.gray
                                            }}
                                        />
                                    </Box>
                                    <Typography sx={{
                                        color: appColor.textGrey
                                    }}>
                                        {user?.userName}
                                    </Typography>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 2
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 0.5
                                        }}>
                                            <Typography sx={{
                                                color: appColor.textGrey
                                            }}>
                                                Email
                                            </Typography>
                                            <CheckCircleRounded sx={{
                                                width: 16,
                                                color: appColor.greenActive
                                            }} />
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 0.5
                                        }}>
                                            <Typography sx={{
                                                color: appColor.textGrey
                                            }}>
                                                KYC
                                            </Typography>
                                            <CheckCircleRounded sx={{
                                                width: 16,
                                                color: user?.vipStatus == "VERIFIED" ? appColor.greenActive : appColor.gray
                                            }} />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    width: 10
                                }} />
                            </Box>
                            <Button
                                variant='contained'
                                size={isMobile == true ? "small" : "large"}
                                onClick={handleMerchantUpdateModal}
                                sx={{
                                    color: appColor.black,
                                    backgroundColor: appColor.yellow,
                                    ":hover": {
                                        backgroundColor: appColor.yellowActive
                                    }
                                }}
                            >{t('Update Merchant')}</Button>
                        </Box>
                        {/* END MC INFO */}
                        {/* START MC PERCENT  */}

                        {mcTradingInfo && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? "row" : "column",
                                    padding: 2,
                                    justifyContent: "space-between",
                                    gap: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: isMobile ? "column" : "row",
                                        paddingX: 2,
                                        justifyContent: "space-between",
                                        gap: 1,
                                        width: "100%"
                                    }}
                                >
                                    <MCInfoPercentSumary
                                        title={"30d Trades"}
                                        titleTooltip={"Total number of completed P2P order(BUY/SELL included) in the past 30 days"}
                                        value={`${mcTradingInfo?.totalAllTrade}`}
                                        valueDes={" Time(s)"}
                                    />
                                    <MCInfoPercentSumary
                                        title={"30d Completion Rate"}
                                        titleTooltip={"Complete Rate = Trades/Orders(30d)*100%"}
                                        value={`${mcTradingInfo?.totalCompletedRate}`}
                                        valueDes={"%"}
                                    />
                                    {/* END MC PERCENT  */}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: isMobile ? "column" : "row",
                                        paddingX: 2,
                                        justifyContent: "space-between",
                                        gap: 1,
                                        width: "100%"
                                    }}
                                >
                                    <MCInfoPercentSumary
                                        title={"Registered"}
                                        titleTooltip={"Registered Date"}
                                        value={moment(mcTradingInfo?.registeredDate, "YYYY-MM-DD").fromNow()}

                                    />
                                    <MCInfoPercentSumary
                                        title={t('View More')}
                                        value={"..."}
                                        isButton={true}
                                        onClick={handleChangeViewMoreModal}
                                    />
                                    {/* END MC PERCENT  */}
                                </Box>
                            </Box>
                        )}
                        <Box
                            position={'sticky'}
                            display={'flex'}
                            flexDirection={'row'}
                            sx={{
                                top: 0,
                                zIndex: 2,
                                padding: '10px',
                                boxShadow: 3,
                                justifyContent: 'space-between',
                                backgroundColor: appColor.background,
                                paddingX: isMobile ? 4 : 25,
                                width: '100%',
                            }}
                        >
                            <ButtonGroup
                                size={isMobile ? 'small' : 'large'}
                                variant="outlined"
                                aria-label="Basic button group"

                            >
                                <Button
                                    size={isMobile ? 'small' : 'large'}
                                    sx={{
                                        px: 2,
                                        backgroundColor: p2pType == "BUY" ? appColor.green : 'transparent',
                                        border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                                        color: p2pType == "BUY" ? appColor.white : appColor.textGrey,
                                        ":hover": {
                                            backgroundColor: p2pType == "BUY" ? appColor.greenActive : 'transparent',
                                            border: `0.5px solid ${appColor.green}`,
                                        }

                                    }}
                                    onClick={() => setP2PTypeHandle("BUY")}

                                >{t('BUY')}</Button>
                                <Button
                                    size={isMobile ? 'small' : 'large'}
                                    sx={{
                                        px: 2,
                                        backgroundColor: p2pType == "SELL" ? appColor.red : 'transparent',
                                        border: `0.5px solid ${p2pType == "BUY" ? appColor.green : appColor.red}`,
                                        color: p2pType == "SELL" ? appColor.white : appColor.textGrey,
                                        ":hover": {
                                            backgroundColor: p2pType == "SELL" ? appColor.redActive : 'transparent',
                                            border: `0.5px solid ${appColor.red}`,
                                        }
                                    }}
                                    onClick={() => setP2PTypeHandle("SELL")}
                                >{t('SELL')}</Button>
                                <Box width={10} />
                                <FormControl sx={{ m: 1, border: '1px solid ' + appColor.yellowActive, borderRadius: 2 }}>
                                    <Select
                                        sx={{
                                            height: isMobile ? 32 : 40,
                                        }}
                                        value={fiatUnitType}
                                        onChange={handleChangeFiatUnitType}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value={"LAK"}>LAK</MenuItem>
                                        <MenuItem value={"THB"}>THB</MenuItem>
                                        <MenuItem value={"USD"}>USD</MenuItem>
                                        <MenuItem value={"KHR"}>KHR</MenuItem>
                                    </Select>
                                </FormControl>
                            </ButtonGroup>
                            <Box sx={{
                                height: 2,
                            }} />
                            <Button
                                size={isMobile ? 'small' : 'large'}
                                sx={{
                                    backgroundColor: appColor.yellow,
                                    color: appColor.black,
                                    ":hover": {
                                        backgroundColor: appColor.yellowActive,
                                        border: `0.5px solid ${appColor.yellowActive}`,
                                    }

                                }}
                                onClick={handleMCCreateOpen}

                            >{t("Post New Ads")}</Button>
                        </Box>

                        <Box sx={{
                            height: 10,
                        }} />
                        {isMobile ?
                            <>
                                <Box
                                >
                                    {(advs && advs.length > 0) ? advs?.map((adv, index) => (
                                        <div
                                            onClick={() => {
                                                handleMCUpdateOpen()
                                                setadvDataUpdate(adv)
                                            }}
                                        >
                                            <P2PMyAdvsItem
                                                // onClick={() => {
                                                //     handleReviewOpen()
                                                //     setP2p(p2p)
                                                // }}
                                                key={index}
                                                p2pType={p2pType}
                                                advs={adv}
                                            // myMC={myMC}
                                            // setP2PTypeHandle={setP2PTypeHandle}
                                            />
                                        </div>

                                    )) : <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: "100%",
                                            height: "45dvh",
                                            justifyContent: "center",
                                            alignItems: "center"

                                        }}>
                                        <Lottie
                                            options={defaultOptions}
                                            height={150}
                                            width={150}
                                        />
                                    </Box>}
                                </Box>

                            </> :
                            <>
                                <TableContainer component={Paper}
                                    sx={{
                                        backgroundColor: appColor.bgDark,
                                    }}
                                >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >#</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Trade Type")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Trade Asset")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Fiat Unit Type")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Tradable Quantity")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Price")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Min Single Trans Quantity")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Max Single Trans Quantity")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("isPublish")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("created_at")}</TableCell>
                                                <TableCell
                                                    sx={{
                                                        color: appColor.textWhite
                                                    }}
                                                >{t("Action")}</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {advs && advs && advs?.map((adv, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.tradeType}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.assetType}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.fiatUnitType}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.assetType} {currencyFormat(parseInt(adv.tradableQuantity))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.fiatUnitType} {currencyFormat(parseInt(adv.price))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.fiatUnitType}  {currencyFormat(parseInt(adv.minSingleTransQuantity))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.fiatUnitType}  {currencyFormat(parseInt(adv.maxSingleTransQuantity))}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.isPublish === true ? t("Yes") : t("No")}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        {adv.createdAt}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row"
                                                        sx={{
                                                            color: appColor.textWhite
                                                        }}
                                                    >
                                                        <Avatar
                                                            sx={{
                                                                width: 34,
                                                                height: 34,
                                                                cursor: "pointer",
                                                                backgroundColor: appColor.primary,
                                                                color: appColor.white,
                                                                "&:hover": {
                                                                    backgroundColor: appColor.primary,
                                                                },
                                                            }}
                                                            onClick={() => {
                                                                handleMCUpdateOpen()
                                                                setadvDataUpdate(adv)
                                                            }}
                                                        >
                                                            <Edit fontSize='10px' />
                                                        </Avatar>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                    </Box>
                </Box>
            </div>
    )
}

export default MyAdvPage
import React, { useEffect, useState } from 'react'
import {
    Box,
    Paper,
    Divider,
    Avatar,
    Button,
    Typography,
    TextField,
    Modal,
    FormControl,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Container,
    Drawer,
    IconButton,
    Badge,
    Grid,
} from "@mui/material";
import { appColor, SEARCH_GOOGLE_IMAGE } from '../../constants';
import { PersonOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api';
import axios from "axios";

// const container = useRef();
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    background: appColor.bgDark,
    color: appColor.white,
};
const ProfileModal = ({
    isEdit,
    showProfile,
    handleCloseProfile,
    toEdit,
    userData,
    getUserData
}) => {
    const { t, i18n } = useTranslation();
    // PROFILE
    const [fullname, setFullname] = useState("");
    const [username, setUsername] = useState("");
    const [userImage, setUserImage] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");



    const onEdit = async () => {
        try {
            let data = {
                fullName: fullname,
                phone: phone,
                userName: username,
            };
            if (password && password != "") data = { ...data, password: password };

            var config = {
                method: "put",
                url: `${END_POINT_SEVER}/api/users/${userData["_id"]}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(data),
            };
            const updateHandle = await axios(config);
            // console.log("updateHandle: ", updateHandle);
            if (updateHandle.data) {
                // console.log("updateHandle: ", updateHandle);
                handleCloseProfile();
                await getUserData(userData?._id);
            }
        } catch (error) {
            console.log(error);
        }
    };



    // // START USER LOCAL DATA
    // const getUserData = async (userId) => {
    //     try {
    //         var config = {
    //             method: "get",
    //             url: `${END_POINT_SEVER}/api/users/${userId}`,
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //         };
    //         const userHandle = await axios(config);
    //         if (userHandle?.data) {
    //             setFullname(userHandle.data?.user?.fullName ?? "");
    //             setUsername(userHandle.data?.user?.userName ?? "");
    //             setPhone(userHandle.data?.user?.phone ?? "");
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    useEffect(() => {
        // getUserData(userData?._id);
        setFullname(userData?.fullName ?? "");
        setUsername(userData?.userName ?? "");
        setUserImage(userData?.image ?? "");
        setPhone(userData?.phone ?? "");
    }, [userData]);
    // END USER LOCAL DATA

    return (
        <Modal
            open={showProfile}
            onClose={handleCloseProfile}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{
                ...style, width: 400,
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
            }}>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    sx={{
                        justifyContent: "space-between",
                        justifyItems: "center",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        {t("personal-information")}
                    </Typography>
                </Box>
                <Box height={10} />
                {/* <Avatar
                    sx={{
                        width: 80,
                        height: 80,
                        cursor: "pointer",
                        border: "4px solid #f9de5d",
                    }}
                ></Avatar> */}
                <img src={userImage && userImage.includes(SEARCH_GOOGLE_IMAGE) ? userImage : `${S3_BUCKET_END_POINT}/images/${userImage}`} style={{ width: 80, height: 80, borderRadius: 40 }} />
                <Box height={10} />
                <Box>
                    <Box>
                        <Box height={10} />
                        <Divider
                            color={appColor.white}
                        />
                        <Box height={2} />
                        <Box display={"flex"} sx={{
                            justifyContent: "space-between",
                            justifyItems: "center",
                            alignItems: "center",
                        }}>
                            <Box>{t("name-and-lastname")}:</Box>
                            <Box>
                                <input
                                    type="text"
                                    disabled={!isEdit}
                                    value={fullname}
                                    onChange={(event) => setFullname(event?.target?.value)}
                                    style={{
                                        width: "100%", height: 40,
                                        border: "none",
                                        outline: "none",
                                        background: appColor.bgDark,
                                        color: appColor.white
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box height={2} />
                        <Divider
                            color={appColor.white}
                        />
                        <Box height={2} />
                        <Box display={"flex"} sx={{
                            justifyContent: "space-between",
                            justifyItems: "center",
                            alignItems: "center",
                        }}>
                            <Box>{t("phone-number")}:</Box>
                            <Box>
                                <input
                                    type="text"
                                    disabled={!isEdit}
                                    value={phone}
                                    onChange={(event) => setPhone(event?.target?.value)}
                                    style={{
                                        width: "100%", height: 40,
                                        // remove input outline and outline focus
                                        border: "none",
                                        outline: "none",
                                        background: appColor.bgDark,
                                        color: appColor.white
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box height={2} />
                        <Divider
                            color={appColor.white}
                        />
                        <Box height={2} />
                        <Box display={"flex"} sx={{
                            justifyContent: "space-between",
                            justifyItems: "center",
                            alignItems: "center",
                        }}>
                            <Box>{t("username")}:</Box>
                            <Box>
                                <input
                                    type="text"
                                    disabled={!isEdit}
                                    value={username}
                                    onChange={(event) => setUsername(event?.target?.value)}
                                    style={{
                                        width: "100%", height: 40,
                                        border: "none",
                                        outline: "none",
                                        background: appColor.bgDark,
                                        color: appColor.white
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box height={2} />
                        <Divider
                            color={appColor.white}
                        />
                        <Box height={2} />

                        <Box display={"flex"} sx={{
                            justifyContent: "space-between",
                            justifyItems: "center",
                            alignItems: "center",

                        }}>
                            <Box>{t("password")}:</Box>
                            <Box>
                                <input
                                    type="password"
                                    disabled={!isEdit}
                                    value={!isEdit ? "******************" : password}
                                    onChange={(event) => setPassword(event?.target?.value)}
                                    style={{
                                        width: "100%",
                                        height: 40,
                                        border: "none",
                                        outline: "none",
                                        background: appColor.bgDark,
                                        color: appColor.white

                                    }}
                                />
                            </Box>
                        </Box>
                        <Box height={2} />
                        <Divider
                            color={appColor.white}
                        />
                        <Box height={2} />
                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                            <br />
                            <Button
                                onClick={() => (!isEdit ? toEdit() : onEdit())}
                                style={{
                                    borderRadius: 28,
                                    color: "white",
                                    minWidth: "170px",
                                    height: 45,
                                    borderColor: "#fff",
                                    background:
                                        "linear-gradient(90deg, rgba(91, 97, 138) 22%, rgba(91, 97, 138) 79%)",
                                    width: "100%",
                                }}
                            >
                                <PersonOutline />
                                <Box width={10} />
                                <div>{!isEdit ? t("edit") + " " + t("profile") : t("save")}</div>
                            </Button>
                        </Grid>
                        <Box height={30} />
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default ProfileModal
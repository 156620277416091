import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    resources: {
        english: {
            translation: require("./languages/english.json")
        },
        thailand: {
            translation: require("./languages/thailand.json")
        },
        lao: {
            translation: require("./languages/lao.json")
        },
        khr: {
            translation: require("./languages/khr.json")
        }
    },
    lng: localStorage.getItem('appLanguage') ? localStorage.getItem('appLanguage') : "english",
    fallbackLng: localStorage.getItem('appLanguage') ? localStorage.getItem('appLanguage') : "english",
    debug: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;

import React from 'react'
import { Modal, Box, Typography, Grid, ButtonGroup, Button, Avatar, AppBar, Menu, MenuItem, Container, useMediaQuery } from '@mui/material'
const AppModalDialog = ({ children, isModalOpen, handleChangeViewMoreModal }) => {
    return (
        <Modal
            open={isModalOpen}
            onClose={handleChangeViewMoreModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={{
                width: 500,
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // bgcolor: "background.paper",
                // border: "2px solid #000",
                borderRadius: 2,
                boxShadow: 24,
                pt: 2,
                px: 6,
                py: 6,
                pb: 4,
            }}>
                {children}
            </Box>
        </Modal>
    )
}

export default AppModalDialog
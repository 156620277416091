import React, { useEffect, useState } from 'react'
import CustomNavbar from '../navbar/Navbar'
import { USER_LOCAL_DATA, screenSize } from '../../constants'
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios'
import { END_POINT_SEVER } from '../../api';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { CREATE_CREDIT_HISTORY, UPDATE_UNREAD_NOTIFICATIONS } from '../../apollo/crypto';
import Deposit from './deposit';
import Withdraw from './withdraw';
import LoadingPage from '../../common/loadingPage';
import { toast } from 'react-toastify';

export default function SelectBankOption() {
    const { t } = useTranslation();
    const [showAddMoney, setShowAddMoney] = useState(false)
    const [showWithdrawMoney, setShowWithdrawMoney] = useState(false)
    const [money, setMoney] = useState(0)
    const [totalMoney, setTotalMoney] = useState(0)
    const [slipImage, setSlipImage] = useState("")
    const [userData, setUserData] = useState()
    const [admin, setAdmin] = useState([])
    const [rate, setRate] = useState()
    const [realCreditCardData, setRealCreditCardData] = useState()
    const [creditHistoryData, setCreditHistoryData] = useState([])
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [updateReadNotification] = useMutation(UPDATE_UNREAD_NOTIFICATIONS)
    const routeNavigate = useNavigate();
    const [stateType, setStateType] = useState("");
    const [credit, setCredit] = useState("");
    const [channelType, setChannelType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [usedBonus, setUsedBonus] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)

    const [binanceEmailOrBinanceID, setBinanceEmailOrBinanceID] = useState('');
    const location = useLocation();

    const toastAlert = (type, msg,) => {
        toast(msg, { type: type == "error" ? "error" : "success" });
    };

    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA)
                if (_localData) {
                    let parseData = JSON.parse(_localData)
                    if (parseData["user"] && parseData["user"]["_id"]) {
                        getUserData(parseData["user"]["_id"])
                        getCreditData(parseData["user"]["_id"])
                        getCreditHistoryData(parseData["user"]["_id"])

                        await updateReadNotification({
                            variables: {
                                receiveId: parseData?.user?.role === "ADMIN" ? "ADMIN" : parseData["user"]["_id"],
                            }
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        getLoalData()
        getAdminBank()
        getRate()
        setStateType(location.state?.type);
        setCredit(location.state?.credit);
    }, [])

    const getUserData = async (userId) => {
        try {
            setIsLoading(true)
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/users/${userId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const userHandle = await axios(config)
            if (userHandle?.data) {
                setUserData(userHandle.data?.user)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const getAdminBank = async () => {
        try {
            setIsLoading(true)
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/bank/?limit=10`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const adminBank = await axios(config)
            if (adminBank?.data?.adminBank?.length > 0) {
                // console.log('admin ', adminBank?.data?.adminBank )
                setAdmin(adminBank?.data?.adminBank);
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const getRate = async () => {
        try {
            setIsLoading(true)
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/rate/?limit=1`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const rateDate = await axios(config)
            if (rateDate?.data?.rate?.length > 0) {
                setRate(rateDate?.data?.rate[0])
                if (location.state?.type == "withdraw") {
                    setMoney(location.state?.credit)
                    setTotalMoney(location.state?.credit * rateDate?.data?.rate[0]?.usdWithdraw)
                }
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const getCreditData = async (userId) => {
        try {
            setIsLoading(true)
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const creditHandle = await axios(config)
            if (creditHandle?.data) {
                for (var i = 0; i < creditHandle.data?.credit?.length; i++) {
                    if (creditHandle.data?.credit[i].type == "REAL") setRealCreditCardData(creditHandle.data?.credit[i])
                }
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const getCreditHistoryData = async (userId) => {
        try {
            setIsLoading(true)
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/credit/histories/?userId=${userId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const creditHistoryHandle = await axios(config)
            if (creditHistoryHandle?.data) {
                setCreditHistoryData(creditHistoryHandle.data?.credit)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const requestPresignedUrlAndUploadImageToS3 = async (event) => {
        try {
            setIsLoading(true)
            const fileData = event?.target?.files[0]
            if (fileData) {
                // TODO: Request Presigned Url
                var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${END_POINT_SEVER}/api/file/pre-signed-url`,
                    headers: { 'Content-Type': 'application/json' },
                    data: JSON.stringify({ "fileName": fileData.name, "folder": "images" })
                };
                const presignedUrl = await axios(config)

                // TODO: Upload to S3
                if (presignedUrl?.data?.url) {
                    let uploadfile = await axios({
                        method: "PUT",
                        url: presignedUrl?.data?.url,
                        data: fileData,
                        headers: {
                            "Content-Type": " file/*; image/*",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })
                    console.log("uploadfile: ", uploadfile)

                    // TODO: Set image name to state
                    if (uploadfile) {
                        setSlipImage(fileData.name)
                    }
                }
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    // START UPDATE_CREDIT_HISTORY
    const [mutationCreateCreditStatusHandle] = useMutation(CREATE_CREDIT_HISTORY);

    // END UPDATE_CREDIT_HISTORY
    const onAddCredit = async () => {
        try {
            setIsSaveLoading(true)
            if (money && money >= 5) {
                let data = {
                    type: "ADD",
                    credit: usedBonus ? totalAmount : parseFloat(money),
                    creditKip: parseFloat(totalMoney),
                    creditRate: rate?.usd ?? 1,
                    image: slipImage,
                    user: userData["_id"],
                    bankName: channelType,
                    usedBonus: usedBonus,
                    realMoney: parseFloat(money)
                }
                await mutationCreateCreditStatusHandle({ variables: { data: data } }).then((res) => {
                    if (res.data) {
                        toastAlert("success", "Request success")
                        setSlipImage("")
                        getCreditData(userData["_id"])
                        getCreditHistoryData(userData["_id"])
                    }
                })

                // END RES CREATE
                setIsSaveLoading(false)
                setMoney(0)
                setSlipImage("")
                setShowAddMoney(false)
                setTotalMoney(0)
            }
            setSlipImage("")
            routeNavigate("/transection-list")
        } catch (error) {
            console.log("ERROR ", error)
            setIsSaveLoading(false)
        }
    }

    const [withdrawCode, setWithdrawCode] = useState("")
    const onWithdrawCredit = async (verifyCode) => {
        try {
            console.log("onWithdrawCredit")
            setIsSaveLoading(true)
            let data = {
                type: "WITHDRAW",
                credit: parseFloat(money),
                creditKip: parseFloat(totalMoney),
                creditRate: rate?.usdWithdraw ?? 1,
                image: slipImage,
                user: userData["_id"],
                bankName: channelType,
                binanceEmailOrBinanceID: binanceEmailOrBinanceID,
                withdrawCode: verifyCode
            }
            await mutationCreateCreditStatusHandle(
                { variables: { data: data } }
            ).then((res) => {
                if (res.data) {
                    if (res.data.createCreditHistory.error != "") {
                        toastAlert("error", res.data.createCreditHistory.error)
                    } else {
                        toastAlert("success", "Request success")
                        getCreditData(userData["_id"])
                        getCreditHistoryData(userData["_id"])
                        setSlipImage("")
                    }
                }
            })
            setIsSaveLoading(false)
            setMoney(0)
            setSlipImage("")
            setShowWithdrawMoney(false)
            routeNavigate("/transection-list")
        } catch (error) {
            console.log(error)
            setIsSaveLoading(false)
            setSlipImage("")
        }
    }

    function currencyFormat(num) {
        return num ? num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0
    }

    function currencyFormatKip(num) {
        return num ? num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0
    }

    return (
        isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
                {!isLoading && <CustomNavbar />}
                <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />
                {screenSize().width > 700 ?
                    <div style={{ padding: 10, fontSize: 13 }}>
                        <h5>Plase select {stateType === "deposit" ? "deposit" : "withdraw"} method</h5>
                        <br />
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-around" }}>
                            {/* <div
                            // onClick={() => routeNavigate("/transection-list")}
                            onClick={() => {
                                setChannelType("BCEL");
                                stateType === "deposit" ? setShowAddMoney(true) : setShowWithdrawMoney(true);

                            }}
                            style={{ width: "100%", cursor: "pointer", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}>
                            <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                <img src="/bcel.png" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                <div style={{ width: 10 }} />
                                <h3 style={{ paddingTop: 5 }}>BCEL One</h3>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>1 ชั่วโมง - 5 ชั่วโมง</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>0 %</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>10 - 10,000 USD</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: 30 }} /> */}
                            <div
                                style={{ width: "100%", opacity: 1, cursor: "pointer", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}
                                onClick={() => {
                                    setChannelType("BinancePay");
                                    stateType === "deposit" ? setShowAddMoney(true) : setShowWithdrawMoney(true);
                                }}
                            >
                                <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                    <img src="/binance.png" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                    <div style={{ width: 10 }} />
                                    <h3 style={{ paddingTop: 5 }}>BinancePay</h3>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>ทันที - 30 นาที</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>1 USDT</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>10 - 15,000 USD</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: 30 }} />
                            <div
                                style={{ width: "100%", opacity: stateType === "deposit" ? 1 : 0.4, cursor: "pointer", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}
                                onClick={() => {
                                    if (stateType === "deposit") {
                                        setChannelType("BNBSmartChain");
                                        stateType === "deposit" ? setShowAddMoney(true) : setShowWithdrawMoney(true);
                                    }
                                }}
                            >
                                <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                    <img src="/tether.png" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                    <div style={{ width: 10 }} />
                                    <h3 style={{ paddingTop: 5 }}>BNB Smart Chain (BEP20) {stateType != "deposit" && " (Coming Soon)"}</h3>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>ทันที - 1 วัน (1d)</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>1 USDT</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>10 - 10,000,000 USD</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 10 }} />
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-around" }}>
                            <div style={{ width: "100%", opacity: 0.4, cursor: "pointer", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}>
                                <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                    <img src="/btc.png" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                    <div style={{ width: 10 }} />
                                    <h3 style={{ paddingTop: 5 }}>Bitcoin (BTC) (Coming Soon)</h3>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>ทันที - 1 วัน (1d)</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>1 USDT</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>10 - 10,000,000 USD</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: 30 }} />
                            <div style={{ width: "100%", opacity: 0.4, cursor: "pointer", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}>
                                <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                    <img src="/bankcard.jpeg" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                    <div style={{ width: 10 }} />
                                    <h3 style={{ paddingTop: 5 }}>Bank Card (Coming Soon)</h3>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>ทันที - 30 นาที</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>1 USDT</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                        <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                        <div style={{ width: 10 }} />
                                        <div style={{ fontWeight: "bold" }}>10 - 10,000 USD</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ padding: 10, fontSize: 13 }}>
                        <h5>Plase select {stateType === "deposit" ? "deposit" : "withdraw"} method</h5>
                        <br />
                        <div
                            style={{ width: "100%", opacity: 1, cursor: "pointer", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}
                            onClick={() => {
                                setChannelType("BinancePay");
                                stateType === "deposit" ? setShowAddMoney(true) : setShowWithdrawMoney(true);
                            }}
                        >
                            <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                <img src="/binance.png" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                <div style={{ width: 10 }} />
                                <h3 style={{ paddingTop: 5 }}>BinancePay</h3>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>ทันที - 30 นาที</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>1 USDT</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>10 - 15,000 USD</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 10 }} />
                        <div
                            style={{ width: "100%", opacity: stateType === "deposit" ? 1 : 0.4, backgroundColor: "rgba(185, 185, 185, 0)", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}
                            onClick={() => {
                                if (stateType === "deposit") {
                                    setChannelType("BNBSmartChain");
                                    stateType === "deposit" ? setShowAddMoney(true) : setShowWithdrawMoney(true);
                                }
                            }}
                        >
                            <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                <img src="/tether.png" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                <div style={{ width: 10 }} />
                                <h6 style={{ paddingTop: 5 }}>BNB Smart Chain (BEP20) {stateType != "deposit" && " (Coming Soon)"}</h6>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>ทันที - 1 วัน (1d)</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>1 USDT</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>10 - 10,000,000 USD</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 10 }} />
                        <div style={{ width: "100%", opacity: 0.4, backgroundColor: "rgba(185, 185, 185, 0)", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}>
                            <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                <img src="/bankcard.jpeg" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                <div style={{ width: 10 }} />
                                <h6 style={{ paddingTop: 5 }}>Bank Card (Coming Soon)</h6>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>ทันที - 30 นาที</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>1 USDT</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>10 - 10,000 USD</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 10 }} />
                        <div style={{ width: "100%", opacity: 0.4, backgroundColor: "rgba(185, 185, 185, 0)", height: 140, display: "flex", flexDirection: "column", border: "1px solid #fff", borderRadius: 8 }}>
                            <div style={{ width: "100%", height: 60, display: "flex", flexDirection: "row", padding: 10, alignItems: "center" }}>
                                <img src="/btc.png" style={{ height: 45, width: 45, borderRadius: 45 }} />
                                <div style={{ width: 10 }} />
                                <h6 style={{ paddingTop: 5 }}>Bitcoin (BTC) (Coming Soon)</h6>
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "column", borderTopWidth: 1, borderTopColor: "#fff", borderTopStyle: "solid", padding: 10 }}>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ระยะเวลาดำเนินการ</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>ทันที - 1 วัน (1d)</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ค่าธรรมเนียม</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>1 USDT</div>
                                </div>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ color: "#b9b9b9" }}>ขีดจำกัด</div>
                                    <div style={{ width: 10 }} />
                                    <div style={{ fontWeight: "bold" }}>10 - 10,000,000 USD</div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <Deposit
                    onCloseDepositPopup={() => setShowAddMoney(false)}
                    isShowDeposithPopup={showAddMoney}
                    money={money}
                    setMoney={setMoney}
                    totalMoney={totalMoney}
                    setTotalMoney={setTotalMoney}
                    rate={rate}
                    currencyFormatKip={currencyFormatKip}
                    slipImage={slipImage}
                    admin={admin.find((item) => item.bankName === channelType)}
                    onAddCredit={onAddCredit}
                    isSaveLoading={isSaveLoading}
                    requestPresignedUrlAndUploadImageToS3={requestPresignedUrlAndUploadImageToS3}
                    credit={credit}
                    channelType={channelType}
                    usedBonus={usedBonus}
                    setUsedBonus={setUsedBonus}
                    totalAmount={totalAmount}
                    setTotalAmount={setTotalAmount}
                    userData={userData}
                />
                <Withdraw
                    onCloseWithdrawPopup={() => setShowWithdrawMoney(false)}
                    isShowWithdrawPopup={showWithdrawMoney}
                    money={money}
                    setMoney={setMoney}
                    totalMoney={totalMoney}
                    setTotalMoney={setTotalMoney}
                    rate={rate}
                    currencyFormatKip={currencyFormatKip}
                    slipImage={slipImage}
                    onWithdrawCredit={onWithdrawCredit}
                    isSaveLoading={isSaveLoading}
                    requestPresignedUrlAndUploadImageToS3={requestPresignedUrlAndUploadImageToS3}
                    credit={credit}
                    channelType={channelType}
                    binanceEmailOrBinanceID={binanceEmailOrBinanceID}
                    setBinanceEmailOrBinanceID={setBinanceEmailOrBinanceID}
                    withdrawCode={withdrawCode}
                    setWithdrawCode={setWithdrawCode}
                    userData={userData}
                />
            </div>
    )
}

import React, { useEffect, useState } from "react";
import { screenSize, SEARCH_GOOGLE_IMAGE } from "../../constants";
import CustomNavbar from "../navbar/Navbar";
import { Typography } from "@mui/material";
import axios from "axios";
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faCircleXmark,
	faClock,
} from "@fortawesome/free-solid-svg-icons";
import LoadingPage from "../../common/loadingPage";

export default function AdminVipDetail() {
	const params = useParams();
	const navigate = useNavigate();
	const [userData, setUserData] = useState();
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getVIPUserDetail();
	}, []);

	const getVIPUserDetail = async () => {
		try {
			setIsLoading(true);
			var config = {
				method: "get",
				url: `${END_POINT_SEVER}/api/users/${params.id}`,
				headers: {
					"Content-Type": "application/json",
				},
			};

			const userRes = await axios(config);
			if (userRes?.data) {
				setUserData(userRes?.data?.user);
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
	};
	const updateVIPStatus = async (status) => {
		try {
			setIsSaveLoading(true);
			var config = {
				method: "put",
				url: `${END_POINT_SEVER}/api/users/${params.id}`,
				headers: {
					"Content-Type": "application/json",
				},
				data: JSON.stringify({
					vipStatus: status,
					vipUser: status == "VERIFIED" ? true : false,
				}),
			};

			const userRes = await axios(config);
			if (userRes?.data) {
				getVIPUserDetail();
			}
			setIsSaveLoading(false);
		} catch (error) {
			console.log(error);
			setIsSaveLoading(false);
		}
	};

	const convertCardType = (card) => {
		let newCard = "";
		switch (card) {
			case "PERSONAL_CARD":
				newCard = "ບັດປະຈຳຕົວ";
				break;
			case "PASSPORT_BOOK":
				newCard = "Passport";
				break;
			case "FAMILY_BOOK":
				newCard = "ປື້ມສຳມະໂນຄົວ";
				break;
			case "DRIVER_LICENSE":
				newCard = "ໃບຂັບຂີ່";
				break;

			default:
				break;
		}
		return newCard;
	};

	return isLoading ? (
		<div
			className="color-body"
			style={{
				width: "100%",
				minHeight: "100vh",
				justifyContent: "center",
				alignItems: "center",
				color: "#fff",
			}}
		>
			<LoadingPage />
		</div>
	) : (
		<div
			className="color-body"
			style={{
				width: "100%",
				minHeight: "100dvh",
				justifyContent: "center",
				alignItems: "center",
				color: "#fff",
			}}
		>
			<CustomNavbar />
			<div style={{ height: screenSize().width > 700 ? 90 : 80 }} />
			<Typography variant="h4">VIP Management Detail</Typography>
			{userData && (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
						padding: 10,
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
							height: 150,
							padding: 10,
							border: "1px solid #fff",
							borderRadius: 8,
						}}
					>
						<div style={{ display: "flex", flexDirection: "row" }}>
							<img
								src={
									userData?.image &&
									userData?.image.includes(SEARCH_GOOGLE_IMAGE)
										? userData?.image
										: `${S3_BUCKET_END_POINT}/images/${userData?.image}`
								}
								style={{ width: 110, height: 110, borderRadius: 8 }}
							/>
							<div style={{ width: 20 }} />
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-evenly",
									alignItems: "flex-start",
								}}
							>
								<div>ຊື່ ແລະ ນາມສະກຸນ:</div>
								<div>Email: </div>
								<div>VIP Status:</div>
								<div>ວັນເດືອນປີເກີດ:</div>
							</div>
							<div style={{ width: 20 }} />
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-evenly",
									alignItems: "flex-start",
								}}
							>
								<div>
									{userData?.realFirstName || userData?.realLastName
										? userData?.realFirstName + " " + userData?.realLastName
										: userData?.fullName}
								</div>
								<div>
									{userData?.email ?? "-"}{" "}
									{userData?.from ? (
										<span
											style={{
												border: "1px solid #fff",
												paddingLeft: 8,
												paddingRight: 8,
												paddingTop: 2,
												paddingBottom: 2,
												borderRadius: 20,
												backgroundColor: "#fff",
												color: "#000",
											}}
										>
											{userData?.from}
										</span>
									) : (
										""
									)}
								</div>
								<div
									style={{
										border: `1px solid ${userData?.vipStatus == "VERIFIED" ? "rgb(95, 182, 120)" : userData?.vipStatus == "REJECTED" ? "#e34828" : "orange"}`,
										paddingLeft: 8,
										paddingRight: 8,
										borderRadius: 20,
										paddingTop: 2,
										color:
											userData?.vipStatus == "VERIFIED"
												? "rgb(95, 182, 120)"
												: userData?.vipStatus == "REJECTED"
													? "#e34828"
													: "orange",
									}}
								>
									<FontAwesomeIcon
										icon={
											userData?.vipStatus == "VERIFIED"
												? faCheckCircle
												: userData?.vipStatus == "REJECTED"
													? faCircleXmark
													: faClock
										}
									/>{" "}
									{userData?.vipStatus}
								</div>
								<div>{moment(userData?.birtday).format("DD/MM/yyyy")}</div>
							</div>
						</div>
						<button
							type="button"
							onClick={() => {
								navigate(`/vip-by-member/${userData?._id}`);
							}}
							style={{
								background: "#2196f3",
								// border: "1px solid #fff",
								border: "none",
								borderRadius: 12,
								color: "#fff",
							}}
						>
							ກວດສອບ VIP
						</button>

						<div style={{ display: "flex", flexDirection: "row" }}>
							<div
								style={{
									height: 110,
									borderRightWidth: 1,
									borderRightColor: "#fff",
									borderRightStyle: "solid",
								}}
							/>
							<div style={{ width: 10 }} />
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "flex-start",
								}}
							>
								<button
									onClick={() => updateVIPStatus("VERIFIED")}
									style={{
										backgroundColor: "rgb(95,182,120)",
										color: "#fff",
										borderWidth: 1,
										borderRadius: 4,
										height: 32,
										width: 200,
									}}
								>
									{isSaveLoading ? (
										<Spinner animation="border" role="status"></Spinner>
									) : (
										"ອະນຸມັດ"
									)}
								</button>
								<div style={{ height: 10 }} />
								<button
									onClick={() => updateVIPStatus("REJECTED")}
									style={{
										backgroundColor: "#e34828",
										color: "#fff",
										borderWidth: 0,
										borderRadius: 4,
										height: 32,
										width: 200,
									}}
								>
									{isSaveLoading ? (
										<Spinner animation="border" role="status"></Spinner>
									) : (
										"ປະຕິເສດ"
									)}
								</button>
								<div style={{ height: 10 }} />
								<button
									onClick={() => updateVIPStatus("REQUESTING")}
									style={{
										backgroundColor: "orange",
										color: "#fff",
										borderWidth: 0,
										borderRadius: 4,
										height: 32,
										width: 200,
									}}
								>
									{isSaveLoading ? (
										<Spinner animation="border" role="status"></Spinner>
									) : (
										"ຮ້ອງຂໍ"
									)}
								</button>
							</div>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
							paddingTop: 20,
						}}
					>
						<div style={{ width: "32vw", borderRadius: 8 }}>ຮູ້ບດ້ານໜ້າຂອງບັດ</div>
						<div style={{ width: "32vw", borderRadius: 8 }}>ຮູ້ບດ້ານຫຼັງຂອງບັດ</div>
						<div style={{ width: "32vw", borderRadius: 8 }}>ຮູ້ບຖ່າຍຄູ່ກັບບັດ</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
							paddingBottom: 10,
						}}
					>
						<img
							src={`${S3_BUCKET_END_POINT}/images/${userData?.cardImageFront}`}
							style={{ width: "32vw", height: "25vw", borderRadius: 8 }}
						/>
						<img
							src={`${S3_BUCKET_END_POINT}/images/${userData?.cardImageBack}`}
							style={{ width: "32vw", height: "25vw", borderRadius: 8 }}
						/>
						<img
							src={`${S3_BUCKET_END_POINT}/images/${userData?.cardImagePerson}`}
							style={{ width: "32vw", height: "25vw", borderRadius: 8 }}
						/>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
							paddingTop: 20,
						}}
					>
						<div
							style={{
								width: "32vw",
								borderRadius: 8,
								display: "flex",
								flexDirection: "row",
								alignItems: "start",
							}}
						>
							<div
								style={{
									width: "45%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "end",
								}}
							>
								<div>ປະເພດບັດ​</div>
								<div>ເລກບັດ​</div>
								<div>ວັນອອກບັດ</div>
								<div>ວັນໝົດອາຍຸບັດ</div>
							</div>
							<div
								style={{
									width: "5%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<div>:</div>
								<div>:</div>
								<div>:</div>
								<div>:</div>
							</div>
							<div
								style={{
									width: "50%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "start",
								}}
							>
								<div>
									{userData?.cardType
										? convertCardType(userData?.cardType)
										: "-"}
								</div>
								<div>{userData?.cardNo ?? "-"}</div>
								<div>
									{userData?.cardOpenDate
										? moment(userData?.cardOpenDate).format("DD/MM/yyyy")
										: "-"}
								</div>
								<div>
									{userData?.cardEndDate
										? moment(userData?.cardEndDate).format("DD/MM/yyyy")
										: "-"}
								</div>
							</div>
						</div>
						<div
							style={{
								height: 100,
								borderRightWidth: 1,
								borderRightColor: "#fff",
								borderRightStyle: "solid",
							}}
						/>
						<div
							style={{
								width: "32vw",
								borderRadius: 8,
								display: "flex",
								flexDirection: "row",
								alignItems: "start",
							}}
						>
							<div
								style={{
									width: "30%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "end",
								}}
							>
								<div>ປະເທດ</div>
								<div>ສະໝັກໂດຍ</div>
								<div>Email</div>
								<div>ເບີໂທ</div>
							</div>
							<div
								style={{
									width: "5%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<div>:</div>
								<div>:</div>
								<div>:</div>
								<div>:</div>
							</div>
							<div
								style={{
									width: "65%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "start",
								}}
							>
								<div>{userData?.country ?? "-"}</div>
								<div>{userData?.from ?? "Coin Options"}</div>
								<div>{userData?.email ?? "-"}</div>
								<div>{userData?.phone ?? "-"}</div>
							</div>
						</div>
						<div
							style={{
								height: 100,
								borderRightWidth: 1,
								borderRightColor: "#fff",
								borderRightStyle: "solid",
							}}
						/>
						<div
							style={{
								width: "32vw",
								borderRadius: 8,
								display: "flex",
								flexDirection: "row",
								alignItems: "start",
							}}
						>
							<div
								style={{
									width: "30%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "end",
								}}
							>
								<div>ຊື່ຜູ້ໃຊ້</div>
								<div>ປະເພດຜູ້ໃຊ້</div>
								<div>VIP Code</div>
								<div>ສະໝັກຈາກ VIP Code</div>
							</div>
							<div
								style={{
									width: "5%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<div>:</div>
								<div>:</div>
								<div>:</div>
								<div>:</div>
							</div>
							<div
								style={{
									width: "65%",
									borderRadius: 8,
									display: "flex",
									flexDirection: "column",
									alignItems: "start",
								}}
							>
								<div>{userData?.userName ?? "-"}</div>
								<div>{userData?.role ?? "-"}</div>
								<div>{userData?.vipCode ?? "-"}</div>
								<div>{userData?.vipS1Code ?? "-"}</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

import React, { useEffect, useState } from 'react'
import { faCheck, faClose, faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api'
import { Button, Container, Form, Image, Spinner } from 'react-bootstrap'
import { Box, Grid, Divider } from '@mui/material'
import { appColor, screenSize } from '../../constants'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export default function Withdraw({
    onCloseWithdrawPopup,
    isShowWithdrawPopup,
    money,
    setMoney,
    totalMoney,
    setTotalMoney,
    rate,
    currencyFormatKip,
    slipImage,
    onWithdrawCredit,
    isSaveLoading,
    requestPresignedUrlAndUploadImageToS3,
    credit,
    channelType,
    binanceEmailOrBinanceID,
    setBinanceEmailOrBinanceID,
    setWithdrawCode,
    withdrawCode,
    userData
}) {
    const { t } = useTranslation();
    const [invalidMoney, setInvalidMoney] = useState(false)
    const [invalidImage, setInvalidImage] = useState(false)
    const [invalidBinanceEmailOrBiannceID, setInvalidBinanceEmailOrBiannceID] = useState(false)
    const [overWithdraw, setOverWithdraw] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const [changeIcon, setChangeIcon] = useState(false)
    const toastAlert = (type, msg,) => {
        toast(msg, { type: type == "error" ? "error" : "success" });
    };

    const checkInvalidData = () => {
        if (!money) {
            setInvalidMoney(true)
            return
        } else {
            setInvalidMoney(false)
        }
        console.log("slipImage: ", slipImage)

        // check slipImage or binanceEmailOrBinanceID
        if (!slipImage && !binanceEmailOrBinanceID) {
            setInvalidImage(true)
            setInvalidBinanceEmailOrBiannceID(true)
            return
        } else if (!slipImage && binanceEmailOrBinanceID) {
            setInvalidImage(false)
            setInvalidBinanceEmailOrBiannceID(false)
        } else if (slipImage && !binanceEmailOrBinanceID) {
            setInvalidImage(false)
            setInvalidBinanceEmailOrBiannceID(false)
        } else {
            setInvalidImage(false)
            setInvalidBinanceEmailOrBiannceID(false)
        }

        if (parseFloat(money) > parseFloat(credit)) {
            setOverWithdraw(true)
            return
        } else {
            setOverWithdraw(false)
        }
        setInvalidMoney(false)
        setInvalidImage(false)
        onWithdrawCredit()
    }

    const requestOtp = async () => {
        try {
            setIsLoading(true)
            const data = JSON.stringify({ "email": userData?.userName });
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/otp/request-otp`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const requestOtpHandle = await axios(config);
            if (requestOtpHandle.data.message == "FINISHED") {
                setChangeIcon(true)
                setIsLoading(false)
                setTimeout(() => {
                    setChangeIcon(false)
                }, 6000);
                toastAlert("success", "Request OTP success")
            } else {
                toastAlert("error", requestOtpHandle.data.message)
                setIsLoading(false)
            }
        } catch (error) {
            toastAlert("error", error.response.data.message)
            console.log(error)
            setIsLoading(false)
        }
    }

    const verifyOtp = async () => {
        try {
            if (!withdrawCode) return

            setIsLoadingSave(true)
            const data = JSON.stringify({
                "email": userData?.userName,
                "code": withdrawCode
            });
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/otp/verify-otp`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const verifyOtpHandle = await axios(config);
            if (verifyOtpHandle.data.message == "VERIFIED") {
                onWithdrawCredit(verifyOtpHandle.data.verifyCode)
            }
            setIsLoadingSave(false)
        } catch (error) {
            console.log(error)
            setIsLoadingSave(false)
            return
        }
    }

    return (
        <div style={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.7)", display: isShowWithdrawPopup ? "flex" : "none", flexDirection: "column", width: "100vw", minHeight: "100vh", top: 50, justifyContent: "center", alignItems: "center" }}>
            {screenSize().width > 700 ? <div style={{ width: 600, minHeight: 350, backgroundColor: appColor.darkColor, borderRadius: 8, padding: 20 }}>
                <Grid container>
                    <div style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: appColor.redActive, fontWeight: "bold", color: "white", fontSize: 18 }}>-{t("withdraw")}  {t("to")}  {channelType}</div>
                </Grid>
                <div style={{ height: 10 }} />
                <div style={{ display: "flex", color: "#fff", flexDirection: "column", width: "100%", justifyContent: "start", alignItems: "flex-start" }}>
                    <p>{t("current-money")}: ${currencyFormatKip(credit)}</p>
                    {/* <p>{t("exchange-rate")}: $1 = {rate?.usd ? currencyFormatKip(rate?.usdWithdraw) : 1} {t("kip")}</p> */}
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                        <input
                            type='number'
                            value={parseInt(money)}
                            onChange={(event) => {
                                setMoney(parseInt(event?.target?.value))
                                setTotalMoney((event?.target?.value ?? 0) * (rate?.usdWithdraw ?? 1))
                            }}
                            style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid " + (invalidMoney ? "red" : "gray"), borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                        />
                        <div style={{ width: 10 }} />
                        <Button
                            onClick={() => {
                                setMoney(credit)
                                setTotalMoney(credit * (rate?.usdWithdraw ?? 1))
                            }}
                            style={{
                                color: "white",
                                width: "100px",
                                borderColor: "#fff",
                                background: "linear-gradient(90deg, rgba(91, 97, 138) 22%, rgba(91, 97, 138) 79%)",
                            }}
                        >
                            <div>{t("max")}</div>
                        </Button>
                    </div>
                    {overWithdraw && <p style={{ color: "red", fontSize: 13, paddingTop: 5 }}>{t("over-withdraw")}</p>}
                </div>
                <Box height={14} />

                <div style={{ display: "flex", flexDirection: "row", color: "#fff" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                        {channelType == "BinancePay" ? <div>
                            <Box height={20} />
                            <p>{t("binance-email-or-binance-id-the-account-that-the-admin-needs-to-transfer")}</p>
                            <input
                                value={binanceEmailOrBinanceID}
                                placeholder="Enter your binance's Email or binanceID"
                                onChange={(event) => {
                                    setBinanceEmailOrBinanceID(event?.target?.value)
                                }}
                                style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid " + (invalidBinanceEmailOrBiannceID ? "red" : "gray"), borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                            />
                            <Box height={30} />
                            <p style={{ textAlign: "left" }}>Confirm Code <span style={{ color: "red" }}>*</span></p>
                            <p style={{ textAlign: "left", fontSize: 12 }}>Check your code on <span style={{ fontWeight: "bold" }}>{userData?.userName}</span></p>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                                <input
                                    type='text'
                                    value={withdrawCode}
                                    placeholder='Verify Code...'
                                    onChange={(event) => setWithdrawCode(event?.target?.value)}
                                    style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid gray", borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                                />
                                <div style={{ width: 10 }} />
                                <Button
                                    onClick={() => requestOtp()}
                                    style={{
                                        color: "#1b1b1b",
                                        width: "150px",
                                        borderColor: "#fff",
                                        background: "linear-gradient(90deg, " + appColor.yellowActive + " 22%, " + appColor.yellowActive + " 79%)",
                                    }}
                                >
                                    <div>{isLoading ? <Spinner animation="border" style={{ width: 20, height: 20 }} role="status"></Spinner> : (changeIcon ? <FontAwesomeIcon icon={faCheck} style={{ fontSize: 20 }} /> : "Get Code")}</div>
                                </Button>
                            </div>
                        </div> : ""}
                    </div>
                </div>
                <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                    <br />
                    <Button
                        onClick={() => verifyOtp()}
                        style={{
                            borderRadius: 28,
                            color: "white",
                            height: 45,
                            borderWidth: 0,
                            backgroundColor: appColor.green,
                            width: "100%"
                        }}
                    >
                        <div>{(isSaveLoading || isLoadingSave) ? <Spinner animation="border" style={{ width: 20, height: 20 }} role="status"></Spinner> : t("save")}</div>
                    </Button>
                </Grid>
                <Box height={20} />
            </div> : <div style={{ width: "95%", backgroundColor: appColor.darkColor, borderRadius: 8, padding: 10 }}>
                <Grid container>
                    <div style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: appColor.redActive, fontWeight: "bold", color: "white", fontSize: 18 }}>-{t("withdraw")}</div>
                </Grid>
                <div style={{ height: 10 }} />
                <div style={{ display: "flex", color: "#fff", flexDirection: "column", width: "100%", justifyContent: "start", alignItems: "flex-start" }}>
                    <p>{t("current-money")}: ${currencyFormatKip(credit)}</p>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                        <input
                            type='number'
                            value={parseInt(money)}
                            onChange={(event) => {
                                setMoney(parseInt(event?.target?.value))
                                setTotalMoney((event?.target?.value ?? 0) * (rate?.usdWithdraw ?? 1))
                            }}
                            style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid " + (invalidMoney ? "red" : "gray"), borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                        />
                        <div style={{ width: 10 }} />
                        <Button
                            onClick={() => {
                                setMoney(credit)
                                setTotalMoney(credit * (rate?.usdWithdraw ?? 1))
                            }}
                            style={{
                                color: "white",
                                width: "100px",
                                borderColor: "#fff",
                                background: "linear-gradient(90deg, rgba(91, 97, 138) 22%, rgba(91, 97, 138) 79%)",
                            }}
                        >
                            <div>{t("max")}</div>
                        </Button>
                    </div>
                    {overWithdraw && <p style={{ color: "red", fontSize: 13, paddingTop: 5 }}>{t("over-withdraw")}</p>}
                </div>
                <Box height={14} />
                <div style={{ display: "flex", flexDirection: "column", color: "#fff" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                        <Box height={2} />
                        {channelType == "BinancePay" ? <div>
                            <Box height={20} />
                            <p>{t("binance-email-or-binance-id-the-account-that-the-admin-needs-to-transfer")}</p>
                            <input
                                value={binanceEmailOrBinanceID}
                                placeholder="Enter your binance's Email or binanceID"
                                onChange={(event) => {
                                    setBinanceEmailOrBinanceID(event?.target?.value)
                                }}
                                style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid " + (invalidBinanceEmailOrBiannceID ? "red" : "gray"), borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                            />
                            <Box height={30} />
                            <p style={{ textAlign: "left" }}>Confirm Code <span style={{ color: "red" }}>*</span></p>
                            <p style={{ textAlign: "left", fontSize: 12 }}>Check your code on <span style={{ fontWeight: "bold" }}>{userData?.userName}</span></p>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                                <input
                                    type='text'
                                    value={withdrawCode}
                                    placeholder='Verify Code...'
                                    onChange={(event) => setWithdrawCode(event?.target?.value)}
                                    style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid gray", borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                                />
                                <div style={{ width: 10 }} />
                                <Button
                                    onClick={() => requestOtp()}
                                    style={{
                                        color: "#1b1b1b",
                                        width: "140px",
                                        borderColor: "#fff",
                                        background: "linear-gradient(90deg, " + appColor.yellowActive + " 22%, " + appColor.yellowActive + " 79%)",
                                    }}
                                >
                                    <div>{isLoading ? <Spinner animation="border" style={{ width: 20, height: 20 }} role="status"></Spinner> : (changeIcon ? <FontAwesomeIcon icon={faCheck} style={{ fontSize: 20 }} /> : "Get Code")}</div>
                                </Button>
                            </div>
                        </div> : ""}
                    </div>
                </div>
                <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                    <br />
                    <Button
                        onClick={() => verifyOtp()}
                        style={{
                            borderRadius: 28,
                            color: "white",
                            height: 45,
                            borderWidth: 0,
                            backgroundColor: appColor.green,
                            width: "100%"
                        }}
                    >
                        <div>{(isSaveLoading || isLoadingSave) ? <Spinner animation="border" style={{ width: 20, height: 20 }} role="status"></Spinner> : t("save")}</div>
                    </Button>
                </Grid>
                <Box height={20} />
            </div>}
            <div style={{ height: 10 }} />
            <div onClick={() => onCloseWithdrawPopup(false)} style={{ width: 50, height: 50, borderRadius: 50, backgroundColor: "rgba(255,255,255,0.2)", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}><FontAwesomeIcon icon={faClose} style={{ color: "#fff", fontSize: 22 }} /></div>
        </div>
    )
}

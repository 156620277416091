import { Box, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom';
import englishFlag from '../../images/flag/english-flag.png';
import thailandFlag from '../../images/flag/thailand-flag.png';
import khrFlag from '../../images/flag/khr-flag.png';
import AppContext from '../../appProvider/context';
import { USER_LOCAL_DATA, appColor, screenSize } from '../../constants';
import RegisterScreen from './registerScreen';
import { END_POINT_SEVER } from "../../api";
import axios from 'axios';
import LoginScreen from './loginScreen';
import VerifyOtpScreen from './verifyOtpScreen';
import { faClose, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function LoginV2() {
    const routeNavigate = useNavigate();
    const { setStateLanguage } = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const search = useLocation().search;
    const qty = new URLSearchParams(search).get('vipCode');
    const [formType, setFormType] = useState("LOGIN");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [fullname, setFullname] = useState("");
    const [vipCode, setVipCode] = useState(qty ?? "");
    const [phone, setPhone] = useState("");
    const [loginFail, setLoginFail] = useState("");
    const [registerType, setRegisterType] = useState("REGISTER");
    const [otpCode, setOtpCode] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showRegisterPassword, setShowRegisterPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPolicy, setConfirmPolicy] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [isFacebookLoading, setIsFacebookLoading] = useState(false);
    const [vipUserData, setVipUserData] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [alreadyHaveEmail, setAlreadyHaveEmail] = useState(false);
    const [alreadyHaveEmailText, setAlreadyHaveEmailText] = useState("");

    const currentLanguage = i18n.language;
    const handleChangeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        setStateLanguage(event.target.value)
        localStorage.setItem('appLanguage', event.target.value)
    };
    useEffect(() => {
        const getAppLanguage = async () => {
            const appLanguage = await localStorage.getItem('appLanguage')
            if (appLanguage) {
                setStateLanguage(appLanguage)
            } else {
                setStateLanguage("english")
            }
        }
        getAppLanguage()
        if (qty && vipUserData == null) getVipMember(qty)
    }, [qty, vipCode])
    const getVipMember = async (code) => {
        try {
            const config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/?vipCode=${code}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data && userHandle?.data?.users?.length > 0) {
                setVipUserData(userHandle?.data?.users[0])
            }
        } catch (error) {
            console.log(error)
        }
    }
    function isValidEmail(email) {
        var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    }
    const requestOtp = async () => {
        try {
            setIsLoading(true)
            setLoginFail("");
            if (!username || !password || !phone || !fullname) {
                setLoginFail(t("please-enter-the-information-completely"));
                setIsLoading(false)
                return;
            }
            if (!isValidEmail(username)) {
                setLoginFail(t("email-is-incorrect-please-try-again"));
                setIsLoading(false)
                return;
            }
            if (password != confirmPassword) {
                setIsLoading(false)
                return;
            }

            const data = JSON.stringify({ "email": username });
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/otp/request-otp`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const requestOtpHandle = await axios(config);
            if (requestOtpHandle.data.message == "FINISHED") {
                setFormType("VERIFY_OTP")
                setRegisterType("VERIFY_OTP")
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    const onLoginStart = useCallback(() => {
        // setIsGoogleLoading(true)
    }, [])
    const onGoogleLogin = async (response) => {
        try {
            setIsGoogleLoading(true)
            console.log("response.credential: ", response.credential)
            const loginData = JSON.stringify({ token: response.credential });
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/auth/google-login`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: loginData,
            };
            const loginHandle = await axios(config);
            if (loginHandle.data) {
                localStorage.setItem(USER_LOCAL_DATA, JSON.stringify(loginHandle.data));
                routeNavigate("/signal/BTCOTCUSDT");
            }
            setIsGoogleLoading(false)
        } catch (error) {
            console.log(error)
            setIsGoogleLoading(false)
        }
    }
    const onFacebookLogin = async (response) => {
        try {
            setIsFacebookLoading(true)
            const loginData = JSON.stringify({ token: response.accessToken, userID: response.userID });
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/auth/facebook-login`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: loginData,
            };
            const loginHandle = await axios(config);
            if (loginHandle.data) {
                localStorage.setItem(USER_LOCAL_DATA, JSON.stringify(loginHandle.data));
                routeNavigate("/signal/BTCOTCUSDT");
            }
            setIsFacebookLoading(false)
        } catch (error) {
            console.log(error)
            setIsFacebookLoading(false)
        }
    }
    const onLogin = async () => {
        try {
            setIsLoading(true)
            setLoginFail("");
            if (!username || !password) {
                setLoginFail(t("please-enter-your-username-and-password-completely"));
                setIsLoading(false)
                return
            }

            const loginData = JSON.stringify({ userName: username, password: password });
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/auth/login`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: loginData,
            };
            const loginHandle = await axios(config);
            if (loginHandle.data) {
                localStorage.setItem(USER_LOCAL_DATA, JSON.stringify(loginHandle.data));
                if(username == "ethusdtotc") routeNavigate("/signal/ETHOTCUSDT");
                else routeNavigate("/signal/BTCOTCUSDT");
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
            if (error?.response?.data?.errorCode == "INVALID_USERNAME_OR_PASSWORD") {
                setAlreadyHaveEmailText(t("email-or-password-is-incorrect-please-try-again"))
                setAlreadyHaveEmail(true)
            }
            setLoginFail(t("invalid-username-or-password"));
        }
    };
    const onRegister = async () => {
        try {
            setIsLoading(true)
            setLoginFail("");
            if (!otpCode) {
                setLoginFail(t("please-enter-the-information-completely"));
                setIsLoading(false)
                return;
            }

            const verifyCode = await verifyOtp();
            if (!verifyCode) {
                setLoginFail(t("fail-confirm-code"));
                setIsLoading(false)
                return;
            }

            let newData = {
                fullName: fullname,
                phone: phone,
                status: "ACTIVE",
                role: "USER",
                userName: username,
                password: password,
                verifyCode: verifyCode
            };
            if (vipCode) newData = { ...newData, vipS1Code: vipCode }
            if (vipUserData?.vipS1?.vipCode) newData = { ...newData, vipS2Code: vipUserData?.vipS1?.vipCode }
            if (vipUserData?.vipS2?.vipCode) newData = { ...newData, vipS3Code: vipUserData?.vipS2?.vipCode }
            if (vipUserData?._id) newData = { ...newData, vipS1: vipUserData?._id }
            if (vipUserData?.vipS1?._id) newData = { ...newData, vipS2: vipUserData?.vipS1?._id }
            if (vipUserData?.vipS2?._id) newData = { ...newData, vipS3: vipUserData?.vipS2?._id }

            const registerData = JSON.stringify(newData);
            const config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/auth/register`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: registerData,
            };
            const registerHandle = await axios(config);
            if (registerHandle.data) {
                localStorage.setItem(
                    USER_LOCAL_DATA,
                    JSON.stringify(registerHandle.data)
                );
                setRegisterType("SUCCESS")
                setTimeout(() => {
                    routeNavigate("/signal/BTCOTCUSDT");
                    setRegisterType("REGISTER")
                }, 2000);
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
            if (error?.response?.data?.errorCode == "USERNAME_HAS_ALREADY_EXIST") {
                setAlreadyHaveEmailText(t("this-email-already-exists-please-login-or-use-a-new-email-to-register"))
                setAlreadyHaveEmail(true)
            }
            setLoginFail(t("something-went-wrong-please-try-again"));
        }
    };
    const verifyOtp = async () => {
        try {
            const data = JSON.stringify({
                "email": username,
                "code": otpCode
            });
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/otp/verify-otp`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            const verifyOtpHandle = await axios(config);
            if (verifyOtpHandle.data.message == "VERIFIED") {
                return verifyOtpHandle.data.verifyCode
            }
        } catch (error) {
            console.log(error)
            return
        }
    }

    return (
        <div style={{ backgroundColor: appColor.bgDark, width: "100%", height: "100%" }}>
            <div style={{ width: "100%", height: 52, display: "flex", flexDirection: "row", justifyContent: "space-between", paddingLeft: 20, paddingRight: 20, paddingTop: 6 }}>
                <img src="/coin-logo-text.png" style={{ height: 45 }} />
                <Select
                    labelId="binary-simple-select-label"
                    id="binary-simple-select"
                    sx={{
                        padding: 0,
                        color: "#FFF",
                        height: 40,
                        width: 120,
                        textAlign: "start",
                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)': { color: 'white' },
                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': { color: 'white' },
                    }}
                    value={currentLanguage}
                    onChange={handleChangeLanguage}
                >
                    <MenuItem value={"english"}>
                        <Box
                            display={"flex"}
                            sx={{
                                gap: 1,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography variant="body1" fontWeight={"bold"}>
                                <img src={englishFlag} style={{ cursor: "pointer" }} width={30} height={20} />&nbsp;&nbsp; EN
                            </Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem value={"thailand"}>
                        <Box
                            display={"flex"}
                            sx={{
                                gap: 1,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography variant="body1" fontWeight={"bold"}>
                                <img src={thailandFlag} style={{ cursor: "pointer" }} width={30} height={20} />&nbsp;&nbsp; TH
                            </Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem value={"khr"}>
                        <Box
                            display={"flex"}
                            sx={{
                                gap: 1,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography variant="body1" fontWeight={"bold"}>
                                <img src={khrFlag} style={{ cursor: "pointer" }} width={30}
                                    height={20} />&nbsp;&nbsp; KHR
                            </Typography>
                        </Box>
                    </MenuItem>
                </Select>
            </div>
            <div style={{ width: "100%", height: screenSize().height - 52, display: "flex" }}>
                {screenSize().width > 600 && <div style={{ width: "100%", height: "100%" }}>
                    <div style={{ position: "absolute", textAlign: "start", paddingTop: 80, paddingLeft: 40, color: "white" }}>
                        <h1>{t("overiew-text-title")}</h1>
                        <div style={{ height: 10 }} />
                        <h4 style={{ fontWeight: "lighter" }}>{t("overiew-text-detail-1")}</h4>
                        <div style={{ height: 5 }} />
                        <h4 style={{ fontWeight: "lighter" }}>{t("overiew-text-detail-2")}</h4>
                    </div>
                    <img src="/login-background.jpg" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                </div>}
                <div style={{ width: screenSize().width > 600 ? 600 : "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", height: 55 }}>
                        <div onClick={() => setFormType("LOGIN")} style={{
                            width: "50%",
                            height: 55,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            color: formType == "LOGIN" ? appColor.yellow : "white",
                            borderTop: "4px solid " + (formType == "LOGIN" ? appColor.yellow : appColor.bgNoti),
                            backgroundColor: formType == "LOGIN" ? appColor.bgDark : appColor.bgNoti
                        }}>
                            {t("login")}
                        </div>
                        <div onClick={() => setFormType("REGISTER")} style={{
                            width: "50%",
                            height: 55,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            color: (formType == "REGISTER" || formType == "VERIFY_OTP") ? appColor.yellow : "white",
                            borderTop: "4px solid " + ((formType == "REGISTER" || formType == "VERIFY_OTP") ? appColor.yellow : appColor.bgNoti),
                            backgroundColor: (formType == "REGISTER" || formType == "VERIFY_OTP") ? appColor.bgDark : appColor.bgNoti
                        }}>
                            {t("register")}
                        </div>

                    </div>

                    {formType == "LOGIN" && <LoginScreen
                        username={username}
                        setUsername={setUsername}
                        showPassword={showPassword}
                        password={password}
                        setPassword={setPassword}
                        setShowPassword={setShowPassword}
                        onLogin={onLogin}
                        isLoading={isLoading}
                        onGoogleLogin={onGoogleLogin}
                        onFacebookLogin={onFacebookLogin}
                    />}
                    {formType == "REGISTER" && <RegisterScreen
                        fullname={fullname}
                        setFullname={setFullname}
                        phone={phone}
                        setPhone={setPhone}
                        loginFail={loginFail}
                        username={username}
                        setUsername={setUsername}
                        isValidEmail={isValidEmail}
                        showRegisterPassword={showRegisterPassword}
                        password={password}
                        setPassword={setPassword}
                        setShowRegisterPassword={setShowRegisterPassword}
                        showConfirmPassword={showConfirmPassword}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        setShowConfirmPassword={setShowConfirmPassword}
                        vipCode={vipCode}
                        setVipCode={setVipCode}
                        setConfirmPolicy={setConfirmPolicy}
                        confirmPolicy={confirmPolicy}
                        requestOtp={requestOtp}
                        isLoading={isLoading}
                        registerType={registerType}
                        onGoogleLogin={onGoogleLogin}
                        onFacebookLogin={onFacebookLogin}
                    />}
                    {formType == "VERIFY_OTP" && <VerifyOtpScreen
                        username={username}
                        requestOtp={requestOtp}
                        otpCode={otpCode}
                        setOtpCode={setOtpCode}
                        loginFail={loginFail}
                        onRegister={onRegister}
                        isLoading={isLoading}
                    />}
                </div>
            </div>
            <div
                style={{
                    position: "absolute",
                    backgroundColor: "rgba(0,0,0,0.7)",
                    display: alreadyHaveEmail ? "flex" : "none",
                    flexDirection: "column",
                    width: "100vw",
                    height: "100vh",
                    top: 0,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: screenSize().width > 600 ? 600 : "85%",
                        height: 200,
                        backgroundColor: "#fff",
                        borderRadius: 8,
                        padding: 20,
                    }}
                >
                    <h4>{t("alert")}</h4>
                    <div style={{ height: 20 }} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: "orange", fontSize: 60 }} />
                        <div style={{ height: 20 }} />
                        <div>{alreadyHaveEmailText}</div>
                    </div>
                    <div style={{ height: 30 }} />
                </div>
                <div style={{ height: 10 }} />
                <div
                    onClick={() => setAlreadyHaveEmail(false)}
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        backgroundColor: "rgba(255,255,255,0.2)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                >
                    <FontAwesomeIcon
                        icon={faClose}
                        style={{ color: "#fff", fontSize: 22 }}
                    />
                </div>
            </div>
        </div>
    )
}

import React, { useState, useEffect, useRef } from 'react'
import { USER_LOCAL_DATA, appColor, screenSize } from '../constants'
import { Spinner } from 'react-bootstrap'
import { CUS_REVIEW_P2P_TRANSACTION_GQL, GET_CHAT_LIST, GET_P2P_TRANSACTION_BY_ID, MC_REVIEW_P2P_TRANSACTION_GQL, P2P_UPDATE_NOTI, RECEIVE_P2P_COUNTDOWN, SEND_MESSAGE, STOP_P2P_COUNTDOWN, SUB_CHAT_MESSAGE } from '../apollo/crypto';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleUser, faFileCircleCheck, faImage, faListAlt, faPaperPlane, faXmark } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import axios from 'axios';
import { toast } from "react-toastify";
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from "../api"
import { GET_MC_GQL } from '../apollo/crypto'
import { Box, Dialog, DialogActions, Slide, Typography, useMediaQuery, Button } from '@mui/material'
import { currencyFormat } from '../utils';
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react';
import BankItemDetail from '../components/chat/bankItemDetail';
import PaymentSuccessModal from '../components/chat/PaymentSuccessModal';
import LoadingPage from '../common/loadingPage';
import { CopyAll } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChatScreen() {
    const { t, i18n } = useTranslation();
    const routeNavigate = useNavigate();
    const routeParams = useParams();
    const [messageInput, setMessageInput] = useState("")
    const [userID, setUserID] = useState("")
    const [user, setUser] = useState("")
    const [customer, setCustomer] = useState("")
    const [messageData, setMessageData] = useState([])
    const [p2pData, setP2pData] = useState()
    const [isLoading, setIsLoading] = useState(false);
    const [isShowApproveButton, setIsShowApproveButton] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [p2pCountdownTime, setP2pCountdownTime] = useState("00:00");
    const [myMC, setMyMC] = useState();
    const [getChats, { data: chatData }] = useLazyQuery(GET_CHAT_LIST, { fetchPolicy: 'network-only' });
    const { data: subChatMessageData } = useSubscription(SUB_CHAT_MESSAGE, { variables: { room: routeParams.chatRoom }, fetchPolicy: 'network-only' });
    const [sendMessage] = useMutation(SEND_MESSAGE)
    const [getAvsDetail, { data: avsData }] = useLazyQuery(GET_P2P_TRANSACTION_BY_ID, { fetchPolicy: 'network-only' })
    const { data: p2pUpdateData } = useSubscription(P2P_UPDATE_NOTI, { variables: { room: routeParams.chatRoom }, fetchPolicy: 'network-only' });
    const { data: receiveP2PCountDownData } = useSubscription(RECEIVE_P2P_COUNTDOWN, { variables: { roomId: routeParams.chatRoom }, fetchPolicy: 'network-only' });

    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("lg"));
    const [isMobile, setIsMobile] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize])

    const toastAlert = (type, msg,) => {
        toast(msg, { type: type == "error" ? "error" : "success" });
    };
    // p2pData
    const [getMyMC, { data: mcGqlData, loading }] = useLazyQuery(GET_MC_GQL, { fetchPolicy: 'network-only' })

    useEffect(() => {
        if (p2pData) {
            getMyMC({
                variables: {
                    userId: p2pData.merchant
                }
            })
            getUserData(p2pData?.customer)
            // p2pData?.customer
        }
    }, [p2pData])

    useEffect(() => {
        if (mcGqlData) {
            if (mcGqlData.getMyMerchant.error == true) {
                console.log(`getMyMC error`, mcGqlData.getMyMerchant.error);
            } else {
                setMyMC(mcGqlData?.getMyMerchant?.data)
                checkApproveButton(avsData?.getP2PTransactionByID, mcGqlData?.getMyMerchant?.data)
            }
        }
    }, [mcGqlData])

    useEffect(() => {
        if (routeParams.chatRoom) {
            getChats({ variables: { room: routeParams.chatRoom, limit: 100, skip: 0 } })
            getAvsDetail({
                variables: {
                    id: routeParams.chatRoom
                },
            });
        }
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (parseData?.user?._id) {
                        setUserID(parseData["user"]["_id"]);
                        setUser(parseData["user"]);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, [])

    useEffect(() => {
        if (chatData?.getChats?.length > 0) {
            setMessageData(chatData?.getChats)
        }
    }, [chatData])

    useEffect(() => {
        if (avsData?.getP2PTransactionByID) {
            checkApproveButton(avsData?.getP2PTransactionByID, myMC)
            setP2pData(avsData?.getP2PTransactionByID)
        }
    }, [avsData])

    useEffect(() => {
        if (subChatMessageData?.chat) {
            setMessageData([subChatMessageData?.chat, ...messageData])
        }
    }, [subChatMessageData]);

    useEffect(() => {
        if (p2pUpdateData?.p2pUpdateNoti) {
            getAvsDetail({
                variables: {
                    id: routeParams.chatRoom
                },
            });
            if (p2pUpdateData?.merchantConfirmType == "APPROVED" || p2pUpdateData?.customerConfirmType == "APPROVED") {
                toastAlert('success', 'Review Success')
            }
        }
    }, [p2pUpdateData]);

    useEffect(() => {
        if (receiveP2PCountDownData?.receiveP2PCountDown) {
            setP2pCountdownTime(receiveP2PCountDownData?.receiveP2PCountDown?.time ?? "00:00")
            if (receiveP2PCountDownData?.receiveP2PCountDown?.time == "00:00") {
                getAvsDetail({
                    variables: {
                        id: routeParams.chatRoom
                    },
                });
            }
        }
    }, [receiveP2PCountDownData]);

    const lastMessageRef = useRef(null);
    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [messageData]);

    const getUserData = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                setCustomer(userHandle.data?.user);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onSendTextMessage = async () => {
        try {
            if (messageInput.trim()) {
                setIsLoading(true)
                await sendMessage({
                    variables: {
                        data: {
                            room: routeParams.chatRoom,
                            sender: userID,
                            message: messageInput,
                            type: "TEXT"
                        }
                    }
                })
                setMessageInput("")
                setIsLoading(false)
            } else {
                setMessageInput("")
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const requestPresignedUrlAndUploadImageToS3 = async (event) => {
        try {
            setIsLoading(true)
            const fileData = event?.target?.files[0]
            if (fileData) {
                // TODO: Request Presigned Url
                var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${END_POINT_SEVER}/api/file/pre-signed-url`,
                    headers: { 'Content-Type': 'application/json' },
                    data: JSON.stringify({ "fileName": fileData.name, "folder": "images" })
                };
                const presignedUrl = await axios(config)

                // TODO: Upload to S3
                if (presignedUrl?.data?.url) {
                    let uploadfile = await axios({
                        method: "PUT",
                        url: presignedUrl?.data?.url,
                        data: fileData,
                        headers: {
                            "Content-Type": " file/*; image/*; video/*",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })

                    // TODO: Set image name to state
                    if (uploadfile) {
                        let dataTyle = fileData.type.split("/")[0];
                        await sendMessage({
                            variables: {
                                data: {
                                    room: routeParams.chatRoom,
                                    sender: userID,
                                    message: fileData.name,
                                    type: dataTyle == "video" ? "VIDEO" : "IMAGE"
                                }
                            }
                        })
                        setMessageInput("")
                        setIsLoading(false)
                    }
                }
            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
    };

    const handleModalBackgroundClick = (event) => {
        if (!event.target.closest('.modal-content')) {
            setSelectedImage(null);
        }
    };

    const [stopP2PCountDown] = useMutation(STOP_P2P_COUNTDOWN)
    const onApproveOrder = async () => {
        try {
            if (userID == p2pData.customer && p2pData.customerConfirmType == "PENDING") {
                handleCusReviewTransaction("APPROVED")
                if (p2pData?.tradeType == "SELL") {
                    stopP2PCountDown({ variables: { roomId: p2pData?._id } })
                    setP2pCountdownTime("00:00")
                }
            }
            if (userID == p2pData.merchant && p2pData.merchantConfirmType == "PENDING") {
                handleMCReviewTransaction("APPROVED")
                if (p2pData?.tradeType == "BUY") {
                    stopP2PCountDown({ variables: { roomId: p2pData?._id } })
                    setP2pCountdownTime("00:00")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    const onCancelOrder = async () => {
        try {
            if (userID == p2pData.customer && p2pData.customerConfirmType == "PENDING") {
                handleCusReviewTransaction("CANCELLED")
                stopP2PCountDown({ variables: { roomId: p2pData?._id } })
                setP2pCountdownTime("00:00")
            }
            if (userID == p2pData.merchant && p2pData.merchantConfirmType == "PENDING") {
                handleMCReviewTransaction("CANCELLED")
                stopP2PCountDown({ variables: { roomId: p2pData?._id } })
                setP2pCountdownTime("00:00")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const [mcReviewTransaction] = useMutation(MC_REVIEW_P2P_TRANSACTION_GQL, {
        onCompleted: (data) => {
            getAvsDetail({
                variables: {
                    id: routeParams.chatRoom
                },
            });
            if (data?.merchantReviewP2PTransaction.error.error == false) {
                toastAlert('success', 'Review Success')
            } else {
                toastAlert('error', `ERROR: ${data?.merchantReviewP2PTransaction.error.errorMsg}`)
            }
        },
        onError: (error) => {
            console.log("error :>> ", error);
        }
    })
    const [cusReviewTransaction] = useMutation(CUS_REVIEW_P2P_TRANSACTION_GQL, {
        onCompleted: async (data) => {
            getAvsDetail({
                variables: {
                    id: routeParams.chatRoom
                },
            });
            if (data?.customerReviewP2PTransaction.error.error == false) {
                toastAlert('success', 'Review Success')
                setOpenDialog(false)
            } else {
                toastAlert('error', `ERROR: ${data?.customerReviewP2PTransaction.error.errorMsg}`)
            }
        },
        onError: (error) => {
            console.log("error :>> ", error);
        }
    })

    const handleCusReviewTransaction = async (customerConfirmType) => {
        try {
            let data = {
                "_id": p2pData?._id,
                "customer": p2pData?.customer,
                "customerConfirmType": customerConfirmType
            }
            await cusReviewTransaction({
                variables: {
                    data: data
                }
            })
            if (customerConfirmType == "APPROVED") {
                sendMessage({
                    variables: {
                        data: {
                            room: routeParams.chatRoom,
                            sender: userID,
                            message: "Confirm success transection ✅",
                            type: "TEXT"
                        }
                    }
                })
            } else {
                if (customerConfirmType == "CANCELLED") {
                    sendMessage({
                        variables: {
                            data: {
                                room: routeParams.chatRoom,
                                sender: userID,
                                message: "Cancel transection ❌",
                                type: "TEXT"
                            }
                        }
                    })
                }
            }

            // TODO: Send mail to merchant
            const config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${p2pData?.merchant}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                const configSendEmail = {
                    method: "post",
                    url: `${END_POINT_SEVER}/api/otp/send-message-by-email`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        email: [userHandle?.data?.user?.email],
                        title: customerConfirmType == "APPROVED" ? "Customer has make payment success" : "Customer has cancelled payment",
                        message: customerConfirmType == "APPROVED" ? `Customer has make payment success, \n See more description as https://www.coinoptions.net/chat/${p2pData?._id}` : `Customer has cancelled payment, \n See more description as https://www.coinoptions.net/chat/${p2pData?._id}`
                    }),
                };
                await axios(configSendEmail);
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleMCReviewTransaction = async (merchantConfirmType) => {
        try {
            let data = {
                "_id": p2pData?._id,
                "merchant": p2pData?.merchant,
                "merchantConfirmType": merchantConfirmType
            }
            await mcReviewTransaction({
                variables: {
                    data: data
                }
            })
            if (merchantConfirmType == "APPROVED") {
                sendMessage({
                    variables: {
                        data: {
                            room: routeParams.chatRoom,
                            sender: userID,
                            message: "Confirm success transection ✅",
                            type: "TEXT"
                        }
                    }
                })
            } else {
                if (merchantConfirmType == "CANCELLED") {
                    sendMessage({
                        variables: {
                            data: {
                                room: routeParams.chatRoom,
                                sender: userID,
                                message: "Cancel transection ❌",
                                type: "TEXT"
                            }
                        }
                    })
                }
            }

            // TODO: Send mail to merchant
            const config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${p2pData?.customer}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                const configSendEmail = {
                    method: "post",
                    url: `${END_POINT_SEVER}/api/otp/send-message-by-email`,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        email: [userHandle?.data?.user?.email],
                        title: merchantConfirmType == "APPROVED" ? "Merchant has make payment success" : "Merchant has cancelled payment",
                        message: merchantConfirmType == "APPROVED" ? `Merchant has make payment success, \n See more description as https://www.coinoptions.net/chat/${p2pData?._id}` : `Merchant has cancelled payment, \n See more description as https://www.coinoptions.net/chat/${p2pData?._id}`
                    }),
                };
                await axios(configSendEmail);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const checkApproveButton = async (avsData, _myMC) => {
        if (avsData) {
            if (_myMC) {
                if (avsData.tradeType == "BUY") {
                    if (userID == avsData.customer && avsData.customerConfirmType == "PENDING") {
                        if (avsData.merchantConfirmType == "PENDING") {
                            setIsShowApproveButton(true)
                        } else {
                            setIsShowApproveButton(false)
                        }
                    } else {
                        if (userID == avsData.merchant && avsData.merchantConfirmType == "PENDING" && avsData.customerConfirmType == "APPROVED") {
                            setIsShowApproveButton(true)
                        } else {
                            setIsShowApproveButton(false)
                        }
                    }
                } else {
                    if (userID == _myMC?.user && avsData.merchantConfirmType == "PENDING") {
                        if (avsData.merchantConfirmType == "PENDING") {
                            setIsShowApproveButton(true)
                        } else {
                            setIsShowApproveButton(false)
                        }
                    } else {
                        if (userID == p2pData?.customer && avsData?.customerConfirmType == "PENDING" && avsData.merchantConfirmType == "APPROVED") {
                            setIsShowApproveButton(true)
                        } else {
                            setIsShowApproveButton(false)
                        }
                    }
                }
            } else {
                getMyMC({
                    variables: {
                        userId: p2pData?.merchant
                    }
                })
            }
        } else {
            setIsShowApproveButton(false)
        }
    }

    return (
        loading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div className='chat-page'>
                <div className='chat-header-box'>
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        position={'sticky'}
                        sx={{
                            alignItems: "center",
                            justifyContent: "space-between",

                            width: "100%",
                            padding: "0px 10px",
                            position: "fixed",
                            top: 0,
                            left: 0,
                            p: 1,
                            zIndex: 100

                        }}>
                        <FontAwesomeIcon onClick={() => routeNavigate("/p2p-advertisement")} icon={faCircleArrowLeft} style={{ fontSize: 24, color: "#fff", cursor: "pointer" }} />
                        {screenSize().width > 700 ? <h5 style={{ color: "#fff" }}>{`${userID == myMC?.user ? `Customer: ${customer != "" ? customer?.fullName : ""}` : `Merchant: ${myMC?.merchantName}`}`} </h5> : <div style={{ color: "#fff", fontSize: 14 }}>{`${userID == myMC?.user ? `Customer: ${customer != "" ? customer?.fullName : ""}` : `Merchant: ${myMC?.merchantName}`}`}</div>}
                        <div style={{ display: "flex" }}>
                            {(userID == p2pData?.customer || userID == myMC?.user) && (((p2pData?.tradeType == "BUY" && userID == p2pData?.customer) || (p2pData?.tradeType == "SELL" && userID == myMC?.user)) && <Box
                                display={"flex"}
                                flexDirection={"row"}
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: isShowApproveButton ? appColor.red : appColor.bgGrey,
                                    borderRadius: 2,
                                    paddingX: 1,
                                    height: screenSize().width > 700 ? 32 : 28,
                                }}
                                onClick={() => isShowApproveButton ? onCancelOrder() : console.log("Disable")}
                                size='small'
                            >
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{ fontSize: 14, color: isShowApproveButton ? "#ffffff" : "#1b1b1b" }}
                                />
                                <Box sx={{ width: 4 }} />
                                <Typography variant="body1" sx={{
                                    cursor: "pointer",
                                    color: isShowApproveButton ? "#ffffff" : "#1b1b1b",
                                    fontSize: 12
                                }}>Cancel</Typography>
                            </Box>)}
                            <div style={{ width: screenSize().width > 700 ? 30 : 10 }} />
                            <Box
                                display={"flex"}
                                flexDirection={"row"}
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: isShowApproveButton ? appColor.yellow : appColor.bgGrey,
                                    borderRadius: 2,
                                    paddingX: 1,
                                    height: screenSize().width > 700 ? 32 : 28,
                                }}
                                onClick={() => isShowApproveButton ? ((userID == p2pData?.customer && p2pData?.tradeType == "BUY") ? setOpenDialog(true) : onApproveOrder()) : console.log("Disable")}
                                size='small'
                            >
                                <FontAwesomeIcon
                                    icon={faFileCircleCheck}
                                    style={{ fontSize: 14, color: "#1b1b1b" }}
                                />
                                <Box sx={{ width: 4 }} />
                                <Typography variant="body1" sx={{
                                    cursor: "pointer",
                                    color: "#1b1b1b",
                                    fontSize: 12
                                }}>Make Payment</Typography>
                            </Box>
                        </div>
                    </Box>

                    <div></div>
                </div>
                <div
                    style={{
                        height: 10,
                    }}
                />
                <Typography variant="body1"
                    sx={{
                        cursor: "pointer",
                        color: "#ffffff",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <div style={{ fontSize: screenSize().width > 700 ? 14 : 12 }}>{p2pData?.tradeType} {t('Fiat amount')} {currencyFormat(p2pData?.fiatAmount)} {p2pData?.fiatUnitType}</div>
                    <div style={{ border: "1px solid " + appColor.yellow, borderRadius: 6, width: 100 }}>{p2pCountdownTime ?? ""}</div>
                    <div style={{ height: 12 }} />
                    <Box sx={{
                        display: "flex",
                        direction: "row",
                        justifyContent: "center",
                        gap: 2
                    }}>
                        {t("transaction_id")}:
                        <p>{routeParams?.chatRoom}</p>
                        <CopyAll
                            onClick={() => {
                                navigator.clipboard.writeText(routeParams?.chatRoom);
                                toastAlert("success", "Copy transaction success")
                            }}
                        />
                    </Box>
                    <div style={{ height: 12 }} />
                    <div style={{ backgroundColor: "rgba(51, 51, 51, 0.6)", borderRadius: 16, width: screenSize().width > 700 ? "65vw" : "90vw", padding: 10, fontSize: 12 }}>{t("chat-policy")}</div>
                </Typography>


                <div className="chat-messages">
                    {messageData.slice(0).reverse().map((data, index) => {
                        if (data?.type == "TEXT") {
                            if (data.sender?.id === userID) {
                                return <div
                                    key={data.id}
                                    className={`${data.sender?.id === userID ? 'chat-message-box-right' : 'chat-message-box-left'}`}
                                    ref={index === messageData.length - 1 ? lastMessageRef : null}
                                >
                                    <div key={data.id} className="chat-message" style={{ borderTopLeftRadius: 20, borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }}>
                                        {/* <div style={{ color: "#808080", fontSize: 12, textAlign: "left" }}>{user?.fullName}</div> */}
                                        {data.message}
                                        <div style={{ color: "#808080", fontSize: 12, textAlign: "left" }}>{moment(data.createdAt).format("HH:mm:ss (DD-MM-YYYY)")}</div>
                                    </div>
                                    <FontAwesomeIcon icon={faCircleUser} style={{ width: 24, height: 24, color: "#fff" }} />
                                </div>
                            } else {
                                return <div
                                    key={data.id}
                                    className={`${data.sender?.id === userID ? 'chat-message-box-right' : 'chat-message-box-left'}`}
                                    ref={index === messageData.length - 1 ? lastMessageRef : null}
                                >
                                    <FontAwesomeIcon icon={faCircleUser} style={{ width: 24, height: 24, color: "#fff" }} />
                                    <div key={data.id} className="chat-message" style={{ borderBottomRightRadius: 20, borderBottomLeftRadius: 20, borderTopRightRadius: 20 }}>
                                        {/* <div style={{ color: "#808080", fontSize: 12, textAlign: "left" }}>{user?.fullName}</div> */}
                                        {data.message}
                                        <div style={{ color: "#808080", fontSize: 12, textAlign: "right" }}>{moment(data.createdAt).format("HH:mm:ss (DD-MM-YYYY)")}</div>
                                    </div>
                                </div>
                            }
                        }
                        if (data?.type == "IMAGE") {
                            if (data.sender?.id === userID) {
                                return <div
                                    key={data.id}
                                    className={`${data.sender?.id === userID ? 'chat-message-box-right' : 'chat-message-box-left'}`}
                                    ref={index === messageData.length - 1 ? lastMessageRef : null}
                                >
                                    <div key={data.id} className="chat-image" style={{ borderTopLeftRadius: 20, borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }}>
                                        <img
                                            src={`${S3_BUCKET_END_POINT}/images/${data?.message}`}
                                            style={{ width: "30vw", height: "auto", borderRadius: 8 }}
                                            onClick={() => handleImageClick(`${S3_BUCKET_END_POINT}/images/${data?.message}`)}
                                        />
                                        <div style={{ color: "#808080", fontSize: 9, textAlign: "left" }}>{moment(data.createdAt).format("HH:mm:ss (DD-MM-YYYY)")}</div>
                                    </div>
                                    <FontAwesomeIcon icon={faCircleUser} style={{ width: 24, height: 24, color: "#fff" }} />
                                </div>
                            } else {
                                return <div
                                    key={data.id}
                                    className={`${data.sender?.id === userID ? 'chat-message-box-right' : 'chat-message-box-left'}`}
                                    ref={index === messageData.length - 1 ? lastMessageRef : null}
                                >
                                    <FontAwesomeIcon icon={faCircleUser} style={{ width: 24, height: 24, color: "#fff" }} />
                                    <div key={data.id} className="chat-image" style={{ borderBottomRightRadius: 20, borderBottomLeftRadius: 20, borderTopRightRadius: 20 }}>
                                        <img
                                            src={`${S3_BUCKET_END_POINT}/images/${data?.message}`}
                                            style={{ width: "30vw", height: "auto", borderRadius: 8 }}
                                            onClick={() => handleImageClick(`${S3_BUCKET_END_POINT}/images/${data?.message}`)}
                                        />
                                        <div style={{ color: "#808080", fontSize: 9, textAlign: "right" }}>{moment(data.createdAt).format("HH:mm:ss (DD-MM-YYYY)")}</div>
                                    </div>
                                </div>
                            }
                        }
                        if (data?.type == "VIDEO") {
                            if (data.sender?.id === userID) {
                                return <div
                                    key={data.id}
                                    className={`${data.sender?.id === userID ? 'chat-message-box-right' : 'chat-message-box-left'}`}
                                    ref={index === messageData.length - 1 ? lastMessageRef : null}
                                >
                                    <div key={data.id} className="chat-image" style={{ borderTopLeftRadius: 20, borderBottomRightRadius: 20, borderBottomLeftRadius: 20 }}>
                                        <video controls style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', margin: 'auto' }}>
                                            <source src={`${S3_BUCKET_END_POINT}/images/${data?.message}`} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <div style={{ color: "#808080", fontSize: 9, textAlign: "left" }}>{moment(data.createdAt).format("HH:mm:ss (DD-MM-YYYY)")}</div>
                                    </div>
                                    <FontAwesomeIcon icon={faCircleUser} style={{ width: 24, height: 24, color: "#fff" }} />
                                </div>
                            } else {
                                return <div
                                    key={data.id}
                                    className={`${data.sender?.id === userID ? 'chat-message-box-right' : 'chat-message-box-left'}`}
                                    ref={index === messageData.length - 1 ? lastMessageRef : null}
                                >
                                    <FontAwesomeIcon icon={faCircleUser} style={{ width: 24, height: 24, color: "#fff" }} />
                                    <div key={data.id} className="chat-image" style={{ borderBottomRightRadius: 20, borderBottomLeftRadius: 20, borderTopRightRadius: 20 }}>
                                        <video controls style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', margin: 'auto' }}>
                                            <source src={`${S3_BUCKET_END_POINT}/images/${data?.message}`} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                        <div style={{ color: "#808080", fontSize: 9, textAlign: "right" }}>{moment(data.createdAt).format("HH:mm:ss (DD-MM-YYYY)")}</div>
                                    </div>
                                </div>
                            }
                        }
                    })}
                </div>

                {/* <PaymentSuccessModal /> */}
                {(p2pData?.customerConfirmType == "APPROVED" && p2pData?.merchantConfirmType == "APPROVED") && <div style={{ color: appColor.green, border: "1px solid " + appColor.green, borderRadius: 20, padding: 10, position: "fixed", bottom: 80 }}>
                    Transection has successfully
                </div>}
                {((p2pData?.customerConfirmType == "CANCELLED" || p2pData?.merchantConfirmType == "CANCELLED") && (p2pData?.transectionStatus != "TIMEOUT")) && <div style={{ color: appColor.yellow, border: "1px solid " + appColor.yellow, borderRadius: 20, padding: 10, position: "fixed", bottom: 80 }}>
                    Transection has cancelled
                </div>}
                {(p2pData?.transectionStatus == "PROBLEM") && <div style={{ color: appColor.red, border: "1px solid " + appColor.red, borderRadius: 20, padding: 10, position: "fixed", bottom: 80 }}>
                    Transection has problem
                </div>}
                {((p2pData?.customerConfirmType == "CANCELLED" || p2pData?.merchantConfirmType == "CANCELLED") && (p2pData?.transectionStatus == "TIMEOUT")) && <div style={{ color: appColor.yellow, border: "1px solid " + appColor.yellow, borderRadius: 20, padding: 10, position: "fixed", bottom: 80 }}>
                    Transection has timeout
                </div>}
                <div className='chat-input-box'>
                    <label htmlFor="upload-photo" style={{ cursor: isLoading ? "not-allowed" : "pointer", }}>
                        <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            accept="image/*, video/*"
                            onChange={(event) => isLoading ? console.log("Loading...") : requestPresignedUrlAndUploadImageToS3(event)}
                        />

                        <div
                            style={{
                                width: 48,
                                height: 48,
                                borderRadius: 48,
                                backgroundColor: "rgba(255,255,255,0.2)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: isLoading ? "not-allowed" : "pointer",
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faImage}
                                style={{ color: "#fff", fontSize: 22 }}
                            />
                        </div>
                    </label>
                    <div style={{ width: 16 }} />
                    <input
                        type="text"
                        value={messageInput}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSendTextMessage()
                                event.preventDefault(); // Prevents the default action of the enter key which is to submit a form
                            }
                        }}
                        onChange={(event) => {
                            setMessageInput(event?.target?.value)
                        }}
                        className='chat-input'
                        placeholder="Type your message..."
                    />
                    <div style={{ width: 16 }} />
                    <Button
                        style={{
                            borderRadius: 30,
                            color: "#1b1b1b",
                            width: 100,
                            height: 45,
                            borderColor: "#1b1b1b",
                            background: appColor.yellow,
                            cursor: isLoading ? "not-allowed" : "pointer",
                        }}
                        onClick={() => isLoading ? console.log("Loading...") : onSendTextMessage()}
                    >
                        {isLoading ? <Spinner animation="border" role="status"></Spinner> : <span>Send <FontAwesomeIcon icon={faPaperPlane} /></span>}
                    </Button>
                </div>

                {selectedImage && (
                    <div className="modal-background" onClick={handleModalBackgroundClick}>
                        <div className="modal-content">
                            <img src={selectedImage} alt="Selected Image" style={{ maxWidth: '100%', maxHeight: '100%', display: 'block', margin: 'auto' }} />
                        </div>
                    </div>
                )}

                <React.Fragment>
                    <Dialog
                        // fullScreen={isMobile ? true : false}
                        maxWidth={'lg'}
                        open={openDialog}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={() => setOpenDialog(false)}
                        aria-describedby="alert-dialog-slide-description"
                        // sx={{ width: isMobile ? '100%' : '40vw', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                        sx={{ width: '100%' }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                // width: '800px',
                                // backgroundColor: appColor.red,
                            }}
                        >
                            <Box height={10} />
                            <Typography variant="h6"
                                sx={{
                                    fontWeight: 'blod',
                                    color: appColor.textGrey,
                                }}
                            >Payment Info</Typography>

                            <hr />

                            <Typography variant="h6"
                                sx={{
                                    fontWeight: 'bold',
                                    color: appColor.white,
                                }}
                            >Transfer {p2pData?.fiatUnitType} {currencyFormat(p2pData?.fiatAmount)} via {myMC?.bankName}</Typography>

                            <Box
                                sx={{
                                    p: 2
                                }}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    sx={{
                                        justifyContent: 'start',
                                        alignItems: 'start',
                                        border: `1px solid ${appColor.bgGrey}`,
                                        borderRadius: 2,
                                        padding: 2,
                                        // width: '600px',
                                    }}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderRadius: 1,
                                        px: 1,
                                        backgroundColor: appColor.bgGrey
                                    }}>
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 'bold',
                                                color: appColor.red
                                            }} >{" | "}
                                        </Typography>
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                color: appColor.black
                                            }} >
                                            {myMC?.bankName}
                                        </Typography>
                                    </Box>

                                    <BankItemDetail
                                        title={"You Pay"}
                                        value={`${p2pData?.fiatUnitType} ${currencyFormat(p2pData?.fiatAmount)}`}
                                        isCopy={true}
                                    />
                                    <BankItemDetail
                                        title={"Name"}
                                        value={`${myMC?.bankAccountName}`}
                                    />
                                    <BankItemDetail
                                        title={"Account number"}
                                        value={`${myMC?.bankAccountNumber}`}
                                        isCopy={true}
                                    />

                                    <Box

                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            p: 1,
                                        }}
                                    >
                                        <Button
                                            size='small'
                                            // variant='contained'
                                            sx={{
                                                backgroundColor: appColor.yellow,
                                                color: appColor.black,
                                                fontWeight: 'bold',
                                                ":hover": {
                                                    backgroundColor: appColor.yellowActive
                                                },
                                                width: '40%'
                                            }}
                                            onClick={() => {
                                                window.open(`${S3_BUCKET_END_POINT}/images/${myMC?.bankAccountQR}`)
                                            }}
                                        >Dowload QR</Button>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                width: '100%',
                                                p: 1,
                                                // backgroundColor: appColor.red
                                            }}
                                        >
                                            {/* <Typography
                                    variant='body1'
                                    sx={{
                                        // color: appColor.red
                                    }} >
                                    QR CODE
                                </Typography> */}

                                            <img
                                                src={`${S3_BUCKET_END_POINT}/images/${myMC?.bankAccountQR}`}
                                                style={{
                                                    width: isMobile ? "50%" : "12vw",
                                                    height: "auto",
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                </Box>
                            </Box>
                            <DialogActions>
                                <Button
                                    sx={{
                                        color: appColor.textGrey,
                                        backgroundColor: appColor.bgGrey,
                                        fontWeight: 'bold',
                                        paddingX: 4,
                                        py: 1,
                                        ":hover": {
                                            backgroundColor: appColor.bgGrey,
                                        }
                                    }}
                                    onClick={() => setOpenDialog(false)}>Cancel</Button>
                                <Box width={10} />
                                <Button
                                    onClick={() => onApproveOrder()}
                                    sx={{
                                        color: appColor.white,
                                        backgroundColor: appColor.green,
                                        fontWeight: 'bold',
                                        paddingX: 8,
                                        py: 1,
                                        ":hover": {
                                            backgroundColor: appColor.greenActive
                                        }
                                    }
                                    }>
                                    {isLoading ? <Spinner animation="border" role="status"></Spinner> : <span>Confirm</span>}
                                </Button>
                            </DialogActions>
                        </Box>
                    </Dialog>
                </React.Fragment>
            </div>
    )
}

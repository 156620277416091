import React from 'react'
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Slide,
    Avatar, Typography,
    Divider, TextField, Switch, Stack,

} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import { ContentCopyOutlined, CopyAllSharp } from '@mui/icons-material'
import { appColor } from '../../constants'
const BankItemDetail = ({ title, value, isCopy }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                px: 2,
            }}
        >
            <Typography
                variant='body1'
                sx={{
                    // color: appColor.red
                }} >
                {title}
            </Typography>
            <Box width={20} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <Typography
                    variant='body1'
                    sx={{
                        fontWeight: 'bold'
                        // color: appColor.red
                    }} >
                    {value}
                </Typography>
                {isCopy == true ?
                    <>
                        <IconButton>
                            <ContentCopyOutlined
                                onClick={() => {
                                    // console.log('copy :>> ');
                                    navigator.clipboard.writeText(value)
                                }}

                                sx={{
                                    width: 18,
                                    color: appColor.yellow
                                }} />
                        </IconButton>
                    </> : <></>}

            </Box>
        </Box>
    )
}

export default BankItemDetail
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../screens/login/login";
import LoginV2 from "../screens/login/loginV2";
import ForgetPassword from "../screens/login/forgetPassword";
import Dashboard from "../screens/dashboard";
import User from "../screens/users";
import Signal10082023 from "../screens/crypto/Signal10082023";
// import ClientTradeScreen from "../screens/crypto/ClientTradeScreen";
import TransectionList from "../screens/transection/transectionList";
import TradeHistories from "../screens/tradeHistory/tradeHistories";
import TransectionListAdmin from "../screens/transection/transectionListAdmin";
import CostPage from "../screens/cost";
import ProfitPage from "../screens/profit";
import SettingPage from "../screens/setting/setting";
import AdminCtrl from "../screens/crypto/AdminCtrl";
import { useAuth } from "../utils/useAuth";
import AdminNotificaiton from "../screens/notifications/NotifiactionList";
import AdminVipList from "../screens/vip/adminVipList";
import AdminVipDetail from "../screens/vip/adminVipDetail";
import VipByMember from "../screens/vip/vipByMember";
import CustomerVipList from "../screens/vip/customerVipList";
import RequestVip from "../screens/vip/requestVip";
import SelectBankOption from "../screens/transection/selectBankOption";
import BankAccount from "../screens/setting/bank";
import P2PPage from "../screens/p2p/p2p-page";
import ChatScreen from "../screens/chat";
import MyAdvPage from "../screens/p2p/my-adv-page";
import P2PMyOrderPage from "../screens/p2p/p2p-my-order";
import P2POrderRequestPage from "../screens/p2p/p2p-order-request";
import Policy from "../screens/login/policy";
import UsersPage from "../screens/setting/users";
import PrivateRoute from "./PrivateRoute";
// import P2PReviewPage from "../screens/setting/p2p-review";
import AdminChatPreviewScreen from "../screens/admin-chat-preview";
import AdminP2PReviewPage from "../screens/setting/admin-p2p-review";
import HomeOverviewV2 from "../screens/crypto/HomeOverviewV2";
import License from "../screens/login/license";
import ClosePage from "../components/closePage";
import VolumnPage from "../screens/setting/valumn";
import NewHomeOverview from "../screens/crypto/NewHomeOverview";

export default function AppRouter() {
	const location = useLocation();
	const pathName = location.pathname.split("/")[1];
	const isAdmin = useAuth();
	// console.log("isAdmin: ", isAdmin);

	return (
		<div style={{ display: "flex" }}>
			<Routes>
				{/* <Route path="/" element={<Dashboard />} /> */}
				{/* <Route path="/" element={<HomeOverviewV2 />} /> */}
				<Route path="/" element={<NewHomeOverview />} />
				<Route path="/auth" element={<LoginV2 />} />
				<Route
					path="/user"
					element={
						<PrivateRoute>
							<User />
						</PrivateRoute>
					}
				/>
				<Route path="/reset-password" element={<ForgetPassword />} />
				{isAdmin == true ? (
					<Route
						path="/signal/:tradeType"
						element={
							<PrivateRoute>
								<AdminCtrl />
							</PrivateRoute>
						}
					/>
				) : (
					<Route
						path="/signal/:tradeType"
						element={
							<PrivateRoute>
								<Signal10082023 />
							</PrivateRoute>
						}
					/>
				)}
				{/* <Route path="/signal/:tradeType" element={<PrivateRoute><ClosePage /></PrivateRoute>} /> */}
				{/* <Route path="/signal/:tradeType" element={<PrivateRoute><Signal10082023 /></PrivateRoute>} /> */}
				{/* <Route path="/client-trade" element={<ClientTradeScreen />} /> */}
				<Route
					path="/transection-list"
					element={
						<PrivateRoute>
							<TransectionList />
						</PrivateRoute>
					}
				/>
				<Route
					path="/transection-list-admin"
					element={
						<PrivateRoute>
							<TransectionListAdmin />
						</PrivateRoute>
					}
				/>
				<Route
					path="/trade-histories"
					element={
						<PrivateRoute>
							<TradeHistories />
						</PrivateRoute>
					}
				/>
				<Route
					path="/cost-page"
					element={
						<PrivateRoute>
							<CostPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/profit-page"
					element={
						<PrivateRoute>
							<ProfitPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/setting-page"
					element={
						<PrivateRoute>
							<SettingPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/notificaiton"
					element={
						<PrivateRoute>
							<AdminNotificaiton />
						</PrivateRoute>
					}
				/>
				<Route
					path="/admin-vip-page"
					element={
						<PrivateRoute>
							<AdminVipList />
						</PrivateRoute>
					}
				/>
				<Route
					path="/admin-vip-detail/:id"
					element={
						<PrivateRoute>
							<AdminVipDetail />
						</PrivateRoute>
					}
				/>
				<Route
					path="/vip-by-member/:id"
					element={
						<PrivateRoute>
							<VipByMember />
						</PrivateRoute>
					}
				/>
				<Route
					path="/vip-page"
					element={
						<PrivateRoute>
							<CustomerVipList />
						</PrivateRoute>
					}
				/>
				<Route
					path="/request-vip-page"
					element={
						<PrivateRoute>
							<RequestVip />
						</PrivateRoute>
					}
				/>
				<Route
					path="/select-bank-option"
					element={
						<PrivateRoute>
							<SelectBankOption />
						</PrivateRoute>
					}
				/>
				<Route
					path="/bank-option"
					element={
						<PrivateRoute>
							<BankAccount />
						</PrivateRoute>
					}
				/>
				<Route
					path="/users-management"
					element={
						<PrivateRoute>
							<UsersPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/valumn-management"
					element={
						<PrivateRoute>
							<VolumnPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/p2p-advertisement"
					element={
						<PrivateRoute>
							<P2PPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/p2p-my-adv"
					element={
						<PrivateRoute>
							<MyAdvPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/p2p-my-order"
					element={
						<PrivateRoute>
							<P2PMyOrderPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/p2p-order-request"
					element={
						<PrivateRoute>
							<P2POrderRequestPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/chat/:chatRoom"
					element={
						<PrivateRoute>
							<ChatScreen />
						</PrivateRoute>
					}
				/>
				<Route
					path="/admin-p2p-review"
					element={
						<PrivateRoute>
							<AdminP2PReviewPage />
						</PrivateRoute>
					}
				/>
				<Route
					path="/admin-chat-preview/:chatRoom"
					element={
						<PrivateRoute>
							<AdminChatPreviewScreen />
						</PrivateRoute>
					}
				/>
				<Route path="/policy" element={<Policy />} />
				<Route path="/license" element={<License />} />
			</Routes>
		</div>
	);
}

import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, Grid, ButtonGroup, Button, Avatar, Divider } from '@mui/material'
import { appColor } from '../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faClockFour, faClockRotateLeft, faExchangeAlt, faStopwatch, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { currencyFormat } from '../utils/index'
import { useTranslation } from 'react-i18next'
import { VerifiedRounded } from '@mui/icons-material'
import { S3_BUCKET_END_POINT } from "../api"

const P2PItem = ({ isMobile, p2pType, handleClickOpen, adv, userID }) => {
    const { t, i18n } = useTranslation();
    /*
    {"__typename":"createAdvertisementDataResponse",
    "merchantName":"Kito MC",
    "verificationStatus":"true",
    "userName":"kitoalone@gmail.com",
    "fullName":"therng1",
    "phone":"2096344502",
    tradeType":"BUY",
    "assetType":"USDT",
    "fiatUnitType":"LAK",
    "price":"23198",
    "minSingleTransQuantity":"10000",
    "maxSingleTransQuantity":"20000",
    "tradableQuantity":"15000",
    "user":"64f771e250a6bd4afc1d8317",
    "isPublish":true,
    "_id":"65d2c86c02dd9d6599368ceb"}
    */
    return (
        <Box sx={{
            // width: '100%',
        }}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                sx={{
                    zIndex: -10,
                    width: '100%'
                }}
            >
                <Box sx={{
                    height: 10
                }} />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        gap: 2
                    }}
                >
                    <Box
                        display={'flex'}
                        flexDirection={isMobile ? 'column' : 'row'}
                        sx={{
                            //SPACE-BETWEEN
                            // justifyContent: 'space-between',
                            alignItems: 'start',
                            flexGrow: 1,
                            gap: 0.5
                        }}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                justifyContent: 'start',
                                alignItems: 'start',
                                width: "25%",
                                gap: 1,
                            }}
                        >
                            {/* AVATAR */}
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'center'

                                }}
                            >
                                {adv?.userImage ? <img src={`${S3_BUCKET_END_POINT}/images/${adv?.userImage}`} style={{ width: 30, height: 30, borderRadius: 30, border: "1px solid #fff" }} /> : <Avatar
                                    sx={{
                                        backgroundColor: appColor.textWhite,
                                        width: '30px',
                                        height: '30px'
                                    }}
                                />}
                                {adv?.merchantActive == true ? <div className='online-mark' /> : <div className='offline-mark' />}
                                <Box sx={{
                                    width: 4
                                }} />
                                <Typography
                                    variant='body1'
                                    sx={{
                                        color: appColor.textWhite,
                                        width: isMobile ? '55vw' : '100%',
                                        textAlign: "left",
                                        display: "flex",
                                    }}>
                                    {adv?.merchantName}
                                    <Box sx={{
                                        width: 4
                                    }} />
                                    <VerifiedRounded
                                        sx={{
                                            color: adv?.verificationStatus ? appColor.greenActive : appColor.gray,
                                            width: isMobile ? 16 : 20,
                                        }}
                                    />
                                </Typography>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={isMobile ? 'column' : 'row'}
                                sx={{
                                    justifyContent: 'space-between',
                                    alignItems: 'start',
                                    gap: 0.5
                                }}>
                                <Box display={'flex'} flexDirection={'column'}
                                    sx={{
                                        justifyContent: 'start',
                                        alignItems: 'start',

                                    }}>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            color: appColor.textGrey,
                                            fontSize: 12,
                                            width: isMobile ? '65vw' : '100%',
                                            textAlign: "left",
                                        }}
                                    >{adv?.meta?.totalAllTrade} orders  |  {adv?.meta?.totalCompletedRate == "NaN" ? "0" : adv?.meta?.totalCompletedRate}% completion  </Typography>

                                    {isMobile ? <></> : <><Box display={'flex'} flexDirection={'row'}>
                                        <FontAwesomeIcon
                                            icon={faThumbsUp}
                                            style={{ fontSize: 14, color: appColor.green }}
                                        />
                                        <Box sx={{
                                            width: 4
                                        }} />
                                        <Typography
                                            sx={{
                                                color: appColor.textGrey,
                                                fontSize: 12,
                                            }}
                                            variant='body2'
                                        > 86.76%</Typography>

                                    </Box></>}
                                    {isMobile ? <>
                                        <Box display={'flex'} flexDirection={'row'} sx={{
                                            gap: 1,
                                            width: isMobile ? '65vw' : '100%',
                                            textAlign: "left",
                                        }}>
                                            <Box display={'flex'} flexDirection={'row'} >
                                                <FontAwesomeIcon
                                                    icon={faThumbsUp}
                                                    style={{ fontSize: 12, color: appColor.textGrey }}
                                                />
                                                <Box sx={{
                                                    width: 4
                                                }} />
                                                <Typography
                                                    sx={{
                                                        color: appColor.textGrey,
                                                        fontSize: 12,
                                                    }}
                                                    variant='body2'
                                                > 86.76%</Typography>

                                            </Box>
                                            <Box display={'flex'} flexDirection={'row'} >

                                                <FontAwesomeIcon
                                                    icon={faStopwatch}
                                                    style={{ fontSize: 12, color: appColor.textGrey }}
                                                />
                                                <Box sx={{
                                                    width: 4
                                                }} />
                                                <Typography
                                                    sx={{
                                                        color: appColor.textGrey,
                                                        fontSize: 12,
                                                    }}
                                                    variant='body2'
                                                > 15 Min</Typography>
                                            </Box>
                                        </Box>


                                    </> : <></>}
                                </Box>
                                {isMobile ?
                                    <><Box display={'flex'} flexDirection={'row'}
                                        sx={{
                                            alignItems: "center",
                                            width: isMobile ? '65vw' : '100%',
                                            textAlign: "left",
                                        }}
                                    >
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 900,
                                                color: appColor.textWhite,
                                                fontSize: 18,
                                            }}>
                                            {adv?.fiatUnitType} {currencyFormat(parseInt(adv?.price))}
                                        </Typography>
                                        <Box sx={{
                                            width: 4
                                        }} />
                                        <Typography
                                            variant='body2'
                                        >{adv?.fiatUnitType}</Typography>
                                    </Box></>
                                    : <></>}
                            </Box>
                        </Box>
                        {isMobile ? <></> : <>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                sx={{
                                    alignItems: 'center',
                                    width: "22%",
                                }}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontWeight: 900,
                                        color: appColor.textWhite,
                                        fontSize: 20,
                                    }}>{currencyFormat(parseInt(adv?.price))}</Typography>
                                <Box sx={{
                                    width: 4
                                }} />
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: appColor.textWhite,
                                    }}
                                >{adv?.fiatUnitType}</Typography>
                            </Box></>}
                        <Box
                            display={'flex'} flexDirection={'column'}
                            sx={{
                                gap: 0.5,
                                width: isMobile ? '65vw' : '38%',
                                textAlign: "left",
                            }}
                        >
                            <Box display={'flex'} flexDirection={'row'}>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: appColor.textGrey,
                                        fontSize: 12

                                    }}>Available</Typography>
                                <Box sx={{ width: 4 }} />
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: appColor.textWhite,
                                        fontSize: 12
                                    }}
                                >{currencyFormat(parseInt(adv?.tradableQuantity))} {adv?.assetType ?? ""}</Typography>
                            </Box>
                            <Box display={'flex'} flexDirection={'row'}>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: appColor.textGrey,
                                        fontSize: 12
                                    }}>Limit </Typography>
                                <Box sx={{ width: 4 }} />
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: appColor.textWhite,
                                        fontSize: 12
                                    }}
                                >{adv?.fiatUnitType} {currencyFormat(parseInt(adv?.minSingleTransQuantity))} - <span style={{ color: adv?.maxSingleTransQuantity <= 0 ? appColor.red : appColor.white }}>{adv?.fiatUnitType} {currencyFormat(parseInt(adv?.maxSingleTransQuantity))}</span></Typography>
                            </Box>
                        </Box>
                        {/* {isMobile ? <></> : <>
                            <Box
                                sx={{
                                    paddingRight: '80px'
                                }}
                                display={'flex'}>
                                <Typography
                                    variant='body1'
                                    sx={{
                                        color: appColor.red,
                                        fontSize: 12,
                                    }} >|
                                </Typography>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey,
                                        fontSize: 12
                                    }}
                                    variant='body1'
                                >BCEL</Typography>
                            </Box>
                        </>} */}
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: isMobile ? 'end' : 'center',
                        alignItems: 'end'
                    }}>
                        {/* {isMobile ? <>
                            <Box
                                display={'flex'}>
                                <Typography
                                    variant='body2'
                                    sx={{
                                        color: appColor.red,
                                        fontSize: 12,
                                    }} >|
                                </Typography>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey,
                                        fontSize: 12
                                    }}
                                    variant='body2'
                                >BCEL</Typography>
                            </Box>
                        </> : <>
                        </>} */}
                        <Box sx={{
                            width: 2,
                            height: isMobile ? 4 : 0
                        }} />
                        <Button
                            size={isMobile ? 'small' : 'large'}
                            disabled={!adv?.merchantActive || adv?.maxSingleTransQuantity <= 0}
                            onClick={() => (adv?.merchantActive == true && adv?.maxSingleTransQuantity > 0) ? handleClickOpen(adv, "CREATE") : console.log("Closed merchant")}
                            sx={{
                                color: appColor.textWhite,
                                backgroundColor: (!adv?.merchantActive || adv?.maxSingleTransQuantity <= 0) ? appColor.textGrey2 : (p2pType === "BUY" ? appColor.green : appColor.red),
                                fontWeight: 'bold',
                                width: isMobile ? '100%' : 'none',
                                ":hover": {
                                    backgroundColor: p2pType === "BUY" ? appColor.greenActive : appColor.redActive
                                }
                            }
                            }>
                            <Box sx={{
                                width: 4,
                                fontWeight: 'bold'
                            }} />
                            {t(`${p2pType}`)} USDT
                        </Button>
                    </Box>
                </Box>
                <Box
                    height={10}
                />
                <Divider
                    sx={{ width: "100%" }}
                    color={appColor.bgGrey}
                />
                <Box
                    height={4}
                />
            </Box>
        </Box>
    )
}

export default P2PItem
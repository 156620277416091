import React, { useEffect, useState } from 'react'
import CustomNavbar from '../navbar/Navbar'
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { AccessTime, Close, Done, Inbox } from '@mui/icons-material'
import { USER_LOCAL_DATA, appColor } from '../../constants'
import { GET_NOTIFICATION_HISTORY, RECEIVE_NOTIFICATION } from '../../apollo/crypto'
import { useLazyQuery, useSubscription } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import LoadingPage from '../../common/loadingPage'

const AdminNotificaiton = () => {
  const routeNavigate = useNavigate();
  const [userData, setUserData] = useState();
  const [userID, setUserID] = useState();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  // GET LOCAL DATA
  useEffect(() => {
    const getLoalData = async () => {
      try {
        const _localData = await localStorage.getItem(USER_LOCAL_DATA);
        if (_localData) {
          let parseData = JSON.parse(_localData);
          if (
            parseData["user"] &&
            parseData["user"] &&
            parseData["user"]["_id"]
          ) {
            setUserData(parseData["user"]);
            setUserID(parseData["user"]["_id"]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    getLoalData();
  }, []);
  // START DATA FROM GRAPHQL
  const [notifiactionList, setnotifiactionList] = useState([]);

  const [getNotification, { loading }] = useLazyQuery(GET_NOTIFICATION_HISTORY, {
    fetchPolicy: 'network-only',
  });


  useEffect(() => {
    getNotification({
      variables: {
        receiveId: userData?.role === "ADMIN" ? "ADMIN" : userID,
      }
    }).then((res) => {
      console.log("res: ", res?.data?.getNotifications);
      setnotifiactionList(res?.data?.getNotifications);
    });
  }, [userID, userData]);



  // START RECEIVE NOTIFICATION
  const { data: receiveNotification } = useSubscription(RECEIVE_NOTIFICATION, {
    variables: {
      userId: userData?.role === "ADMIN" ? "ADMIN" : userID,
    },
  });

  useEffect(() => {
    /**
    senderId
    senderName
    receiveId
    receiveName
    category
    type
    title
    detail
     */
    // console.log('receiveNotification :>> ', receiveNotification);
    // console.log('receiveNotification :>> ', receiveNotification?.receiveNotification);
    // toast(receiveNotification?.receiveNotification?.detail, { type: receiveNotification?.receiveNotification.type === "APPROVED" ? "sucess" : "error" });
    getNotification({
      variables: {
        receiveId: userData?.role === "ADMIN" ? "ADMIN" : userID,
      }
    }).then((res) => {
      console.log("res: ", res?.data?.getNotifications);
      setnotifiactionList(res?.data?.getNotifications);
    });
  }, [receiveNotification]);

  // END RECEIVE NOTIFICATION
  return (
    loading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
      <LoadingPage />
    </div>
      :
      <div
        className="color-body"
        style={{
          width: "100%",
          minHeight: "100vh",
          height: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomNavbar />
        <div style={{ height: 100 }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <Box sx={{
            width: '100%',
            backgroundColor: appColor.primary,
            color: appColor.white,
            borderRadius: 4,
          }}>
            <Typography
              sx={{
                p: 2
              }} variant="h4">Notification</Typography>
            <List>
              {notifiactionList?.map((item, index) => (
                <ListItem
                  onClick={() =>
                    userData?.role == "ADMIN"
                      ? routeNavigate("/transection-list-admin")
                      : routeNavigate("/transection-list")
                  }
                  sx={{
                    backgroundColor: appColor.bgNoti,
                    borderRadius: 2,
                    color: appColor.white,
                    margin: "10px 0px",
                  }}
                  key={index}>
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        color: appColor.white,
                      }}
                    >
                      {item?.type === "WAITTING" ? <AccessTime /> : item?.type === "REJECTED" ? <Close /> : <Done />}
                      {/* // <Inbox /> */}
                    </ListItemIcon>
                    <ListItemText
                      sx={{ color: appColor.white, fontFamily: currentLanguage == "lao" ? "Phetsarath_OT" : "Myriad_Pro", }}
                      primaryTypographyProps={{

                        // fontSize: 22,
                        color: appColor.white,
                      }}
                      secondaryTypographyProps={{
                        // fontSize: 15,
                        color: appColor.gray,
                      }}
                      primary={`${t(`${item.title}`)}`} secondary={`${t(`${item.detail}`)}`} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </div>

      </div>
  )
}

export default AdminNotificaiton
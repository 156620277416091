import React from 'react'

export default function Layout({ title, children, height }) {
  return (
    <div
      className="k-line-chart-container"
      style={{
        // paddingTop: 50,
        height: height,
      }}
    >
      <h3
        className="k-line-chart-title">{title}</h3>
      {children}
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel';
import { Box, Typography, TextField, MenuItem, Grid, Button, useMediaQuery } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { t } from 'i18next';
import { toast } from "react-toastify";
import { appColor, screenSize } from '../../constants';
import { currencyFormat } from '../../utils';
import { END_POINT_SEVER } from '../../api';
import axios from "axios";
import { LoopOutlined, MultipleStopOutlined } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';
import { useTheme } from '@emotion/react';
export default function CreditTransfer({ userID, handlePopupTransfer, getCreditData }) {

    const [creditList, setCreditList] = useState([]);
    // const [creditListFilter, setcreditListFilter] = useState([]);

    const [creditFromType, setcreditFromType] = useState('REAL');

    const [creditFrom, setcreditFrom] = useState('');
    const [creditTo, setcreditTo] = useState('');

    const [creditAmountTrans, setcreditAmountTrans] = useState(0);
    const [creditAmountError, setcreditAmountError] = useState('');

    const [maxCreditAmountFrom, setMaxCreditAmountFrom] = useState();

    const [creditIDFrom, setcreditIDFrom] = useState('');
    const [creditIDTo, setcreditIDTo] = useState('');

    const handleChangeSwitchWallet = () => {
        if (creditFromType == "USDT") {
            setcreditFromType("REAL");
        } else {
            setcreditFromType("USDT");
        }
    }


    const toastAlert = (msg, type) => {
        toast(msg, { type: type == "win" ? "success" : "error" });
    };

    // const handleChangeFrom = (event) => {
    //     setcreditFrom(event.target.value);
    // };
    // const handleChangeTo = (event) => {
    //     setcreditTo(event.target.value);
    // };
    useEffect(() => {
        const filterUSDTWallet = creditList.filter((item) => item.type === "USDT");
        setcreditFrom(currencyFormat(filterUSDTWallet[0]?.credit));
        const filteRealWallet = creditList.filter((item) => item.type === "REAL");
        setcreditTo(currencyFormat(filteRealWallet[0]?.credit));

        if (creditFromType == "USDT") {
            const filterUSDTWallet = creditList.filter((item) => item.type === "USDT");
            setcreditIDFrom(filterUSDTWallet[0]?._id);
            setcreditIDTo(filteRealWallet[0]?._id);
        } else {
            const filterUSDTWallet = creditList.filter((item) => item.type === "REAL");
            setcreditIDFrom(filterUSDTWallet[0]?._id);
            const filteRealWallet = creditList.filter((item) => item.type === "USDT");
            setcreditIDTo(filteRealWallet[0]?._id);
        }

    }, [creditList, creditFromType]);



    useEffect(() => {
        // setcreditIDFrom(creditListFilter.find((item) => item.type == creditFrom)?._id);
        // setMaxCreditAmountFrom(creditListFilter.find((item) => item.type == creditFrom)?.credit);
        const filterMaxCredit = creditList.filter((item) => item.type === creditFromType);
        console.log('filterMaxCreditx :>> ', filterMaxCredit[0]);

        const newPrice = currencyFormat(filterMaxCredit[0]?.credit);
        console.log('newPrice filterMaxCredit :>> ', newPrice);
        // const floatValue = parseFloat(filterMaxCredit[0]?.credit.replace(/,/g, ''));
        // console.log('floatValue :>> ', floatValue);
        // let newPrice = parseFloat(floatValue);
        setMaxCreditAmountFrom(newPrice);
        setcreditAmountTrans(0);
    }, [creditFromType, creditList]);



    // get credit
    const getCreditDataTrans = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const creditHandle = await axios(config);
            if (creditHandle?.data) {
                setCreditList(creditHandle.data?.credit)
            }
        } catch (error) {
            console.log(error);
        }
    };

    // CREDIT TRANSFER
    const handleCreditTransfer = async () => {
        try {
            if (creditAmountTrans <= 0) {
                toastAlert(`SORRY: Amount must be greater than 0`);
                setcreditAmountError('Amount must be greater than 0')
                return;
            }
            if (creditIDFrom == creditIDTo) {
                toastAlert(`SORRY: Account type can't be same`);
                return;
            }
            console.log('creditAmountTrans :>> ', creditAmountTrans);
            var config = {
                method: "put",
                url: `${END_POINT_SEVER}/api/credit/transfer/${userID}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    transFromID: creditIDFrom,
                    transToID: creditIDTo,
                    transType: creditFromType == "USDT" ? "USDTTOREAL" : "REALTOUSDT",
                    credit: parseFloat(creditAmountTrans?.replace(/,/g, '')),
                }),
            };
            const updateHandle = await axios(config);
            if (updateHandle.data) {
                toastAlert("Transfer Success", "win");
                getCreditDataTrans(userID);
                getCreditData(userID);
                handlePopupTransfer();
                setcreditAmountError('')
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.errorCode == "MARKETING_USER") toastAlert(`SORRY: You are marketing account`);
            else toastAlert(`SORRY: ${error?.response?.data?.errorCode}`);
        }
    };

    useEffect(() => {
        getCreditDataTrans(userID);
    }, [userID]);

    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize])

    const isIPadSize = useMediaQuery(theme.breakpoints.down('md'));
    const [isIPad, setIsIPad] = useState(false);
    useEffect(() => {
        setIsIPad(isIPadSize)
    }, [isIPadSize])
    return (
        <Box
            sx={{
                width: isMobile ? "100dvw" : isIPad ? "50dvw" : "30dvw",
                px: 2
            }}
        >
            <Box style={{
                backgroundColor: appColor.background,
                height: "50%",
                padding: 20,
                borderRadius: 10,
            }}>
                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 10,
                }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "center",
                            width: "100%",
                            // px: 1,
                            // backgroundColor: appColor.bgDark,
                            borderRadius: "4px",
                            gap: 0.5
                        }}
                    >
                        <Box style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "start",
                            justifyContent: "space-between",
                            gap: 10,
                        }}>

                            <Typography color="white" style={{
                                fontFamily: "Phetsarath_OT"
                            }}>{t("from")}</Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: appColor.bgDark,
                                    px: 0.5,
                                    py: 1,
                                    width: '100%',
                                }}>
                                <Typography variant='body2' color="white">{creditFromType === "USDT" ? "Wallet" : "Real"}</Typography>
                                <Typography
                                    variant='body2'
                                    color="white"
                                    sx={{
                                        color: appColor.greenActive,
                                        px: 1,
                                        py: 1,
                                        fontSize: 18
                                    }}>
                                    {creditFromType === "USDT" ? creditFrom : creditTo}
                                </Typography>
                                <Typography variant='body2' color="white" style={{
                                    fontFamily: "Phetsarath_OT"
                                }}>{t("USDT")}</Typography>

                            </Box>
                        </Box>
                        <Box
                            sx={{
                                height: 10
                            }}
                        />
                        <MultipleStopOutlined
                            onClick={handleChangeSwitchWallet}
                            sx={{
                                cursor: 'pointer',
                                backgroundColor: appColor.bgDark
                            }}

                        />

                        <Box width={"100%"} style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "start",
                            justifyContent: "space-between",
                            gap: 10,
                        }}>
                            <Typography color="white" >{t("to")} </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: appColor.bgDark,
                                    px: 1,
                                    py: 1,
                                    width: '100%',
                                }}>
                                <Typography variant='body2' color="white" style={{
                                    fontFamily: "Phetsarath_OT"
                                }}>{creditFromType === "USDT" ? "Real" : "Wallet"}</Typography>
                                <Typography
                                    variant='body2'
                                    color="white"
                                    sx={{
                                        color: appColor.greenActive,
                                        px: 0.5,
                                        py: 1,
                                        fontSize: 18
                                    }}>{creditFromType === "USDT" ? creditTo : creditFrom}</Typography>
                                <Typography variant='body2' color="white" >{t("USDT")}</Typography>

                            </Box>
                        </Box>


                    </Box>

                    {/* <div style={{ height: 50 }} />
                    <Box
                        display={"flex"}
                        sx={{
                            justifyContent: "space-between",
                            justifyItems: "center",
                            alignContent: "center",
                            alignItems: "center",
                            width: "100%",
                            backgroundColor: "#343f59",
                            px: 2,
                            py: 1,
                            borderRadius: "4px",
                        }}
                    >
                        <Typography color="white" style={{
                            fontFamily: "Phetsarath_OT"
                        }}>{t("from")}</Typography>
                        <FormControl >
                            <Select
                                labelId="binary-simple-select-label"
                                id="binary-simple-select"
                                sx={{
                                    padding: 0,
                                    color: "#FFF",
                                    height: 40,
                                    width: 180,
                                    textAlign: "start",
                                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                    {
                                        color: 'white',
                                    },
                                    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                        color: 'white',
                                    },
                                }}
                                value={creditFrom}
                                onChange={handleChangeFrom}
                            >
                                {creditListFilter && creditListFilter.map((item, index) => (
                                    <MenuItem key={index} value={item.type}>
                                        <Box
                                            display={"flex"}
                                            sx={{
                                                gap: 1,
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography variant="body1" fontWeight={"bold"}>
                                                <div style={{ color: "#04871e", fontSize: 14 }}>
                                                    {item.type}
                                                </div>
                                                <div style={{ fontSize: 18, color: "#0D9276" }}>
                                                    $
                                                    {item?.credit
                                                        ? currencyFormat(item?.credit)
                                                        : 0}{" "}
                                                </div>
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <div style={{ width: 20, height: 4 }} />
                    <LoopOutlined />
                    <div style={{ width: 20, height: 4 }} />
                    
                    <Box
                        display={"flex"}
                        sx={{
                            justifyContent: "space-between",
                            justifyItems: "center",
                            alignContent: "center",
                            alignItems: "center",
                            width: "100%",
                            backgroundColor: "#343f59",
                            px: 2,
                            py: 1,
                            borderRadius: "4px",
                        }}
                    >
                        <Typography color="white" style={{
                            fontFamily: "Phetsarath_OT"
                        }}>{t("to")}</Typography>
                        <div style={{ width: 20, height: 10 }} />
                        <FormControl >
                            <Select
                                labelId="binary-simple-select-label"
                                id="binary-simple-select"
                                sx={{
                                    padding: 0,
                                    color: "#FFF",
                                    height: 40,
                                    width: 150,
                                    textAlign: "start",
                                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                    {
                                        color: 'white',
                                    },
                                    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                        color: 'white',
                                    },
                                }}
                                value={creditTo}
                                onChange={handleChangeTo}
                            >
                                {creditListFilter && creditListFilter.map((item, index) => (
                                    <MenuItem key={index} value={item.type}>
                                        <Box
                                            display={"flex"}
                                            sx={{
                                                gap: 1,
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography variant="body1" fontWeight={"bold"}>
                                                <div style={{ color: "#0B60B0", fontSize: 14 }}>
                                                    {item.type}
                                                </div>
                                                <div style={{ fontSize: 18, color: "#40A2E3" }}>
                                                    $
                                                    {item?.credit
                                                        ? currencyFormat(item?.credit)
                                                        : 0}{" "}
                                                </div>
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <div style={{ height: 30 }} /> */}

                </Box>
                {/* <div style={{ height: 10 }} />
                <Typography color="white" style={{
                    fontFamily: "Phetsarath_OT"
                }}>{creditFromType}: {maxCreditAmountFrom}</Typography> */}
                <div style={{ height: 10 }} />
                <Typography color="white" style={{
                    fontFamily: "Phetsarath_OT"
                }}>{t("amount-of-money")}</Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyItems: "center",
                        alignItems: "center",
                        backgroundColor: appColor.bgDark,
                        justifyContent: "space-between",
                    }}
                >


                    <NumericFormat
                        customInput={TextField}
                        decimalScale={0}
                        thousandSeparator={true}
                        id="outlined-basic"
                        placeholder='Enter Amount'
                        variant="standard"
                        // value={amountToPay}
                        sx={{
                            width: '100%',
                            px: 1,
                        }}
                        value={creditAmountTrans}
                        onChange={(event) => {
                            const value = event.target.value;
                            const valueFloat = parseFloat(value.replace(/,/g, ''));
                            const maxFloat = parseFloat(maxCreditAmountFrom.replace(/,/g, ''));
                            // console.log('event :>> ', valueFloat);
                            // console.log('event :>> ', maxFloat);
                            if (valueFloat >= 0) {
                                if (
                                    valueFloat <=
                                    parseFloat(maxFloat)
                                ) {
                                    setcreditAmountTrans(value);
                                } else {
                                    setcreditAmountTrans(maxFloat.toFixed(2));
                                }
                            } else {
                                setcreditAmountTrans(0);
                            }
                        }}

                        InputProps={{
                            disableUnderline: true,
                        }}
                    />
                    <Button
                        onClick={() => {
                            setcreditAmountTrans(0);
                            console.log('maxCreditAmountFrom :>> ', maxCreditAmountFrom);
                            setcreditAmountTrans(maxCreditAmountFrom);
                        }}
                        style={{
                            color: "white",
                            height: 48,
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            py: 1,
                            // width: "100px",
                            backgroundColor: appColor.bgDark
                        }}
                    >
                        <div>{t("max")}</div>
                    </Button>
                </Box>

                {/* <Typography color="white" style={{
                    fontFamily: "Phetsarath_OT"
                }}>{t("amount-of-money")}</Typography> */}
                {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Box
                        display={"flex"}
                        sx={{
                            justifyContent: "space-between",
                            justifyItems: "center",
                            alignContent: "center",
                            alignItems: "center",
                            backgroundColor: "#343f59",
                            px: 2,
                            py: 1,
                            borderRadius: "4px",
                            width: "100%"
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            fullWidth
                            inputProps={{
                                inputMode: 'numeric',
                                min: 0,
                                style: {
                                    textAlign: "center",
                                    color: "#FFFFFF ",
                                    fontWeight: "bold",
                                    padding: 0,
                                    margin: 0,
                                    border: "#FFFFFF",
                                },
                            }}
                            value={creditAmountTrans}
                            onChange={(event) => {
                                if (parseFloat(event.target.value) >= 0) {

                                    if (parseFloat(event.target.value) <= maxCreditAmountFrom) {
                                        setcreditAmountTrans(parseFloat(event.target.value))
                                    } else {
                                        setcreditAmountTrans(maxCreditAmountFrom)
                                    }
                                } else {
                                    setcreditAmountTrans(0)
                                }
                            }}
                            error={creditAmountError != '' ? false : true}
                            helperText={creditAmountError}
                            FormHelperTextProps={{
                                style: {
                                    color: "#FF0000",
                                    textAlign: "center",
                                    fontSize: "14px",
                                },
                            }}
                        />
                    </Box>
                    <Box width={10} />
                    <Button
                        onClick={() => setcreditAmountTrans(maxCreditAmountFrom)}
                        style={{
                            color: "white",
                            height: 45,
                            width: "100px",
                            borderColor: "#fff",
                            background: "linear-gradient(90deg, rgba(91, 97, 138) 22%, rgba(91, 97, 138) 79%)",
                        }}
                    >
                        <div>{t("max")}</div>
                    </Button>
                </div> */}
            </Box>
            <div style={{ height: 20 }} />
            <Button
                onClick={handleCreditTransfer}
                style={{
                    borderRadius: 4,
                    minWidth: "170px",
                    height: 45,
                    color: appColor.black,
                    backgroundColor: appColor.yellow,
                    // background:
                    // "linear-gradient(90deg, rgba(91, 97, 138) 22%, rgba(91, 97, 138) 79%)",
                    width: "100%",
                }}
            >
                <div>{t("confirm")}</div>
            </Button>
        </Box>
    )
}
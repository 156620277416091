import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { useSpring, animated } from '@react-spring/web';
import { appColor } from '../constants';
import { S3_BUCKET_END_POINT } from '../api';
import { VerifiedRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: appColor.bgDark,
    border: '2px solid #FFF',
    boxShadow: 24,
    borderRadius: 2,
    color: appColor.white,
    p: 4,
};

export default function NewCusSpringModal({ isMobile, open, handleOpenCusModal, cusDetail }) {

    const { t } = useTranslation();
    return (
        <div>
            {/* <Button onClick={handleOpenMCModal}>{t("Merchant-Detail")}</Button> */}
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleOpenCusModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}

            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="spring-modal-title" variant="h6" component="h6">
                            {t("Customer-Detail")}
                        </Typography>

                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1
                        }}>
                            <Divider />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <Typography variant="body2" >
                                    {t("name-and-lastname")}:
                                </Typography>
                                {cusDetail?.fullName}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <Typography variant="body2" >
                                    {t("username")}:
                                </Typography>
                                {cusDetail?.userName}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <Typography variant="body2" >
                                    {t("phone-number")}:
                                </Typography>
                                {cusDetail?.phone}
                            </Box>
                        </Box>

                        {/* <Box
                            display={'flex'}
                            flexDirection={'row'}
                            sx={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >

                            {mcDetail?.image ? <img src={`${S3_BUCKET_END_POINT}/images/${mcDetail?.image}`} style={{ width: 22, height: 22, borderRadius: 22, border: "1px solid #fff" }} /> : <Avatar
                                sx={{
                                    backgroundColor: appColor.textWhite,
                                    width: '22px',
                                    height: '22px'
                                }}
                            />}
                            {mcDetail?.isActive == true ? <div className='online-mark' /> : <div className='offline-mark' />}
                            <Box sx={{
                                width: 4
                            }} />
                            <Typography
                                variant='body1'
                                sx={{
                                    color: appColor.textWhite,
                                    width: isMobile ? '55vw' : '100%',
                                    textAlign: "left",
                                    display: "flex",
                                }}>
                                {mcDetail?.merchantName}
                                <Box sx={{
                                    width: 4
                                }} />
                                <VerifiedRounded
                                    sx={{
                                        color: mcDetail?.verificationStatus ? appColor.greenActive : appColor.gray,
                                        width: isMobile ? 16 : 20,
                                    }}
                                />
                            </Typography>
                        </Box> */}
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}

import React, { useEffect, useState } from 'react'
import CustomNavbar from '../navbar/Navbar'
import Typography from '@mui/material/Typography'
import { appColor, screenSize } from '../../constants'
import { Box, Grid, ButtonGroup, Button, Avatar, useMediaQuery, Zoom, Fab } from '@mui/material'
import P2PItem from '../../components/p2p-tiem'
import P2PNavMenu from '../../components/p2p-nav-menu'
import { useTheme } from "@emotion/react";
import P2PBuySellPopup from '../../components/p2p-buy-sell-popup'
import { useLazyQuery } from '@apollo/client'
import { GET_AVS_GQL, GET_P2P_TRANSACTION_GQL } from '../../apollo/crypto'
import { USER_LOCAL_DATA } from '../../constants'
import { GET_MC_GQL } from '../../apollo/crypto';
import CreateMcPagePopup from '../../components/mc/mc-page'
import { MoreVert } from '@mui/icons-material'
import LoadingPage from '../../common/loadingPage'
import loadingAnimationData from '../../images/empty-item-animation.json'
import { END_POINT_SEVER } from '../../api'
import Lottie from 'react-lottie';
import { toast } from "react-toastify";
import axios from "axios";

const P2PPage = () => {
    const [p2psCus, setP2pCus] = useState();
    const [p2psMC, setP2psMC] = useState();
    const [p2pCusMeta, setP2pCusMeta] = useState();
    const [p2pMCMeta, setP2pMCMeta] = useState();
    const [advs, setAdvs] = useState();
    const [adv, setAdv] = useState();
    const [popupType, setPopupType] = useState("CREATE");
    const [userID, setUserID] = useState();
    const [user, setUser] = useState();
    const [myMC, setMyMC] = useState();
    const [fiatUnitType, setFiatUnitType] = useState("LAK");

    const [p2pType, setp2pType] = useState("BUY"); // BUY, SELL
    const setP2PTypeHandle = (indexType) => {
        setp2pType(indexType)
    }

    // LOTTIE
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    // LOTTIE
    const [scrolledDown, setScrolledDown] = useState(false);
    // const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;

            // If scrolled down more than 50px, set scrolledDown to true
            if (position >= 50 && !scrolledDown) {
                setScrolledDown(true);
                console.log('Scrolled down to 50px or more');
            }

            // If scrolled back to top, set scrolledDown to false
            if (position < 50 && scrolledDown) {
                setScrolledDown(false);
                console.log('Scrolled back to top');
            }
        };

        // Add the scroll event listener to window
        window.addEventListener('scroll', handleScroll);

        // Clean up the scroll listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrolledDown]);

    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down('lg'));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize])

    // MODAL BUY OR SELL
    const [open, setOpen] = useState(false);
    const [openMC, setOpenMC] = useState(false);
    const handleClickOpen = async (adv, type) => {
        const _checkUserData = await checkUserData()
        if (_checkUserData == true) {
            setPopupType(type)
            setAdv(adv);
            if (type == "EDIT") {
                setOpenMC(true)
            } else {
                setOpen(true);
            }
        }
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseMC = () => {
        setOpenMC(false);
    };

    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (
                        parseData
                    ) {
                        setUserID(parseData?.user?._id);
                        setUser(parseData?.user);
                    }
                }

            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);

    let queryUser = {
        "query": {
            "tradeType": p2pType,
            "assetType": "USDT",
            "fiatUnitType": fiatUnitType,
            "priceSort": p2pType == "BUY" ? "ASC" : "DESC"
        }
    }
    const [getAvsList, { loading }] = useLazyQuery(GET_AVS_GQL, {
        variables: queryUser,
        fetchPolicy: 'network-only'
    })
    const [getMyMC] = useLazyQuery(GET_MC_GQL, {
        variables: {
            userId: userID
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            if (data.getMyMerchant.error == true) {
                console.log(`getMyMC error`, data.getMyMerchant.error);
            } else {
                setMyMC(data.getMyMerchant.data)
            }
        },
        onError: (error) => {
            console.log(`getMyMC error`, error)
        }
    })
    const getAvs = async () => {
        setAdv([])
        const { data } = await getAvsList()
        setAdvs(data?.getAdvertisement?.data)
    }

    let queryP2PUser = {
        "query": {
            "merchant": "",
            "customer": userID,
            "tradeType": p2pType
        }
    }

    const [getP2PTransaction] = useLazyQuery(GET_P2P_TRANSACTION_GQL, { fetchPolicy: 'network-only' })
    const getP2PCUSList = async () => {
        const { data } = await getP2PTransaction({
            variables: queryP2PUser,
        })
        setP2pCus(data?.getP2PTransaction?.data)
        setP2pCusMeta(data?.getP2PTransaction?.meta)

    }
    let queryP2PMC = {
        "query": {
            "merchant": userID,
            "customer": "",
            "tradeType": p2pType
        }
    }
    const getP2PMCList = async () => {
        const { data } = await getP2PTransaction({
            variables: queryP2PMC,
        })
        setP2psMC(data?.getP2PTransaction?.data)
        setP2pMCMeta(data?.getP2PTransaction?.meta)

    }

    useEffect(() => {
        getAvs()
        getMyMC()
        getP2PCUSList()
        getP2PMCList()
    }, [fiatUnitType, p2pType, user, userID]);

    const checkUserData = async () => {
        try {
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/users/${userID}`,
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const userData = await axios(config)
            if (!userData) {
                toastAlert(`SORRY: Permission Denied`);
                return false;
            }
            if (userData?.data?.user?.userMode == "MARKETING") {
                toastAlert(`SORRY: You are marketing account`);
                return false;
            }
            return true;
        } catch (error) {
            console.log(error)
            toastAlert(`SORRY: Permission Denied`);
            return false;
        }
    }

    const toastAlert = (msg, type) => {
        toast(msg, { type: type == "win" ? "success" : "error" });
    };

    return (
        loading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center" }}>
                <CreateMcPagePopup
                    isMobile={isMobile}
                    open={openMC}
                    handleClose={handleCloseMC}
                    adv={adv}
                    myMC={myMC}
                    popupType={popupType}
                    checkUserData={checkUserData}
                />

                {/* {scrolledDown === false ? <> */}
                <CustomNavbar />
                <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />
                {/* </> : <></>} */}
                <P2PBuySellPopup
                    isMobile={isMobile}
                    p2pType={p2pType}
                    open={open}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    adv={adv}
                    advs={advs}
                />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    sx={{
                        backgroundColor: appColor.bgDark,
                    }}>


                    <P2PNavMenu
                        p2pType={p2pType}
                        setP2PTypeHandle={setP2PTypeHandle}
                        isMobile={isMobile}
                        p2pCusMeta={p2pCusMeta}
                        p2pMCMeta={p2pMCMeta}
                        fiatUnitType={fiatUnitType}
                        setFiatUnitType={setFiatUnitType}
                        checkUserData={checkUserData}
                    />
                    <Box sx={{
                        paddingX: isMobile ? 2 : 20,
                    }}>
                        {(advs && advs.length > 0) ? advs?.map((adv, index) => {
                            return <P2PItem isMobile={isMobile} p2pType={p2pType} handleClickOpen={handleClickOpen} key={index} adv={adv} userID={userID} />
                        }) : <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: "100%",
                                height: "90dvh",
                                justifyContent: "center",
                                alignItems: "center"

                            }}>
                            <Lottie
                                options={defaultOptions}
                                height={150}
                                width={150}
                            />
                        </Box>}




                        {/* <Box sx={{
                        borderRadius: "10px",
                        padding: '10px',
                        border: `0.5px solid ${appColor.bgGrey}`
                    }}>
                    </Box> */}
                    </Box>
                    {/* <Zoom
                    key={fabGreenStyle.color}
                    // in={value === index}
                    timeout={transitionDuration}
                    // style={{
                    //     transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
                    // }}
                    unmountOnExit
                >
                    <Fab
                        sx={fabStyle}
                        aria-label={'orders'}
                    // sx={fab.sx} aria-label={fab.label} color={fab.color}
                    >
                        <MoreVert />
                    </Fab>
                </Zoom> */}
                </Box>
            </div>
    )
}

export default P2PPage
import React, { useEffect, useState } from 'react'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api'
import { Button, Form, Image } from 'react-bootstrap'
import { Box, Avatar, Typography } from '@mui/material'
import { appColor } from '../../constants'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Table } from "react-bootstrap";
import CustomNavbar from '../navbar/Navbar'
import moment from 'moment'
import { Delete, DeleteForever, Edit } from "@mui/icons-material";
import LoadingPage from '../../common/loadingPage'



export default function BankAccount() {
    const [banks, setBanks] = useState([]);
    const { t } = useTranslation();
    const [admin, setAdmin] = useState()
    const [bankName, setBankName] = useState("")
    const [bankAccount, setBankAccount] = useState("")
    const [bankNumber, setBankNumber] = useState("")
    const [bankQR, setBankQR] = useState("")
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        getAdminBank()
    }, [])

    const requestPresignedUrlAndUploadImageToS3 = async (event) => {
        try {
            const fileData = event?.target?.files[0]
            if (fileData) {
                // TODO: Request Presigned Url
                var config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${END_POINT_SEVER}/api/file/pre-signed-url`,
                    headers: { 'Content-Type': 'application/json' },
                    data: JSON.stringify({ "fileName": fileData.name, "folder": "images" })
                };
                const presignedUrl = await axios(config)

                // TODO: Upload to S3
                if (presignedUrl?.data?.url) {
                    let uploadfile = await axios({
                        method: "PUT",
                        url: presignedUrl?.data?.url,
                        data: fileData,
                        headers: {
                            "Content-Type": " file/*; image/*",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                            "Access-Control-Allow-Headers":
                                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                        },
                    })

                    // TODO: Set image name to state
                    if (uploadfile) {
                        setBankQR(fileData.name)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAdminBank = async () => {
        try {
            setIsLoading(true)
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/bank?limit=10`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const adminBank = await axios(config)

            if (adminBank?.data?.adminBank?.length > 0) {
                setBanks(adminBank?.data?.adminBank)
                console.log(adminBank?.data);
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const onSave = async () => {
        try {
            var config = {
                method: 'put',
                url: `${END_POINT_SEVER}/api/bank/${admin["_id"]}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    bankName: bankName,
                    bankAccountName: bankAccount,
                    bankAccountNumber: bankNumber,
                    bankQRCode: bankQR
                })
            };
            const adminBank = await axios(config)
            if (adminBank?.data) {
                getAdminBank()
                setIsShowPopup(false)
                toast(t("corrected-successfully"), { type: "success" });
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div
                className="color-body"
                style={{ width: "100%", minHeight: "100vh", color: "white", padding: 10 }}
            >
                <CustomNavbar />
                <div style={{ height: 100 }} />

                {/* Add button on the right */}
                <Typography variant="h4">{t("bank-list")}</Typography>

                <div style={{ padding: 20 }}>
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t("account-type")}</th>
                                <th>{t("account-name")}</th>
                                <th>{t("account-number")}</th>
                                <th>{t("account-qr")}</th>
                                <th>{t("country")}</th>
                                <th>{t("time-to-create")}</th>
                                <th>{t("manage")}</th>
                            </tr>
                        </thead>
                        <tbody
                        // set to center colume
                        >
                            {/* 
                        bankAccountName
                        bankAccountNumber
                        bankName
                        bankQRCode
                        country
                        createdAt
                        _id
                         */}
                            {banks && banks.map((bank, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{bank.bankName}</td>
                                    <td>{bank.bankAccountName}</td>
                                    <td>{bank.bankAccountNumber}</td>
                                    <td>
                                        <Image
                                            src={`${S3_BUCKET_END_POINT}/images/${bank.bankQRCode}`}
                                            style={{ height: "auto", width: 150, borderRadius: 8 }}
                                        />
                                    </td>
                                    {/* <img src={bank.bankQRCode} alt="bankQRCode" /> */}
                                    <td>{bank.country}</td>
                                    <td>{moment(bank.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                                    <td align="center">
                                        <Box
                                            display={"flex"}
                                            flexDirection={"row"}
                                            sx={{
                                                gap: 1,
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    width: 34,
                                                    height: 34,
                                                    cursor: "pointer",
                                                    backgroundColor: appColor.info,
                                                    "&:hover": {
                                                        backgroundColor: appColor.infoActive,
                                                    },
                                                    //   backgroundColor: `${
                                                    //     isCounting ? "#343F59" : "#f9de5d"
                                                    //   }`,
                                                    // },
                                                }}
                                                onClick={() => {
                                                    setIsShowPopup(true)
                                                    setAdmin(bank)
                                                    setBankName(bank.bankName)
                                                    setBankAccount(bank.bankAccountName)
                                                    setBankNumber(bank.bankAccountNumber)
                                                    setBankQR(bank.bankQRCode)
                                                }}
                                            >
                                                <Edit />
                                            </Avatar>
                                        </Box>
                                    </td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </Table>
                </div>

                <div style={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.7)", display: isShowPopup ? "flex" : "none", flexDirection: "column", width: "100vw", height: "100vh", top: 0, justifyContent: "center", alignItems: "center" }}>
                    <div style={{ width: 600, height: 700, backgroundColor: appColor.darkColor, borderRadius: 8, padding: 20, color: "#fff" }}>
                        <h4>{t("bank-account")}</h4>
                        {admin && <div>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "start", alignItems: "start" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: 150 }}>{t("name-of-the-bank")}:</div>
                                    <input type='text' style={{ width: 400 }} value={bankName} onChange={(e) => setBankName(e.target.value)} />
                                </div>
                                <div style={{ height: 10 }} />
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: 150 }}>{t("account-name")}:</div>
                                    <input type='text' style={{ width: 400 }} value={bankAccount} onChange={(e) => setBankAccount(e.target.value)} />
                                </div>
                                <div style={{ height: 10 }} />
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: 150 }}>{t("account-number")}:</div>
                                    <input type='text' style={{ width: 400 }} value={bankNumber} onChange={(e) => setBankNumber(e.target.value)} />
                                </div>
                            </div>
                            <div style={{ height: 10 }} />
                            <Image src={`${S3_BUCKET_END_POINT}/images/${bankQR}`} style={{ height: 400, width: 400 }} />
                            <Box height={8} />
                            <Form.Control type="file" onChange={(event) => requestPresignedUrlAndUploadImageToS3(event)} />
                        </div>}
                        <div style={{ height: 20 }} />
                        <Button
                            onClick={() => onSave()}
                            style={{
                                borderRadius: 28,
                                color: "white",
                                height: 45,
                                borderWidth: 0,
                                backgroundColor: appColor.green,
                                width: "100%"
                            }}
                        >
                            <div>{t("save")}</div>
                        </Button>
                    </div>
                    <div style={{ height: 10 }} />
                    <div onClick={() => setIsShowPopup(false)} style={{ width: 50, height: 50, borderRadius: 50, backgroundColor: "rgba(255,255,255,0.2)", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}><FontAwesomeIcon icon={faClose} style={{ color: "#fff", fontSize: 22 }} /></div>
                </div>
            </div>


    )
}

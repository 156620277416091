import { faGhost, faPaperPlane, faRankingStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appColor } from '../../constants';

export default function Requesting({ userData }) {
    const routeNavigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div>
            <div style={{ height: 80 }} />
            {userData?.vipStatus == "REQUESTING" ?
                <div>
                    <FontAwesomeIcon icon={faPaperPlane} style={{ color: "white", fontSize: 50 }} />
                    <div style={{ height: 10 }} />
                    <div>{t("REVIEWING_DOCS")}</div>
                </div>
                :
                userData?.vipStatus == "REJECTED" ?
                    <div>
                        <FontAwesomeIcon icon={faGhost} style={{ color: "white", fontSize: 50 }} />
                        <div style={{ height: 10 }} />
                        <div>{t("YOUR_REQUEST_HAS_BEEN_REJECT_PLEASE_UPDATE_AND_RESEND_AGAIN")}</div>
                    </div>
                    :
                    <div></div>
            }
            <div style={{ height: 80 }} />
            {userData?.vipStatus != "REQUESTING" && <Button
                onClick={() => routeNavigate("/request-vip-page")}
                style={{
                    borderRadius: 28,
                    borderWidth: 0,
                    color: "white",
                    width: 250,
                    backgroundColor: appColor.yellow,
                    color: appColor.primary,
                }}
            >
                <div><FontAwesomeIcon icon={faRankingStar} style={{ color: appColor.primary, fontSize: 32 }} /></div>
                <div>Register VIP Member Get Free 10usdt Welcom Bonus</div>
            </Button>}
        </div>
    )
}

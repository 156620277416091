import { Navigate } from "react-router-dom";
import { USER_LOCAL_DATA } from "../constants";

const PrivateRoute = ({ children }) => {
	const userAccess = JSON.parse(localStorage.getItem(USER_LOCAL_DATA));
	const userData = userAccess?.user || null;

	return userData ? children : <Navigate to="/" />;
};

export default PrivateRoute;

import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Spinner, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { WEB_VERSION, appColor } from '../../constants';

export default function VerifyOtpScreen({
  username,
  requestOtp,
  otpCode,
  setOtpCode,
  loginFail,
  onRegister,
  isLoading,
}) {
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "85%",
        height: "100%",
        alignItems: "center",
        paddingTop: 16,
        position: "relative"
      }}
    >
      <p style={{ color: "#fff", fontSize: 14, width: "100%", textAlign: "start" }}>
        {t("confirm-code-from")} <span style={{ fontWeight: "bold" }}>{username}</span>
      </p>
      <div style={{ height: 10 }} />
      <p onClick={() => requestOtp()} style={{ color: "#fff", fontSize: 16, color: "rgb(91,139,291)", cursor: "pointer", width: "100%", textAlign: "start" }}>
        <u><span>Resend</span></u>
      </p>
      <div style={{ height: 20 }} />
      <input
        type="number"
        placeholder="OTP Code"
        value={otpCode}
        onChange={(event) => setOtpCode(event?.target?.value)}
        style={{
          backgroundColor: "transparent",
          border: "1px solid " + (loginFail == t("please-enter-the-information-completely") && !otpCode ? "red" : "#fff"),
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderRadius: 0,
          height: 40,
          width: "100%",
          padding: 8,
          color: "#fff",
        }}
      />
      <div style={{ height: 50 }} />
      <button
        onClick={() => onRegister()}
        style={{
          backgroundColor: appColor.yellowActive,
          color: "#1b1b1b",
          borderWidth: 0,
          borderRadius: 4,
          height: 40,
          width: "85%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isLoading ? <Spinner animation="border" role="status"></Spinner> : t("register")}
        &nbsp;{" "}
        {!isLoading && <FontAwesomeIcon icon={faCircleArrowRight} style={{ color: "#1b1b1b" }} />}
      </button>
      <div style={{ height: 30 }} />
      <div>{WEB_VERSION}</div>
      <div style={{ width: "100%", bottom: 0, position: "absolute", bottom: 0, paddingBottom: 20 }}>
        <div style={{ color: "#ffffff", textAlign: "start", width: "100%" }}>{t("contact-us")}</div>
        <div style={{ display: "flex" }}>
          <img src="/social-media/instagram.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
          <div style={{ width: 6 }} />
          <img src="/social-media/whatsapp.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
          <div style={{ width: 6 }} />
          <img src="/social-media/twitter.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
          <div style={{ width: 6 }} />
          <img src="/social-media/snapchat.jpeg" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
          <div style={{ width: 6 }} />
          <img src="/social-media/tiktok.png" style={{ width: 22, height: 22, borderRadius: 4, cursor: "pointer" }} />
          <div style={{ width: 6 }} />
          <img src="/social-media/facebook.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
        </div>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { appColor, screenSize } from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons'
import { BarChart } from '@mui/x-charts'
import "./style.css"
import { useTranslation } from 'react-i18next'

export default function Overview({ userData, vipData }) {
    const [selectFilterTap, setSelectFilterTap] = useState("ThisMonth")
    const [countCode, setCountCode] = useState(0);
    const [countLink, setCountLink] = useState(0);
    const [vipType, setVipType] = useState(1);
    const [mainGraphHeaders, setMainGraphHeaders] = useState([]);
    const [mainGraphPrices, setMainGraphPrices] = useState([]);
    const [mainGraphTotalPrices, setMainGraphTotalPrices] = useState(0);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        getUserEarnData()
    }, [vipData])

    const getUserEarnData = async () => {
        try {
            let _mainGraphHeaders = []
            let _mainGraphPrices = []
            let _mainGraphTotalPrices = 0
            for (var i = 0; i < vipData?.statistic?.all?.length; i++) {
                _mainGraphHeaders.push(vipData?.statistic?.all[i]?._id.toString())
                _mainGraphPrices.push(vipData?.statistic?.all[i]?.price ? parseFloat(vipData?.statistic?.all[i]?.price.toFixed(2)) : 0)
                _mainGraphTotalPrices += (vipData?.statistic?.all[i]?.price ?? 0)
            }
            setMainGraphTotalPrices(_mainGraphTotalPrices)
            setMainGraphHeaders(_mainGraphHeaders)
            setMainGraphPrices(_mainGraphPrices)
            if (vipData?.s3?.count + vipData?.s3Withdraw?.count > 0) {
                setVipType(3)
            } else {
                if (vipData?.s2?.count + vipData?.s2Withdraw?.count > 0) {
                    setVipType(2)
                } else {
                    setVipType(1)
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onCopyCode = async (copyText, type) => {
        try {
            navigator.clipboard.writeText(copyText);
            if (type == "code") setCountCode(3)
            if (type == "link") setCountLink(3)
        } catch (error) {
            console.log("error: ", error)
        }
    }
    useEffect(() => {
        if (countCode > 0) {
            const timerId = setTimeout(() => setCountCode(countCode - 1), 1000);
            return () => clearTimeout(timerId);
        }
        if (countLink > 0) {
            const timerId = setTimeout(() => setCountLink(countLink - 1), 1000);
            return () => clearTimeout(timerId);
        }
    }, [countCode, countLink]);

    function currencyFormat(num) {
        return num ? num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
    }
    function currencyFormatNotFixed(num) {
        return num ? num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
    }

    return (
        <div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 8 }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: appColor.bgDark, borderRadius: 5, padding: 16 }}>
                    <div className="gold-number">{vipType}</div>
                    <div style={{ height: 10 }} />
                    <h4><b>{t("Level")} 1</b></h4>
                    <div style={{ height: 10 }} />
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", fontSize: 14 }}>
                        <div>{t("Volume")}</div>
                        <div style={{ height: 5 }} />
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div>A1</div>
                            <div>{vipData?.s1?.price ? currencyFormat(vipData?.s1?.price) : 0}$</div>
                        </div>
                        <div style={{ height: 5 }} />
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div>A2</div>
                            <div>{vipData?.s2?.price ? currencyFormat(vipData?.s2?.price) : 0}$</div>
                        </div>
                        <div style={{ height: 5 }} />
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div>A3</div>
                            <div>{vipData?.s3?.price ? currencyFormat(vipData?.s3?.price) : 0}$</div>
                        </div>
                    </div>
                    <div style={{ height: 16 }} />
                    <div style={{ width: "100%", borderBottom: `2px solid ${appColor.background}` }} />
                    <div style={{ height: 16 }} />
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", fontSize: 14 }}>
                        <div>{t("VIP Member")}</div>
                        <div style={{ height: 5 }} />
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div>A1 VIP</div>
                            <div>{vipData?.allUserConnection?.s1 ?? 0}</div>
                        </div>
                        <div style={{ height: 5 }} />
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div>A2 VIP</div>
                            <div>{vipData?.allUserConnection?.s2 ?? 0}</div>
                        </div>
                        <div style={{ height: 5 }} />
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div>A3 VIP</div>
                            <div>{vipData?.allUserConnection?.s3 ?? 0}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: 10 }} />
            <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ width: "32%", height: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", backgroundColor: appColor.bgDark, borderRadius: 5, padding: 16, fontSize: 14 }}>
                    <div>{t("Comission")} {t("BUY_SELL")}</div>
                    <div style={{ height: 16 }} />
                    <div>{vipData?.s1 ? currencyFormat((vipData?.s1?.price + vipData?.s2?.price + vipData?.s3?.price) + (vipData?.s1Withdraw?.price + vipData?.s2Withdraw?.price + vipData?.s3Withdraw?.price)) : 0}$</div>
                </div>
                <div style={{ width: 10 }} />
                <div style={{ width: "32%", height: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", backgroundColor: appColor.bgDark, borderRadius: 5, padding: 16, fontSize: 14 }}>
                    <div>{t("TotalTradeVolume")}</div>
                    <div style={{ height: 16 }} />
                    <div>{currencyFormatNotFixed((vipData?.s1?.count + vipData?.s2?.count + vipData?.s3?.count) + (vipData?.s1Withdraw?.count + vipData?.s2Withdraw?.count + vipData?.s3Withdraw?.count))}</div>
                </div>
                <div style={{ width: 10 }} />
                <div style={{ width: "32%", height: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", backgroundColor: appColor.bgDark, borderRadius: 5, padding: 16, fontSize: 14 }}>
                    <div>{t("TotalAgent")}</div>
                    <div style={{ height: 16 }} />
                    <div>{vipData?.allUserConnection?.s1 + vipData?.allUserConnection?.s2 + vipData?.allUserConnection?.s3}</div>
                </div>
            </div>
            <div style={{ height: 10 }} />
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: appColor.bgDark, borderRadius: 5, padding: 16, fontSize: 14 }}>
                    <h6>{`${t("Ref")}: ${t("RefCode")}`}</h6>
                    <div style={{ height: 10 }} />
                    <div>{t("RefCode")}</div>
                    <div style={{ position: 'relative', width: "100%", height: 40 }}>
                        <input
                            type="text"
                            placeholder={t("RefCode")}
                            value={userData?.vipCode ?? ""}
                            disabled
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid #fff",
                                borderRadius: 4,
                                height: 35,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <FontAwesomeIcon
                            icon={countCode > 0 ? faCheck : faCopy}
                            onClick={() => onCopyCode(userData?.vipCode, "code")}
                            style={{
                                position: 'absolute',
                                top: '40%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                color: "#fff"
                            }}
                        />
                    </div>
                    <div style={{ height: 5 }} />
                    <div>{t("RefLink")}</div>
                    <div style={{ position: 'relative', width: "100%", height: 40, zIndex: 0 }}>
                        <input
                            type="text"
                            placeholder="url code"
                            value={`https://coinoptions.net/auth?vipCode=${userData?.vipCode ?? ""}`}
                            disabled
                            style={{
                                backgroundColor: "transparent",
                                border: "1px solid #fff",
                                borderRadius: 4,
                                height: 35,
                                width: "100%",
                                padding: 8,
                                color: "#fff",
                            }}
                        />
                        <FontAwesomeIcon
                            icon={countLink > 0 ? faCheck : faCopy}
                            onClick={() => onCopyCode(`https://coinoptions.net/auth?vipCode=${userData?.vipCode}`, "link")}
                            style={{
                                position: 'absolute',
                                top: '40%',
                                right: '10px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                                color: "#fff"
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={{ height: 10 }} />
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: appColor.bgDark, borderRadius: 5, padding: 16, fontSize: 14 }}>
                    <h6>{t("LatestStatistic")}</h6>
                    <div style={{ height: 10 }} />
                    <div style={{ width: "100%", height: 30, display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center" }}>
                        <div
                            onClick={() => setSelectFilterTap("ThisMonth")}
                            style={{ width: "35%", fontSize: 14, height: 30, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        >
                            {t("ThisMonth")}
                            <div style={{ height: 2, width: "100%", borderRadius: 2, backgroundColor: selectFilterTap == "ThisMonth" ? "rgb(95,182,120)" : appColor.bgDark }} />
                        </div>
                        <div
                            onClick={() => setSelectFilterTap("LastMonth")}
                            style={{ width: "35%", fontSize: 14, height: 30, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        >
                            {t("LatestMonth")}
                            <div style={{ height: 2, width: "100%", borderRadius: 2, backgroundColor: selectFilterTap == "LastMonth" ? "rgb(95,182,120)" : appColor.bgDark }} />
                        </div>
                    </div>
                    <div style={{ height: 10 }} />
                    <h6>{t("TotalAgent")}</h6>
                    <div style={{ height: 10 }} />
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ color: "rgb(95,182,120)" }}>{t("TotalTradeVolume")}</div>
                        <h2>{vipData?.statistic[selectFilterTap == "ThisMonth" ? "thisMonth" : "lastMonth"].count}</h2>
                        <div style={{ height: 10 }} />
                        <div style={{ color: "#e34828" }}>{t("Comission")} {t("Total")} </div>
                        <h2>{vipData?.statistic ? currencyFormat(vipData?.statistic[selectFilterTap == "ThisMonth" ? "thisMonth" : "lastMonth"].price) : 0}$</h2>
                    </div>
                </div>
            </div>
            <div style={{ height: 10 }} />
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "start", backgroundColor: appColor.bgDark, borderRadius: 5, padding: 16, fontSize: 14 }}>
                    <h6>{t("LatestStatistic")}</h6>
                    <div style={{ height: 10 }} />
                    <div style={{ color: "rgb(95,182,120)" }}>{t("ThisMonth")} ({vipData?.statistic ? currencyFormat(vipData?.statistic?.thisMonth?.price) : 0}$)</div>
                    <div style={{ color: "#e34828" }}>{t("LatestMonth")} ({vipData?.statistic ? currencyFormat(vipData?.statistic?.lastMonth?.price) : 0}$)</div>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ height: 10 }} />
                        <h6 style={{ color: "rgb(95,182,120)" }}>{t("Total")}: {currencyFormat(mainGraphTotalPrices)}$</h6>
                        {(mainGraphHeaders && mainGraphHeaders.length > 0) && <BarChart className="my-barchart"
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: mainGraphHeaders ?? [],
                                    scaleType: 'band',
                                },
                            ]}
                            series={[
                                {
                                    data: mainGraphPrices ?? [],
                                },
                            ]}
                            // width={screenSize().width}
                            height={400}
                            colors={["rgb(95,182,120)"]}
                            theme={{
                                axis: {
                                    tickColor: 'white',
                                    labelColor: 'white',
                                    axisLineColor: 'white', // This is hypothetical and may not work.
                                },
                            }}
                        />}
                    </div>
                </div>
            </div>
        </div>
    )
}

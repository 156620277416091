import { ApolloClient, HttpLink, InMemoryCache, split, from, concat, ApolloLink } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { options } from "less";
import * as _ from "lodash";
import { USER_LOCAL_DATA } from "../constants";

// setting configuration for websocket connect for subscription
const httpLink = new HttpLink({
  uri: "https://subapi.forexxo.com/graphql",
});
let activeSocket, timedOut;
const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://subapi.forexxo.com/graphql",
    kekeepAlive: 10000, // ping server every 10 seconds
    ping: (received) => {
      if (!received) // sent
        timedOut = setTimeout(() => {
          if (activeSocket.readyState === WebSocket.OPEN)
            activeSocket.close(4408, 'Request Timeout');
        }, 5_000); // wait 5 seconds for the pong and then close the connection
    },
    pong: (received) => {
      if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
    },
    on: {
      open: (socket) => {
        activeSocket = socket;
      },
      close: () => {
        clearTimeout(timedOut);
      },
    },
    generateID: () => {
      return Math.random().toString(36).substr(2, 9);
    },
    keepAlive: 10_000, // ping server every 10 seconds
    disablePong: false, // enable pong
    retryAttempts: 5,
    retryTimeout: 3_000, // retry every 3 seconds
    shouldRetry: (error) => {
      console.error("SHOULDRETRY_SOCKET_ERROR: ", error);
      console.log('shouldRetry :>> ');
      return true;
    },
    retryWait: (count) => {
      console.log('retryWait :>> ');
      return 1000 * count;
    },
    onNonLazyError: (error) => {
      console.error("ONNONLAZYERROR_SOCKET_ERROR: ", error);
    },
    lazyCloseTimeout: 10_000, // wait 10 seconds for the lazy connection to close after the last subscription is unsubscribed
    inactivityTimeout: 10_000, // close the connection after 10 seconds of inactivity
    connectionParams: () => {
      return {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("token")}`,
        // },
      };
    },
    webSocketImpl: WebSocket,
    lazy: true,
    connectionAckWaitTimeout: 10_000, // wait 10 seconds for the connection to be established
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
  const user = localStorage.getItem(USER_LOCAL_DATA);
  let token;
  if (user) {
    token = JSON.parse(user)["accessToken"];
  }
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ?? "",
    },
  }));

  return forward(operation);
});

export const client = new ApolloClient({
  link: concat(authMiddleware, splitLink),
  cache: new InMemoryCache(),
  request: (operation) => {
    const user = localStorage.getItem(USER_LOCAL_DATA);
    if (user) {
      const token = JSON.parse(user)["accessToken"];
      console.log("token: ", token)
      operation.setContext({
        headers: {
          authorization: token ?? "",
        },
      });
      return;
    }
  },
  onError: (err) => {
    console.log("ERROR: ", err);
    let isTokenError1 = _.some(err.graphQLErrors, {
      message: "Error: TokenExpiredError: jwt expired",
    });
    let isTokenError2 = _.some(err.graphQLErrors, {
      message: "Error: JsonWebTokenError: jwt must be provided",
    });
    if (isTokenError1 || isTokenError2) {
      window.location.replace("/");
    }
  },
});

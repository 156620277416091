import React, { useEffect, useState } from 'react'
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Slide,
    Avatar, Typography,
    Divider, TextField, Switch, Stack
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { USER_LOCAL_DATA, appColor } from '../../constants';
import { useMutation } from '@apollo/client';
import { CREATE_AVS_GQL, CREATE_MC_GQL, CUS_REVIEW_P2P_TRANSACTION_GQL, MC_REVIEW_P2P_TRANSACTION_GQL, STOP_P2P_COUNTDOWN } from '../../apollo/crypto';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { toast } from "react-toastify";
import { currencyFormat } from '../../utils';
import { useNavigate } from "react-router-dom";
import { Chat } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const P2PReviewPopup = ({
    isMobile,
    open,
    handleClose,
    p2p,
    myMC,
    getP2PList,
    userID
}) => {
    const routeNavigate = useNavigate();
    const { t, i18n } = useTranslation();
    const toastAlert = (type, msg,) => {
        toast(msg, { type: type == "error" ? "error" : "success" });
    };


    const [merchantStatus, setMerchantStatus] = useState("");
    const handleChangeMrchantStatus = (event) => {
        setMerchantStatus(event.target.value);
    }

    const [customerStatus, setCustomerStatus] = useState("");
    const handleChangeCustomerStatus = (event) => {
        setCustomerStatus(event.target.value);
    }

    const [iamMerchant, setiamMerchant] = useState(false);

    useEffect(() => {
        setMerchantStatus(p2p?.merchantConfirmType)
        setCustomerStatus(p2p?.merchantConfirmType)
        // setCustomerStatus(p2p?.customerConfirmType)
        setiamMerchant(myMC?.user === p2p?.merchant ? true : false)
    }, [p2p]);

    const [cusReviewTransaction] = useMutation(CUS_REVIEW_P2P_TRANSACTION_GQL, {
        onCompleted: (data) => {
            handleClose()
            // console.log('data :>> ', data);
            // console.log('data?.createMerchant.error :>> ', data?.createMerchant.error.error);
            if (data?.customerReviewP2PTransaction.error.error == false) {
                getP2PList();
                toastAlert('success', 'Review Success')
            } else {
                // console.log('data?.createMerchant.error.errorMsg :>> ', data?.createMerchant.error.errorMsg);
                toastAlert('error', `ERROR: ${data?.customerReviewP2PTransaction.error.errorMsg}`)
            }
        },
        onError: (error) => {
            console.log("error :>> ", error);
        }
    })

    const [mcReviewTransaction] = useMutation(MC_REVIEW_P2P_TRANSACTION_GQL, {
        onCompleted: (data) => {
            handleClose()
            // console.log('data :>> ', data);
            // console.log('data?.createMerchant.error :>> ', data?.createMerchant.error.error);
            if (data?.merchantReviewP2PTransaction.error.error == false) {
                getP2PList();
                toastAlert('success', 'Review Success')
            } else {
                // console.log('data?.createMerchant.error.errorMsg :>> ', data?.createMerchant.error.errorMsg);
                toastAlert('error', `ERROR: ${data?.merchantReviewP2PTransaction.error.errorMsg}`)
            }
        },
        onError: (error) => {
            console.log("error :>> ", error);
        }
    })

    const [stopP2PCountDown] = useMutation(STOP_P2P_COUNTDOWN)
    const handleCusReviewTransaction = async () => {
        try {
            let data = {
                "_id": p2p?._id,
                "customer": p2p?.customer,
                "customerConfirmType": customerStatus
            }
            await cusReviewTransaction({
                variables: {
                    data: data
                }
            })
            if (p2p?.customerStatus == "APPROVED") {
                if (userID == p2p?.customer && p2p?.customerConfirmType == "PENDING") {
                    if (p2p?.tradeType == "SELL") {
                        stopP2PCountDown({ variables: { roomId: p2p?._id } })
                    }
                }
            }
            if (p2p?.customerStatus == "CANCELLED") {
                if (userID == p2p?.customer && p2p?.customerConfirmType == "PENDING") {
                    stopP2PCountDown({ variables: { roomId: p2p?._id } })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleMCReviewTransaction = async () => {
        try {
            let data = {
                "_id": p2p?._id,
                "merchant": p2p?.merchant,
                "merchantConfirmType": merchantStatus
            }
            await mcReviewTransaction({
                variables: {
                    data: data
                }
            })
            if (p2p?.customerStatus == "APPROVED") {
                if (userID == p2p?.merchant && p2p?.merchantConfirmType == "PENDING") {
                    if (p2p?.tradeType == "BUY") {
                        stopP2PCountDown({ variables: { roomId: p2p?._id } })
                    }
                }
            }
            if(p2p?.customerStatus == "CANCELLED") {
                if (userID == p2p?.merchant && p2p?.merchantConfirmType == "PENDING") {
                    stopP2PCountDown({ variables: { roomId: p2p?._id } })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }



    return (
        <React.Fragment>
            <Dialog
                maxWidth={'lg'}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    width: '100%',
                }}
            >
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    sx={{
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        paddingY: 2,
                        paddingX: 1,
                    }}
                >
                    <Typography variant='h6'>Order Review</Typography>
                    <Avatar
                        sx={{
                            width: 24,
                            height: 24,
                            cursor: "pointer",
                            padding: 2,
                            backgroundColor: appColor.textGrey,
                            "&:hover": {
                                backgroundColor: appColor.textGrey
                            },
                        }}
                        onClick={() => {
                            routeNavigate(`/chat/${p2p?._id}`)
                        }}
                    >
                        {" "}
                        <Chat
                            sx={{
                                color: appColor.white
                            }} />
                    </Avatar>

                    {/* routeNavigate("/chat/ */}
                </Box>
                {/* <DialogTitle>Order Created </DialogTitle> */}
                {/* <DialogTitle>I am MC : {iamMerchant == true ? "YES" : "NO"} </DialogTitle> */}
                {/* <DialogTitle>{myMC?.user} {myMC.merchantName}</DialogTitle> */}
                {/* <DialogTitle>{p2p?.customer}</DialogTitle> */}
                {/* <DialogTitle>{p2p?.merchant}</DialogTitle> */}
                {/* AVATAR */}
                {/* <Typography variant="h5" color={appColor.textGrey}>
                    Pay the seller within: 
                </Typography> */}
                <Box
                    display={'flex'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    // flexDirection={'row'}
                    sx={{
                        justifyContent: 'space-around',
                        alignItems: 'start',
                    }}
                >
                    {/*START LEFT */}
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            padding: 2,
                            paddingBottom: isMobile ? 0 : 2,

                        }}
                    >

                        {/* START TRADABLE QUANTITY */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Trade Type")}</Typography>
                                <Typography variant="h6" >{p2p?.tradeType}</Typography>
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />

                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Trade Asset")}</Typography>
                                <Typography variant="h6" >{p2p?.assetType}</Typography>
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />

                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Fiat Unit Type")}</Typography>
                                <Typography variant="h6" >{p2p?.fiatUnitType}</Typography>
                            </Box>
                            <Box sx={{
                                height: 10
                            }} />

                        </Box>
                        {/* END TRADABLE QUANTITY */}

                    </Box>
                    {/*END LEFT */}


                    {/*START CENTER */}
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            paddingX: 2,
                            paddingBottom: isMobile ? 0 : 2,

                        }}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'space-between',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Price")}</Typography>
                                <Typography variant="h6" >{currencyFormat(parseInt(p2p?.price))} {p2p?.fiatUnitType}</Typography>
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Crypto amount")}</Typography>
                                <Typography variant="h6" >{currencyFormat(parseInt(p2p?.amount))} {p2p?.assetType}</Typography>
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Fiat amount")}</Typography>
                                <Typography variant="h6" >{currencyFormat(parseInt(p2p?.fiatAmount))} {p2p?.fiatUnitType}</Typography>
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />
                        </Box>

                    </Box>
                    {/*END CENTER */}

                    {/*START RIGHT */}
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            padding: 2,
                            paddingBottom: isMobile ? 0 : 2,

                        }}
                    >
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'space-between',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("merchantConfirmType")}</Typography>
                                <FormControl variant="standard"
                                    sx={{
                                        m: 1, width: "100%",
                                    }}
                                >
                                    <Select
                                        labelId="trade-type-select-standard-label"
                                        id="trade-type-select-standard"
                                        value={merchantStatus}
                                        onChange={handleChangeMrchantStatus}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        disabled={myMC ? false : true}

                                    >
                                        <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                        <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                        <MenuItem value={"CANCELLED"}>CANCELLED</MenuItem>
                                    </Select>
                                </FormControl>
                                {/* <Typography variant="body2"
                                    sx={{
                                        color: appColor.white,
                                        backgroundColor: p2p?.merchantConfirmType == "APPROVED" ? appColor.greenActive : appColor.textGrey,
                                        px: 1,
                                        borderRadius: 2,
                                    }}
                                > {p2p?.merchantConfirmType}</Typography> */}
                                {/* <Typography variant="h6" >{merchantStatus} </Typography> */}
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("customerConfirmType")}</Typography>
                                <FormControl variant="standard"
                                    sx={{
                                        m: 1, width: "100%",
                                    }}
                                >
                                    <Select
                                        labelId="trade-type-select-standard-label"
                                        id="trade-type-select-standard"
                                        value={customerStatus}
                                        onChange={handleChangeCustomerStatus}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        disabled={myMC ? true : false}
                                    >
                                        <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                        <MenuItem value={"APPROVED"}>APPROVED</MenuItem>
                                        <MenuItem value={"CANCELLED"}>CANCELLED</MenuItem>
                                    </Select>
                                </FormControl>
                                {/* <Typography variant="body2"
                                    sx={{
                                        color: appColor.white,
                                        backgroundColor: p2p?.customerConfirmType == "APPROVED" ? appColor.greenActive : appColor.textGrey,
                                        px: 1,
                                        borderRadius: 2,
                                    }}
                                > {p2p?.customerConfirmType}</Typography> */}
                                {/* <Typography variant="h6" >{p2p?.customerConfirmType} </Typography> */}
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />

                        </Box>

                    </Box>
                    {/*END RIGHT */}
                </Box>
                <DialogActions>

                    <Button
                        onClick={() => {
                            if (iamMerchant == true) {
                                handleMCReviewTransaction()
                            } else {
                                handleCusReviewTransaction()
                            }
                        }}
                        sx={{
                            color: appColor.bgDark,
                            backgroundColor: appColor.yellow,
                            fontWeight: 'bold',
                            paddingX: 8,
                            // width: isMobile ? '100%' : 'none',
                            ":hover": {
                                backgroundColor: appColor.yellowActive,
                            }
                        }
                        }>
                        <Box sx={{
                            width: 2,
                            fontWeight: 'bold'
                        }} />
                        Submit
                    </Button>
                </DialogActions>

                {/* </DialogContent> */}

            </Dialog>
        </React.Fragment>
    )
}

export default P2PReviewPopup
import React from 'react'
import { Box, Typography, Tooltip, Grid, ButtonGroup, Button, Avatar, AppBar, Menu, MenuItem, Container, useMediaQuery } from '@mui/material'
import { appColor } from '../../constants'
import { InfoOutlined, InfoRounded, MoreHorizRounded } from '@mui/icons-material'
const MCInfoPercentSumary = ({
    title,
    titleTooltip,
    value,
    valueDes,
    positive,
    negative,
    isButton,
    onClick
}) => {
    return (
        <Box
            sx={{
                border: `0.5px solid ${appColor.bgGrey}`,
                borderRadius: "10px",
                padding: 2,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                justifyContent: "center",

            }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                    justifyContent: "center",
                    gap: 1
                }}
            >
                <Typography sx={{
                    color: appColor.textGrey,
                    fontSize: 14
                }}>
                    {title}
                </Typography>
                {titleTooltip &&
                    <>
                        <Tooltip title={titleTooltip}>
                            <InfoRounded
                                sx={{
                                    width: 14,
                                    color: appColor.textGrey,
                                    ":hover": {
                                        color: appColor.orange,
                                    }
                                }} />
                        </Tooltip>
                    </>
                }


            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,

                }}
            >
                {isButton == true ? <Box
                    sx={{
                        backgroundColor: appColor.orangeActive,
                        borderRadius: 100,
                        color: appColor.white,
                        cursor: 'pointer'
                    }}
                    onClick={onClick}
                >
                    <MoreHorizRounded
                    />
                </Box> : <>
                    <Typography sx={{
                        color: appColor.white,
                        fontWeight: "bold",
                        fontSize: 20
                    }}>
                        {value}
                    </Typography>
                </>}

                <Typography sx={{
                    color: appColor.white,
                    fontSize: 16
                }}>
                    {valueDes}
                </Typography>
            </Box>
            {positive && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1
                    }}
                >
                    <Typography sx={{
                        color: appColor.textGreenActive,
                        fontSize: 16
                    }}>
                        Positive {positive}
                    </Typography>
                    <Typography sx={{
                        color: appColor.textRedActive,
                        fontSize: 16
                    }}>
                        Negative {negative}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

export default MCInfoPercentSumary
import React from 'react'
import { Typography, useMediaQuery, Box, Button, ButtonGroup, Avatar } from '@mui/material'
import { appColor } from '../../constants'
import { ContentCopy } from '@mui/icons-material'
const P2PRequestItemContentDisplay = ({ title, value, isCopy }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            <Typography
                variant='body2'
                sx={{
                    color: appColor.textGrey,
                }}>
                {title}
            </Typography>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1
            }}>
                <Typography
                    variant='body2'
                    sx={{
                        color: appColor.textGrey,
                    }}>
                    {value}
                </Typography>
                {isCopy && <ContentCopy sx={{
                    cursor: "pointer",
                    color: appColor.textGrey,
                    fontSize: "0.8rem"

                }} />}
            </Box>

        </Box>
    )
}

export default P2PRequestItemContentDisplay
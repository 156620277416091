import React, { useState } from 'react'
import { faCheck, faClose, faCopy, faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { S3_BUCKET_END_POINT } from '../../api'
import { Button, Image, Spinner } from 'react-bootstrap'
import { Box, Grid } from '@mui/material'
import { appColor, screenSize } from '../../constants'
import { useTranslation } from 'react-i18next'

export default function Deposit({
    onCloseDepositPopup,
    isShowDeposithPopup,
    money,
    setMoney,
    totalMoney,
    setTotalMoney,
    rate,
    currencyFormatKip,
    slipImage,
    admin,
    orderID,
    setOrderID,
    onAddCredit,
    isSaveLoading,
    requestPresignedUrlAndUploadImageToS3,
    credit,
    channelType,
    usedBonus,
    setUsedBonus,
    totalAmount,
    setTotalAmount,
    userData
}) {
    const { t } = useTranslation();
    const [invalidMoney, setInvalidMoney] = useState(false)
    const [invalidImage, setInvalidImage] = useState(false)
    const [isEnoughCredit, setIsEnoughCredit] = useState(false)

    const checkInvalidData = () => {
        if (!money) {
            setInvalidMoney(true)
            return
        } else {
            setInvalidMoney(false)
        }
        if (usedBonus && userData?.bonusCredit < totalAmount) {
            setIsEnoughCredit(true)
            return
        } else {
            setIsEnoughCredit(false)
        }
        if (!slipImage) {
            setInvalidImage(true)
            return
        } else {
            setInvalidImage(false)
        }
        if (parseFloat(money) < 10) {
            setInvalidMoney(true)
            return
        } else {
            setInvalidMoney(false)
        }
        setInvalidMoney(false)
        setInvalidImage(false)

        onAddCredit()
    }

    const [isCopy, setIsCopy] = useState(false)
    const bnbSmartChainID = "0xac7babeb5f6d82a644097e219bb75e6ad6948ad3"
    const onCopy = () => {
        navigator.clipboard.writeText(bnbSmartChainID)
        setIsCopy(true)
        setTimeout(() => {
            setIsCopy(false)
        }, 3000);
    }

    return (
        <div style={{ position: "absolute", backgroundColor: "rgba(0,0,0,0.7)", display: isShowDeposithPopup ? "flex" : "none", flexDirection: "column", width: "100vw", minHeight: "100vh", top: channelType == "BNBSmartChain" ? 20 : 50, justifyContent: "center", alignItems: "center", paddingTop: 10, paddingBottom: 10 }}>
            {screenSize().width > 700 ? <div style={{ width: 600, minHeight: 550, backgroundColor: appColor.darkColor, borderRadius: 8, padding: 20 }}>
                <Grid container>
                    <div style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "#35a947", fontWeight: "bold", color: "white", fontSize: 18 }}>+{t("deposit")} {t("from")} {channelType == "BNBSmartChain" ? "BNB Smart Chain (BEP20)" : channelType}</div>
                </Grid>
                <div>
                    {channelType == "BinancePay" ? <div>
                        <div style={{ height: 10 }} />
                        <div
                            style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "#FF0000", fontWeight: "bold", color: "white", fontSize: 14, paddingTop: 6, paddingBottom: 6 }}>
                            {t("deposit-usdt-to-binacepay-note")}
                        </div>
                    </div> : <div></div>}
                </div>

                <div style={{ height: 10 }} />
                <div style={{ display: "flex", color: "#fff", flexDirection: "column", width: "100%", justifyContent: "start", alignItems: "flex-start" }}>
                    <p>{t("minimum")} $10</p>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                        <input
                            type='number'
                            value={money}
                            onChange={(event) => {
                                setMoney(event?.target?.value)
                                setTotalMoney((event?.target?.value ?? 0) * (rate?.usd ?? 1))
                                const _totalAmount = parseFloat(event?.target?.value ?? 0) + (parseFloat(event?.target?.value ?? 0) * 0.5)
                                setTotalAmount(_totalAmount)
                                if (usedBonus && userData?.bonusCredit < _totalAmount) {
                                    setIsEnoughCredit(true)
                                } else {
                                    setIsEnoughCredit(false)
                                }
                                if (parseFloat(event?.target?.value) < 30) {
                                    setInvalidMoney(true)
                                } else {
                                    setInvalidMoney(false)
                                }
                            }}
                            style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid " + (invalidMoney ? "red" : "gray"), borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                        />
                    </div>
                    <div style={{ height: 5 }} />
                    {usedBonus && <div style={{ fontSize: 16, textAlign: "start", color: "#fff" }}>
                        Total amount: ${totalAmount || 0}
                    </div>}
                    {(usedBonus && isEnoughCredit) && <div style={{ fontSize: 16, textAlign: "start", color: "#fff", color: "red" }}>
                        Not enough credit
                    </div>}
                </div>
                <Box height={14} />
                <div style={{ display: "flex", flexDirection: "row", color: "#fff" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "50%", alignItems: "center" }}>
                        <p>Slip {t("money-for-deposit")}</p>
                        {slipImage ?
                            <Image src={`${S3_BUCKET_END_POINT}/images/${slipImage}`} style={{ height: "auto", width: 170, marginBottom: 8 }} />
                            :
                            <div style={{ display: "flex", height: 200, width: 170, borderRadius: 5, backgroundColor: "#e6e6e6", border: "1px solid " + (invalidImage ? "red" : "#e6e6e6"), justifyContent: "center", alignItems: "center" }}>
                                <FontAwesomeIcon icon={faImage} style={{ color: "#bebebe", fontSize: 40 }} />
                            </div>
                        }
                        <Box height={8} />
                        <label htmlFor="upload-photo"
                            style={{
                                backgroundColor: appColor.warning,
                                paddingLeft: 40,
                                paddingRight: 40,
                                paddingTop: 4,
                                paddingBottom: 4,
                                cursor: "pointer",
                                borderRadius: 30,
                                fontSize: 14
                            }}>
                            <input
                                style={{ display: 'none' }}
                                id="upload-photo"
                                name="upload-photo"
                                type="file"
                                onChange={(event) => requestPresignedUrlAndUploadImageToS3(event)}
                            />
                            Choose file
                            <br />
                        </label>
                    </div>
                    {admin && <div style={{ display: "flex", flexDirection: "column", width: "50%", alignItems: "center", textAlign: "left", fontSize: 14 }}>
                        <div>
                            <div>Name: {admin?.bankAccountName}</div>
                            <div onClick={() => onCopy()} style={{ color: appColor.yellow, fontWeight: "bold", cursor: "pointer" }}>{channelType == "BinancePay" ? "ID" : t("account-number")}: {admin?.bankAccountNumber} {" "} <FontAwesomeIcon icon={isCopy ? faCheck : faCopy} style={{ color: appColor.yellow, paddingLeft: 6 }} /></div>
                        </div>
                        <Image src={`${S3_BUCKET_END_POINT}/images/${admin?.bankQRCode}`} style={{ height: 200, width: 200 }} />
                    </div>}
                    {channelType == "BNBSmartChain" && <div style={{ display: "flex", flexDirection: "column", width: "50%", alignItems: "center", textAlign: "left", fontSize: 14 }}>
                        <div>{t("account-number")}</div>
                        <div onClick={() => onCopy()} style={{ color: appColor.yellow, fontWeight: "bold", fontSize: 12, cursor: "pointer" }}>{bnbSmartChainID} {" "} <FontAwesomeIcon icon={isCopy ? faCheck : faCopy} style={{ color: appColor.yellow, paddingLeft: 6 }} /></div>
                        <Image src={`/bnb-smart-chain.jpeg`} style={{ height: 200, width: 200 }} />
                    </div>}
                </div>
                <Box height={20} />
                <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                    <br />
                    <Button
                        onClick={() => checkInvalidData()}
                        style={{
                            borderRadius: 28,
                            color: "white",
                            height: 45,
                            borderWidth: 0,
                            backgroundColor: appColor.green,
                            width: "100%"
                        }}
                    >
                        <div>{isSaveLoading ? <Spinner animation="border" role="status"></Spinner> : t("save")}</div>
                    </Button>
                </Grid>
                <Box height={20} />
            </div> :
                <div style={{ paddingTop: channelType == "BNBSmartChain" ? 0 : 50 }}>
                    <div style={{ width: "90vw", backgroundColor: appColor.darkColor, borderRadius: 8, padding: 10 }}>
                        <Grid container>
                            <div style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "#35a947", fontWeight: "bold", color: "white", fontSize: 16 }}>+{t("deposit")} {t("from")} {channelType}</div>
                        </Grid>
                        <div>
                            {channelType == "BinancePay" ? <div>
                                <div style={{ height: 10 }} />
                                <div
                                    style={{ paddingLeft: 20, paddingRight: 20, borderRadius: 10, backgroundColor: "#FF0000", fontWeight: "bold", color: "white", fontSize: 12, paddingTop: 6, paddingBottom: 6 }}>
                                    {t("deposit-usdt-to-binacepay-note")}
                                </div>
                            </div> : <div></div>}
                        </div>
                        <div style={{ height: 10 }} />
                        <div style={{ display: "flex", color: "#fff", flexDirection: "column", width: "100%", justifyContent: "start", alignItems: "flex-start" }}>
                            <p style={{ fontSize: 14 }}>{t("minimum")} {channelType == "BNBSmartChain" ? "$10" : "$30"}</p>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
                                <input
                                    type='number'
                                    value={money}
                                    onChange={(event) => {
                                        setMoney(event?.target?.value)
                                        setTotalMoney((event?.target?.value ?? 0) * (rate?.usd ?? 1))
                                        const _totalAmount = parseFloat(event?.target?.value ?? 0) + (parseFloat(event?.target?.value ?? 0) * 0.5)
                                        setTotalAmount(_totalAmount)
                                        if (usedBonus && userData?.bonusCredit < _totalAmount) {
                                            setIsEnoughCredit(true)
                                        } else {
                                            setIsEnoughCredit(false)
                                        }
                                        if (parseFloat(event?.target?.value) < 30) {
                                            setInvalidMoney(true)
                                        } else {
                                            setInvalidMoney(false)
                                        }
                                    }}
                                    style={{ width: "100%", height: 40, backgroundColor: appColor.darkColor, border: "1px solid " + (invalidMoney ? "red" : "gray"), borderRadius: 4, color: "#fff", paddingLeft: 10 }}
                                />
                                <div style={{ height: 5 }} />
                                {usedBonus && <div style={{ fontSize: 14, textAlign: "start", color: "#fff" }}>
                                    Total amount: ${totalAmount || 0}
                                </div>}
                                {(usedBonus && isEnoughCredit) && <div style={{ fontSize: 16, textAlign: "start", color: "#fff", color: "red" }}>
                                    Not enough credit
                                </div>}
                            </div>
                        </div>
                        <Box height={8} />
                        {channelType == "BNBSmartChain" && <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", textAlign: "left" }}>
                            <div style={{ fontSize: 14, width: "90vw", textAlign: "center" }}>
                                <div>{t("account-number")}</div>
                                <div onClick={() => onCopy()} style={{ color: appColor.yellow, fontWeight: "bold", cursor: "pointer", fontSize: 14 }}>{bnbSmartChainID} {" "} <FontAwesomeIcon icon={isCopy ? faCheck : faCopy} style={{ color: appColor.yellow, paddingLeft: 6 }} /></div>
                            </div>
                            <Image src={`/bnb-smart-chain.jpeg`} style={{ height: "35vw", width: "35vw" }} />
                        </div>}
                        <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "center", color: "#fff" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                                <Box height={14} />
                                <p style={{ fontSize: 14 }}>Slip {t("money-for-deposit")}</p>
                                {slipImage ?
                                    <Image src={`${S3_BUCKET_END_POINT}/images/${slipImage}`} style={{ height: "auto", width: 120, height: 190, marginBottom: 8 }} />
                                    :
                                    <div style={{ display: "flex", height: 190, width: 120, borderRadius: 5, backgroundColor: "#e6e6e6", border: "1px solid " + (invalidImage ? "red" : "#e6e6e6"), justifyContent: "center", alignItems: "center" }}>
                                        <FontAwesomeIcon icon={faImage} style={{ color: "#bebebe", fontSize: 40 }} />
                                    </div>
                                }
                                <Box height={8} />
                                <label htmlFor="upload-photo"
                                    style={{
                                        backgroundColor: appColor.warning,
                                        paddingLeft: 40,
                                        paddingRight: 40,
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        cursor: "pointer",
                                        borderRadius: 30,
                                        fontSize: 12
                                    }}>
                                    <input
                                        style={{ display: 'none' }}
                                        id="upload-photo"
                                        name="upload-photo"
                                        type="file"
                                        onChange={(event) => requestPresignedUrlAndUploadImageToS3(event)}
                                    />
                                    Choose file
                                    <br />
                                </label>
                            </div>
                            {admin && <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", textAlign: "left" }}>
                                <div style={{ fontSize: 14 }}>
                                    <div>Name: {admin?.bankAccountName}</div>
                                    <div onClick={() => onCopy()} style={{ color: appColor.yellow, fontWeight: "bold", cursor: "pointer" }}>{channelType == "BinancePay" ? "ID" : t("account-number")}: {admin?.bankAccountNumber} {" "} <FontAwesomeIcon icon={isCopy ? faCheck : faCopy} style={{ color: appColor.yellow, paddingLeft: 6 }} /></div>
                                </div>
                                <Image src={`${S3_BUCKET_END_POINT}/images/${admin?.bankQRCode}`} style={{ height: "45vw", width: "45vw" }} />
                            </div>}
                        </div>
                        <Box height={20} />
                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                            <br />
                            <Button
                                onClick={() => checkInvalidData()}
                                style={{
                                    borderRadius: 28,
                                    color: "white",
                                    height: 40,
                                    borderWidth: 0,
                                    backgroundColor: appColor.green,
                                    width: "100%"
                                }}
                            >
                                <div>{isSaveLoading ? <Spinner animation="border" role="status"></Spinner> : t("save")}</div>
                            </Button>
                        </Grid>
                        <Box height={20} />
                    </div>
                </div>
            }
            <div style={{ height: 10 }} />
            <div onClick={() => onCloseDepositPopup(false)} style={{ width: 50, height: 50, borderRadius: 50, backgroundColor: "rgba(255,255,255,0.2)", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}><FontAwesomeIcon icon={faClose} style={{ color: "#fff", fontSize: 22 }} /></div>
        </div>
    )
}

import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import {
    INDICATORMAIN,
    INDICATORSUB,
    INDICATOR_MAIN,
    INDICATOR_SUB,
    USER_LOCAL_DATA,
    appColor,
    screenSize
} from "../../constants";
import { BINANCE_URL, END_POINT_SEVER } from "../../api";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import {
    Box,
    Paper,
    Divider,
    Avatar,
    Button,
    Typography,
    TextField,
    Modal,
    FormControl,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Container,
    Drawer,
    IconButton,
    Badge,
} from "@mui/material";
import {
    LeaderboardOutlined,
    WalletOutlined,
    PersonOutline,
    History,
    AttachMoney,
    LogoutOutlined,
    SettingsOutlined,
    NotificationsOutlined,
    Add,
    Remove,
    TrendingUp,
    TrendingDown,
    ArrowDropDown,
    ExitToApp,
    Percent,
    Language,
    NotificationsOff,
    VolumeUpTwoTone,
    VolumeOffOutlined,
    Cached,
    Person,
    ListAlt,
    Menu,
    SwapHoriz,
    DragIndicator,
    DragIndicatorOutlined,
    CandlestickChart,
    ShowChart,
    SwapHorizOutlined,
    ArrowBack,
    MultipleStop,
} from "@mui/icons-material";
import { FaBtc, FaEthereum, FaEuroSign } from "react-icons/fa";
import { SiBinance } from "react-icons/si";
import { AiOutlineGold } from "react-icons/ai";
import { init, dispose, registerOverlay } from "klinecharts";
import Layout from "../../components/Layout";
import moment, { max } from "moment";
import { toast } from "react-toastify";
import ReactAudioPlayer from "react-audio-player";
import { MODAL_STYLE, appProfitCalculator } from "../../utils";
import { useLazyQuery, useSubscription, useMutation } from "@apollo/client";
import {
    CRYPTO_HISTORY,
    CRYPTO_HISTORY_1M,
    FETCH_NEW_TRADING,
    RECEIVE_GRAPH1S,
    RECEIVE_GRAPH_COUNTDOWN,
    RECEIVE_NOTIFICATION,
    RECEIVE_TRADE,
} from "../../apollo/crypto";
import { useTranslation } from 'react-i18next'
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AppContext from "../../appProvider/context";
import laoFlag from '../../images/flag/lao-flag.png';
import englishFlag from '../../images/flag/english-flag.png';
import thailandFlag from '../../images/flag/thailand-flag.png';
import khrFlag from '../../images/flag/khr-flag.png';
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTheme } from "@emotion/react";
import { faArrowAltCircleUp, faExchange, faExchangeAlt, faRankingStar, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDataFromLocalStorage, setDataToLocalStorage } from "../../utils/localStorage";
import { CANDLE_TYPE } from "../../constants/localKey";
import CreditTransfer from "../transection/creditTransfer";
import AppPopup from "../../common/popup";
import { RiP2PFill } from "react-icons/ri";
import ProfileModal from "../../components/profile/profileModal";
import LoadingPage from "../../common/loadingPage";
import TransferToBonus from "../transection/transferToBonus";
import bonusBackgroundImage from '../../images/bonus1.png';

let tvScriptLoadingPromise;

export default function Signal10082023() {
    const routeNavigate = useNavigate();
    const { t, i18n } = useTranslation();
    const routeParams = useParams();
    const [binary, setBinary] = useState('');

    const [timeOfTank, setTimeOfTank] = useState(30000);
    const [lastData, setLastData] = useState(0);
    const [dataPrice, setDataPrice] = useState([]);
    const [dataPriceRealTime, setDataPriceRealTime] = useState([]);

    const [startPrice2Tank, setStartPrice2Tank] = useState(0);
    const [startPrice3Tank, setStartPrice3Tank] = useState(0);

    const [countTrue2, setCountTrue2] = useState(0);
    const [countTrue3, setCountTrue3] = useState(0);
    const [countFail2, setCountFail2] = useState(0);
    const [countFail3, setCountFail3] = useState(0);
    const [selectTankType, setSelectTankType] = useState("TWO_TANK");

    const [noti2Tank, setNoti2Tank] = useState("");
    const [noti3Tank, setNoti3Tank] = useState("");

    const [tradeType, setTradeType] = useState("MANNUAL");
    const [userData, setUserData] = useState();
    const [userID, setUserID] = useState();
    const [demoCreditCardData, setDemoCreditCardData] = useState();
    const [realCreditCardData, setRealCreditCardData] = useState();
    const [usdtWalletData, setUsdtWalletData] = useState();
    const [bonusWalletData, setBonusWalletData] = useState();
    const [packageWalletData, setPackageWalletData] = useState();
    const [activeCard, setActiveCard] = useState("DEMO");
    const [draffActiveCard, setDraffActiveCard] = useState("DEMO");

    const [tradeMoney, setTradeMoney] = useState(10);
    const [countTrade, setCountTrade] = useState(0);

    const [profit, setProfit] = useState("$0");
    const [isProfit, setIsProfit] = useState(true);
    const soundWinPlayer = useRef();
    const soundLossPlayer = useRef();
    const orderSoundPlayer = useRef();
    const balanceIsNotEnoughSoundPlayer = useRef();

    const kLineChart = useRef();

    const [countfetch, setCountFetch] = useState(timeOfTank / 1000);
    const [profitPercent, setProfitPercent] = useState();

    const { setStateLanguage } = useContext(AppContext);

    const [isShowPopupTransfer, setIsShowPopupTransfer] = useState(false);
    const [creditList, setCreditList] = useState([]);

    const _maximumTradeAmount = 5000;

    const handlePopupTransfer = () => {
        setIsShowPopupTransfer(!isShowPopupTransfer);
    }

    useEffect(() => {
        if (tradeMoney && profitPercent) profitCalculate();
    }, [tradeMoney, profitPercent]);

    const profitCalculate = () => {
        setProfit(
            "$ " + currencyFormat(appProfitCalculator(tradeMoney, profitPercent))
        );
    };

    // END PROFIT CALCULATE
    //START CHECK BALANCE ENOUGH OR NOT
    const [balanceIsEnough, setbalanceIsEnough] = useState(false);

    useEffect(() => {
        switch (draffActiveCard) {
            case "REAL":
                if (realCreditCardData?.credit > 0) {
                    setbalanceIsEnough(true);
                } else {
                    setbalanceIsEnough(false);
                }
                break;
            case "DEMO":
                if (demoCreditCardData?.credit > 0) {
                    setbalanceIsEnough(true);
                } else {
                    setbalanceIsEnough(false);
                }
                break;
            case "USDT":
                if (usdtWalletData?.credit > 0) {
                    setbalanceIsEnough(true);
                } else {
                    setbalanceIsEnough(false);
                }
                break;
            case "BONUS":
                if (bonusWalletData?.credit > 0) {
                    setbalanceIsEnough(true);
                } else {
                    setbalanceIsEnough(false);
                }
                break;
            case "PACKAGE":
                if (packageWalletData?.credit > 0) {
                    setbalanceIsEnough(true);
                } else {
                    setbalanceIsEnough(false);
                }
                break;

            default:
                break;
        }
    }, [draffActiveCard, realCreditCardData, usdtWalletData, bonusWalletData, packageWalletData, demoCreditCardData]);

    //START CHECK BALANCE ENOUGH OR NOT
    // COUNTDOWN
    const duration = 30;
    // const container = useRef();
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        background: appColor.bgDark,
        color: appColor.white,
    };

    const styleTrade = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "#030711",
        opacity: 0.8,
        // border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    useEffect(() => {
        if (routeParams?.tradeType) setBinary(routeParams.tradeType);
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (
                        parseData["user"] &&
                        parseData["user"] &&
                        parseData["user"]["_id"]
                    ) {
                        getUserData(parseData["user"]["_id"]);
                        setUserID(parseData["user"]["_id"]);
                        getCreditData(parseData["user"]["_id"]);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);

    useEffect(() => {
        if (userData) {
            getTradeHistoryData(userData["_id"]);
        }
    }, [activeCard]);

    useEffect(() => {
        if (userData) {
            getTradeHistoryData(userData["_id"]);
        }
    }, [realCreditCardData]);

    useEffect(() => {
        if (userData) {
            getTradeHistoryData(userData["_id"]);
        }
    }, [demoCreditCardData]);

    // START INIT NEW CHART
    const _limit = 500;
    const [newStreamingData, setNewStreamingData] = useState();

    useEffect(() => {
        if (dataPriceRealTime.length > 0 && newStreamingData) {
            const currentData = {
                open: parseFloat(newStreamingData.open),
                low: parseFloat(newStreamingData.low),
                high: parseFloat(newStreamingData.high),
                close: parseFloat(newStreamingData.close),
                volume: parseFloat(newStreamingData.volume),
                timestamp: newStreamingData.timestamp,
                turnover: parseFloat(newStreamingData.turnover),
            };
            kLineChart.current?.updateData(currentData);
        }
    }, [newStreamingData]);

    const [isMobile, setIsMobile] = useState(false);
    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
    // check device type desktop or mobile

    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize, isMobile, window.screen.width, window.screen.height]);

    var chartCfg = {
        grid: {
            show: true,
            horizontal: {
                show: true,
                size: 1,
                color: appColor.background,
                style: 'dashed',
                dashedValue: [2, 2]
            },
            vertical: {
                show: true,
                size: 1,
                color: appColor.background,
                style: 'dashed',
                dashedValue: [2, 2]
            }
        },
        candle: {
            // 'candle_solid'|'candle_stroke'|'candle_up_stroke'|'candle_down_stroke'|'ohlc'|'area'
            type: 'candle_solid',
            bar: {
                upColor: appColor.green,
                downColor: appColor.red,
                noChangeColor: '#888888',
                upBorderColor: appColor.green,
                downBorderColor: appColor.red,
                noChangeBorderColor: '#888888',
                upWickColor: appColor.green,
                downWickColor: appColor.red,
                noChangeWickColor: '#888888'
            },
            area: {
                lineSize: 2,
                lineColor: '#2196F3',
                value: 'close',
                backgroundColor: [{
                    offset: 0,
                    color: 'rgba(33, 150, 243, 0.01)'
                }, {
                    offset: 1,
                    color: 'rgba(33, 150, 243, 0.2)'
                }]
            },
            priceMark: {
                show: true,
                high: {
                    show: true,
                    color: '#D9D9D9',
                    textMargin: 5,
                    textSize: 10,
                    textFamily: 'Helvetica Neue',
                    textWeight: 'normal'
                },
                low: {
                    show: true,
                    color: '#D9D9D9',
                    textMargin: 5,
                    textSize: 10,
                    textFamily: 'Helvetica Neue',
                    textWeight: 'normal',
                },
                last: {
                    show: true,
                    upColor: appColor.green,
                    downColor: appColor.red,
                    noChangeColor: '#888888',
                    line: {
                        show: true,
                        // 'solid' | 'dashed'
                        style: 'dashed',
                        dashedValue: [4, 4],
                        size: 1
                    },
                    text: {
                        show: true,
                        // 'fill' | 'stroke' | 'stroke_fill'
                        style: 'fill',
                        size: 12,
                        paddingLeft: 4,
                        paddingTop: 4,
                        paddingRight: 4,
                        paddingBottom: 4,
                        // 'solid' | 'dashed'
                        borderStyle: 'solid',
                        borderSize: 1,
                        borderDashedValue: [2, 2],
                        color: '#FFFFFF',
                        family: 'Helvetica Neue',
                        weight: 'normal',
                        borderRadius: 2
                    }
                }
            },
            tooltip: {
                // 'always' | 'follow_cross' | 'none'
                showRule: isMobile ? 'none' : 'always',
                // 'standard' | 'rect'
                showType: 'standard',
                // Custom display, it can be a callback method or an array, when it is a method, it needs to return an array
                // The child item type of the array is { title, value }
                // title and value can be strings or objects, and the object type is { text, color }
                // title or title.text can be an internationalized key,
                // value or value.text supports string templates
                // For example: want to display time, opening and closing, configure [{ title: 'time', value: '{time}' }, { title: 'open', value: '{open}' }, { title: ' close', value: '{close}' }]
                custom: null,
                defaultValue: 'n/a',
                rect: {
                    // 'fixed' | 'pointer'
                    position: 'pointer',
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    paddingBottom: 6,
                    offsetLeft: 10,
                    offsetTop: 8,
                    offsetRight: 10,
                    offsetBottom: 8,
                    borderRadius: 4,
                    borderSize: 1,
                    borderColor: '#f2f3f5',
                    color: '#FEFEFE'
                },
                text: {
                    size: 12,
                    family: 'Helvetica Neue',
                    weight: 'normal',
                    color: '#D9D9D9',
                    marginLeft: 10,
                    marginTop: 8,
                    marginRight: 6,
                    marginBottom: 0
                },
                // sample:
                // [{
                //   id: 'icon_id',
                //   position: 'left', // types include 'left', 'middle', 'right'
                //   marginLeft: 8,
                //   marginTop: 6,
                //   marginRight: 0,
                //   marginBottom: 0,
                //   paddingLeft: 1,
                //   paddingTop: 1,
                //   paddingRight: 1,
                //   paddingBottom: 1,
                //   icon: '\ue900',
                //   fontFamily: 'iconfont',
                //   size: 12,
                //   color: '#76808F',
                //   backgroundColor: 'rgba(33, 150, 243, 0.2)',
                //   activeBackgroundColor: 'rgba(33, 150, 243, 0.4)'
                // }]
                icons: [<FontAwesomeIcon icon={faArrowAltCircleUp} style={{ fontSize: 20 }} />]
            }
        },
        indicator: {
            ohlc: {
                upColor: 'rgba(45, 192, 142, .7)',
                downColor: 'rgba(249, 40, 85, .7)',
                noChangeColor: '#888888'
            },
            bars: [{
                // 'fill' | 'stroke' | 'stroke_fill'
                style: 'fill',
                // 'solid' | 'dashed'
                borderStyle: 'solid',
                borderSize: 1,
                borderDashedValue: [2, 2],
                upColor: 'rgba(45, 192, 142, .7)',
                downColor: 'rgba(249, 40, 85, .7)',
                noChangeColor: '#888888'
            }],
            lines: [
                {
                    // 'solid' | 'dashed'
                    style: 'solid',
                    smooth: false,
                    size: 1,
                    dashedValue: [2, 2],
                    color: '#FF9600'
                }, {
                    style: 'solid',
                    smooth: false,
                    size: 1,
                    dashedValue: [2, 2],
                    color: '#935EBD'
                }, {
                    style: 'solid',
                    smooth: false,
                    size: 1,
                    dashedValue: [2, 2],
                    color: '#2196F3'
                }, {
                    style: 'solid',
                    smooth: false,
                    size: 1,
                    dashedValue: [2, 2],
                    color: '#E11D74'
                }, {
                    style: 'solid',
                    smooth: false,
                    size: 1,
                    dashedValue: [2, 2],
                    color: '#01C5C4'
                }
            ],
            circles: [{
                // 'fill' | 'stroke' | 'stroke_fill'
                style: 'fill',
                // 'solid' | 'dashed'
                borderStyle: 'solid',
                borderSize: 1,
                borderDashedValue: [2, 2],
                upColor: 'rgba(45, 192, 142, .7)',
                downColor: 'rgba(249, 40, 85, .7)',
                noChangeColor: '#888888'
            }],
            lastValueMark: {
                show: false,
                text: {
                    show: false,
                    // 'fill' | 'stroke' | 'stroke_fill'
                    style: 'fill',
                    color: '#FFFFFF',
                    size: 12,
                    family: 'Helvetica Neue',
                    weight: 'normal',
                    // 'solid' | 'dashed'
                    borderStyle: 'solid',
                    borderSize: 1,
                    borderDashedValue: [2, 2],
                    paddingLeft: 4,
                    paddingTop: 4,
                    paddingRight: 4,
                    paddingBottom: 4,
                    borderRadius: 2
                }
            },
            tooltip: {
                // 'always' | 'follow_cross' | 'none'
                showRule: isMobile ? 'none' : 'always',
                // 'standard' | 'rect'
                showType: 'standard',
                showName: true,
                showParams: true,
                defaultValue: 'n/a',
                text: {
                    size: 12,
                    family: 'Helvetica Neue',
                    weight: 'normal',
                    color: '#D9D9D9',
                    marginTop: 8,
                    marginRight: 6,
                    marginBottom: 0,
                    marginLeft: 10
                },
                // sample:
                // [{
                //   id: 'icon_id',
                //   position: 'left', // types include 'left', 'middle', 'right'
                //   marginLeft: 8,
                //   marginTop: 6,
                //   marginRight: 0,
                //   marginBottom: 0,
                //   paddingLeft: 1,
                //   paddingTop: 1,
                //   paddingRight: 1,
                //   paddingBottom: 1,
                //   icon: '\ue900',
                //   fontFamily: 'iconfont',
                //   size: 12,
                //   color: '#76808F',
                //   backgroundColor: 'rgba(33, 150, 243, 0.2)',
                //   activeBackgroundColor: 'rgba(33, 150, 243, 0.4)'
                // }]
                icons: [<FontAwesomeIcon icon={faArrowAltCircleUp} style={{ fontSize: 20 }} />]
            }
        },
        xAxis: {
            show: true,
            size: 'auto',
            axisLine: {
                show: false,
                color: '#888888',
                size: 1
            },
            tickText: {
                show: true,
                color: '#D9D9D9',
                family: 'Helvetica Neue',
                weight: 'normal',
                size: 12,
                marginStrat: 4,
                marginBottom: 4
            },
            tickLine: {
                show: true,
                size: 1,
                length: 3,
                color: '#888888'
            }
        },
        yAxis: {
            show: true,
            size: 'auto',
            // 'left' | 'right'
            position: 'right',
            // 'normal' | 'percentage' | 'log'
            type: 'normal',
            inside: false,
            reverse: false,
            axisLine: {
                show: false,
                color: '#888888',
                size: 1
            },
            tickText: {
                show: true,
                color: '#D9D9D9',
                family: 'Helvetica Neue',
                weight: 'normal',
                size: 12,
                marginStrat: 4,
                marginBottom: 4
            },
            tickLine: {
                show: true,
                size: 1,
                length: 3,
                color: '#888888'
            }
        },
        separator: {
            size: 1,
            color: '#888888',
            fill: true,
            activeBackgroundColor: 'rgba(230, 230, 230, .15)'
        },
        crosshair: {
            show: true,
            horizontal: {
                show: true,
                line: {
                    show: true,
                    // 'solid'|'dashed'
                    style: 'dashed',
                    dashedValue: [4, 2],
                    size: 1,
                    color: '#888888'
                },
                text: {
                    show: true,
                    // 'fill' | 'stroke' | 'stroke_fill'
                    style: 'fill',
                    color: '#FFFFFF',
                    size: 12,
                    family: 'Helvetica Neue',
                    weight: 'normal',
                    // 'solid' | 'dashed'
                    borderStyle: 'solid',
                    borderDashedValue: [2, 2],
                    borderSize: 1,
                    borderColor: '#686D76',
                    borderRadius: 2,
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 4,
                    paddingBottom: 4,
                    backgroundColor: '#686D76'
                }
            },
            vertical: {
                show: true,
                line: {
                    show: true,
                    // 'solid'|'dashed'
                    style: 'dashed',
                    dashedValue: [4, 2],
                    size: 1,
                    color: '#888888'
                },
                text: {
                    show: true,
                    // 'fill' | 'stroke' | 'stroke_fill'
                    style: 'fill',
                    color: '#FFFFFF',
                    size: 12,
                    family: 'Helvetica Neue',
                    weight: 'normal',
                    // 'solid' | 'dashed'
                    borderStyle: 'solid',
                    borderDashedValue: [2, 2],
                    borderSize: 1,
                    borderColor: '#686D76',
                    borderRadius: 2,
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 4,
                    paddingBottom: 4,
                    backgroundColor: '#686D76'
                }
            }
        },
        overlay: {
            point: {
                color: '#1677FF',
                borderColor: 'rgba(22, 119, 255, 0.35)',
                borderSize: 1,
                radius: 5,
                activeColor: '#1677FF',
                activeBorderColor: 'rgba(22, 119, 255, 0.35)',
                activeBorderSize: 3,
                activeRadius: 5
            },
            line: {
                // 'solid' | 'dashed'
                style: 'solid',
                smooth: false,
                color: '#FFFFFF',
                size: 1,
                dashedValue: [2, 2]
            },
            rect: {
                // 'fill' | 'stroke' | 'stroke_fill'
                style: 'fill',
                color: 'rgba(22, 119, 255, 0.25)',
                borderColor: '#1677FF',
                borderSize: 1,
                borderRadius: 0,
                // 'solid' | 'dashed'
                borderStyle: 'solid',
                borderDashedValue: [2, 2]
            },
            polygon: {
                // 'fill' | 'stroke' | 'stroke_fill'
                style: 'fill',
                color: '#1677FF',
                borderColor: '#1677FF',
                borderSize: 1,
                // 'solid' | 'dashed'
                borderStyle: 'solid',
                borderDashedValue: [2, 2]
            },
            circle: {
                // 'fill' | 'stroke' | 'stroke_fill'
                style: 'fill',
                color: 'rgba(22, 119, 255, 0.25)',
                borderColor: '#1677FF',
                borderSize: 1,
                // 'solid' | 'dashed'
                borderStyle: 'solid',
                borderDashedValue: [2, 2]
            },
            arc: {
                // 'solid' | 'dashed'
                style: 'solid',
                color: '#1677FF',
                size: 1,
                dashedValue: [2, 2]
            },
            text: {
                color: '#FFFFFF',
                size: 12,
                family: 'Helvetica Neue',
                weight: 'normal'
            },
            rectText: {
                // 'fill' | 'stroke' | 'stroke_fill'
                style: 'fill',
                color: '#FFFFFF',
                size: 12,
                family: 'Helvetica Neue',
                weight: 'normal',
                // 'solid' | 'dashed'
                borderStyle: 'solid',
                borderDashedValue: [2, 2],
                borderSize: 1,
                borderRadius: 2,
                borderColor: '#1677FF',
                paddingLeft: 4,
                paddingRight: 4,
                paddingTop: 4,
                paddingBottom: 4,
                backgroundColor: '#1677FF'
            }
        }
    };

    registerOverlay({
        name: 'circle',
        needDefaultPointFigure: true,
        needDefaultXAxisFigure: true,
        needDefaultYAxisFigure: true,
        totalStep: 3,
        createPointFigures: function ({ overlay, coordinates }) {
            if (coordinates.length === 2) {
                const xDis = Math.abs(coordinates[0].x - coordinates[1].x)
                const yDis = Math.abs(coordinates[0].y - coordinates[1].y)
                const radius = Math.sqrt(xDis * xDis + yDis * yDis)
                return {
                    key: 'circle',
                    type: 'circle',
                    attrs: {
                        ...coordinates[0],
                        r: radius
                    },
                    styles: {
                        style: 'stroke_fill'
                    }
                }
            }
            return []
        }
    })

    useEffect(() => {
        if (dataPriceRealTime.length > 0) {
            kLineChart.current = init("update-k-line");
            kLineChart.current?.applyNewData(dataPriceRealTime);
            kLineChart.current?.setStyles(chartCfg);

            setNewStreamingData(dataPriceRealTime[0]);
        }
        return () => {
            dispose("update-k-line");
        };
    }, [dataPriceRealTime]);

    const removePriceLineMarker = () => {
        kLineChart.current?.removeOverlay()
    }
    const toastAlert = (msg, type) => {
        toast(msg, { type: type == "win" ? "success" : "error" });
    };
    const toastAlertSuccess = (type, msg,) => {
        toast(msg, { type: type == "error" ? "error" : "success" });
    };

    const [creditHistoryData, setTradeHistoryData] = useState([]);
    const [creditHistoryCloseData, setTradeHistoryCloseData] = useState([]);

    const getTradeHistoryData = async (userId) => {
        try {
            let walletID = ""
            switch (activeCard) {
                case "DEMO":
                    walletID = demoCreditCardData["_id"]
                    break;
                case "BONUS":
                    walletID = bonusWalletData["_id"]
                    break;
                case "PACKAGE":
                    walletID = packageWalletData["_id"]
                    break;
                case "REAL":
                    walletID = realCreditCardData["_id"]
                    break;
                default:
                    walletID = demoCreditCardData["_id"]
                    break;
            }
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/trade/?user=${userId}&status=OPEN&card=${walletID}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const tradeHistoryHandle = await axios(config);
            if (tradeHistoryHandle?.data) {
                setTradeHistoryData(tradeHistoryHandle.data?.trade);
                getTradeHistoryClosedData(userId);
                handleCreateRemarkPoints(tradeHistoryHandle.data?.trade);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // getGraphData
    useEffect(() => {
        getGraphData();
    }, [binary]);

    const [getGraphData, { data: graphData, loading }] = useLazyQuery(CRYPTO_HISTORY_1M, {
        variables: { binary: binary, limit: _limit },
        fetchPolicy: 'network-only',

    });
    // getGraphData
    // remarkPoints
    const handleCreateRemarkPoints = async (creditHistoryData) => {
        creditHistoryData &&
            creditHistoryData.map((point, index) => {
                const timestamp = new Date(point?.createdAt).getTime();
                kLineChart?.current?.createOverlay({
                    name: 'priceLine',
                    extendData: 'priceLine',
                    points: [{
                        dataIndex: index,
                        timestamp: timestamp,
                        value: point?.openPrice
                    }],
                    styles: {
                        line: {
                            style: 'solid',
                            color: point?.type == "BUY" ? appColor.greenActive : appColor.redActive,
                            size: 1
                        }
                    },
                    // now allow to move line
                    lock: true,
                })
            })
    }

    const getTradeHistoryClosedData = async (userId) => {
        try {
            let walletID = ""
            switch (activeCard) {
                case "DEMO":
                    walletID = demoCreditCardData["_id"]
                    break;
                case "BONUS":
                    walletID = bonusWalletData["_id"]
                    break;
                case "PACKAGE":
                    walletID = packageWalletData["_id"]
                    break;
                case "REAL":
                    walletID = realCreditCardData["_id"]
                    break;
                default:
                    walletID = demoCreditCardData["_id"]
                    break;
            }
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/trade/?user=${userId}&status=CLOSE&card=${walletID}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const tradeHistoryHandle = await axios(config);
            if (tradeHistoryHandle?.data) {
                setTradeHistoryCloseData(tradeHistoryHandle.data?.trade);
            }
        } catch (error) {
            console.log(error);
        }
    };
    // END TRADE HISTORY

    const onLoadScriptRef = useRef();
    useEffect(() => {
        if (binary) {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement("script");
                    script.id = "tradingview-widget-loading-script";
                    script.src = "https://s3.tradingview.com/tv.js";
                    script.type = "text/javascript";
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(
                () => onLoadScriptRef.current && onLoadScriptRef.current()
            );

            return () => (onLoadScriptRef.current = null);

            function createWidget() {
                if (
                    document.getElementById("tradingview_22cb2") &&
                    "TradingView" in window
                ) {
                    new window.TradingView.widget({
                        width: window.innerWidth,
                        height: 400,
                        symbol:
                            binary == "GOLDUSDT" ? "FOREXCOM:XAUUSD" : `BINANCE:${binary}`,
                        interval: "1",
                        timezone: "Asia/Bangkok",
                        theme: "dark",
                        style: "1",
                        locale: "en",
                        enable_publishing: false,
                        save_image: false,
                        container_id: "tradingview_22cb2",
                    });
                }
            }
        }
    }, [binary]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (countfetch > 0) {
                if (countfetch - 1 == 0) {
                    setCountFetch(timeOfTank / 1000 + 1);
                }
                setCountFetch((prevCountdown) => prevCountdown - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [countfetch]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (countTrade > 0) {
                setCountTrade((prevCountdown) => prevCountdown - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [countTrade]);

    const [countfetch2, setCountFetch2] = useState(timeOfTank / 1000);
    useEffect(() => {
        const interval = setInterval(() => {
            if (countfetch2 > 0) {
                if (countfetch2 - 1 == 0) {
                    setCountFetch2(timeOfTank / 1000 + 1);
                }
                setCountFetch2((prevCountdown) => prevCountdown - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [countfetch2]);

    const [countSignal2, setCountSignal2] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (countSignal2 > 0) {
                setCountSignal2((prevCountdown) => prevCountdown - 1);
            } else {
                if (startPrice3Tank && lastData) {
                    if (noti3Tank == "BUY" && lastData > startPrice3Tank)
                        setCountTrue3(countTrue3 + 1);
                    if (noti3Tank == "BUY" && lastData < startPrice3Tank)
                        setCountFail3(countFail3 + 1);
                    if (noti3Tank == "SELL" && lastData < startPrice3Tank)
                        setCountTrue3(countTrue3 + 1);
                    if (noti3Tank == "SELL" && lastData > startPrice3Tank)
                        setCountFail3(countFail3 + 1);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [countSignal2]);

    const [countSignal22, setCountSignal22] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (countSignal22 > 0) {
                setCountSignal22((prevCountdown) => prevCountdown - 1);
            } else {
                if (startPrice2Tank && lastData) {
                    if (noti2Tank == "BUY" && lastData > startPrice2Tank)
                        setCountTrue2(countTrue2 + 1);
                    if (noti2Tank == "BUY" && lastData < startPrice2Tank)
                        setCountFail2(countFail2 + 1);
                    if (noti2Tank == "SELL" && lastData < startPrice2Tank)
                        setCountTrue2(countTrue2 + 1);
                    if (noti2Tank == "SELL" && lastData > startPrice2Tank)
                        setCountFail2(countFail2 + 1);
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [countSignal22]);

    const [countSignal, setCountSignal] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (countSignal > 0) {
                setCountSignal((prevCountdown) => prevCountdown - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [countSignal]);

    const [countSignal2Tank, setCountSignal2Tank] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (countSignal2Tank > 0) {
                setCountSignal2Tank((prevCountdown) => prevCountdown - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [countSignal2Tank]);

    useEffect(() => {
        if (dataPrice.length > 0) {
            setLastData(dataPrice.slice(-1)[0]);

            // Signal 2 tank
            if (
                dataPrice.slice(-1)[0] > dataPrice.slice(-2)[0] &&
                dataPrice.slice(-2)[0] > dataPrice.slice(-3)[0] &&
                countSignal2Tank <= 0 &&
                countSignal22 <= 0
            ) {
                setStartPrice2Tank(0);
                setStartPrice2Tank(dataPrice.slice(-1)[0]);
                setNoti2Tank("BUY");
                setCountSignal2Tank(timeOfTank / 1000);
                setCountFetch2(timeOfTank / 1000);
                setCountSignal22(timeOfTank / 1000);

                if (tradeType == "AUTO" && selectTankType == "TWO_TANK")
                    onCreateBuyAndSell("BUY");
            }
            if (
                dataPrice.slice(-1)[0] < dataPrice.slice(-2)[0] &&
                dataPrice.slice(-2)[0] < dataPrice.slice(-3)[0] &&
                countSignal2Tank <= 0 &&
                countSignal22 <= 0
            ) {
                setStartPrice2Tank(0);
                setStartPrice2Tank(dataPrice.slice(-1)[0]);
                setNoti2Tank("SELL");
                setCountSignal2Tank(timeOfTank / 1000);
                setCountFetch2(timeOfTank / 1000);
                setCountSignal22(timeOfTank / 1000);

                if (tradeType == "AUTO" && selectTankType == "TWO_TANK")
                    onCreateBuyAndSell("SELL");
            }

            // Signal 3 tank
            if (
                dataPrice.slice(-1)[0] > dataPrice.slice(-2)[0] &&
                dataPrice.slice(-2)[0] > dataPrice.slice(-3)[0] &&
                dataPrice.slice(-3)[0] > dataPrice.slice(-4)[0] &&
                countSignal <= 0 &&
                countSignal2 <= 0
            ) {
                setStartPrice3Tank(0);
                setStartPrice3Tank(dataPrice.slice(-1)[0]);
                setNoti3Tank("BUY");
                setCountSignal(timeOfTank / 1000);
                setCountFetch(timeOfTank / 1000);
                setCountSignal2(timeOfTank / 1000);

                if (tradeType == "AUTO" && selectTankType == "THREE_TANK") onCreateBuyAndSell("BUY");
            }
            if (
                dataPrice.slice(-1)[0] < dataPrice.slice(-2)[0] &&
                dataPrice.slice(-2)[0] < dataPrice.slice(-3)[0] &&
                dataPrice.slice(-3)[0] < dataPrice.slice(-4)[0] &&
                countSignal <= 0 &&
                countSignal2 <= 0
            ) {
                setStartPrice3Tank(0);
                setStartPrice3Tank(dataPrice.slice(-1)[0]);
                setNoti3Tank("SELL");
                setCountSignal(timeOfTank / 1000);
                setCountFetch(timeOfTank / 1000);
                setCountSignal2(timeOfTank / 1000);

                if (tradeType == "AUTO" && selectTankType == "THREE_TANK") onCreateBuyAndSell("SELL");
            }
        }
    }, [dataPrice]);

    const getUserData = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                setUserData(userHandle.data?.user);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [currentActiveCard, setCurrentActiveCard] = useState("DEMO");
    const getCreditData = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const creditHandle = await axios(config);
            if (creditHandle?.data) {
                setCreditList(creditHandle.data?.credit)
                for (var i = 0; i < creditHandle.data?.credit.length; i++) {
                    if (creditHandle.data?.credit[i].type == "DEMO")
                        setDemoCreditCardData(creditHandle.data?.credit[i]);
                    if (creditHandle.data?.credit[i].type == "REAL")
                        setRealCreditCardData(creditHandle.data?.credit[i]);
                    if (creditHandle.data?.credit[i].type == "USDT")
                        setUsdtWalletData(creditHandle.data?.credit[i]);
                    if (creditHandle.data?.credit[i].type == "BONUS")
                        setBonusWalletData(creditHandle.data?.credit[i]);
                    if (creditHandle.data?.credit[i].type == "PACKAGE")
                        setPackageWalletData(creditHandle.data?.credit[i]);
                    if (creditHandle.data?.credit[i].isUse == true) {
                        setCurrentActiveCard(creditHandle.data?.credit[i])
                        setActiveCard(creditHandle.data?.credit[i].type);
                        setDraffActiveCard(creditHandle.data?.credit[i].type);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const [isEdit, setIsEdit] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const handleCloseProfile = () => {
        setShowProfile(false);
        setIsEdit(false);
    };
    const toEdit = () => {
        setIsEdit(true);
    };

    const onChangeCreditCard = async (card) => {
        try {
            let reqCard = ""
            switch (card) {
                case "DEMO":
                    reqCard = demoCreditCardData["_id"]
                    break;
                case "REAL":
                    reqCard = realCreditCardData["_id"]
                    break;
                case "BONUS":
                    reqCard = bonusWalletData["_id"]
                    break;
                case "PACKAGE":
                    reqCard = packageWalletData["_id"]
                    break;
                default:
                    reqCard = demoCreditCardData["_id"]
                    break;
            }
            const config = {
                method: "put",
                url: `${END_POINT_SEVER}/api/credit/${reqCard}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({ isUse: true }),
            };
            const updateHandle = await axios(config);
            const configFalse = {
                method: "put",
                url: `${END_POINT_SEVER}/api/credit/${currentActiveCard["_id"]}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({ isUse: false }),
            };
            await axios(configFalse);

            if (updateHandle.data) {
                getCreditData(userData["_id"]);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const resetDemoCard = async (cardId) => {
        try {
            var config = {
                method: "put",
                url: `${END_POINT_SEVER}/api/credit/${cardId}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({ credit: 10000 }),
            };
            const updateHandle = await axios(config);
            if (updateHandle.data) {
                getCreditData(userData["_id"]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    function currencyFormat(num) {
        return num != undefined
            ? parseFloat(num).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            : 0;
    }

    const changeToBinaryName = (binary) => {
        const serachBinary = profits.filter(e => e.binary == binary)
        if (serachBinary.length > 0) return serachBinary[0].displayName
        else return ""
    }

    const changeToBinaryLogo = (binary) => {
        const serachBinary = profits.filter(e => e.binary == binary)
        if (serachBinary.length > 0) return serachBinary[0].logo
        else return ""
    }

    const [mutationFetchNewTrading] = useMutation(FETCH_NEW_TRADING)
    const [isTradeLoading, setIsTradeLoading] = useState(false)
    const onCreateBuyAndSell = async (type) => {
        try {
            const newValue = parseFloat(tradeMoney);
            if (draffActiveCard == "DEMO") {
                let _demoCreditCardData = { ...demoCreditCardData }
                _demoCreditCardData["credit"] = _demoCreditCardData["credit"] - newValue
                if (_demoCreditCardData["credit"] >= 0) setDemoCreditCardData(_demoCreditCardData)
            }
            if (draffActiveCard == "REAL") {
                let _realCreditCardData = { ...realCreditCardData }
                _realCreditCardData["credit"] = _realCreditCardData["credit"] - newValue
                if (_realCreditCardData["credit"] >= 0) setRealCreditCardData(_realCreditCardData)
            }
            if (draffActiveCard == "BONUS") {
                let _bonusWalletData = { ...bonusWalletData }
                _bonusWalletData["credit"] = _bonusWalletData["credit"] - newValue
                if (_bonusWalletData["credit"] >= 0) setBonusWalletData(_bonusWalletData)
            }
            if (draffActiveCard == "PACKAGE") {
                let _packageWalletData = { ...packageWalletData }
                _packageWalletData["credit"] = _packageWalletData["credit"] - newValue
                if (_packageWalletData["credit"] >= 0) setPackageWalletData(_packageWalletData)
            }
            if (draffActiveCard == "USDT") {
                let _usdtWalletData = { ...usdtWalletData }
                _usdtWalletData["credit"] = _usdtWalletData["credit"] - newValue
                if (_usdtWalletData["credit"] >= 0) setUsdtWalletData(_usdtWalletData)
            }
            // set delay 2 secs
            // await new Promise(resolve => setTimeout(resolve, 1500));
            if (notificationIsOn) orderSound();

            let reqCardID = "";
            switch (draffActiveCard) {
                case "DEMO":
                    reqCardID = demoCreditCardData["_id"]
                    break;
                case "REAL":
                    reqCardID = realCreditCardData["_id"]
                    break;
                case "USDT":
                    reqCardID = usdtWalletData["_id"]
                    break;
                case "BONUS":
                    reqCardID = bonusWalletData["_id"]
                    break;
                case "PACKAGE":
                    reqCardID = packageWalletData["_id"]
                    break;
                default:
                    reqCardID = demoCreditCardData["_id"]
                    break;
            }
            const createData = {
                type: type,
                cardType: draffActiveCard,
                card: reqCardID,
                status: "OPEN",
                binary: binary,
                binaryName: changeToBinaryName(binary),
                binaryLogo: changeToBinaryLogo(binary),
                openPrice: newStreamingData?.close,
                money: newValue,
                user: userData["_id"],
            }

            var config = {
                method: "post",
                url: `${END_POINT_SEVER}/api/trade`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(createData),
            };
            const createHandle = await axios(config);
            if (createHandle.data) {
                setCountTrade(duration);
                getCreditData(userData["_id"]);
                mutationFetchNewTrading()
            }
        } catch (error) {
            if (error?.response?.data?.errorCode == "ORDER_ERROR") {
                setIsTradeLoading(true)
            } else {
                toastAlert(`SORRY: ${error?.response?.data?.errorCode}`);
            }
        }
    };

    const [isTradeStatus, setisTradeStatus] = useState(false);

    const winSound = () => {
        try {
            // setisTradeStatus(true);
            soundWinPlayer?.current?.audioEl?.current?.play();
        } catch (error) {
            console.log(error);
        }
    };

    const lossSound = () => {
        try {
            // setisTradeStatus(true);
            soundLossPlayer?.current?.audioEl?.current?.play();
        } catch (error) {
            console.log(error);
        }
    };
    const orderSound = () => {
        try {
            // setisTradeStatus(true);
            orderSoundPlayer?.current?.audioEl?.current?.play();
        } catch (error) {
            console.log(error);
        }
    };
    const balanceIsNotEnoughSound = () => {
        try {
            // setisTradeStatus(true);
            balanceIsNotEnoughSoundPlayer?.current?.audioEl?.current?.play();
        } catch (error) {
            console.log(error);
        }
    };

    const [showModalLogou, setShowModalLogout] = useState(false);

    const handleModelLogout = () => {
        setShowModalLogout(!showModalLogou);
    };
    const handleLogout = () => {
        localStorage.removeItem('CLOSE_POPUP');
        localStorage.removeItem('CLOSE_POPUP_IMAGE');
        localStorage.removeItem(USER_LOCAL_DATA);
        routeNavigate("/");
    };

    const [profits, setProfits] = useState([]);
    useEffect(() => {
        fetchProfitList();

    }, []);

    useEffect(() => {
        profits.forEach((item, index) => {
            if (binary == profits[index]?.binary) {
                setProfitPercent(profits[index]?.percent);
            }
        })
    }, [profits, binary]);

    const fetchProfitList = async () => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/profit`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const tradeHistoryHandle = await axios(config);
            if (tradeHistoryHandle?.data) {
                setProfits(tradeHistoryHandle.data?.profit);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // START DATA FROM GRAPHQL
    const [getGraphData1m] = useLazyQuery(CRYPTO_HISTORY_1M, {
        variables: { binary: binary, limit: 1 },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (graphData?.getGraph1m) {
            // DISABLE GRAPHQL SUBSCRIPTION
            setDataPriceRealTime(graphData?.getGraph1m);
        }
    }, [graphData]);

    const { data: receiveGraph1s } = useSubscription(RECEIVE_GRAPH1S, {
        variables: {
            binary: binary
        },
        onData: (data) => {
            // console.log('RECEIVE_GRAPH1S data :>> ', data);
        },
        onError: (error) => {
            // console.log('RECEIVE_GRAPH1S error :>> ', error);
        },
        shouldResubscribe: true,
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (receiveGraph1s?.receiveGraph1s) {
            setNewStreamingData(receiveGraph1s?.receiveGraph1s);
        }
    }, [receiveGraph1s]);

    // END DATA FROM GRAPHQL
    const [countdownUpdate, setCountdownUpdate] = useState(0);
    const [enableTrading, setEnableTrading] = useState(false);

    const { data: countdownRes } = useSubscription(RECEIVE_GRAPH_COUNTDOWN, {
        variables: {
            roomId: "GraphCountDown",
        },
        fetchPolicy: 'network-only',
        onData: (data) => {
            // console.log('RECEIVE_GRAPH_COUNTDOWN data :>> ', data);
        },
        onError: (error) => {
            // console.log('RECEIVE_GRAPH_COUNTDOWN error :>> ', error);
            window.location.reload();
        },
        shouldResubscribe: true,
    });

    useEffect(() => {
        if (countdownRes?.receiveGraphCountDown) {
            setCountdownUpdate(countdownRes?.receiveGraphCountDown?.countNumber);
            setEnableTrading(countdownRes?.receiveGraphCountDown?.enable);
            if (countdownRes?.receiveGraphCountDown?.countNumber == 1 && isTradeLoading) {
                setIsTradeLoading(false)
                toastAlert(`Sorry: ${t("something went wrong")} 🙏`);
            }
        }
    }, [countdownRes, binary]);
    // END SUBSCRIPTION

    const handleChange = (event) => {
        if (event.target.value == "BTCUSDT") {
            setBinary(event.target.value);
        }
    };

    const [cardClick, setCardClick] = useState(false);

    const handleCardMenu = () => {
        setCardClick(!cardClick);
    }
    const handleChangeCard = (event) => {
        if (event.target.value != "USDT") {
            setDraffActiveCard(event.target.value);
            onChangeCreditCard(event.target.value)
        } else {
            onChangeCreditCard(draffActiveCard)
        }

    };

    // LANGUAGE
    const currentLanguage = i18n.language;
    const handleChangeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
        setStateLanguage(event.target.value)
        localStorage.setItem('appLanguage', event.target.value)
    };

    // START RECEIVE_TRADE
    const [receiveTrade, setReceiveTrade] = useState();
    useEffect(() => {
        if (userID) getTradeHistoryData(userID);
        // receiveTrade
        receiveTrade?.map((item) => {
            getCreditData(userData["_id"]);
            if (item.isProfit === true) {
                const totalWin = appProfitCalculator(item?.money, item?.profit);
                if (notificationIsOn) winSound();
                toastAlert(`${t("win")} ${currencyFormat(totalWin)} `, "win");
            } else {
                if (notificationIsOn) lossSound();
                toastAlert(`${t("loss")} ${currencyFormat(item?.money)} `, "error");
            }
        });

    }, [receiveTrade]);
    const { data: receiveTradeResult } = useSubscription(RECEIVE_TRADE, {
        variables: {
            userId: userID,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        removePriceLineMarker();
        setReceiveTrade(receiveTradeResult?.receiveTrade);
    }, [receiveTradeResult]);

    // END RECEIVE_TRADE

    // START RECEIVE NOTIFICATION
    const { data: receiveNotification } = useSubscription(RECEIVE_NOTIFICATION, {
        variables: {
            userId: userID,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        toast(receiveNotification?.receiveNotification?.detail, { type: receiveNotification?.receiveNotification?.type === "APPROVED" ? "sucess" : "error" });
    }, [receiveNotification]);
    // END RECEIVE NOTIFICATION

    const tooltipVolume = (
        <Tooltip id="tooltip">
            <div style={{ fontFamily: "Phetsarath_OT" }}>{t("on-and-off-sound")}</div>
        </Tooltip>
    );

    const [notificationIsOn, setNotificationIsOn] = useState(true);
    const setNotificationStatus = () => {
        setNotificationIsOn(!notificationIsOn);
        localStorage.setItem('NOTI_STATUS', !notificationIsOn);
    }

    useEffect(() => {
        const status = localStorage.getItem('NOTI_STATUS');
        if (status == 'true') {
            setNotificationIsOn(true);
        } else {
            setNotificationIsOn(false);
        }
    }, [notificationIsOn]);

    const [isDrawerIndicatorOpen, setisDrawerIndicatorOpen] = useState(false);
    const [isDrawerOpen, setisDrawerOpen] = useState(false);
    const [drawerType, setDrawerType] = useState("left");
    const [isDrawerHistory, setIsDrawerHistory] = useState(false);

    const toggleDrawer = (clickDrawerType) => {
        setDrawerType(clickDrawerType)
        setisDrawerOpen(!isDrawerOpen);
        setisDrawerIndicatorOpen(!isDrawerIndicatorOpen);
        if (isDrawerHistory) setIsDrawerHistory(!isDrawerHistory);
    }

    const [isCandle, setIsCandle] = useState(true);
    const [candleSelected, setcandleSelected] = useState("");

    const updateCandleStyle = () => {
        setIsCandle(!isCandle);
        setcandleSelected(candleTypes[candleTypes.indexOf(candleSelected) + 1] || candleTypes[0]);
        setDataToLocalStorage({ key: CANDLE_TYPE, value: candleTypes[candleTypes.indexOf(candleSelected) + 1] || candleTypes[0] })
    }
    const _getCanleType = async () => {
        let res = await getDataFromLocalStorage({ key: CANDLE_TYPE });
        // console.log('_getCanleType01 :>> ', res);
        if (res) {
            kLineChart.current?.setStyles({
                candle: {
                    type: res
                    // isCandle ? "candle_solid" : "area"
                }
            })
            setcandleSelected(res);
            // console.log('_getCanleType02 :>> ', res);
        }
    }
    useEffect(() => {
        _getCanleType();
    }, []);

    const candleTypes = ['candle_solid', 'candle_stroke', 'candle_up_stroke', 'candle_down_stroke', 'ohlc', 'area']
    useEffect(() => {
        // console.log('candleSelected1 :>> ', candleSelected);
        kLineChart.current?.setStyles({
            candle: {
                type: candleSelected
                // isCandle ? "candle_solid" : "area"
            }
        })
    }, [isCandle, candleSelected]);

    const paneId = useRef('')
    const mainIndicators = ["MA", "EMA", "BOLL", "SAR", "BBI"]
    const [mainSelected, setMainSelected] = useState('');

    const subIndicators = ['VOL', 'MACD', 'KDJ', "RSI"]
    const [subSelected, setSubSelected] = useState('');

    const setIndicator = (indicatorType) => {
        setMainSelected(indicatorType)
        toggleDrawer(drawerType);
        localStorage.setItem(INDICATOR_MAIN, indicatorType);

        if (indicatorType == mainSelected) {
            kLineChart.current?.removeIndicator("candle_pane");
            setMainSelected("")
            localStorage.removeItem(INDICATOR_MAIN);
        } else {
            kLineChart.current?.createIndicator(indicatorType, false, { id: 'candle_pane' });
        }
    }
    const setSubIndicator = (indicatorType) => {
        setSubSelected(indicatorType)

        toggleDrawer(drawerType);
        localStorage.setItem(INDICATOR_SUB, indicatorType);
        if (indicatorType === subSelected) {
            kLineChart.current?.removeIndicator(paneId.current);
            setSubSelected("")
            localStorage.removeItem(INDICATOR_SUB);
        } else {
            paneId.current = kLineChart.current?.createIndicator(indicatorType, false, { id: paneId.current });
        }
    }

    useEffect(() => {
        const getIndicator = async () => {
            const indiMain = await localStorage.getItem(INDICATOR_MAIN);
            if (indiMain) {
                // console.log('indiMain :>> ', indiMain);
                setMainSelected(indiMain);
                kLineChart.current?.createIndicator(indiMain, false, { id: 'candle_pane' });
            }
            const indiSub = await localStorage.getItem(INDICATOR_SUB);
            if (indiSub) {
                // console.log('indiSub :>> ', indiSub);
                setSubSelected(indiSub);
                kLineChart.current?.removeIndicator(paneId.current);
                setTimeout(() => {
                    paneId.current = kLineChart.current?.createIndicator(indiSub, false, { id: paneId.current });
                }, 1000)
            }
        }
        setTimeout(() => {
            getIndicator();
        }, 1000)
    }, []);

    const [device, setDevice] = useState('desktop');
    const [hasVIPBonusModal, setHasVIPBonusModal] = useState(false);

    const checkDevice = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            setDevice('mobile');
        } else if (screenWidth >= 768 && screenWidth < 1024) {
            setDevice('iPad');
        } else {
            setDevice('desktop');
        }
    };

    useEffect(() => {
        checkDevice();
        window.addEventListener('resize', checkDevice);
        return () => window.removeEventListener('resize', checkDevice);
    }, []);

    const onReceiveBonus = async () => {
        try {
            setHasVIPBonusModal(true)
            var config = {
                method: "post",
                url: `${END_POINT_SEVER}/api/users/recieve-vip-bonus`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({ userId: userData["_id"] }),
            };
            const receiveBonusHandle = await axios(config);
            if (receiveBonusHandle?.data) {
                getUserData(userData["_id"]);
                getCreditData(userData["_id"]);
            }
            // setTimeout(() => {
            //     setHasVIPBonusModal(false)
            // }, 3000);
        } catch (error) {
            console.log(error);
        }
    }

    const [showTransferToBonus, setShowTransferToBonus] = useState(false)
    const [money, setMoney] = useState(0)
    const [isSaveLoading, setIsSaveLoading] = useState(false)

    const onTransferCredit = async () => {
        try {
            setIsSaveLoading(true)
            const data = {
                realMoney: parseFloat(money),
                fromCard: "USDT",
                fromCardId: usdtWalletData?._id,
            }
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/credit/transfer-to-package`,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(data)
            };
            const creditHistoryHandle = await axios(config)
            if (creditHistoryHandle?.data) {
                getCreditData(userData["_id"])
                getCreditData(userData["_id"])
                getUserData(userData["_id"])
            }
            setIsSaveLoading(false)
            setShowTransferToBonus(false)
            setMoney(0)
            toastAlertSuccess('success', "Transfer success")
        } catch (error) {
            toastAlertSuccess("error", "Transfer failed " + error?.response?.data?.errorCode)
            console.log(error)
            setIsSaveLoading(false)
            setShowTransferToBonus(false)
            setMoney(0)
        }
    }

    const onchangeTradeMoney = (event) => {
        const value = event.target.value;
        const regexDot = /^\d*\.?\d*$/;
        const regexComma = /^\d*\,?\d*$/;
        if (regexDot.test(value) || regexComma.test(value)) {
            const hasDecimalAndEndsWithNumberDot = /^\d*\.?\d+$/.test(value);
            if (!hasDecimalAndEndsWithNumberDot) {
                const hasDecimalAndEndsWithNumberComma = /^\d*\,?\d+$/.test(value);
                if (!hasDecimalAndEndsWithNumberComma) {
                    setTradeMoney(value);
                } else {
                    let newValue2 = value.replace(/,/g, '.');
                    let newValue = parseFloat(newValue2);
                    setTradeMoney(newValue);
                }
            } else {
                let newValue = parseFloat(value)
                modifyTradeMoney(newValue)
            }
        }
    }

    const modifyTradeMoney = (newValue) => {
        // Ensure the value is not below 0
        if (newValue < 0) {
            newValue = 0;
        }

        switch (draffActiveCard) {
            case "REAL":
                if (newValue <= realCreditCardData?.credit) {
                    setTradeMoney(newValue);
                } else {
                    setTradeMoney(realCreditCardData?.credit);
                }
                break;
            case "DEMO":
                if (newValue <= demoCreditCardData?.credit) {
                    setTradeMoney(newValue);
                } else {
                    setTradeMoney(demoCreditCardData?.credit);
                }
                break;
            case "USDT":
                if (newValue <= usdtWalletData?.credit) {
                    setTradeMoney(newValue);
                } else {
                    setTradeMoney(usdtWalletData?.credit);
                }
                break;
            case "BONUS":
                if (newValue <= bonusWalletData?.credit) {
                    setTradeMoney(newValue);
                } else {
                    setTradeMoney(bonusWalletData?.credit);
                }
                break;
            case "PACKAGE":
                if (newValue <= packageWalletData?.credit) {
                    setTradeMoney(newValue);
                } else {
                    setTradeMoney(packageWalletData?.credit);
                }
                break;
            default:
                break;
        }
    }

    return (
        <Box sx={{
            width: "100%",
            backgroundColor: appColor.background,
            height: "100dvh",
            overflow: "scroll",
            color: "#fff",
        }}>
            {
                isMobile ?
                    ((userData?.vipStatus == "VERIFIED" && !userData?.hasVIPBonus) ? <div /> : <div onClick={() => {
                        if (!userData?.vipUser || userData?.vipStatus != "VERIFIED") {
                            routeNavigate("/vip-page");
                        } else {
                            if (userData?.vipStatus == "VERIFIED" && userData?.hasVIPBonus) {
                                onReceiveBonus();
                            }
                        }
                    }} style={{ display: "flex", flexDirection: "column", cursor: "pointer", alignItems: "center", width: 70, height: 90, position: "absolute", zIndex: 999, top: "17vh", left: 4 }}>
                        <img src="/bonus-small-size.png" style={{ width: 55, height: 55 }} className="image-animation" />
                        <div style={{ height: 5 }} />
                        <div style={{ fontSize: 10, color: "#101010", fontWeight: "bold", backgroundColor: "#FFD700", borderRadius: 30, width: 70, height: 22, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>{t("get bonus 50% button")} $10</div>
                    </div>)
                    :
                    ((userData?.vipStatus == "VERIFIED" && !userData?.hasVIPBonus) ? <div /> : <div onClick={() => {
                        if (!userData?.vipUser || userData?.vipStatus != "VERIFIED") {
                            routeNavigate("/vip-page");
                        } else {
                            if (userData?.vipStatus == "VERIFIED" && userData?.hasVIPBonus) {
                                onReceiveBonus();
                            }
                        }
                    }} style={{ display: "flex", flexDirection: "column", cursor: "pointer", alignItems: "center", width: 70, height: 90, position: "absolute", zIndex: 999, left: 80, top: "25vh" }}>
                        <img src="/bonus-small-size.png" style={{ width: 55, height: 55 }} className="image-animation" />
                        <div style={{ height: 5 }} />
                        <div style={{ fontSize: 10, color: "#101010", fontWeight: "bold", backgroundColor: "#FFD700", borderRadius: 30, width: 70, height: 22, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>{t("get bonus 50% button")} $10</div>
                    </div>)
            }
            <ReactAudioPlayer
                src="/sound/win.mpeg"
                type={"audio/mpeg"}
                ref={soundWinPlayer}
                autoplay={false}
            />
            <ReactAudioPlayer
                src="/sound/loss.mpeg"
                type={"audio/mpeg"}
                ref={soundLossPlayer}
                autoplay={false}
            />
            <ReactAudioPlayer
                src="/sound/orderSound.mpeg"
                type={"audio/mpeg"}
                ref={orderSoundPlayer}
                autoplay={false}
            />
            <ReactAudioPlayer
                src="/sound/balanceNotEnough.mpeg"
                type={"audio/mpeg"}
                ref={balanceIsNotEnoughSoundPlayer}
                autoplay={false}
            />
            {/* START RESPONESIVE */}
            <Box
                display="flex"
                flexDirection={"column"}
            >
                <Drawer
                    anchor={drawerType}
                    open={isDrawerOpen}
                    onClose={() => toggleDrawer(drawerType)}
                >
                    <Box display="flex"
                        sx={{

                            height: isDrawerIndicatorOpen && isDrawerHistory === false && drawerType !== "left" && isMobile ? "45vh" : "100vh",
                            // height: "100vh",
                            width: 1,
                        }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                backgroundColor: appColor.bgDark,
                                color: appColor.textGrey,
                                // paddingY: 3,
                                width: 1,
                                // height: "100vh",
                                // height: "100%",
                            }}
                        >
                            {drawerType == "left" ?
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    sx={{
                                        p: 2,
                                        justifyItems: "center",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                    height={`calc(100%)`}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            gap: 2,
                                            justifyItems: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            sx={{
                                                cursor: "pointer",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                gap: 1
                                            }}
                                            onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                                        >

                                            <img src="/coin-logo.png" style={{ height: 52 }} />
                                        </Box>
                                        <Box
                                            height={4}
                                        />
                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            sx={{
                                                cursor: "pointer",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                            onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                                        >
                                            <LeaderboardOutlined style={{ fontSize: 30 }} />
                                            <p style={{ fontSize: 12 }}>{t("trading")}
                                            </p>
                                        </Box>
                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            sx={{
                                                cursor: "pointer",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                            onClick={() => routeNavigate("/trade-histories")}
                                        >
                                            <History style={{ fontSize: 30 }} />
                                            <p style={{ fontSize: 12 }}>{t("history")}</p>
                                        </Box>
                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            sx={{
                                                cursor: "pointer",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                            // select-bank-option
                                            onClick={() => routeNavigate("/transection-list")}
                                        >
                                            <WalletOutlined style={{ fontSize: 30 }} />
                                            <p style={{ fontSize: 12 }}>{t("wallet")}</p>
                                        </Box>                                    <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            sx={{
                                                cursor: "pointer",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                            onClick={() => routeNavigate("/p2p-advertisement")}
                                        >
                                            <RiP2PFill size={32} />{" "}
                                            {/* <FontAwesomeIcon
                                            icon={faExchangeAlt}
                                            style={{ fontSize: 20, color: "#fff" }}
                                        /> */}
                                            <p style={{ fontSize: 12 }}>{t("P2P")}</p>
                                        </Box>
                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            sx={{
                                                cursor: "pointer",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                            // select-bank-option
                                            onClick={() => {
                                                handlePopupTransfer();
                                                toggleDrawer(drawerType);
                                            }}
                                        >
                                            <SwapHorizOutlined style={{ fontSize: 30 }} />
                                            <p style={{ fontSize: 12 }}>{t("transfer")}</p>
                                        </Box>
                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            sx={{
                                                cursor: "pointer",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                            onClick={() => routeNavigate(userData?.role === "ADMIN" ? "/admin-vip-page" : "/vip-page")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faRankingStar}
                                                style={{ fontSize: 30 }}
                                            />
                                            <p style={{ fontSize: 11 }}>VIP Member</p>
                                        </Box>
                                        <Box
                                            display={"flex"}
                                            flexDirection={"column"}
                                            sx={{
                                                cursor: "pointer",
                                                justifyItems: "center",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                            }}
                                            onClick={() => setShowProfile(true)}
                                        >
                                            <PersonOutline style={{ fontSize: 30 }} />
                                            <p style={{ fontSize: 12 }}>{t("profile")}</p>
                                        </Box>
                                        {userData?.role === "ADMIN" ? (
                                            <>
                                                <Box
                                                    display={"flex"}
                                                    flexDirection={"column"}
                                                    sx={{
                                                        cursor: "pointer",
                                                        justifyItems: "center",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}
                                                    onClick={() => routeNavigate("/cost-page")}
                                                >
                                                    <AttachMoney style={{ fontSize: 30 }} />
                                                    <p style={{ fontSize: 12 }}>{t("capital-cost")}</p>
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    flexDirection={"column"}
                                                    sx={{
                                                        cursor: "pointer",
                                                        justifyItems: "center",
                                                        alignItems: "center",
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}
                                                    onClick={() => routeNavigate("/profit-page")}
                                                >
                                                    <Percent style={{ fontSize: 30 }} />
                                                    <p style={{ fontSize: 12 }}>Profit</p>
                                                </Box>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            cursor: "pointer",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                        onClick={handleModelLogout}
                                    >
                                        <LogoutOutlined style={{ fontSize: 30 }} />
                                        <p style={{ fontSize: 12 }}>Logout </p>
                                    </Box>
                                </Box> :
                                isDrawerIndicatorOpen && isDrawerHistory == false ?
                                    <Box sx={{
                                        px: isMobile ? 2 : 4,
                                        py: 2,
                                        // backgroundColor:'red',
                                        // height: "100%",
                                    }}>

                                        <Box
                                            display={"flex"}
                                            flexDirection={"row"}
                                            sx={{
                                                justifyItems: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <DragIndicatorOutlined
                                            />
                                            <Typography variant={isMobile ? "body2" : "h6"}>Indicators</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                height: 10,
                                            }}
                                        />
                                        <Box>
                                            <Typography variant="body1">Main</Typography>
                                            <Grid
                                                container
                                                gap={1}
                                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                                sx={{
                                                    justifyItems: "center",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                    justifyContent: "center",
                                                    // backgroundColor: "#000"
                                                }}
                                            >
                                                {mainIndicators.map((item, index) => {
                                                    return <Grid
                                                        xs={3}
                                                        item
                                                        key={index}
                                                        display={"flex"}
                                                        flexDirection={"column"}
                                                        sx={{
                                                            justifyItems: "center",
                                                            alignItems: "center",
                                                            alignContent: "center",
                                                            justifyContent: "center",
                                                            border: `1px solid ${mainSelected == item ? appColor.orange : appColor.textWhite}`,
                                                            borderRadius: "10px",
                                                            cursor: "pointer",
                                                            py: isMobile ? 0.5 : 1,
                                                            px: 2
                                                        }}
                                                        onClick={() => setIndicator(item)}
                                                    >
                                                        {item}
                                                    </Grid>

                                                })}
                                            </Grid>
                                            <Box
                                                sx={{
                                                    height: 4,
                                                }}
                                            />
                                            <Typography variant="body1">Sub</Typography>
                                            <Grid
                                                container
                                                gap={2}
                                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                                                sx={{
                                                    justifyItems: "center",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                    justifyContent: "center",
                                                    // backgroundColor: "#000"
                                                }}
                                            >
                                                {subIndicators.map((item, index) => {
                                                    return <Grid
                                                        xs={3}
                                                        item
                                                        key={index}
                                                        display={"flex"}
                                                        flexDirection={"column"}
                                                        sx={{
                                                            justifyItems: "center",
                                                            alignItems: "center",
                                                            alignContent: "center",
                                                            justifyContent: "center",
                                                            border: `1px solid ${subSelected == item ? appColor.orange : appColor.textWhite}`,
                                                            borderRadius: "10px",
                                                            cursor: "pointer",
                                                            py: isMobile ? 0.5 : 1,
                                                            px: 2
                                                        }}
                                                        onClick={() => setSubIndicator(item)}
                                                    >
                                                        {item}
                                                    </Grid>
                                                })}
                                            </Grid>
                                        </Box>
                                    </Box>
                                    :
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            gap: 0.5,
                                            backgroundColor: appColor.bgDark,
                                            overflow: "auto",
                                            px: 2,
                                            py: 1
                                            // padding: 2,
                                        }}
                                    >

                                        <Typography variant="body1"
                                            style={{ fontFamily: currentLanguage == "lao" ? "Phetsarath_OT" : "Myriad_Pro" }}>{t("opening")}</Typography>
                                        <Divider
                                            color={appColor.yellow}
                                            sx={{
                                                height: "0.5px",
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                                // height: "220px",

                                                cursor: "pointer",

                                            }}
                                            onClick={() => routeNavigate("/trade-histories")}
                                        >
                                            {creditHistoryData &&
                                                creditHistoryData.map((item, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            border: 1,
                                                            borderColor: appColor.bgDark,
                                                            backgroundColor: appColor.background,
                                                            borderRadius: "10px",
                                                            width: "200px",
                                                            height: "90px",
                                                        }}
                                                    >
                                                        <Box
                                                            display="flex"
                                                            sx={{
                                                                p: 1,
                                                                gap: 2,
                                                                alignItems: "center",
                                                                justifyContent: "space-between",
                                                            }}
                                                        >
                                                            <Box
                                                                display="flex"
                                                                sx={{
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Typography variant="body2"
                                                                    sx={{
                                                                        color: appColor.textWhite,
                                                                        fontSize: 12
                                                                    }}
                                                                >
                                                                    {item?.binaryName ?? item?.binary}
                                                                </Typography>
                                                                <Typography
                                                                    variant="caption"
                                                                    sx={{
                                                                        backgroundColor: draffActiveCard == "REAL" ? appColor.greenActive : appColor.orangeActive,
                                                                        cursor: "pointer",
                                                                        borderRadius: "4px",
                                                                        px: 1,
                                                                        color: appColor.textWhite,
                                                                        fontSize: 12
                                                                    }}
                                                                >
                                                                    {item.cardType}
                                                                </Typography>
                                                            </Box>
                                                            <Avatar
                                                                sx={{
                                                                    width: 18,
                                                                    height: 18,
                                                                    backgroundColor: "#FFF",
                                                                    p: 0.5,
                                                                }}
                                                            >
                                                                <img src={item?.binaryLogo} style={{ width: 18, height: 18, borderRadius: 18 }} />
                                                            </Avatar>
                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            sx={{
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                px: 1,
                                                            }}
                                                        >
                                                            {item.type == "BUY" ? (
                                                                <TrendingUp
                                                                    sx={{
                                                                        color: appColor.greenActive,
                                                                    }}
                                                                />
                                                            ) : (
                                                                <TrendingDown
                                                                    sx={{
                                                                        color: appColor.redActive,
                                                                    }}
                                                                />
                                                            )}
                                                            <Typography variant="body2" sx={{
                                                                color: draffActiveCard == "REAL" ? appColor.greenActive : appColor.orangeActive,
                                                                fontWeight: "bold"
                                                            }}>
                                                                $ {item.money}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            display="flex"
                                                            sx={{
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                px: 1,
                                                            }}
                                                        >
                                                            <Typography variant="body2" sx={{
                                                                color: appColor.textWhite,
                                                            }}>
                                                                {moment(item.createdAt).format(
                                                                    "YYYY-MM-DD HH:mm:ss"
                                                                )}
                                                            </Typography>
                                                            <Typography variant="caption">{t("opening")}</Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                        </Box>
                                    </Box>
                            }

                        </Paper>
                    </Box>
                </Drawer>

                <Box
                    display="flex"
                    flexDirection={"row"}
                    sx={{
                        justifyContent: "space-between",
                        paddingX: 2,
                        paddingY: 1,
                    }}
                >
                    {isMobile ? <></> :
                        <Box
                            display={"flex"}
                            sx={{ alignItems: "center", cursor: "pointer" }}

                        >
                            <div
                                onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}
                            >
                                {" "}
                                <img src="/coin-logo-text.png" style={{ height: 45 }} />
                                {" "}
                            </div>
                        </Box>
                    }

                    <Box
                        display={"flex"}
                        sx={{
                            justifyContent: "space-between",
                            itemsAlign: "center",
                            alignContent: "center",
                            alignItems: "center",
                            width: "100%",
                            // gap: 2,
                            // paddingLeft: "2px"
                        }}
                    >
                        {/* <Language /> TH <ArrowDropDown /> */}
                        {isMobile ?
                            <Box
                                sx={{
                                    border: `0.1px solid ${appColor.textGrey}`,
                                    borderRadius: 1,
                                    p: 1
                                }}
                            >
                                <Menu
                                    onClick={() => toggleDrawer("left")}
                                    color={appColor.textGrey}
                                    sx={{
                                        height: 20,
                                        width: 20,
                                    }}
                                />
                            </Box> :
                            <Box></Box>
                        }


                        {userData?.role == "ADMIN" ? (
                            <Box
                                display={"flex"}
                                sx={{
                                    alignItems: "center",
                                    gap: 2,
                                }}
                                onClick={() => routeNavigate("/transection-list-admin")}
                            >
                                <FormControl fullWidth sx={{ pl: isMobile ? 2 : 0 }}>
                                    <Select
                                        labelId="binary-simple-select-label"
                                        id="binary-simple-select"
                                        onOpen={handleCardMenu}
                                        onClose={handleCardMenu}
                                        sx={{
                                            color: appColor.bgDark,
                                            backgroundColor: appColor.background,
                                            height: 50,
                                            width: 160,
                                            textAlign: "start",
                                            border: `0.5px solid ${appColor.bgDark}`,
                                            '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                            {
                                                color: appColor.bgDark,
                                            },
                                            '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                                color: appColor.bgDark,
                                            },
                                        }}
                                        value={draffActiveCard}
                                        onChange={handleChangeCard}
                                    >
                                        <MenuItem value={"DEMO"}>
                                            <Box
                                                display={"flex"}
                                                sx={{
                                                    gap: 1,
                                                    px: 2,
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    <div style={{ color: "orange", fontSize: 12 }}>
                                                        Demo Account
                                                    </div>
                                                    <div style={{ fontSize: 14 }}>
                                                        {`$ ${demoCreditCardData?.credit
                                                            ? currencyFormat(demoCreditCardData?.credit)
                                                            : 0}`}{" "}
                                                    </div>
                                                </Typography>
                                                <Cached
                                                    color={appColor.warning}
                                                    sx={{
                                                        cursor: "pointer",
                                                        display: `${cardClick == true ? "block" : "none"}`,
                                                        "&:hover": {
                                                            color: appColor.darkColor,
                                                        }
                                                    }}
                                                    onClick={() => resetDemoCard(demoCreditCardData["_id"])}
                                                    variant="outline-secondary"
                                                />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={"REAL"}>
                                            <Box
                                                display={"flex"}
                                                sx={{
                                                    gap: 1,
                                                    px: 2,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    <div style={{ color: "#04871e", fontSize: 12 }}>
                                                        Real Account
                                                    </div>
                                                    <div style={{ fontSize: 14 }}>
                                                        $
                                                        {realCreditCardData?.credit
                                                            ? currencyFormat(realCreditCardData?.credit)
                                                            : 0}{" "}
                                                    </div>
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={"BONUS"}>
                                            <Box
                                                display={"flex"}
                                                sx={{
                                                    gap: 1,
                                                    px: 2,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    <div style={{ color: "blue", fontSize: 14 }}>
                                                        Bonus Wallet
                                                    </div>
                                                    <div style={{ fontSize: 14 }}>
                                                        {`$ ${bonusWalletData?.credit
                                                            ? currencyFormat(bonusWalletData?.credit)
                                                            : 0}`}
                                                    </div>
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        {packageWalletData && <MenuItem value={"PACKAGE"}>
                                            <Box
                                                display={"flex"}
                                                sx={{
                                                    gap: 1,
                                                    px: 2,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    <div style={{ color: "#FFD700", fontSize: 14 }}>
                                                        Package (50%)
                                                    </div>
                                                    <div style={{ fontSize: 14, color: "#FFD700" }}>
                                                        {`$ ${packageWalletData?.credit ? currencyFormat(packageWalletData?.credit) : 0}`} / {`$ ${packageWalletData?.creditTotal ? currencyFormat(packageWalletData?.creditTotal) : 0}`}
                                                    </div>
                                                </Typography>
                                            </Box>
                                        </MenuItem>}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    sx={{
                                        fontStyle: "bold",
                                        "&:hover": {
                                            backgroundColor: "#273eea",
                                        },
                                        height: 48
                                    }}
                                >
                                    +Deposit & Withdraw
                                </Button>
                                <SettingsOutlined sx={{
                                    width: 32,
                                    height: 32,
                                }} />
                            </Box>
                        ) : (
                            <Box display={"flex"} sx={{
                                alignItems: "center",
                                gap: 2,
                            }}>
                                {isMobile ? <></> : <Box
                                    sx={{
                                        px: 0.5
                                    }}
                                >
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="binary-simple-select-label"
                                            id="binary-simple-select"
                                            sx={{
                                                backgroundColor: appColor.background,
                                                padding: 0,
                                                color: "#fff",
                                                height: 50,
                                                width: 110,
                                                border: `0.5px solid ${appColor.bgDark}`,
                                                textAlign: "start",
                                                '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                                {
                                                    color: appColor.white,
                                                },
                                                '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                                    color: appColor.white,
                                                },
                                            }}
                                            value={currentLanguage}
                                            onChange={handleChangeLanguage}
                                        >
                                            <MenuItem value={"english"}>
                                                <Box
                                                    display={"flex"}
                                                    sx={{
                                                        gap: 1,
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Typography variant="body1" fontWeight={"bold"}>
                                                        <img src={englishFlag} style={{ cursor: "pointer" }} width={30}
                                                            height={20} />&nbsp;&nbsp; EN
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                            <MenuItem value={"thailand"}>
                                                <Box
                                                    display={"flex"}
                                                    sx={{
                                                        gap: 1,
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Typography variant="body1" fontWeight={"bold"}>
                                                        <img src={thailandFlag} style={{ cursor: "pointer" }} width={30}
                                                            height={20} />&nbsp;&nbsp; TH
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                            <MenuItem value={"khr"}>
                                                <Box
                                                    display={"flex"}
                                                    sx={{
                                                        gap: 1,
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Typography variant="body1" fontWeight={"bold"}>
                                                        <img src={khrFlag} style={{ cursor: "pointer" }} width={30}
                                                            height={20} />&nbsp;&nbsp; KHR
                                                    </Typography>
                                                </Box>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>}


                                <FormControl fullWidth sx={{ pl: isMobile ? 2 : 0 }}>
                                    <Select
                                        labelId="binary-simple-select-label"
                                        id="binary-simple-select"
                                        onOpen={handleCardMenu}
                                        onClose={handleCardMenu}
                                        sx={{
                                            color: appColor.bgDark,
                                            backgroundColor: appColor.background,
                                            height: 50,
                                            width: 160,
                                            textAlign: "start",
                                            border: `0.5px solid ${appColor.bgDark}`,
                                            // backgroundColor: appColor.red, // Replace with your desired color
                                            // '& .MuiMenu-paper': { // Target the select input element
                                            //     backgroundColor: appColor.red, // Ensure consistent color
                                            // },
                                            'MuiMenu-paper': {
                                                backgroundColor: appColor.background,
                                            },
                                            '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                            {
                                                color: appColor.white,
                                            },
                                            '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                                color: appColor.white,
                                            },
                                        }}
                                        value={draffActiveCard}
                                        onChange={handleChangeCard}
                                    >

                                        <MenuItem
                                            value={"DEMO"}
                                        >
                                            <Box
                                                display={"flex"}
                                                sx={{
                                                    gap: 1,
                                                    px: 2,
                                                    width: "100%",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    <div style={{ color: appColor.textWhite, fontSize: 12 }}>
                                                        Demo Account
                                                    </div>
                                                    <div style={{ fontSize: 14, color: appColor.textWhite }}>
                                                        {`$ ${demoCreditCardData?.credit
                                                            ? currencyFormat(demoCreditCardData?.credit)
                                                            : 0}`}{" "}
                                                    </div>
                                                </Typography>
                                                <Cached
                                                    color={appColor.warning}
                                                    sx={{
                                                        cursor: "pointer",
                                                        display: `${cardClick == true ? "block" : "none"}`,
                                                        "&:hover": {
                                                            color: appColor.darkColor,
                                                        }
                                                    }}
                                                    onClick={() => resetDemoCard(demoCreditCardData["_id"])}
                                                    variant="outline-secondary"
                                                />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={"REAL"}>
                                            <Box
                                                display={"flex"}
                                                sx={{
                                                    gap: 1,
                                                    px: 2,
                                                    width: "100%",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    <div style={{ color: appColor.greenActive, fontSize: 12 }}>
                                                        Real Account
                                                    </div>
                                                    <div style={{ fontSize: 14, color: appColor.greenActive, }}>
                                                        {`$  ${realCreditCardData?.credit
                                                            ? currencyFormat(realCreditCardData?.credit)
                                                            : 0}`}
                                                    </div>
                                                </Typography>
                                                <MultipleStop
                                                    color={appColor.warning}
                                                    sx={{
                                                        cursor: "pointer",
                                                        color: appColor.greenActive,
                                                        display: `${cardClick == true ? "block" : "none"}`,
                                                        "&:hover": {
                                                            color: appColor.darkColor,
                                                        }
                                                    }}
                                                    variant="outline-secondary"
                                                    onClick={handlePopupTransfer}
                                                />
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={"BONUS"}>
                                            <Box
                                                display={"flex"}
                                                sx={{
                                                    gap: 1,
                                                    px: 2,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    <div style={{ color: "#0B60B0", fontSize: 14 }}>
                                                        Bonus Wallet
                                                    </div>
                                                    <div style={{ fontSize: 14, color: "#40A2E3" }}>
                                                        {`$ ${bonusWalletData?.credit
                                                            ? currencyFormat(bonusWalletData?.credit)
                                                            : 0}`}
                                                    </div>
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                        {packageWalletData && <MenuItem value={"PACKAGE"}>
                                            <Box
                                                display={"flex"}
                                                sx={{
                                                    gap: 1,
                                                    px: 2,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography variant="body1" fontWeight={"bold"}>
                                                    <div style={{ color: "#FFD700", fontSize: 14 }}>
                                                        Package (50%)
                                                    </div>
                                                    <div style={{ fontSize: 14, color: "#FFD700" }}>
                                                        {`$ ${packageWalletData?.credit ? currencyFormat(packageWalletData?.credit) : 0}`} / {`$ ${packageWalletData?.creditTotal ? currencyFormat(packageWalletData?.creditTotal) : 0}`}
                                                    </div>
                                                </Typography>
                                            </Box>
                                        </MenuItem>}
                                    </Select>
                                </FormControl>
                                {isMobile ?
                                    <Box
                                        sx={{
                                            border: `0.1px solid ${appColor.textGrey}`,
                                            borderRadius: 1,
                                            p: 0.5,
                                            backgroundColor: appColor.yellow,
                                        }}
                                    >
                                        <WalletOutlined
                                            onClick={() => routeNavigate("/transection-list")}
                                            sx={{
                                                color: appColor.background,
                                                height: 20,
                                                width: 20,
                                            }} />
                                    </Box>
                                    :
                                    <>
                                        {/* <Button
                                            onClick={handlePopupTransfer}
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#0C2D57",
                                                fontStyle: "bold",
                                                "&:hover": {
                                                    backgroundColor: "#0C2D57",
                                                },
                                                height: 48,
                                                width: 150,
                                            }}
                                        >
                                            Transfer
                                        </Button> */}
                                        <Button
                                            // onClick={() => routeNavigate("/select-bank-option")}
                                            onClick={() => routeNavigate("/transection-list")}
                                            // onClick={() => routeNavigate("/transection-list")}
                                            variant="contained"
                                            sx={{
                                                backgroundColor: "#f9de5d",
                                                fontStyle: "bold",
                                                "&:hover": {
                                                    backgroundColor: "#f9de5d",
                                                },
                                                height: 48,
                                                width: 250,
                                                color: "#000000"
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faWallet} style={{ paddingRight: 5 }} /> Deposit
                                        </Button>
                                    </>
                                }
                                <OverlayTrigger placement="bottom" overlay={tooltipVolume}>
                                    {notificationIsOn == true ?
                                        <VolumeUpTwoTone
                                            sx={{
                                                width: isMobile ? 20 : 36,
                                                height: isMobile ? 20 : 36,
                                                cursor: "pointer",
                                                "&:hover": {
                                                    color: appColor.gray,
                                                }
                                            }}
                                            onClick={() => setNotificationStatus()}
                                        /> : <VolumeOffOutlined
                                            sx={{
                                                width: isMobile ? 20 : 36,
                                                height: isMobile ? 20 : 36,
                                                cursor: "pointer",
                                                "&:hover": {
                                                    color: appColor.gray,
                                                }
                                            }}
                                            onClick={() => setNotificationStatus()}
                                        />}
                                </OverlayTrigger>
                                <NotificationsOutlined
                                    onClick={() => routeNavigate("/notificaiton")}
                                    sx={{
                                        width: isMobile ? 20 : 36,
                                        height: isMobile ? 20 : 36,
                                        cursor: "pointer"
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display="flex">
                    {/* START RIGHT SIDE */}
                    {isMobile ? <></> :
                        <Paper
                            elevation={0}
                            sx={{
                                backgroundColor: appColor.background,
                                color: "#fff",
                                // paddingY: 3,
                            }}
                        >
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                sx={{
                                    p: 2,
                                    justifyItems: "center",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                                height={`calc(100%)`}
                            >
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    sx={{
                                        gap: 2,
                                    }}
                                >
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            cursor: "pointer",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => routeNavigate("/signal/BTCOTCUSDT")}
                                    >
                                        <LeaderboardOutlined style={{ fontSize: 30 }} />
                                        <p style={{ fontSize: 12 }}>{t("trading")}</p>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            cursor: "pointer",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => routeNavigate("/trade-histories")}
                                    >
                                        <History style={{ fontSize: 30 }} />
                                        <p style={{ fontSize: 12 }}>{t("history")}</p>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            cursor: "pointer",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                        // onClick={() => routeNavigate("/transection-list")}
                                        onClick={() => routeNavigate("/transection-list")}
                                    >
                                        <WalletOutlined style={{ fontSize: 30 }} />
                                        <p style={{ fontSize: 12 }}>{t("wallet")}</p>
                                    </Box>

                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            cursor: "pointer",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => routeNavigate("/p2p-advertisement")}
                                    >
                                        <RiP2PFill size={30} />{" "}
                                        {/* <FontAwesomeIcon
                                            icon={faExchangeAlt}
                                            style={{ fontSize: 20, color: "#fff" }}
                                        /> */}
                                        <p style={{ fontSize: 12 }}>{t("P2P")}</p>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            cursor: "pointer",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => routeNavigate(userData?.role === "ADMIN" ? "/admin-vip-page" : "/vip-page")}
                                    >
                                        <FontAwesomeIcon
                                            icon={faRankingStar}
                                            style={{ fontSize: 20, color: "#fff" }}
                                        />
                                        <p style={{ fontSize: 12 }}>{t("VIP")}</p>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        flexDirection={"column"}
                                        sx={{
                                            cursor: "pointer",
                                            justifyItems: "center",
                                            alignItems: "center",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                        onClick={() => setShowProfile(true)}
                                    >
                                        <PersonOutline style={{ fontSize: 30 }} />
                                        <p style={{ fontSize: 12 }}>{t("profile")}</p>
                                    </Box>
                                    {userData?.role === "ADMIN" ? (
                                        <>
                                            <Box
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => routeNavigate("/cost-page")}
                                            >
                                                <AttachMoney style={{ fontSize: 30 }} />
                                                <p style={{ fontSize: 12 }}>{t("capital-cost")}</p>
                                            </Box>
                                            <Box
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => routeNavigate("/profit-page")}
                                            >
                                                <Percent style={{ fontSize: 30 }} />
                                                <p style={{ fontSize: 12 }}>Profit</p>
                                            </Box>
                                        </>
                                    ) : (
                                        ""
                                    )}

                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    sx={{
                                        cursor: "pointer",
                                        justifyItems: "center",
                                        alignItems: "center",
                                        alignContent: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={handleModelLogout}
                                >
                                    <LogoutOutlined style={{ fontSize: 30 }} />
                                    <p style={{ fontSize: 12 }}>Logout </p>
                                </Box>
                            </Box>
                        </Paper>
                    }
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{
                            width: "100%",
                            alignContent: "start",
                            justifyContent: "start",
                            alignItems: "start",
                            // px: 1,
                            // py: 0,
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            sx={{
                                gap: 0.5,
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: loading ? appColor.background : appColor.bgDark,

                            }}
                        >
                            <div style={{ width: 380, display: "flex" }}>
                                <Select
                                    key={binary}
                                    labelId="binary-simple-select-label"
                                    id="binary-simple-select"
                                    sx={{
                                        color: "#FFF",
                                        backgroundColor: appColor.background,
                                        // backgroundColor: "red",
                                        height: 40,
                                        padding: 0,
                                        margin: 0,
                                        width: isMobile ? 180 : 250,
                                        border: `0.5px solid ${appColor.bgDark}`,
                                        '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                                        {
                                            color: appColor.white,
                                        },
                                        '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                                            color: appColor.white,
                                        },
                                    }}
                                    value={binary}
                                    onChange={handleChange}
                                    defaultValue={binary}
                                >
                                    {profits &&
                                        profits.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={item?.binary}
                                                    style={{ cursor: item.status == "OPENING" ? "pointer" : "not-allowed" }}
                                                    onClick={() => {
                                                        if (item.status == "OPENING") {
                                                            routeNavigate(`/signal/${item?.binary}`);
                                                            setBinary(item?.binary);
                                                        }
                                                    }}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        sx={{
                                                            gap: 1,
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <img src={item?.logo} style={{ width: 24, height: 24, borderRadius: 24, opacity: item.status == "OPENING" ? 1 : 0.5 }} />
                                                        <Typography variant={isMobile ? "caption" : "body2"} fontWeight={"bold"} style={{ opacity: item.status == "OPENING" ? 1 : 0.5 }}>
                                                            <span>{item?.displayName}</span>{" "}
                                                            {/* {isMobile ? "" : item?.displayName} */}
                                                            <span style={{ color: "#F6931A" }}>
                                                                {item?.percent} %
                                                            </span>{" "}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                                {/* <div style={{ width: 20 }} /> */}
                            </div>
                            <div style={{ display: "flex" }}>
                                {!isMobile && <div>
                                    {/* {(userData?.vipStatus == "VERIFIED" && !userData?.hasVIPBonus) ? <div /> : <img
                                        onClick={() => {
                                            if (!userData?.vipUser || userData?.vipStatus != "VERIFIED") {
                                                routeNavigate("/vip-page");
                                            } else {
                                                if (userData?.vipStatus == "VERIFIED" && userData?.hasVIPBonus) {
                                                    onReceiveBonus();
                                                }
                                            }
                                        }}
                                        src="/bonus-small-size.png"
                                        style={{ width: 65, height: 60, cursor: "pointer" }}
                                    />} */}
                                    <div className='customImageBackgroundSmail' onClick={() => setShowTransferToBonus(true)} style={{ backgroundImage: `url(${bonusBackgroundImage})`, width: 150, paddingLeft: 60, borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div>
                                                <div style={{ fontSize: 9 }}><b>Get Bonus 50%</b></div>
                                                <div style={{ fontSize: 7 }}>On your deposit</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {/* <div style={{ width: 50 }} /> */}
                                {isMobile && <div>
                                    {/* {(userData?.vipStatus == "VERIFIED" && !userData?.hasVIPBonus) ? <div /> :
                                        <img
                                            onClick={() => {
                                                if (!userData?.vipUser || userData?.vipStatus != "VERIFIED") {
                                                    routeNavigate("/vip-page");
                                                } else {
                                                    if (userData?.vipStatus == "VERIFIED" && userData?.hasVIPBonus) {
                                                        onReceiveBonus();
                                                    }
                                                }
                                            }}
                                            src="/bonus-small-size.png"
                                            style={{ width: 43, height: 41, cursor: "pointer" }}
                                        />
                                    } */}
                                    <div className='customImageBackgroundSmail' onClick={() => setShowTransferToBonus(true)} style={{ backgroundImage: `url(${bonusBackgroundImage})` }}>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div>
                                                <div style={{ fontSize: 9 }}><b>Get Bonus 50%</b></div>
                                                <div style={{ fontSize: 7 }}>On your deposit</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <IconButton
                                    onClick={updateCandleStyle}
                                    size="small" aria-label="show 4 new mails" color="inherit">
                                    <Badge color="error">
                                        {isCandle ? <ShowChart
                                            sx={{
                                                width: 24,
                                                cursor: "pointer"
                                            }}
                                        /> : <CandlestickChart
                                            sx={{
                                                width: 24,
                                                cursor: "pointer"
                                            }}
                                        />}
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        toggleDrawer(isMobile ? "bottom" : "right");
                                    }}
                                    size="small" aria-label="show 4 new mails" color="inherit">
                                    <Badge color="error">
                                        <DragIndicatorOutlined
                                            sx={{
                                                width: 24,
                                                cursor: "pointer"
                                            }}
                                        />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        if (creditHistoryData?.length > 0) {
                                            toggleDrawer("right")
                                            setIsDrawerHistory(!isDrawerHistory)
                                        }
                                    }}
                                    size="small" aria-label="show 4 new mails" color="inherit">
                                    <Badge badgeContent={creditHistoryData?.length} color="error">
                                        <SwapHoriz sx={{
                                            width: 24,
                                        }} />
                                    </Badge>
                                </IconButton>
                            </div>
                        </Box>
                        <Box sx={{
                            width: "100%",
                            zIndex: 0,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            backgroundColor: appColor.bgDark,
                        }}>
                            {loading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
                                <LoadingPage />
                            </div>
                                :
                                <Layout
                                    height={`${device == "mobile" ? window.innerHeight - 300 : window.innerHeight - 140}px`}
                                >
                                    <div
                                        id="update-k-line"
                                        className="k-line-chart"
                                        style={{
                                            grid: {
                                                show: false,
                                                horizontal: {
                                                    show: true,
                                                    size: 1,
                                                    color: "#FFD700",
                                                    style: "dashed",
                                                    dashedValue: [2, 2],
                                                },
                                                vertical: {
                                                    show: true,
                                                    size: 1,
                                                    color: "#FFD700",
                                                    style: "dashed",
                                                    dashedValue: [2, 2],
                                                },
                                            },
                                        }}
                                    />
                                </Layout>
                            }
                            {device == "mobile" ? <Box
                                display={"flex"}
                                flexDirection={"column"}
                                height={`180px`}
                                sx={{
                                    justifyItems: "center",
                                    alignItems: "center",
                                    gap: 0.2,
                                    pb: 1,
                                }}
                            >
                                <div style={{ border: "1px dashed #e6e6e6", borderRadius: 4, width: "95%" }}>
                                    <Box
                                        display={"flex"}
                                        sx={{
                                            gap: 1,
                                            p: 0,
                                            justifyContent: "center",
                                            justifyItems: "center",
                                            alignContent: "center",
                                            textAlign: "center",
                                            display: "flex",
                                            height: 35,
                                            paddingTop: "3px"
                                        }}
                                    >
                                        <Typography variant="body1" style={{ paddingTop: 6, color: "#b6b6b6", fontSize: 14 }}>{t("profit")}:</Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: appColor.green,
                                                fontSize: 22
                                            }}
                                        >
                                            +{profitPercent}%
                                        </Typography>
                                        <Typography variant="body1" style={{ paddingTop: 6, color: "#b6b6b6", fontSize: 14 }}>{t("payout")}:</Typography>
                                        <Typography variant="body1" style={{ color: `${isProfit ? appColor.green : "#e34828"}`, fontSize: 22 }}>{profit}</Typography>
                                    </Box>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 6, paddingBottom: 4, justifyContent: "space-between", paddingLeft: 10, paddingRight: 10 }}>
                                    <div style={{ width: "40%", paddingRight: 5 }}>
                                        <div style={{ width: "100%", height: 35, border: "1px solid white", borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center", paddingTop: 4 }}>
                                            <div style={{ fontSize: 22 }}>{`00:${countdownUpdate < 10 ? `0${countdownUpdate}` : countdownUpdate}:00`}</div>
                                        </div>
                                    </div>
                                    <div style={{ width: "60%", paddingLeft: 5 }}>
                                        <Box
                                            display="flex"
                                            flexDirection={"column"}
                                            sx={{
                                                justifyContent: "center",
                                                justifyItems: "center",
                                                alignContent: "center",
                                                gap: 0,
                                            }}
                                        >
                                            <Box
                                                display={"flex"}
                                                flexDirection={"row"}
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    // gap: 1
                                                }}
                                            >
                                                {/* <Avatar
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                cursor: "pointer",
                                                backgroundColor: appColor.red,
                                                "&:hover": {
                                                    backgroundColor: appColor.redActive,
                                                },
                                            }}
                                            onClick={() => {
                                                if (tradeMoney > 1) setTradeMoney(tradeMoney - 1);
                                            }}
                                        >
                                            <Remove />
                                            </Avatar> */}
                                                <div
                                                    style={{
                                                        width: 35,
                                                        height: 35,
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderTopLeftRadius: 4,
                                                        borderBottomLeftRadius: 4,
                                                        backgroundColor: "red",
                                                        margin: "0px !important",
                                                        "&:hover": { backgroundColor: appColor.redActive }
                                                    }}
                                                    onClick={() => {
                                                        if (tradeMoney > 1) setTradeMoney(tradeMoney - 1);
                                                    }}
                                                >
                                                    <Remove />
                                                </div>
                                                <Box
                                                    display={"flex"}
                                                    sx={{
                                                        justifyContent: "space-between",
                                                        justifyItems: "center",
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        backgroundColor: appColor.background,
                                                        height: 35,
                                                        // borderRadius: "4px",
                                                        margin: "0px !important",
                                                    }}
                                                >
                                                    <TextField
                                                        id="outlined-basic"
                                                        variant="standard"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                        inputProps={{
                                                            inputMode: 'decimal',
                                                            min: 0,
                                                            style: {
                                                                textAlign: "center",
                                                                color: "#FFFFFF ",
                                                                fontWeight: "bold",
                                                                padding: 0,
                                                                margin: 0,
                                                            },
                                                        }}
                                                        value={tradeMoney}
                                                        onChange={(event) => onchangeTradeMoney(event)}
                                                    />
                                                </Box>
                                                <div
                                                    style={{
                                                        width: 35,
                                                        height: 35,
                                                        cursor: "pointer",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        borderTopRightRadius: 4,
                                                        borderBottomRightRadius: 4,
                                                        backgroundColor: appColor.green,
                                                        margin: "0px !important",
                                                        "&:hover": { backgroundColor: appColor.greenActive }
                                                    }}
                                                    onClick={() => {
                                                        switch (draffActiveCard) {
                                                            case "REAL":
                                                                // if (tradeMoney < realCreditCardData?.credit) setTradeMoney(tradeMoney + 1);
                                                                if (tradeMoney < _maximumTradeAmount && tradeMoney < realCreditCardData?.credit) setTradeMoney(tradeMoney + 1);
                                                                break;

                                                            case "DEMO":
                                                                // if (tradeMoney < demoCreditCardData?.credit) setTradeMoney(tradeMoney + 1);
                                                                if (tradeMoney < _maximumTradeAmount && tradeMoney < demoCreditCardData?.credit) setTradeMoney(tradeMoney + 1);
                                                                break;

                                                            case "USDT":
                                                                // if (tradeMoney < usdtWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                                if (tradeMoney < _maximumTradeAmount && tradeMoney < usdtWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                                break;

                                                            case "BONUS":
                                                                // if (tradeMoney < usdtWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                                if (tradeMoney < _maximumTradeAmount && tradeMoney < bonusWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                                break;

                                                            case "PACKAGE":
                                                                // if (tradeMoney < usdtWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                                if (tradeMoney < _maximumTradeAmount && tradeMoney < packageWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                                break;

                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                >
                                                    <Add />
                                                </div>
                                            </Box>
                                        </Box>
                                        {/* OPTION TRADE */}
                                    </div>
                                </div>
                                <div style={{ display: "flex", width: "100%", justifyContent: "space-between", paddingLeft: 10, paddingRight: 10 }}>
                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.background,
                                            width: "20%",
                                            height: "35px",
                                            margin: "2px !important",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.background,
                                            },
                                        }}
                                        onClick={() => {
                                            // setTradeMoney(5);
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // setTradeMoney(realCreditCardData?.credit ?? 0)
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (realCreditCardData?.credit > 5) {
                                                        setTradeMoney(5);
                                                    } else {
                                                        setTradeMoney(realCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "DEMO":
                                                    // setTradeMoney(demoCreditCardData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (demoCreditCardData?.credit > 5) {
                                                        setTradeMoney(5)
                                                    } else {
                                                        setTradeMoney(demoCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "USDT":
                                                    // setTradeMoney(usdtWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (usdtWalletData?.credit > 5) {
                                                        setTradeMoney(5)
                                                    } else {
                                                        setTradeMoney(usdtWalletData?.credit)
                                                    }
                                                    break;

                                                case "BONUS":
                                                    // setTradeMoney(bonusWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (bonusWalletData?.credit > 5) {
                                                        setTradeMoney(5)
                                                    } else {
                                                        setTradeMoney(bonusWalletData?.credit)
                                                    }
                                                    break;

                                                case "PACKAGE":
                                                    // setTradeMoney(packageWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (packageWalletData?.credit > 5) {
                                                        setTradeMoney(5)
                                                    } else {
                                                        setTradeMoney(packageWalletData?.credit)
                                                    }
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        {" "}
                                        $5{" "}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.background,
                                            width: "20%",
                                            height: "35px",
                                            margin: "2px !important",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.background,
                                            },
                                        }}
                                        onClick={() => {
                                            // setTradeMoney(10);
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // setTradeMoney(realCreditCardData?.credit ?? 0)
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (realCreditCardData?.credit > 10) {
                                                        setTradeMoney(10);
                                                    } else {
                                                        setTradeMoney(realCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "DEMO":
                                                    // setTradeMoney(demoCreditCardData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (demoCreditCardData?.credit > 10) {
                                                        setTradeMoney(10)
                                                    } else {
                                                        setTradeMoney(demoCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "USDT":
                                                    // setTradeMoney(usdtWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (usdtWalletData?.credit > 10) {
                                                        setTradeMoney(10)
                                                    } else {
                                                        setTradeMoney(usdtWalletData?.credit)
                                                    }
                                                    break;

                                                case "BONUS":
                                                    // setTradeMoney(bonusWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (bonusWalletData?.credit > 10) {
                                                        setTradeMoney(10)
                                                    } else {
                                                        setTradeMoney(bonusWalletData?.credit)
                                                    }
                                                    break;

                                                case "PACKAGE":
                                                    // setTradeMoney(packageWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (packageWalletData?.credit > 10) {
                                                        setTradeMoney(10)
                                                    } else {
                                                        setTradeMoney(packageWalletData?.credit)
                                                    }
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        {" "}
                                        $10{" "}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.background,
                                            width: "20%",
                                            height: "35px",
                                            margin: "2px !important",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.background,
                                            },
                                        }}
                                        onClick={() => {
                                            // setTradeMoney(50);
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // setTradeMoney(realCreditCardData?.credit ?? 0)
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (realCreditCardData?.credit > 50) {
                                                        setTradeMoney(50);
                                                    } else {
                                                        setTradeMoney(realCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "DEMO":
                                                    // setTradeMoney(demoCreditCardData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (demoCreditCardData?.credit > 50) {
                                                        setTradeMoney(50)
                                                    } else {
                                                        setTradeMoney(demoCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "USDT":
                                                    // setTradeMoney(usdtWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (usdtWalletData?.credit > 50) {
                                                        setTradeMoney(50)
                                                    } else {
                                                        setTradeMoney(usdtWalletData?.credit)
                                                    }
                                                    break;

                                                case "BONUS":
                                                    // setTradeMoney(bonusWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (bonusWalletData?.credit > 50) {
                                                        setTradeMoney(50)
                                                    } else {
                                                        setTradeMoney(bonusWalletData?.credit)
                                                    }
                                                    break;

                                                case "PACKAGE":
                                                    // setTradeMoney(packageWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (packageWalletData?.credit > 50) {
                                                        setTradeMoney(50)
                                                    } else {
                                                        setTradeMoney(packageWalletData?.credit)
                                                    }
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        {" "}
                                        $50{" "}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.background,
                                            width: "20%",
                                            height: "35px",
                                            margin: "2px !important",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.background,
                                            },
                                        }}
                                        onClick={() => {
                                            setTradeMoney(100);
                                        }}
                                    >
                                        {" "}
                                        $100{" "}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.background,
                                            width: "20%",
                                            height: "35px",
                                            margin: "2px !important",
                                            cursor: "pointer",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.background,
                                            },
                                        }}
                                        onClick={() => {
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // setTradeMoney(realCreditCardData?.credit ?? 0)
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (realCreditCardData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(realCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "DEMO":
                                                    // setTradeMoney(demoCreditCardData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (demoCreditCardData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(demoCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "USDT":
                                                    // setTradeMoney(usdtWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (usdtWalletData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(usdtWalletData?.credit)
                                                    }
                                                    break;

                                                case "BONUS":
                                                    // setTradeMoney(bonusWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (bonusWalletData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(bonusWalletData?.credit)
                                                    }
                                                    break;

                                                case "PACKAGE":
                                                    // setTradeMoney(packageWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (packageWalletData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(packageWalletData?.credit)
                                                    }
                                                    break;

                                                default:
                                                    break;
                                            }

                                        }}
                                    >
                                        {" "}
                                        All{" "}
                                    </Typography>
                                </div>
                                {/* {demoCreditCardData?.credit} */}
                                {enableTrading ? (
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        sx={{
                                            gap: 1,
                                            width: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            paddingLeft: "10px",
                                            paddingRight: "10px"
                                        }}
                                    >
                                        <Button
                                            variant={"container"}
                                            style={{
                                                color: appColor.textRedActive,
                                                fontWeight: "bold",
                                                fontSize: isMobile ? "20px" : "30px"
                                            }}
                                            sx={{
                                                height: isMobile ? 55 : 50,
                                                width: "100%",
                                                color: "white",
                                                borderRadius: "6px !important",
                                                padding: "0px !important",
                                                backgroundColor: `${enableTrading ? appColor.red : appColor.background
                                                    }`,
                                                "&:hover": {
                                                    backgroundColor: `${enableTrading ? appColor.redActive : appColor.background
                                                        }`,
                                                },
                                            }}
                                            onClick={
                                                enableTrading || !isTradeLoading
                                                    ? () => {
                                                        if (balanceIsEnough) {
                                                            onCreateBuyAndSell("SELL");
                                                        } else {
                                                            balanceIsNotEnoughSound();
                                                            toastAlert(`${t("balance-is-not-enough")} `, "error");
                                                        }
                                                    }
                                                    : () => {
                                                        console.log("counting...");
                                                    }
                                            }
                                        >
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "10px !important", alignItems: "end", width: "100%", height: 55 }}>
                                                <img src="/down-icon.png" style={{ height: 50, opacity: 0.4 }} />
                                                <div style={{ paddingRight: 30, paddingBottom: 0, color: appColor.bgGrey }}>{isTradeLoading ? <Spinner animation="border" role="status"></Spinner> : "Down"}</div>
                                            </div>
                                        </Button>
                                        <Button
                                            variant={"container"}
                                            style={{
                                                color: appColor.textGreenActive,
                                                fontWeight: "bold",
                                                fontSize: isMobile ? "20px" : "30px"
                                            }}
                                            sx={{
                                                height: isMobile ? 55 : 50,
                                                width: "100%",
                                                color: "white",
                                                borderRadius: "6px !important",
                                                padding: "0px !important",
                                                backgroundColor: `${enableTrading ? appColor.green : appColor.background
                                                    }`,
                                                "&:hover": {
                                                    backgroundColor: `${enableTrading ? appColor.greenActive : appColor.background
                                                        }`,
                                                },
                                            }}
                                            onClick={
                                                enableTrading || !isTradeLoading
                                                    ? () => {
                                                        if (balanceIsEnough) {
                                                            onCreateBuyAndSell("BUY");
                                                        } else {
                                                            balanceIsNotEnoughSound();
                                                            toastAlert(`${t("balance-is-not-enough")} `, "error");
                                                        }

                                                    }
                                                    : () => {
                                                        console.log("counting...");
                                                    }
                                            }
                                        >
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "10px !important", alignItems: "end", width: "100%", height: 55 }}>
                                                <img src="/up-icon.png" style={{ height: 50, opacity: 0.4 }} />
                                                <div style={{ paddingRight: 30, paddingBottom: 0, color: appColor.bgGrey }}>{isTradeLoading ? <Spinner animation="border" role="status"></Spinner> : "Up"}</div>
                                            </div>
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box
                                        display={"flex"}
                                        flexDirection={"row"}
                                        sx={{
                                            gap: 1,
                                            width: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            fontSize: isMobile ? "20px" : "30px",
                                            paddingLeft: "10px",
                                            paddingRight: "10px"
                                        }}
                                    >
                                        <Button
                                            variant={"container"}
                                            style={{
                                                color: appColor.primary,
                                                fontWeight: "bold",
                                                fontSize: isMobile ? "20px" : "30px"
                                            }}
                                            sx={{
                                                height: isMobile ? 55 : 50,
                                                width: "100%",
                                                color: "white",
                                                borderRadius: "6px !important",
                                                padding: "0px !important",
                                                backgroundColor: appColor.background,
                                                "&:hover": {
                                                    backgroundColor: appColor.background,
                                                },
                                            }}

                                        >
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "10px !important", alignItems: "end", width: "100%", height: 55 }}>
                                                <img src="/down-icon.png" style={{ height: 50, opacity: 0.4 }} />
                                                <div style={{ paddingRight: 30, paddingBottom: 0, color: appColor.bgGrey }}>Down</div>
                                            </div>
                                        </Button>
                                        <Button
                                            variant={"container"}
                                            style={{
                                                color: appColor.primary,
                                                fontWeight: "bold",
                                                fontSize: isMobile ? "20px" : "30px"
                                            }}
                                            sx={{
                                                height: isMobile ? 55 : 50,
                                                width: "100%",
                                                color: "white",
                                                borderRadius: "6px !important",
                                                padding: "0px !important",
                                                backgroundColor: appColor.background,
                                                "&:hover": {
                                                    backgroundColor: appColor.background,
                                                },
                                            }}

                                        >
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "10px !important", alignItems: "end", width: "100%", height: 55 }}>
                                                <img src="/up-icon.png" style={{ height: 50, opacity: 0.4 }} />
                                                <div style={{ paddingRight: 30, paddingBottom: 0, color: appColor.bgGrey }}>Up</div>
                                            </div>
                                        </Button>

                                    </Box>
                                )}
                            </Box> : <></>}
                        </Box>
                    </Box>

                    {/* START RIGHT SIDE */}
                    {isMobile ? <></> : <Paper
                        elevation={0}
                        sx={{
                            backgroundColor: appColor.background,
                            width: "300px",
                            color: "#fff",
                            height: "100vd",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            sx={{
                                justifyItems: "center",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            <br />
                            <div style={{ width: "84%", height: 80, border: "1px solid white", borderRadius: 5 }}>
                                <div style={{ fontSize: 36 }}>{`00:${countdownUpdate < 10 ? `0${countdownUpdate}` : countdownUpdate}:00`}</div>
                                <div style={{ borderTop: "1px solid white", width: "100%" }} />
                            </div>
                            <div style={{ backgroundImage: "linear-gradient(to right, #f7020f, #f7b602, #02f727)", width: "84%", height: 6, borderRadius: 10 }}></div>
                            <Box
                                display="flex"
                                flexDirection={"column"}
                                sx={{
                                    justifyContent: "center",
                                    justifyItems: "center",
                                    alignContent: "center",
                                    gap: 1,
                                }}
                            >
                                <Box
                                    display={"flex"}
                                    sx={{
                                        justifyContent: "space-between",
                                        justifyItems: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        width: "200px",
                                        backgroundColor: appColor.background,
                                        px: 2,
                                        py: 1,
                                        borderRadius: "4px",
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            cursor: "pointer",
                                            backgroundColor: appColor.red,
                                            "&:hover": {
                                                backgroundColor: appColor.redActive,
                                            },
                                        }}
                                        onClick={() => {
                                            if (tradeMoney > 1) setTradeMoney(tradeMoney - 1);
                                        }}
                                    >
                                        <Remove />
                                    </Avatar>
                                    <TextField
                                        id="outlined-basic"
                                        variant="standard"
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        inputProps={{
                                            inputMode: 'decimal',
                                            min: 0,
                                            style: {
                                                textAlign: "center",
                                                color: "#FFFFFF ",
                                                fontWeight: "bold",
                                                fontSize: "20px",
                                            },
                                        }}
                                        value={tradeMoney}
                                        onChange={(event) => onchangeTradeMoney(event)}
                                    />
                                    <Avatar
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            cursor: "pointer",
                                            backgroundColor: appColor.green,
                                            "&:hover": {
                                                backgroundColor: appColor.greenActive,
                                            },
                                        }}
                                        onClick={() => {
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // if (tradeMoney < realCreditCardData?.credit) setTradeMoney(tradeMoney + 1);
                                                    if (tradeMoney < _maximumTradeAmount && tradeMoney < realCreditCardData?.credit) setTradeMoney(tradeMoney + 1);
                                                    break;
                                                case "DEMO":
                                                    // if (tradeMoney < demoCreditCardData?.credit) setTradeMoney(tradeMoney + 1);
                                                    if (tradeMoney < _maximumTradeAmount && tradeMoney < demoCreditCardData?.credit) setTradeMoney(tradeMoney + 1);
                                                    break;
                                                case "USDT":
                                                    // if (tradeMoney < usdtWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                    if (tradeMoney < _maximumTradeAmount && tradeMoney < usdtWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                    break;
                                                case "BONUS":
                                                    // if (tradeMoney < bonusWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                    if (tradeMoney < _maximumTradeAmount && tradeMoney < bonusWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                    break;
                                                case "PACKAGE":
                                                    // if (tradeMoney < packageWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                    if (tradeMoney < _maximumTradeAmount && tradeMoney < packageWalletData?.credit) setTradeMoney(tradeMoney + 1);
                                                    break;
                                                default:
                                                // do something
                                            }
                                        }}
                                    >
                                        <Add />
                                    </Avatar>
                                </Box>
                                <Box
                                    display={"flex"}
                                    sx={{
                                        alignItems: "center",
                                        // justifyContent: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.bgDark,
                                            px: 2,
                                            py: 1,
                                            cursor: "pointer",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.bgDark,
                                            },
                                        }}
                                        onClick={() => {
                                            // setTradeMoney(5);
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // setTradeMoney(realCreditCardData?.credit ?? 0)
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (realCreditCardData?.credit > 5) {
                                                        setTradeMoney(5);
                                                    } else {
                                                        setTradeMoney(realCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "DEMO":
                                                    // setTradeMoney(demoCreditCardData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (demoCreditCardData?.credit > 5) {
                                                        setTradeMoney(5)
                                                    } else {
                                                        setTradeMoney(demoCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "USDT":
                                                    // setTradeMoney(usdtWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (usdtWalletData?.credit > 5) {
                                                        setTradeMoney(5)
                                                    } else {
                                                        setTradeMoney(usdtWalletData?.credit)
                                                    }
                                                    break;

                                                case "BONUS":
                                                    // setTradeMoney(bonusWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (bonusWalletData?.credit > 5) {
                                                        setTradeMoney(5)
                                                    } else {
                                                        setTradeMoney(bonusWalletData?.credit)
                                                    }
                                                    break;

                                                case "PACKAGE":
                                                    // setTradeMoney(packageWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (packageWalletData?.credit > 5) {
                                                        setTradeMoney(5)
                                                    } else {
                                                        setTradeMoney(packageWalletData?.credit)
                                                    }
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        {" "}
                                        5{" "}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.bgDark,
                                            px: 2,
                                            py: 1,
                                            cursor: "pointer",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.bgDark,
                                            },
                                        }}
                                        onClick={() => {
                                            // setTradeMoney(10);
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // setTradeMoney(realCreditCardData?.credit ?? 0)
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (realCreditCardData?.credit > 10) {
                                                        setTradeMoney(10);
                                                    } else {
                                                        setTradeMoney(realCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "DEMO":
                                                    // setTradeMoney(demoCreditCardData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (demoCreditCardData?.credit > 10) {
                                                        setTradeMoney(10)
                                                    } else {
                                                        setTradeMoney(demoCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "USDT":
                                                    // setTradeMoney(usdtWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (usdtWalletData?.credit > 10) {
                                                        setTradeMoney(10)
                                                    } else {
                                                        setTradeMoney(usdtWalletData?.credit)
                                                    }
                                                    break;

                                                case "BONUS":
                                                    // setTradeMoney(bonusWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (bonusWalletData?.credit > 10) {
                                                        setTradeMoney(10)
                                                    } else {
                                                        setTradeMoney(bonusWalletData?.credit)
                                                    }
                                                    break;

                                                case "PACKAGE":
                                                    // setTradeMoney(packageWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (packageWalletData?.credit > 10) {
                                                        setTradeMoney(10)
                                                    } else {
                                                        setTradeMoney(packageWalletData?.credit)
                                                    }
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        {" "}
                                        10{" "}
                                    </Typography>

                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.bgDark,
                                            px: 2,
                                            py: 1,
                                            cursor: "pointer",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.bgDark,
                                            },
                                        }}
                                        onClick={() => {
                                            // setTradeMoney(50);
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // setTradeMoney(realCreditCardData?.credit ?? 0)
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (realCreditCardData?.credit > 50) {
                                                        setTradeMoney(50);
                                                    } else {
                                                        setTradeMoney(realCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "DEMO":
                                                    // setTradeMoney(demoCreditCardData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (demoCreditCardData?.credit > 50) {
                                                        setTradeMoney(50)
                                                    } else {
                                                        setTradeMoney(demoCreditCardData?.credit)
                                                    }
                                                    break;

                                                case "USDT":
                                                    // setTradeMoney(usdtWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (usdtWalletData?.credit > 50) {
                                                        setTradeMoney(50)
                                                    } else {
                                                        setTradeMoney(usdtWalletData?.credit)
                                                    }
                                                    break;

                                                case "BONUS":
                                                    // setTradeMoney(bonusWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (bonusWalletData?.credit > 50) {
                                                        setTradeMoney(50)
                                                    } else {
                                                        setTradeMoney(bonusWalletData?.credit)
                                                    }
                                                    break;

                                                case "PACKAGE":
                                                    // setTradeMoney(packageWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (packageWalletData?.credit > 50) {
                                                        setTradeMoney(50)
                                                    } else {
                                                        setTradeMoney(packageWalletData?.credit)
                                                    }
                                                    break;

                                                default:
                                                    break;
                                            }
                                        }}
                                    >
                                        {" "}
                                        50{" "}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            backgroundColor: appColor.bgDark,
                                            px: 2,
                                            py: 1,
                                            cursor: "pointer",
                                            borderRadius: "4px",
                                            "&:hover": {
                                                backgroundColor: appColor.bgDark,
                                            },
                                        }}
                                        onClick={() => {
                                            switch (draffActiveCard) {
                                                case "REAL":
                                                    // setTradeMoney(realCreditCardData?.credit ?? 0)
                                                    if (realCreditCardData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(realCreditCardData?.credit)
                                                    }

                                                    break;
                                                case "DEMO":
                                                    // setTradeMoney(demoCreditCardData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (demoCreditCardData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(demoCreditCardData?.credit)
                                                    }
                                                    break;
                                                case "USDT":
                                                    // setTradeMoney(usdtWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (usdtWalletData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(usdtWalletData?.credit)
                                                    }
                                                    break;
                                                case "BONUS":
                                                    // setTradeMoney(bonusWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (bonusWalletData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(bonusWalletData?.credit)
                                                    }
                                                    break;
                                                case "PACKAGE":
                                                    // setTradeMoney(packageWalletData?.credit ?? 0);
                                                    // setTradeMoney(_maximumTradeAmount)
                                                    if (packageWalletData?.credit > _maximumTradeAmount) {
                                                        setTradeMoney(_maximumTradeAmount)
                                                    } else {
                                                        setTradeMoney(packageWalletData?.credit)
                                                    }
                                                    break;
                                                default:
                                                // do something
                                            }
                                        }}
                                    >
                                        {" "}
                                        All{" "}
                                    </Typography>
                                </Box>
                            </Box>
                            <div>
                                <div>{t("payout")}</div>
                                <Box
                                    display={"flex"}
                                    sx={{
                                        gap: 1,
                                        pt: 2,
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            color: appColor.green,
                                        }}
                                    >
                                        +{profitPercent}%
                                    </Typography>
                                </Box>
                                <div>{t("profit")}</div>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        color: `${isProfit ? appColor.green : "#e34828"}`,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {profit}
                                </Typography>
                            </div>
                            {/* <Avatar
                                sx={{
                                    width: 120,
                                    height: 120,
                                    cursor: "pointer",
                                    backgroundColor: "transparent",
                                }}
                            >
                                <CircularProgressbar
                                    styles={buildStyles({
                                        pathColor: `${countdownUpdate <= 10 ? appColor.red : appColor.green}`,
                                        textColor: "#fff",
                                        trailColor: appColor.background,
                                        backgroundColor: appColor.background,
                                    })}
                                    maxValue={30}
                                    value={countdownUpdate}
                                    text={`${countdownUpdate} s`}
                                    counterClockwise
                                />
                            </Avatar> */}

                            {enableTrading ? (
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    sx={{
                                        gap: 2,
                                        width: "200px",
                                    }}
                                >
                                    <Button
                                        variant={"container"}
                                        style={{ color: appColor.textGreenActive, fontWeight: "bold", fontSize: "24px" }}
                                        sx={{
                                            height: 80,
                                            color: "white",
                                            borderRadius: "10px",
                                            padding: "0px !important",
                                            backgroundColor: `${enableTrading ? appColor.green : appColor.background
                                                }`,
                                            "&:hover": {
                                                backgroundColor: `${enableTrading ? appColor.greenActive : appColor.background
                                                    }`,
                                            },
                                        }}
                                        onClick={
                                            enableTrading || !isTradeLoading
                                                ? () => {
                                                    if (balanceIsEnough) {
                                                        onCreateBuyAndSell("BUY");
                                                    } else {
                                                        balanceIsNotEnoughSound();
                                                        toastAlert(`${t("balance-is-not-enough")} `, "error");
                                                    }

                                                }
                                                : () => {
                                                    console.log("counting...");
                                                }
                                        }
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "10px !important", alignItems: "end", width: "100%", height: 80 }}>
                                            <img src="/up-icon.png" style={{ height: 70, opacity: 0.4 }} />
                                            <div style={{ paddingRight: 10, paddingBottom: 10, color: appColor.bgGrey }}>{isTradeLoading ? <Spinner animation="border" role="status"></Spinner> : "Up"}</div>
                                        </div>
                                    </Button>
                                    <Button
                                        variant={"container"}
                                        style={{ color: appColor.textRedActive, fontWeight: "bold", fontSize: "24px" }}
                                        sx={{
                                            height: 80,
                                            color: "white",
                                            borderRadius: "10px",
                                            padding: "0px !important",
                                            backgroundColor: `${enableTrading ? appColor.red : appColor.background
                                                }`,
                                            "&:hover": {
                                                backgroundColor: `${enableTrading ? appColor.redActive : appColor.background
                                                    }`,
                                            },
                                        }}
                                        onClick={
                                            enableTrading || !isTradeLoading
                                                ? () => {
                                                    if (balanceIsEnough) {
                                                        onCreateBuyAndSell("SELL");
                                                    } else {
                                                        balanceIsNotEnoughSound();
                                                        toastAlert(`${t("balance-is-not-enough")} `, "error");
                                                    }
                                                }
                                                : () => {
                                                    console.log("counting...");
                                                }
                                        }
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "10px !important", alignItems: "end", width: "100%", height: 80 }}>
                                            <img src="/down-icon.png" style={{ height: 70, opacity: 0.4 }} />
                                            <div style={{ paddingRight: 10, paddingBottom: 10, color: appColor.bgGrey }}>{isTradeLoading ? <Spinner animation="border" role="status"></Spinner> : "Down"}</div>
                                        </div>
                                    </Button>
                                </Box>
                            ) : (
                                <Box
                                    display={"flex"}
                                    flexDirection={"column"}
                                    sx={{
                                        gap: 2,
                                        width: "200px",
                                    }}
                                >
                                    <Button
                                        variant={"container"}
                                        sx={{
                                            height: 80,
                                            borderRadius: "10px",
                                            color: appColor.bgDark, fontWeight: "bold", fontSize: "24px",
                                            backgroundColor: appColor.bgDark,
                                            padding: "0px !important",
                                            "&:hover": {
                                                backgroundColor: appColor.bgDark,
                                            },
                                        }}
                                        disabled
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "10px !important", alignItems: "end", width: "100%", height: 80 }}>
                                            <img src="/up-icon.png" style={{ height: 70, opacity: 0.4 }} />
                                            <div style={{ paddingRight: 10, paddingBottom: 10, color: appColor.bgGrey }}>Up</div>
                                        </div>
                                    </Button>
                                    <Button
                                        variant={"container"}
                                        sx={{
                                            height: 80,
                                            borderRadius: "10px",
                                            color: appColor.bgDark, fontWeight: "bold", fontSize: "24px",
                                            backgroundColor: appColor.bgDark,
                                            padding: "0px !important",
                                            "&:hover": {
                                                backgroundColor: appColor.bgDark,
                                            },
                                        }}
                                        disabled
                                    >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "10px !important", alignItems: "end", width: "100%", height: 80 }}>
                                            <img src="/down-icon.png" style={{ height: 70, opacity: 0.4 }} />
                                            <div style={{ paddingRight: 10, paddingBottom: 10, color: appColor.bgGrey }}>Down</div>
                                        </div>
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Paper>}
                    {/* END RIGHT SIDE */}
                </Box>
            </Box>
            {/* END RESPONESIVE */}

            <Modal Modal
                open={isTradeStatus}
                onClose={() => setisTradeStatus(false)
                }
            >
                <Box sx={{ ...styleTrade, width: 400 }}>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{
                            alignItems: "center",
                        }}
                    >
                        {/* <Done color="success" sx={{ width: 64, height: 64 }} /> */}
                        {isProfit ? (
                            <img src="/FinishIcon.gif" alt="" width={80} height={80} />
                        ) : (
                            <img src="/SadIcon.gif" alt="" width={80} height={80} />
                        )}
                        <Typography
                            variant="h5"
                            sx={{
                                color: "#FFFFFF",
                                fontWeight: "bold",
                            }}
                        >
                            {isProfit ? "Congraturations" : "Loss"}
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                color: `${isProfit ? "#f9de5d" : "#e34828"}`,
                                fontWeight: "bold",
                            }}
                        >
                            {profit}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={hasVIPBonusModal}
                onClose={() => setHasVIPBonusModal(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{
                    transform: "translate(-50%, -50%)",
                    width: "25vw",
                    height: "25vw",
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                    position: "absolute",
                    top: isMobile ? "25%" : "40%",
                    left: "50%",
                }}>
                    <img src="/success-receive.jpg" style={{ width: isMobile ? "70vw" : "25vw", height: isMobile ? "70vw" : "25vw" }} />
                </Box>
            </Modal>

            {/* START PROFILE MODAL */}
            <ProfileModal
                isEdit={isEdit}
                toEdit={toEdit}
                showProfile={showProfile}
                handleCloseProfile={handleCloseProfile}
                userData={userData}
                getUserData={getUserData}
            />
            {/* START PROFILE MODAL */}

            <TransferToBonus
                onCloseTransferPopup={() => setShowTransferToBonus(false)}
                isShowTransferPopup={showTransferToBonus}
                money={money ?? 0}
                setMoney={setMoney}
                currencyFormatKip={currencyFormat}
                onTransferCredit={onTransferCredit}
                isSaveLoading={isSaveLoading}
                credit={usdtWalletData?.credit ?? 0}
                userData={userData}
            />

            <Modal
                open={showModalLogou}
                onClose={handleModelLogout}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...MODAL_STYLE, width: 400 }}>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: "bold",
                            }}
                        >
                            {t("do-you-really-want-to-logout")}
                        </Typography>
                        <ExitToApp
                            color="warning"
                            sx={{
                                width: 100,
                                height: 100,
                            }}
                        />
                    </Box>

                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{
                            gap: 2,
                        }}
                    >
                        <br />
                        <Button
                            onClick={handleLogout}
                            style={{
                                borderRadius: 28,
                                color: "white",
                                minWidth: "170px",
                                height: 45,
                                borderColor: "#fff",
                                background: appColor.warning,
                                width: "100%",
                            }}
                        >
                            {t("confirm-logout")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={isShowPopupTransfer}
                onClose={handlePopupTransfer}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{
                    ...style, width: 600,
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                }}>
                    <Box
                        display={"flex"}
                        flexDirection={"row"}
                        sx={{
                            justifyContent: "space-between",
                            justifyItems: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: "bold",
                                fontFamily: currentLanguage == "lao" ? "Phetsarath_OT" : "Myriad_Pro"
                            }}
                        >
                            {t("transfer")}
                        </Typography>
                    </Box>
                    <CreditTransfer creditList={creditList} userID={userID} handlePopupTransfer={handlePopupTransfer} getCreditData={getCreditData} />
                </Box>
            </Modal>
        </Box>
    );
}

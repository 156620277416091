import React from 'react'
import { Modal, Typography, Box, Grid, ButtonGroup, Button, Avatar, AppBar, Menu, MenuItem, Container, useMediaQuery } from '@mui/material'
import { appColor } from '../../constants'

const TradeInfoComponent = ({ title, value, subValueLeft, subValueRight }) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            p: 1
        }}>
            <Typography id="parent-modal-description-all"
                sx={{
                    mt: 2,
                    color: appColor.textGrey
                }}>
                {title}
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    justifyContent: "center",
                    alignItems: "end",
                }}
            >
                <Typography id="parent-modal-description-time"
                    sx={{
                        mt: 2,
                        fontWeight: 'bold',
                        color: appColor.textGrey
                    }}>
                    {value}
                </Typography>

                {subValueLeft && (

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyItems: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                        }}
                    >
                        <Typography sx={{
                            color: appColor.textGreenActive,
                            fontSize: 16
                        }}>
                            {subValueLeft}
                        </Typography>
                        /
                        <Typography sx={{
                            color: appColor.textRedActive,
                            fontSize: 16
                        }}>
                            {subValueRight}
                        </Typography>
                    </Box>
                )}


            </Box>
        </Box>
    )
}

export default TradeInfoComponent
import React, { useState, useEffect } from "react";
import CustomNavbar from "../navbar/Navbar";
import BankAccount from "./bank";
import ExchangeRate from "./rate";
import P2PSetup from "./p2p-setup";
import { AiFillBank, AiOutlineDollar, AiOutlineUser, AiOutlineAreaChart } from "react-icons/ai";
import { RiP2PFill, RiPlug2Fill } from "react-icons/ri";
import { CampaignOutlined, CloudUpload } from "@mui/icons-material";
import AppPopup from "../../common/popup";
import { Box, Button, Typography } from "@mui/material";
import { appColor } from "../../constants";
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from "../../api";
import axios from "axios";
import { Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../common/loadingPage";

export default function SettingPage() {
  const { t } = useTranslation();
  const [popupData, setPopupData] = useState();
  const [popupImage, setPopupImage] = useState("");
  const [showExchangeRatePopup, setShowExchangeRatePopup] = useState(false);
  const [showP2PPopup, setShowP2PPopup] = useState(false);
  const [showBankAccountPopup, setShowBankAccountPopup] = useState(false);
  const [isShowCampaign, setIsShowCampaign] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const campaignHandle = () => {
    setIsShowCampaign(!isShowCampaign);
  };
  const routeNavigate = useNavigate();

  useEffect(() => {
    getPopup();
  }, [])

  const getPopup = async () => {
    try {
      setIsLoading(true)
      var config = {
        method: 'get',
        url: `${END_POINT_SEVER}/api/popup/?limit=1`,
        headers: {
          'Content-Type': 'application/json'
        },
      };
      const popupData = await axios(config)
      if (popupData?.data?.popup?.length > 0) {
        setPopupData(popupData?.data?.popup[0])
        setPopupImage(popupData?.data?.popup[0].image)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const requestPresignedUrlAndUploadImageToS3 = async (event) => {
    try {
      const fileData = event?.target?.files[0]
      if (fileData) {
        // TODO: Request Presigned Url
        var config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${END_POINT_SEVER}/api/file/pre-signed-url`,
          headers: { 'Content-Type': 'application/json' },
          data: JSON.stringify({ "fileName": fileData.name, "folder": "images" })
        };
        const presignedUrl = await axios(config)

        // TODO: Upload to S3
        if (presignedUrl?.data?.url) {
          let uploadfile = await axios({
            method: "PUT",
            url: presignedUrl?.data?.url,
            data: fileData,
            headers: {
              "Content-Type": " file/*; image/*",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
              "Access-Control-Allow-Headers":
                "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
            },
          })

          // TODO: Set image name to state
          if (uploadfile) {
            setPopupImage(fileData.name)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSave = async () => {
    try {
      var config = {
        method: popupData ? 'put' : 'post',
        url: `${END_POINT_SEVER}/api/popup/${popupData ? popupData["_id"] : ""}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          image: popupImage
        })
      };
      const createPopup = await axios(config)
      if (createPopup?.data) {
        getPopup()
        setIsShowCampaign(false)
        toast(t("corrected-successfully"), { type: "success" });
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
      <LoadingPage />
    </div>
      :
      <div
        className="color-body"
        style={{
          width: "100%",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CustomNavbar />
        <div style={{ height: 100 }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <div
            // onClick={() => setShowBankAccountPopup(true)}
            // bank-option
            onClick={() => setShowP2PPopup(true)}
            style={{
              width: 200,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(91, 97, 138, 0.5)",
              color: "#fff",
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <RiP2PFill size={20} />{" "}
            <h6
              style={{
                paddingLeft: 10,
              }}
            >
              {t("set-up-p2p")}
            </h6>
          </div>
          <div style={{ width: 20 }} />
          <div
            // onClick={() => setShowBankAccountPopup(true)}
            // bank-option
            onClick={() => routeNavigate("/admin-p2p-review")}
            style={{
              width: 200,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(91, 97, 138, 0.5)",
              color: "#fff",
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <RiPlug2Fill size={20} />{" "}
            <h6
              style={{
                paddingLeft: 10,
              }}
            >
              {t("p2p-review")}
            </h6>
          </div>
          <div style={{ width: 20 }} />
          <div
            // onClick={() => setShowBankAccountPopup(true)}
            // bank-option
            onClick={() => routeNavigate("/bank-option")}
            style={{
              width: 200,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(91, 97, 138, 0.5)",
              color: "#fff",
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <AiFillBank size={20} />{" "}
            <h6
              style={{
                paddingLeft: 10,
              }}
            >
              {t("set-up-bank-account")}
            </h6>
          </div>
          <div style={{ width: 20 }} />
          <div
            onClick={() => setShowExchangeRatePopup(true)}
            style={{
              width: 200,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(91, 97, 138, 0.5)",
              color: "#fff",
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <AiOutlineDollar size={20} />{" "}
            <h6
              style={{
                paddingLeft: 10,
              }}
            >
              {t("set-up-exchange-rate")}
            </h6>
          </div>
          <div style={{ width: 20 }} />
          <div
            onClick={campaignHandle}
            style={{
              width: 200,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(91, 97, 138, 0.5)",
              color: "#fff",
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <CampaignOutlined size={20} />{" "}
            <h6
              style={{
                paddingLeft: 10,
              }}
            >
              {t("manage-advertising")}
            </h6>
          </div>
          <div style={{ width: 20 }} />
          <div
            // onClick={() => setShowBankAccountPopup(true)}
            // bank-option
            onClick={() => routeNavigate("/users-management")}
            style={{
              width: 200,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(91, 97, 138, 0.5)",
              color: "#fff",
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <AiOutlineUser size={20} />{" "}
            <h6
              style={{
                paddingLeft: 10,
              }}
            >
              {t("USERS-MANAGEMENT")}
            </h6>
          </div>
          <div style={{ width: 20 }} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          }}
        >
          <div
            // onClick={() => setShowBankAccountPopup(true)}
            // bank-option
            onClick={() => routeNavigate("/valumn-management")}
            style={{
              width: 200,
              height: 60,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(91, 97, 138, 0.5)",
              color: "#fff",
              borderRadius: 8,
              cursor: "pointer",
            }}
          >
            <AiOutlineAreaChart size={20} />{" "}
            <h6
              style={{
                paddingLeft: 10,
              }}
            >
              {t("Valumn")}
            </h6>
          </div>
        </div>
        <AppPopup isShowPopup={isShowCampaign} handdlePopup={campaignHandle}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("upload-your-ad")}
          </Typography>
          <Image src={`${S3_BUCKET_END_POINT}/images/${popupImage}`} style={{ height: 400, width: 500, alignSelf: "center" }} />
          <Box height={8} />
          <Form.Control type="file" onChange={(event) => requestPresignedUrlAndUploadImageToS3(event)} />
          <Button
            onClick={() => onSave()}
            style={{
              borderRadius: 28,
              color: "white",
              minWidth: "170px",
              height: 45,
              borderColor: "#fff",
              background: appColor.green,
              width: "100%",
            }}
          >
            {t("save")}
          </Button>
        </AppPopup>
        {showBankAccountPopup && (
          <BankAccount
            onCloseBankPopup={setShowBankAccountPopup}
            isShowPopup={showBankAccountPopup}
          />
        )}
        {showExchangeRatePopup && (
          <ExchangeRate
            onCloseExchangeRatePopup={setShowExchangeRatePopup}
            isShowPopup={showExchangeRatePopup}
          />
        )}
        {showP2PPopup && (
          <P2PSetup
            closeShowP2PPopup={setShowP2PPopup}
            isShowPopup={showP2PPopup}
          />
        )}
      </div>
  );
}

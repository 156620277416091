import React, { useState, useEffect } from 'react'
import { faCircleCheck, faCircleXmark, faClock, faCoins, faDownload, faMoneyBillTransfer, faPlus, faRightLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomNavbar from '../navbar/Navbar'
import { Button, Table } from 'react-bootstrap'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { END_POINT_SEVER, S3_BUCKET_END_POINT } from '../../api'
import axios from 'axios'
import { USER_LOCAL_DATA, appColor, screenSize } from '../../constants'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { UPDATE_UNREAD_NOTIFICATIONS } from '../../apollo/crypto'
import { useMutation } from '@apollo/client'
import { useNavigate } from "react-router-dom";
import CreditTransfer from './creditTransfer'
import AppModalDialog from '../../common/appModalDialog'
import LoadingPage from '../../common/loadingPage'
import TransferToBonus from './transferToBonus'
import bonusBackgroundImage from '../../images/bonus3.png';
import bonusBackgroundImage4 from '../../images/bonus4.png';
import { useTheme } from '@emotion/react'
import { toast } from 'react-toastify'
import CreditTransferPackage from './creditTransferPackage'

export default function TransectionList() {
    const { t } = useTranslation();
    const [showTransferToBonus, setShowTransferToBonus] = useState(false)
    const [money, setMoney] = useState(0)
    const [userData, setUserData] = useState()
    const [realCreditCardData, setRealCreditCardData] = useState()
    const [usdtCreditCardData, setUsdtCreditCardData] = useState()
    const [bonusCreditCardData, setBonusCreditCardData] = useState()
    const [packageCreditCardData, setPackageCreditCardData] = useState()
    const [creditHistoryData, setCreditHistoryData] = useState([])
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [updateReadNotification] = useMutation(UPDATE_UNREAD_NOTIFICATIONS)
    const routeNavigate = useNavigate();
    const [creditList, setCreditList] = useState([]);
    const [isShowPopupTransfer, setIsShowPopupTransfer] = useState(false);
    const [isShowPopupTransferPackage, setIsShowPopupTransferPackage] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const toastAlert = (type, msg,) => {
        toast(msg, { type: type == "error" ? "error" : "success" });
    };

    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA)
                if (_localData) {
                    let parseData = JSON.parse(_localData)
                    if (parseData["user"] && parseData["user"]["_id"]) {
                        getUserData(parseData["user"]["_id"])
                        getCreditData(parseData["user"]["_id"])
                        getCreditHistoryData(parseData["user"]["_id"])

                        await updateReadNotification({
                            variables: {
                                receiveId: parseData?.user?.role === "ADMIN" ? "ADMIN" : parseData["user"]["_id"],
                            }
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        getLoalData()
    }, [])

    const getUserData = async (userId) => {
        try {
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/users/${userId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const userHandle = await axios(config)
            if (userHandle?.data) {
                setUserData(userHandle.data?.user)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getCreditData = async (userId) => {
        try {
            setIsLoading(true)
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const creditHandle = await axios(config)
            if (creditHandle?.data) {
                setCreditList(creditHandle.data?.credit)
                for (var i = 0; i < creditHandle.data?.credit?.length; i++) {
                    if (creditHandle.data?.credit[i].type == "REAL") setRealCreditCardData(creditHandle.data?.credit[i])
                    if (creditHandle.data?.credit[i].type == "USDT") setUsdtCreditCardData(creditHandle.data?.credit[i])
                    if (creditHandle.data?.credit[i].type == "BONUS") setBonusCreditCardData(creditHandle.data?.credit[i])
                    if (creditHandle.data?.credit[i].type == "PACKAGE") setPackageCreditCardData(creditHandle.data?.credit[i])
                }
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const getCreditHistoryData = async (userId) => {
        try {
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/credit/histories/?userId=${userId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const creditHistoryHandle = await axios(config)
            if (creditHistoryHandle?.data) {
                setCreditHistoryData(creditHistoryHandle.data?.credit)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function currencyFormat(num) {
        return num ? num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0
    }

    const onTransferCredit = async () => {
        try {
            setIsSaveLoading(true)
            const data = {
                realMoney: parseFloat(money),
                fromCard: "USDT",
                fromCardId: usdtCreditCardData?._id,
            }
            const config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${END_POINT_SEVER}/api/credit/transfer-to-package`,
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(data)
            };
            const creditHistoryHandle = await axios(config)
            if (creditHistoryHandle?.data) {
                getCreditData(userData["_id"])
                getCreditHistoryData(userData["_id"])
                getUserData(userData["_id"])
            }
            setIsSaveLoading(false)
            setShowTransferToBonus(false)
            setMoney(0)
            toastAlert('success', "Transfer success")
        } catch (error) {
            toastAlert("error", "Transfer failed " + error?.response?.data?.errorCode)
            console.log(error)
            setIsSaveLoading(false)
            setShowTransferToBonus(false)
            setMoney(0)
        }
    }

    function currencyFormatKip(num) {
        return num ? num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
        background: appColor.bgDark,
        color: appColor.white,
    };

    const handlePopupTransfer = () => {
        setIsShowPopupTransfer(!isShowPopupTransfer);
    }
    const handlePopupTransferPackage = () => {
        setIsShowPopupTransferPackage(!isShowPopupTransferPackage);
    }
    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down('lg'));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize])

    return (
        isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center" }}>
                <CustomNavbar />
                <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />
                {screenSize().width > 700 ? <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "row", paddingLeft: 30 }}>
                        <img src={"/tether-usdt-logo.png"} style={{ height: 80, width: 80 }} />
                        <div style={{ width: 20 }} />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                            <div style={{ textAlign: "start", display: "flex" }}>
                                <div>
                                    <h6 style={{ color: "#b6b6b6" }}>Wallet: {usdtCreditCardData?.credit ? currencyFormat(usdtCreditCardData?.credit) : 0} USDT</h6>
                                    <div style={{ height: 6 }} />
                                    <div style={{ width: "100%", borderBottom: "1px solid #b6b6b6" }} />
                                    <div style={{ height: 6 }} />
                                    <h6 style={{ color: "#35a947", fontWeight: 'bold' }}>Real: {realCreditCardData?.credit ? currencyFormat(realCreditCardData?.credit) : 0} USDT</h6>
                                    <div style={{ height: 6 }} />
                                    <div style={{ width: "100%", borderBottom: "1px solid #35a947" }} />
                                    <div style={{ height: 6 }} />
                                    <h6 style={{ color: "#FFD700" }}>Package: {packageCreditCardData?.credit ? currencyFormat(packageCreditCardData?.credit) : 0} USDT</h6>
                                    <div style={{ height: 6 }} />
                                    <div style={{ width: "100%", borderBottom: "1px solid #FFD700" }} />
                                    <div style={{ height: 6 }} />
                                    <h6 style={{ color: "#b6b6b6" }}>Bonus: {bonusCreditCardData?.credit ? currencyFormat(bonusCreditCardData?.credit) : 0} USDT</h6>
                                </div>
                                <div style={{ width: 20 }} />
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ height: 28 }} />
                                    <Button onClick={handlePopupTransfer} style={{ height: 28, width: 40, padding: 4, backgroundColor: "#35a947", borderWidth: 0 }}>
                                        <FontAwesomeIcon icon={faRightLeft} style={{ color: "white" }} />
                                    </Button>
                                    <div style={{ height: 4 }} />
                                    <Button onClick={handlePopupTransferPackage} style={{ height: 28, width: 40, padding: 4, backgroundColor: "#FFD700", borderWidth: 0 }}>
                                        <FontAwesomeIcon icon={faRightLeft} style={{ color: "white" }} />
                                    </Button>
                                </div>
                            </div>
                            <h4 style={{ color: "#ffffff" }}>Total: {currencyFormat(realCreditCardData?.credit + (usdtCreditCardData?.credit ?? 0) + (bonusCreditCardData?.credit ?? 0) + (packageCreditCardData?.credit ?? 0))} USDT</h4>
                        </div>
                    </div>

                    <div style={{ paddingRight: 20, display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "end", gap: 2 }}>
                        <div className='customImageBackground' onClick={() => setShowTransferToBonus(true)} style={{ backgroundImage: `url(${bonusBackgroundImage})` }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <div>
                                    <div style={{ fontSize: 14 }}><b>{t("get bonus 50% button title")}</b></div>
                                    <div style={{ fontSize: 10 }}>{t("get bonus 50% button detail")}</div>
                                </div>
                                <div style={{ width: 20 }} />
                                <div style={{ fontSize: 11, color: "#101010", fontWeight: "bold", backgroundColor: "#FFD700", borderRadius: 30, width: 150, height: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>{t("get bonus 50% button")}</div>
                            </div>
                        </div>
                        <Box width={10} />
                        <Button
                            onClick={() => routeNavigate("/select-bank-option", { state: { type: 'deposit', credit: usdtCreditCardData?.credit ?? 0 } })}
                            style={{
                                borderWidth: 0,
                                color: "white",
                                width: 200,
                                height: 45,
                                opacity: 0.8,
                                backgroundColor: appColor.green
                            }}
                        >
                            <div><FontAwesomeIcon icon={faPlus} style={{ color: "white" }} />{" "} {t("deposit")}</div>
                        </Button>
                        <Box width={10} />
                        <Button
                            onClick={() => routeNavigate("/select-bank-option", { state: { type: 'withdraw', credit: usdtCreditCardData?.credit ?? 0 } })}
                            style={{
                                borderWidth: 0,
                                color: "#fff",
                                width: 200,
                                height: 45,
                                opacity: 0.8,
                                backgroundColor: appColor.red
                            }}
                        >
                            <div><FontAwesomeIcon icon={faMoneyBillTransfer} style={{ color: "#fff" }} />{" "} {t("withdraw")}</div>
                        </Button>
                    </div>
                </div> : <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", paddingLeft: 30 }}>
                        <img src={"/tether-usdt-logo.png"} style={{ height: 60, width: 60 }} />
                        <div style={{ width: 20 }} />
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                            <div style={{ textAlign: "start", display: "flex" }}>
                                <div style={{ fontSize: 14 }}>
                                    <div style={{ color: "#b6b6b6" }}>Wallet: {usdtCreditCardData?.credit ? currencyFormat(usdtCreditCardData?.credit) : 0} USDT</div>
                                    <div style={{ height: 6 }} />
                                    <div style={{ width: "100%", borderBottom: "1px solid #b6b6b6" }} />
                                    <div style={{ height: 6 }} />
                                    <div style={{ color: "#35a947" }}>Real: {realCreditCardData?.credit ? currencyFormat(realCreditCardData?.credit) : 0} USDT</div>
                                    <div style={{ height: 6 }} />
                                    <div style={{ width: "100%", borderBottom: "1px solid #35a947" }} />
                                    <div style={{ height: 6 }} />
                                    <div style={{ color: "#FFD700" }}>Package: {packageCreditCardData?.credit ? currencyFormat(packageCreditCardData?.credit) : 0} USDT</div>
                                    <div style={{ height: 6 }} />
                                    <div style={{ width: "100%", borderBottom: "1px solid #FFD700" }} />
                                    <div style={{ height: 6 }} />
                                    <div style={{ color: "#b6b6b6" }}>Bonus: {bonusCreditCardData?.credit ? currencyFormat(bonusCreditCardData?.credit) : 0} USDT</div>
                                </div>
                                <div style={{ width: 20 }} />
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ height: 30 }} />
                                    <Button onClick={handlePopupTransfer} style={{ height: 28, width: 40, padding: 4, backgroundColor: "#35a947", borderWidth: 0 }}>
                                        <FontAwesomeIcon icon={faRightLeft} style={{ color: "white" }} />
                                    </Button>
                                    <div style={{ height: 6 }} />
                                    <Button onClick={handlePopupTransferPackage} style={{ height: 28, width: 40, padding: 4, backgroundColor: "#FFD700", borderWidth: 0 }}>
                                        <FontAwesomeIcon icon={faRightLeft} style={{ color: "white" }} />
                                    </Button>
                                </div>
                            </div>
                            <h6 style={{ color: "#ffffff", fontWeight: "bold" }}>Total: {currencyFormat(realCreditCardData?.credit + (usdtCreditCardData?.credit ?? 0) + (bonusCreditCardData?.credit ?? 0) + (packageCreditCardData?.credit ?? 0))} USDT</h6>
                        </div>
                    </div>

                    <div style={{ height: 20 }} />
                    <div className='customImageBackground4' onClick={() => setShowTransferToBonus(true)} style={{ backgroundImage: `url(${bonusBackgroundImage4})`, width: "94%", alignSelf: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div>
                                <div style={{ fontSize: 12 }}><b>{t("get bonus 50% button title")}</b></div>
                                <div style={{ fontSize: 8 }}>{t("get bonus 50% button detail")}</div>
                            </div>
                            <div style={{ fontSize: 11, color: "#101010", fontWeight: "bold", backgroundColor: "#FFD700", borderRadius: 30, width: 150, height: 24, textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>{t("get bonus 50% button")}</div>
                        </div>
                    </div>
                    <div style={{ padding: 10, display: "flex" }}>
                        <div style={{ width: 16 }} />
                        <Button
                            onClick={() => routeNavigate("/select-bank-option", { state: { type: 'deposit', credit: usdtCreditCardData?.credit ?? 0 } })}
                            style={{
                                borderWidth: 0,
                                color: "white",
                                width: 200,
                                height: 45,
                                backgroundColor: appColor.green
                            }}
                        >
                            <div><FontAwesomeIcon icon={faPlus} style={{ color: "white" }} />{" "} {t("deposit")}</div>
                        </Button>
                        <div style={{ width: 16 }} />
                        <Button
                            onClick={() => routeNavigate("/select-bank-option", { state: { type: 'withdraw', credit: usdtCreditCardData?.credit ?? 0 } })}
                            style={{
                                borderWidth: 0,
                                color: "#fff",
                                width: 200,
                                height: 45,
                                backgroundColor: appColor.red
                            }}
                        >
                            <div><FontAwesomeIcon icon={faMoneyBillTransfer} style={{ color: "#fff" }} />{" "} {t("withdraw")}</div>
                        </Button>
                    </div>
                </div>}
                {screenSize().width > 700 ? <div style={{ padding: 20 }}>
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t("account-type")}</th>
                                <th>{t("amount-of-money")}</th>
                                <th>Wallet</th>
                                <th>{t("type")}</th>
                                <th>{t("picture-of-money-transfer")}</th>
                                <th>{t("status")}</th>
                                <th>{t("time-to-create")}</th>
                                <th>{t("last-update")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {creditHistoryData && creditHistoryData.map((value, index) => <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{value.bankName ?? "Transfer"}</td>
                                <td style={{ color: value?.type == "ADD" ? "#35a947" : "#f7052d" }}>{value?.credit ? currencyFormatKip(value?.credit) : 0} USD</td>
                                <td>{value?.usedBonus ? "BONUS" : (value?.usedPackage ? "PACKAGE" : "USDT")}</td>
                                <td>{value?.type == "ADD" ? t("deposit") : t("withdraw")}</td>
                                <td>
                                    {value?.type == "ADD" ? <a href={`${S3_BUCKET_END_POINT}/images/${value?.image}`} download={value?.image} target="_blank" style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faDownload} /></a> :
                                        value?.adminSlip ? <a href={`${S3_BUCKET_END_POINT}/images/${value?.adminSlip}`} download={value?.adminSlip} target="_blank" style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faDownload} /></a> : "-"}
                                </td>
                                <td>{value?.status == "WAITTING" ?
                                    <span><FontAwesomeIcon icon={faClock} style={{ color: "orange" }} />{" "}{t("waiting-for-approval")}</span> :
                                    (value?.status == "APPROVED" ?
                                        <span><FontAwesomeIcon icon={faCircleCheck} style={{ color: "#35a947" }} />{" "}{t("approved")}</span> :
                                        <span><FontAwesomeIcon icon={faCircleXmark} style={{ color: "#f7052d" }} />{" "}{t("rejected")}</span>)
                                }</td>
                                <td>{moment(value?.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                                <td>{moment(value?.updatedAt).format("yyyy/MM/DD HH:mm")}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div> : <div style={{ padding: 10, paddingTop: 0 }}>
                    {creditHistoryData && creditHistoryData.map((value, index) => <div key={index} style={{ paddingTop: 10 }}>
                        <div key={index} style={{ border: "1px solid gray", borderRadius: 5, display: "flex", flexDirection: "row", width: "100%", height: 110, padding: 8, alignItems: "center", justifyContent: "space-between" }}>
                            <div style={{ color: "#fff", paddingLeft: 6 }}>{index + 1}</div>
                            <div style={{ height: 85, borderRightWidth: 0.5, borderRightColor: "#b6b6b6", borderRightStyle: "solid" }} />
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                                <div style={{ color: "#fff" }}><b style={{ color: "gray" }}>{t("amount-of-money")}</b>:&nbsp;&nbsp; {value?.credit ? currencyFormat(value?.credit) : 0} USD</div>
                                <div style={{ color: "#fff" }}><b style={{ color: "gray" }}>{t("type")}</b>:&nbsp;&nbsp; {value?.status == "WAITTING" ?
                                    <span><FontAwesomeIcon icon={faClock} style={{ color: "orange" }} /></span> : (value?.status == "APPROVED" ?
                                        <span><FontAwesomeIcon icon={faCircleCheck} style={{ color: "#35a947" }} /></span> :
                                        <span><FontAwesomeIcon icon={faCircleXmark} style={{ color: "#f7052d" }} /></span>)
                                } {value?.type == "ADD" ? (value?.usedBonus ? "USDT > BONUS" : (value?.usedPackage ? "USDT > PACKAGE" : t("deposit"))) : t("withdraw")}</div>
                                <div style={{ color: "#fff" }}><b style={{ color: "gray" }}>{t("time-to-create")}</b>:&nbsp;&nbsp; {moment(value?.createdAt).format("yyyy/MM/DD HH:mm")}</div>
                                <div style={{ color: "#fff" }}><b style={{ color: "gray" }}>{t("last-update")}</b>:&nbsp;&nbsp; {moment(value?.updatedAt).format("yyyy/MM/DD HH:mm")}</div>
                            </div>
                            <div style={{ height: 85, borderRightWidth: 0.5, borderRightColor: "#b6b6b6", borderRightStyle: "solid" }} />
                            <div style={{ color: "#fff", paddingRight: 6 }}>{value?.type == "ADD" ? <a href={`${S3_BUCKET_END_POINT}/images/${value?.image}`} download={value?.image} target="_blank" style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faDownload} /></a> :
                                value?.adminSlip ? <a href={`${S3_BUCKET_END_POINT}/images/${value?.adminSlip}`} download={value?.adminSlip} target="_blank" style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faDownload} /></a> : <FontAwesomeIcon icon={faDownload} style={{ color: "gray" }} />}</div>
                        </div>
                    </div>)}
                </div>}
                <TransferToBonus
                    onCloseTransferPopup={() => setShowTransferToBonus(false)}
                    isShowTransferPopup={showTransferToBonus}
                    money={money}
                    setMoney={setMoney}
                    currencyFormatKip={currencyFormat}
                    onTransferCredit={onTransferCredit}
                    isSaveLoading={isSaveLoading}
                    credit={usdtCreditCardData?.credit ?? 0}
                    userData={userData}
                />
                <AppModalDialog
                    isModalOpen={isShowPopupTransfer}
                    handleChangeViewMoreModal={handlePopupTransfer}
                >
                    <Box sx={{
                        ...style,
                        width: 600,
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        alignItems: "center",
                    }}>
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            sx={{
                                justifyContent: "space-between",
                                justifyItems: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "bold",
                                    fontFamily: "Myriad_Pro"
                                }}
                            >
                                {t("transfer")}
                            </Typography>
                        </Box>
                        <CreditTransfer creditList={creditList} userID={userData?._id} handlePopupTransfer={handlePopupTransfer} getCreditData={getCreditData} />
                    </Box>
                </AppModalDialog>
                <AppModalDialog
                    isModalOpen={isShowPopupTransferPackage}
                    handleChangeViewMoreModal={handlePopupTransferPackage}
                >
                    <Box sx={{
                        ...style,
                        width: 600,
                        display: "flex",
                        flexDirection: "column",
                        justifyItems: "center",
                        alignItems: "center",
                    }}>
                        <Box
                            display={"flex"}
                            flexDirection={"row"}
                            sx={{
                                justifyContent: "space-between",
                                justifyItems: "center",
                                alignItems: "center",
                            }}
                        >
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "bold",
                                    fontFamily: "Myriad_Pro"
                                }}
                            >
                                {t("transfer")}
                            </Typography>
                        </Box>
                        <CreditTransferPackage creditList={creditList} userID={userData?._id} handlePopupTransfer={handlePopupTransferPackage} getCreditData={getCreditData} />
                    </Box>
                </AppModalDialog>
            </div>
    )
}

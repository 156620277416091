import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// USE FOR NEW CHART
import { ThemeProvider, createTheme } from "@mui/material";
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n";
import { appColor } from "./constants";

import "./foreign-xo.css";

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: appColor.background,
    }
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    // fontFamily: "Phetsarath_OT",
    fontFamily: "Myriad_Pro",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </I18nextProvider>

  </React.StrictMode>
);
reportWebVitals();

import React, { useEffect, useState } from 'react'
import { BarChart, PieChart } from '@mui/x-charts';
import "./style.css"
import { appColor, screenSize } from '../../constants';
import { useTranslation } from 'react-i18next';

export default function Commission({ userData, vipData }) {
    const { t, i18n } = useTranslation();
    const [pieChartData, setPieChartData] = useState([
        { id: 0, value: 0, color: '#e34828' },
        { id: 1, value: 0, color: "#0ea172" },
        { id: 2, value: 0, color: "#cc7c21" },
    ])
    const [mainGraphHeaders, setMainGraphHeaders] = useState([]);
    const [mainGraphPrices, setMainGraphPrices] = useState([]);

    useEffect(() => {
        if (vipData?.statistic) {
            setPieChartData([
                { id: 0, value: currencyFormat(vipData?.statistic?.twoMonth?.price), color: '#e34828' },
                { id: 1, value: currencyFormat(vipData?.statistic?.lastMonth?.price), color: "#0ea172" },
                { id: 2, value: currencyFormat(vipData?.statistic?.thisMonth?.price), color: "#cc7c21" },
            ])
            getUserEarnData()
        }
    }, [])

    const getUserEarnData = async () => {
        try {
            let _mainGraphHeaders = []
            let _mainGraphPrices = []
            for (var i = 0; i < vipData?.statistic?.all?.length; i++) {
                _mainGraphHeaders.push(vipData?.statistic?.all[i]?._id.toString())
                _mainGraphPrices.push(vipData?.statistic?.all[i]?.price ? parseFloat(vipData?.statistic?.all[i]?.price.toFixed(2)) : 0)
            }
            setMainGraphHeaders(_mainGraphHeaders)
            setMainGraphPrices(_mainGraphPrices)
        } catch (error) {
            console.log(error);
        }
    };

    function currencyFormat(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    function currencyFormatNotFixed(num) {
        return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    return (
        <div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 8 }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <div style={{ width: "100%", fontSize: 13, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "rgb(95,182,120)", height: 30, borderRadius: 30 }}>
                        <div style={{ width: "23%" }}>{t("Time")}</div>
                        <div style={{ width: "22%" }}>{t("CashOut")}</div>
                        <div style={{ width: "22%" }}>{t("TotalTradeVolume")}</div>
                        <div style={{ width: "27%" }}>{t("TradeVolumn")}</div>
                    </div>
                    <div style={{ height: 10 }} />
                    {(vipData && vipData?.statistic?.sevenDay.length > 0) && (vipData?.statistic?.sevenDay.map((item, index) => <div key={index} style={{ width: "100%" }}>
                        <div style={{ width: "100%", fontSize: 13, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: appColor.bgDark, height: 30, borderRadius: 30 }}>
                            <div style={{ width: "23%" }}>{item?._id}</div>
                            <div style={{ width: "22%" }}>{currencyFormat(item?.price)}$</div>
                            <div style={{ width: "22%" }}>{currencyFormatNotFixed(item?.count)}</div>
                            <div style={{ width: "27%" }}>{currencyFormat(item?.tradePrice)}$</div>
                        </div>
                        <div style={{ height: 5 }} />
                    </div>))}
                    {/* <div style={{ height: 10 }} /> */}

                    <div style={{ height: 10 }} />

                    <div style={{ width: "100%", height: 335, display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", backgroundColor: appColor.bgDark, borderRadius: 8, padding: 10 }}>
                        <h6>{t("GraphVolumnTrade")}</h6>
                        <div style={{ height: 10 }} />
                        <div style={{ color: '#cc7c21', fontSize: 13 }}>{t("ThisMonth")} ({currencyFormat(vipData?.statistic?.thisMonth?.price)}$)</div>
                        <div style={{ height: 5 }} />
                        <div style={{ color: '#0ea172', fontSize: 13 }}>{t("LatestMonth")} ({currencyFormat(vipData?.statistic?.lastMonth?.price)}$)</div>
                        <div style={{ height: 5 }} />
                        <div style={{ color: '#e34828', fontSize: 13 }}>{t("TwoMonthAgo")} ({currencyFormat(vipData?.statistic?.twoMonth?.price)}$)</div>
                        <PieChart
                            series={[
                                {
                                    data: pieChartData,
                                    innerRadius: 65,
                                    outerRadius: 100,
                                    paddingAngle: 1,
                                    cornerRadius: 3,
                                    startAngle: -180,
                                    endAngle: 180,
                                    cx: 150,
                                    cy: 110,
                                }
                            ]}
                            theme={{
                                axis: {
                                    tickColor: 'white',
                                    labelColor: 'white',
                                    axisLineColor: 'white',
                                },
                            }}
                        />
                    </div>
                    <div style={{ height: 10 }} />
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", backgroundColor: appColor.bgDark, borderRadius: 8, padding: 20 }}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "start" }}>
                            <h6>{t("GraphCommission")}</h6>
                        </div>
                        {(mainGraphHeaders && mainGraphHeaders.length > 0) && <BarChart className="my-barchart"
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: mainGraphHeaders ?? [],
                                    scaleType: 'band',
                                },
                            ]}
                            series={[
                                {
                                    data: mainGraphPrices ?? [],
                                },
                            ]}
                            // width={screenSize().width}
                            height={400}
                            colors={["rgb(95,182,120)"]}
                            theme={{
                                axis: {
                                    tickColor: 'white',
                                    labelColor: 'white',
                                    axisLineColor: 'white',
                                },
                            }}
                        />}
                    </div>
                </div>
            </div>
        </div >
    )
}

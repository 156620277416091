import React from 'react'
import { useTranslation } from 'react-i18next'
import laoFlag from '../images/flag/lao-flag.png';
import englishFlag from '../images/flag/english-flag.png';
import thailandFlag from '../images/flag/thailand-flag.png';
import { Container } from 'react-bootstrap';
import AppPopup from "../common/popup";

export default function ChangeLanguage({ appLanguages, showModalLanguages, setShowModalLanguages, onChangeLanguage, width, height }) {
    const { t } = useTranslation();

    return (
        <AppPopup isShowPopup={showModalLanguages} handdlePopup={() => setShowModalLanguages(false)} width={width ?? 400} height={height ?? 200}>
            <h3>{t('change-language')}</h3>
            <Container>
                <div style={{ display: "flex", flexDirection: "row", width: 330, height: 100, justifyContent: "center", alignItems: "center" }}>
                    {/* <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => {
                        onChangeLanguage("lao")
                        setShowModalLanguages(false)
                    }}>
                        <img src={laoFlag} style={{ cursor: "pointer" }} width={70} height={45} />
                        <p>ພາສາລາວ</p>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", border: "1px solid rgb(95,182,120)", borderRadius: 20, width: 20, height: 20 }}>
                            {appLanguages == "lao" && <div style={{ display: "flex", flexDirection: "column", border: "1px solid rgb(95,182,120)", backgroundColor: "rgb(95,182,120)", borderRadius: 12, width: 12, height: 12 }} />}
                        </div>
                    </div>
                    <div style={{ width: 10 }} /> */}
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => {
                        onChangeLanguage("thailand")
                        setShowModalLanguages(false)
                    }}>
                        <img src={thailandFlag} style={{ cursor: "pointer" }} width={70} height={45} />
                        <p>ภาษาไทย</p>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", border: "1px solid rgb(95,182,120)", borderRadius: 20, width: 20, height: 20 }}>
                            {appLanguages == "thailand" && <div style={{ display: "flex", flexDirection: "column", border: "1px solid rgb(95,182,120)", backgroundColor: "rgb(95,182,120)", borderRadius: 12, width: 12, height: 12 }} />}
                        </div>
                    </div>
                    <div style={{ width: 10 }} />
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center", cursor: "pointer" }} onClick={() => {
                        onChangeLanguage("english")
                        setShowModalLanguages(false)
                    }}>
                        <img src={englishFlag} style={{ cursor: "pointer" }} width={70} height={45} />
                        <p>English</p>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", border: "1px solid rgb(95,182,120)", borderRadius: 20, width: 20, height: 20 }}>
                            {appLanguages == "english" && <div style={{ display: "flex", flexDirection: "column", border: "1px solid rgb(95,182,120)", backgroundColor: "rgb(95,182,120)", borderRadius: 12, width: 12, height: 12 }} />}
                        </div>
                    </div>
                </div>
            </Container>
        </AppPopup>
    )
}

import { USER_LOCAL_DATA } from "../constants";

export const useAuth = () => {
  try {
    const _localData = localStorage.getItem(USER_LOCAL_DATA);
    if (_localData) {
      let parseData = JSON.parse(_localData);
      if (parseData["user"] && parseData["user"]["_id"]) {
        if (parseData["user"]["role"] === "ADMIN") {
          return true;
        } else {
          return false;
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

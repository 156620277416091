import React, { useEffect, useState } from 'react'
import CustomNavbar from '../navbar/Navbar'
import { USER_LOCAL_DATA, appColor, screenSize } from '../../constants'
import Overview from './overview'
import Requesting from './requesting'
import { END_POINT_SEVER } from '../../api'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Commission from './commission'
import Marketing from './marketing'
import ManageSystem from './manageSystem'
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import LoadingPage from '../../common/loadingPage'

export default function CustomerVipList() {
    const routeNavigate = useNavigate();
    const { t } = useTranslation();
    const [selectTap, setSelectTap] = useState("Overview")
    const [userData, setUserData] = useState();
    const [vipData, setVipData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getLoalData = async () => {
            try {
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (parseData["user"] && parseData["user"]["_id"]) {
                        getUserData(parseData["user"]["_id"]);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);

    const getUserData = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/users/${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                setUserData(userHandle.data?.user);
                getUserEarnData(userHandle.data?.user["_id"])
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getUserEarnData = async (userId) => {
        try {
            setIsLoading(true)
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/vip-earn-history/?to=${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const userHandle = await axios(config);
            if (userHandle?.data) {
                console.log("userHandle?.data: ", userHandle?.data?.countVipType)
                setVipData(userHandle?.data?.countVipType)
            }
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error);
        }
    };

    const [isMobile, setIsMobile] = useState(false);
    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down("sm"));
    // check device type desktop or mobile

    useEffect(() => {
        // console.log('isMobileSize :>> ', isMobileSize);
        setIsMobile(isMobileSize)
        // if (isMobile==true) {
        //   console.log("isMobile");
        //   kLineChart.current?.setStyles(
        //     {
        //       candle: {
        //         tooltip: {
        //           showRule: "none",
        //         }
        //       }
        //     });
        // } else {
        //   console.log("isDesktop");
        //   kLineChart.current?.setStyles(
        //     {
        //       candle: {
        //         tooltip: {
        //           showRule: "none",
        //         }
        //       }
        //     });
        // }
    }, [isMobileSize, isMobile, window.screen.width, window.screen.height]);


    return (
        isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <>
                <CustomNavbar />
                <div
                    className='color-body'
                    style={{
                        minHeight: "100vh",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#fff",
                        padding: isMobile ? 2 : 100
                    }}>

                    <div style={{ height: screenSize().width > 700 ? 100 : 80 }} />
                    <div style={{
                        width: "100%",
                        height: 40,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",

                    }}>
                        <div
                            onClick={() => setSelectTap("Overview")}
                            style={{ width: "25%", backgroundColor: selectTap == "Overview" ? appColor.bgDark : appColor.background, borderRight: "1px solid #515361", borderBottom: "1px solid #515361", fontSize: 14, height: 40, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        >
                            {t('Overview')}
                        </div>
                        <div
                            onClick={() => setSelectTap("Commission")}
                            style={{ width: "25%", backgroundColor: selectTap == "Commission" ? appColor.bgDark : appColor.background, borderRight: "1px solid #515361", borderBottom: "1px solid #515361", fontSize: 14, height: 40, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        >
                            {t('Comission')}
                        </div>
                        <div
                            onClick={() => setSelectTap("ManageSystem")}
                            style={{ width: "25%", backgroundColor: selectTap == "ManageSystem" ? appColor.bgDark : appColor.background, borderRight: "1px solid #515361", borderBottom: "1px solid #515361", fontSize: 14, height: 40, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        >
                            {t('Manage System')}
                        </div>
                        <div
                            onClick={() => setSelectTap("Marketing")}
                            style={{ width: "25%", backgroundColor: selectTap == "Marketing" ? appColor.bgDark : appColor.background, borderBottom: "1px solid #515361", fontSize: 14, height: 40, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
                        >
                            {t('Marketing')}
                        </div>
                    </div>
                    {selectTap == "Overview" && (userData?.vipUser ? <Overview userData={userData} vipData={vipData} /> : <Requesting userData={userData} />)}
                    {selectTap == "Commission" && (userData?.vipUser ? <Commission userData={userData} vipData={vipData} /> : <Requesting userData={userData} />)}
                    {selectTap == "Marketing" && (userData?.vipUser ? <Marketing userData={userData} vipData={vipData} /> : <Requesting userData={userData} />)}
                    {selectTap == "ManageSystem" && (userData?.vipUser ? <ManageSystem userData={userData} vipData={vipData} /> : <Requesting userData={userData} />)}
                </div>
            </>

    )
}

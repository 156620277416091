import React, { useState, useEffect } from 'react'
import { Box, Typography, TextField, Button, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { toast } from "react-toastify";
import { appColor } from '../../constants';
import { currencyFormat } from '../../utils';
import { END_POINT_SEVER } from '../../api';
import axios from "axios";
import { MultipleStopOutlined } from '@mui/icons-material';
import { NumericFormat } from 'react-number-format';
import { useTheme } from '@emotion/react';

export default function CreditTransferPackage({ userID, handlePopupTransfer, getCreditData }) {
    const [creditList, setCreditList] = useState([]);
    const [creditFrom, setcreditFrom] = useState('');
    const [creditTo, setcreditTo] = useState('');
    const [maxCreditAmountFrom, setMaxCreditAmountFrom] = useState();
    const [creditIDFrom, setcreditIDFrom] = useState('');
    const [creditIDTo, setcreditIDTo] = useState('');
    const [packageData, setPackageData] = useState();
    const [moneyCanWithdrawn, setMoneyCanWithdrawn] = useState(0);

    const toastAlert = (msg, type) => {
        toast(msg, { type: type == "win" ? "success" : "error" });
    };

    useEffect(() => {
        const filterUSDTWallet = creditList.filter((item) => item.type === "USDT");
        if (filterUSDTWallet && filterUSDTWallet.length > 0) setcreditTo(currencyFormat(filterUSDTWallet[0]?.credit));

        const filtePackageWallet = creditList.filter((item) => item.type === "PACKAGE");
        if (filtePackageWallet && filtePackageWallet.length > 0) {
            setPackageData(filtePackageWallet[0])
            let _amountOfBonus = filtePackageWallet[0].realMoney * 0.5
            let _moneyCanWithdrawn = filtePackageWallet[0].credit - _amountOfBonus
            if (_moneyCanWithdrawn <= 0) {
                setMoneyCanWithdrawn(0)
                setMaxCreditAmountFrom(0)
            } else {
                setMoneyCanWithdrawn(_moneyCanWithdrawn)
                setMaxCreditAmountFrom(_moneyCanWithdrawn)
            }
            setcreditFrom(currencyFormat(filtePackageWallet[0]?.credit));

            setcreditIDFrom(filtePackageWallet[0]?._id);
            setcreditIDTo(filterUSDTWallet[0]?._id);
        }
    }, [creditList]);

    // get credit
    const getCreditDataTrans = async (userId) => {
        try {
            var config = {
                method: "get",
                url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const creditHandle = await axios(config);
            if (creditHandle?.data) {
                setCreditList(creditHandle.data?.credit)
            }
        } catch (error) {
            console.log(error);
        }
    };

    // CREDIT TRANSFER
    const handleCreditTransfer = async () => {
        try {
            if (creditIDFrom == creditIDTo) {
                toastAlert(`SORRY: Account type can't be same`);
                return;
            }
            var config = {
                method: "put",
                url: `${END_POINT_SEVER}/api/credit/transfer/${userID}`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    transFromID: creditIDFrom,
                    transToID: creditIDTo,
                    transType: "PACKAGETOUSDT",
                    credit: parseFloat(maxCreditAmountFrom),
                }),
            };
            const updateHandle = await axios(config);
            if (updateHandle.data) {
                toastAlert("Transfer Success", "win");
                getCreditDataTrans(userID);
                getCreditData(userID);
                handlePopupTransfer();
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.errorCode == "MARKETING_USER") toastAlert(`SORRY: You are marketing account`);
            else toastAlert(`SORRY: ${error?.response?.data?.errorCode}`);
        }
    };

    useEffect(() => {
        getCreditDataTrans(userID);
    }, [userID]);

    const theme = useTheme();
    const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        setIsMobile(isMobileSize)
    }, [isMobileSize])

    const isIPadSize = useMediaQuery(theme.breakpoints.down('md'));
    const [isIPad, setIsIPad] = useState(false);
    useEffect(() => {
        setIsIPad(isIPadSize)
    }, [isIPadSize])

    return (
        <Box
            sx={{
                width: isMobile ? "100dvw" : isIPad ? "50dvw" : "30dvw",
                px: 2
            }}
        >
            <Box style={{
                backgroundColor: appColor.background,
                height: "50%",
                padding: 20,
                borderRadius: 10,
            }}>
                <Box style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyItems: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 10,
                }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "center",
                            width: "100%",
                            // px: 1,
                            // backgroundColor: appColor.bgDark,
                            borderRadius: "4px",
                            gap: 0.5
                        }}
                    >
                        <Box style={{
                            width: '100%',
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "start",
                            justifyContent: "space-between",
                            gap: 10,
                        }}>

                            <Typography color="white" style={{
                                fontFamily: "Phetsarath_OT"
                            }}>{t("from")}</Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: appColor.bgDark,
                                    px: 0.5,
                                    py: 1,
                                    width: '100%',
                                }}>
                                <Typography variant='body2' color="white">Package</Typography>
                                <Typography
                                    variant='body2'
                                    color="white"
                                    sx={{
                                        color: appColor.greenActive,
                                        px: 1,
                                        py: 1,
                                        fontSize: 18
                                    }}>
                                    {creditFrom}
                                </Typography>
                                <Typography variant='body2' color="white" style={{
                                    fontFamily: "Phetsarath_OT"
                                }}>{t("USDT")}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ height: 10 }} />
                        <MultipleStopOutlined sx={{ backgroundColor: appColor.bgDark }} />
                        <Box width={"100%"} style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyItems: "center",
                            alignItems: "start",
                            justifyContent: "space-between",
                            gap: 10,
                        }}>
                            <Typography color="white" >{t("to")} </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyItems: "center",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: appColor.bgDark,
                                    px: 1,
                                    py: 1,
                                    width: '100%',
                                }}>
                                <Typography variant='body2' color="white" style={{
                                    fontFamily: "Phetsarath_OT"
                                }}>Wallet</Typography>
                                <Typography
                                    variant='body2'
                                    color="white"
                                    sx={{
                                        color: appColor.greenActive,
                                        px: 0.5,
                                        py: 1,
                                        fontSize: 18
                                    }}>{creditTo}</Typography>
                                <Typography variant='body2' color="white" >{t("USDT")}</Typography>

                            </Box>
                        </Box>
                    </Box>
                </Box>
                <div style={{ height: 10 }} />
                <Typography color="white" style={{ fontFamily: "Phetsarath_OT" }}>{t("amount-of-money")} (Package 50%)</Typography>
                {packageData && <Typography style={{ fontFamily: "Phetsarath_OT", fontSize: 11, color: "#FFD700" }}>{t("money set package")} {packageData?.creditTotal ? currencyFormat(packageData?.creditTotal) : 0} USDT, {t("money that can be withdrawn")} {moneyCanWithdrawn ? currencyFormat(moneyCanWithdrawn) : 0} USDT</Typography>}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyItems: "center",
                        alignItems: "center",
                        backgroundColor: appColor.bgDark,
                        justifyContent: "space-between",
                    }}
                >
                    <NumericFormat
                        customInput={TextField}
                        decimalScale={0}
                        thousandSeparator={true}
                        id="outlined-basic"
                        placeholder='Enter Amount'
                        variant="standard"
                        sx={{ width: '100%', px: 1 }}
                        disabled
                        value={maxCreditAmountFrom}
                        InputProps={{
                            disableUnderline: true,
                        }}
                    />
                </Box>
            </Box>
            <div style={{ height: 20 }} />
            <Button
                onClick={handleCreditTransfer}
                style={{
                    borderRadius: 4,
                    minWidth: "170px",
                    height: 45,
                    color: appColor.black,
                    backgroundColor: appColor.yellow,
                    width: "100%",
                }}
            >
                <div>{t("confirm")}</div>
            </Button>
        </Box>
    )
}
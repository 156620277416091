import React, { useEffect, useState } from 'react'
import {
    Box, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, Slide,
    Avatar, Typography,
    Divider, TextField, Switch, Stack
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { USER_LOCAL_DATA, appColor } from '../../constants';
import { useMutation } from '@apollo/client';
import { CREATE_AVS_GQL, CREATE_MC_GQL } from '../../apollo/crypto';
import { useTranslation } from 'react-i18next';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { END_POINT_SEVER } from '../../api'
import { toast } from "react-toastify";
import axios from 'axios'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MCCreateAdsPopup = ({
    isMobile,
    open,
    handleClose,
    getAvs
}) => {
    const { t, i18n } = useTranslation();
    const [userID, setUserID] = useState();
    const [mcName, setMCName] = useState();
    const [isPublishStatus, setisPublishStatus] = useState(true);

    const [tradeType, setTradeType] = useState("BUY");
    const [tradeAsset, setTradeAsset] = useState("USDT");
    const [fiatUnitType, setFiatUnitType] = useState("LAK");

    const [advPrice, setAdvPrice] = useState();
    const [tradableQuantity, setTradableQuantity] = useState();

    const [minSingleTransQuantity, setMinSingleTransQuantity] = useState();
    const [maxSingleTransQuantity, setMaxSingleTransQuantity] = useState();
    const [usdtCreditCardData, setUsdtCreditCardData] = useState()
    const [rate, setRate] = useState()

    const toastAlert = (type, msg,) => {
        toast(msg, { type: type == "error" ? "error" : "success" });
    };

    const handleChangeTradeType = (event) => {
        setTradeType(event.target.value);
    }
    const handleChangeTradeAsset = (event) => {
        setTradeAsset(event.target.value);
    }
    const handleChangeFiatUnitType = (event) => {
        setFiatUnitType(event.target.value);
    }

    const handleChangeAdvPrice = (event) => {
        let newPrice = parseInt(event.target.value);
        if (newPrice >= 0) {
            setAdvPrice(newPrice);
        } else {
            setAdvPrice(0);
        }
    }
    const handleChangeTradableQuantity = (event) => {
        let newPrice = parseInt(event.target.value);
        if (newPrice >= 0) {
            setTradableQuantity(newPrice);
            // if (tradeType == "SELL")
            setMaxSingleTransQuantity(newPrice * advPrice)
        } else {
            setTradableQuantity(0);
            // if (tradeType == "SELL")
            setMaxSingleTransQuantity(0)
        }
    }

    const handleChangeMinSingleTransQuantity = (event) => {
        let newPrice = parseInt(event.target.value);
        if (newPrice >= 0) {
            setMinSingleTransQuantity(newPrice);
        } else {
            setMinSingleTransQuantity(0);
        }
    }
    const handleChangeMaxSingleTransQuantity = (event) => {
        let newPrice = parseInt(event.target.value);
        if (newPrice >= 0) {
            setMaxSingleTransQuantity(newPrice);
            // if (tradeType == "SELL")
            setTradableQuantity(newPrice / advPrice)
        } else {
            setMaxSingleTransQuantity(0);
            // if (tradeType == "SELL")
            setTradableQuantity(0)
        }
    }

    useEffect(() => {
        setAdvPrice(0)
        setTradableQuantity(0)
        setMaxSingleTransQuantity(0)
        setMinSingleTransQuantity(0)
    }, [tradeType]);

    const [mutationCreateAdv] = useMutation(CREATE_AVS_GQL, {
        onCompleted: (data) => {
            handleClose()
            // console.log('data :>> ', data);
            // console.log('data?.createMerchant.error :>> ', data?.createMerchant.error.error);
            if (data?.createAdvertisement.error.error == false) {
                getAvs();
                toastAlert('success', 'Create ADV Success')
            } else {
                // console.log('data?.createMerchant.error.errorMsg :>> ', data?.createMerchant.error.errorMsg);
                toastAlert('error', `ERROR: ${data?.createAdvertisement.error.errorMsg}`)
            }
        },
        onError: (error) => {
            console.log("error :>> ", error);
        }
    })
    const handleCreateAds = async () => {
        try {
            if (!tradeType) return toastAlert('error', 'Trade Type is required')
            if (!tradeAsset) return toastAlert('error', 'Trade Asset is required')
            if (!fiatUnitType) return toastAlert('error', 'Fiat Unit Type is required')
            if (!advPrice) return toastAlert('error', 'Price is required')
            if (!tradableQuantity) return toastAlert('error', 'Tradable Quantity is required')
            if (!minSingleTransQuantity) return toastAlert('error', 'min Single Trans Quantity is required')
            if (!maxSingleTransQuantity) return toastAlert('error ', 'max Single Trans Quantity is required')
            if (minSingleTransQuantity > maxSingleTransQuantity) return toastAlert('error', 'min Single Trans Quantity must be less than max Single Trans Quantity')

            let data = {
                "tradeType": tradeType,
                "assetType": tradeAsset,
                "fiatUnitType": fiatUnitType,
                "price": advPrice.toString(),
                "minSingleTransQuantity": minSingleTransQuantity.toString(),
                "maxSingleTransQuantity": maxSingleTransQuantity.toString(),
                "tradableQuantity": tradableQuantity.toString(),
                "user": userID,
                "isPublish": true
            }
            await mutationCreateAdv({
                variables: {
                    data: data
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const getCreditData = async (userId) => {
        try {
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/credit/?userId=${userId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const creditHandle = await axios(config)
            if (creditHandle?.data) {
                for (var i = 0; i < creditHandle.data?.credit?.length; i++) {
                    if (creditHandle.data?.credit[i].type == "USDT") setUsdtCreditCardData(creditHandle.data?.credit[i])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        const getLoalData = async () => {
            try {
                getRate()
                const _localData = await localStorage.getItem(USER_LOCAL_DATA);
                if (_localData) {
                    let parseData = JSON.parse(_localData);
                    if (parseData) {
                        setUserID(parseData?.user?._id);
                        getCreditData(parseData?.user?._id)
                    }
                }

            } catch (error) {
                console.log(error);
            }
        };
        getLoalData();
    }, []);

    const getRate = async () => {
        try {
            var config = {
                method: 'get',
                url: `${END_POINT_SEVER}/api/rate/?limit=1`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const rateDate = await axios(config)
            if (rateDate?.data?.rate?.length > 0) {
                setRate(rateDate?.data?.rate[0])
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <React.Fragment>
            <Dialog
                maxWidth={'lg'}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    width: '100%',
                }}
            >
                <DialogTitle>Create Ads</DialogTitle>
                {/* AVATAR */}

                <Box
                    display={'flex'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    // flexDirection={'row'}
                    sx={{
                        justifyContent: 'space-around',
                        alignItems: 'start',
                    }}
                >
                    {/*START LEFT */}
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            paddingX: 4,
                            paddingBottom: isMobile ? 0 : 2,

                        }}
                    >

                        {/* START TRADABLE QUANTITY */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Trade Type")}</Typography>
                                <FormControl variant="standard"
                                    sx={{
                                        m: 1, width: "100%",
                                    }}
                                >
                                    <Select
                                        labelId="trade-type-select-standard-label"
                                        id="trade-type-select-standard"
                                        value={tradeType}
                                        onChange={handleChangeTradeType}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                        <MenuItem value={"BUY"}>BUY</MenuItem>
                                        <MenuItem value={"SELL"}>SELL</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Trade Asset")}</Typography>
                                <FormControl variant="standard"
                                    sx={{ m: 1, width: "100%" }}
                                >
                                    <Select
                                        labelId="trade-asset-select-standard-label"
                                        id="trade-asset-select-standard"
                                        value={tradeAsset}
                                        onChange={handleChangeTradeAsset}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                        <MenuItem value={"USDT"}>USDT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box sx={{
                                height: 10
                            }} />
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Fiat Unit Type")}</Typography>
                                <FormControl variant="standard"
                                    sx={{ m: 1, width: "100%" }}
                                >
                                    <Select
                                        labelId="fiat-unit-type-select-standard-label"
                                        id="fiat-unit-type-select-standard"
                                        value={fiatUnitType}
                                        onChange={handleChangeFiatUnitType}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                        <MenuItem value={"LAK"}>LAK</MenuItem>
                                        <MenuItem value={"THB"}>THB</MenuItem>
                                        <MenuItem value={"USD"}>USD</MenuItem>
                                        <MenuItem value={"KHR"}>KHR</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                        </Box>
                        {/* END TRADABLE QUANTITY */}

                    </Box>
                    {/*END LEFT */}


                    {/*START CENTER */}
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            paddingX: 4,
                            paddingBottom: isMobile ? 0 : 2,

                        }}
                    >

                        {/* START TRADE PRICE */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Price")} ({fiatUnitType})</Typography>

                                <TextField
                                    id="adv-outlined-basic"
                                    placeholder="1000"
                                    variant="standard"
                                    value={advPrice}
                                    sx={{
                                        width: '100%'
                                    }}
                                    onChange={handleChangeAdvPrice}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        min: 0,
                                        style: {
                                            textAlign: "start",
                                            // color: "#FFFFFF ",
                                            fontWeight: "bold",
                                            // fontSize: "10px",
                                            padding: 0,
                                            margin: 0,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                height: 10
                            }} />

                        </Box>
                        {/* END TRADE PRICE */}
                        <Box sx={{
                            height: 10
                        }} />
                        {/* START TRADABLE QUANTITY */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Tradable Quantity")} ({tradeAsset})</Typography>

                                <TextField
                                    id="Quantity-outlined-basic"
                                    placeholder="1000"
                                    variant="standard"
                                    value={tradableQuantity}
                                    sx={{
                                        width: '100%'
                                    }}
                                    onChange={handleChangeTradableQuantity}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        min: 0,
                                        style: {
                                            textAlign: "start",
                                            // color: "#FFFFFF ",
                                            fontWeight: "bold",
                                            // fontSize: "10px",
                                            padding: 0,
                                            margin: 0,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                height: 10
                            }} />

                        </Box>
                        {/* END TRADABLE QUANTITY */}



                        <Box sx={{
                            height: 10
                        }} />


                    </Box>
                    {/*END CENTER */}

                    {/*START RIGHT */}
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        sx={{
                            justifyContent: 'start',
                            alignItems: 'start',
                            paddingX: 4,
                            paddingBottom: isMobile ? 0 : 2,

                        }}
                    >

                        {/* START MIN SINGLE TRANS QUANTITY */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Min Single Trans Quantity")} ({fiatUnitType})</Typography>

                                <TextField
                                    id="Min-outlined-basic"
                                    placeholder="1000"
                                    variant="standard"
                                    value={minSingleTransQuantity}
                                    sx={{
                                        width: '100%'
                                    }}
                                    onChange={handleChangeMinSingleTransQuantity}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        min: 0,
                                        style: {
                                            textAlign: "start",
                                            // color: "#FFFFFF ",
                                            fontWeight: "bold",
                                            // fontSize: "10px",
                                            padding: 0,
                                            margin: 0,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                height: 10
                            }} />

                        </Box>
                        {/* END MIN SINGLE TRANS QUANTITY */}
                        <Box sx={{
                            height: 10
                        }} />
                        {/* START MAX SINGLE TRANS QUANTITY */}
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("Max Single Trans Quantity")} ({fiatUnitType})</Typography>

                                <TextField
                                    id="MAX-outlined-basic"
                                    placeholder="1000"
                                    variant="standard"
                                    value={maxSingleTransQuantity}
                                    sx={{
                                        width: '100%'
                                    }}
                                    onChange={handleChangeMaxSingleTransQuantity}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        min: 0,
                                        style: {
                                            textAlign: "start",
                                            // color: "#FFFFFF ",
                                            fontWeight: "bold",
                                            // fontSize: "10px",
                                            padding: 0,
                                            margin: 0,
                                        },
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                height: 10
                            }} />

                        </Box>
                        {/* END MAX SINGLE TRANS QUANTITY */}

                        {/* START ADS STATUS */}

                        {/* <Box
                            display={'flex'}
                            flexDirection={'column'}
                            sx={{
                                width: '300px',
                            }}
                        >
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                sx={{
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                    border: `1px solid ${appColor.bgGrey}`,
                                    borderRadius: 2,
                                    padding: 2,
                                }}>
                                <Typography
                                    sx={{
                                        color: appColor.textGrey
                                    }}
                                >{t("isPublish")}</Typography>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography>{t("Inactive")}</Typography>
                                    <Switch
                                        value={isPublishStatus}
                                        onChange={handlePublishStatusActive} />
                                    <Typography>{t("Active")}</Typography>
                                </Stack>
                            </Box>
                            <Box sx={{
                                height: 10
                            }} />

                        </Box> */}
                        {/* END ADS STATUS */}
                    </Box>
                    {/*END RIGHT */}
                </Box>
                <DialogActions>
                    <Button
                        onClick={handleCreateAds}
                        sx={{
                            color: appColor.white,
                            backgroundColor: appColor.green,
                            fontWeight: 'bold',
                            paddingX: 8,
                            // width: isMobile ? '100%' : 'none',
                            ":hover": {
                                backgroundColor: appColor.greenActive,
                            }
                        }
                        }>
                        <Box sx={{
                            width: 2,
                            fontWeight: 'bold'
                        }} />
                        Submit
                    </Button>
                </DialogActions>

                {/* </DialogContent> */}

            </Dialog >
        </React.Fragment >
    )
}

export default MCCreateAdsPopup
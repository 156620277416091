import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { END_POINT_SEVER } from '../../api'
import { Typography, Divider, Switch, Select, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Table } from "react-bootstrap";
import CustomNavbar from '../navbar/Navbar'
import moment from 'moment'
import LoadingPage from '../../common/loadingPage'
import { appCreditShowREAL, appCreditShowUSDT, currencyFormat } from '../../utils'

export default function UsersPage() {
    const [users, setUsers] = useState([]);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(1000);
    const [skip, setSkip] = useState(0);
    const [totalUser, setTotalUser] = useState(0);
    const [totalReal, setTotalReal] = useState(0);
    const [totalUsdt, setTotalUsdt] = useState(0);
    const [selectFiltertype, setSelectFiltertype] = useState("ONLYCREDIT");

    useEffect(() => {
        getUsers(selectFiltertype)
    }, [])

    const getUsers = async (selectFiltertype) => {
        try {
            setIsLoading(true)
            var config = {
                method: 'get',
                // url: `${END_POINT_SEVER}/api/users?limit=${limit}&skip=${skip}`,
                url: `${END_POINT_SEVER}/api/users/user-credits?${selectFiltertype == "ONLYCREDIT" ? "type=ONLYCREDIT" : ""}`,
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const users = await axios(config)

            setTotalUser(users?.data?.total)
            if (users?.data?.users?.length > 0) {
                setUsers(sortUsersByCredits(users?.data?.users))
            }
            setTotalReal(users?.data?.totalCredit?.totalReal);
            setTotalUsdt(users?.data?.totalCredit?.totalUsdt);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
    }

    const onChangeFilterType = (e) => {
        setSelectFiltertype(e.target.value)
        getUsers(e.target.value)
    }

    const changeUserMode = async (userId, userMode, index) => {
        try {
            let _users = [...users]
            _users[index]["userMode"] = userMode
            setUsers(_users)

            var config = {
                method: 'put',
                url: `${END_POINT_SEVER}/api/users/${userId}`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    userMode: userMode
                })
            };
            await axios(config)
        } catch (error) {
            console.log(error)
        }
    }

    const sortUsersByCredits = (users) => {
        return users.map(user => {
            const totalCredits = user.credits.reduce((acc, credit) => {
                if (credit.type === "REAL" || credit.type === "USDT") {
                    return acc + parseFloat(credit.credit);
                }
                return acc;
            }, 0);
            return { ...user, totalCredits };
        }).sort((a, b) => b.totalCredits - a.totalCredits);
    }

    return (
        isLoading ? <div className='color-body' style={{ width: "100%", minHeight: "100vh", justifyContent: "center", alignItems: "center", color: "#fff" }}>
            <LoadingPage />
        </div>
            :
            <div
                className="color-body"
                style={{ width: "100%", minHeight: "100vh", color: "white", padding: 10 }}
            >
                <CustomNavbar />
                <div style={{ height: 100 }} />

                {/* Add button on the right */}
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "start" }}>
                    <div style={{ height: "33%", textAlign: "left" }}>
                        <Typography variant="h5">{t("users-list")}: {totalUser} {t("Users")}</Typography>
                        <Typography variant="h6">{t("total")} {t("usdt-credit")} + {t("real-credit")}: {currencyFormat(parseFloat(totalReal) + parseFloat(totalUsdt))} USDT</Typography>
                    </div>
                    <div style={{ height: "33%", textAlign: "left" }}>
                        <Typography variant="h6">{t("total")} {t("real-credit")}: {currencyFormat(parseFloat(totalReal))} USDT</Typography>
                        <Typography variant="h6">{t("total")} {t("usdt-credit")}: {currencyFormat(parseFloat(totalUsdt))} USDT</Typography>
                    </div>
                    <div style={{ height: "33%", textAlign: "left" }}>
                        <Select
                            sx={{ width: "200px", height: 40 }}
                            value={selectFiltertype}
                            onChange={(e) => onChangeFilterType(e)}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={"ALL"}>All</MenuItem>
                            <MenuItem value={"ONLYCREDIT"}>Only have money</MenuItem>
                        </Select>
                    </div>
                </div>
                <div style={{ padding: 20 }}>
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t("name-and-lastname")}</th>
                                <th>{t("phone-number")}</th>
                                <th>{t("real-credit")}</th>
                                <th>{t("usdt-credit")}</th>
                                <th>{t("Total")}</th>
                                <th>{t("Email")}</th>
                                <th>{t("status")}</th>
                                {/* <th>{t("country")}</th> */}
                                <th>{t("vipUser")}</th>
                                <th>{t("Marketing")}</th>
                                <th>{t("time-to-create")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.map((user, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td style={{ textAlign: "left" }}>{user.fullName}</td>
                                    <td style={{ textAlign: "left" }}>{user.phone}</td>
                                    <td style={{ textAlign: "left" }}>{(user?.credits && appCreditShowREAL(user.credits) > 0) ? `${currencyFormat(appCreditShowREAL(user.credits))}` : "-"}</td>
                                    <td style={{ textAlign: "left" }}>{(user?.credits && appCreditShowUSDT(user.credits) > 0) ? `${currencyFormat(appCreditShowUSDT(user.credits))}` : "-"}</td>
                                    <td style={{ textAlign: "left" }}>{user?.credits.length > 0 ? currencyFormat(parseFloat(appCreditShowREAL(user.credits)) + parseFloat(appCreditShowUSDT(user.credits))) : "-"}</td>
                                    <td style={{ textAlign: "left" }}>{user.userName}</td>
                                    <td>{user.status}</td>
                                    {/* <td>{user.country}</td> */}
                                    <td>{user.vipUser == true ? "🟢" : "🔴"}</td>
                                    <td>
                                        <Switch
                                            onChange={() => changeUserMode(user._id, (user?.userMode == "MARKETING") ? "NORMAL" : "MARKETING", index)}
                                            color="success"
                                            checked={user?.userMode == "MARKETING"}
                                        />
                                    </td>
                                    <td>{moment(user.createdAt).format("yyyy/MM/DD HH:mm")}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </Table>
                </div>

            </div>
    )
}

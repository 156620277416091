import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import './home.css';

const Home = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <nav
          id="sidebar"
          className="col-md-3 col-lg-2 d-md-block bg-light sidebar"
        >
          <div className="position-sticky">
            <ul className="nav flex-column">
              <li className="nav-item">
                <a className="nav-link active" href={"!#"}>
                  Dashboard
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="!#">
                  Orders
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="!#">
                  User
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
          <h2>Dashboard</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="card mb-4">
                <div className="card-header">Card 1</div>
                <div className="card-body">
                  <p>This is the content of Card 1.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mb-4">
                <div className="card-header">Card 2</div>
                <div className="card-body">
                  <p>This is the content of Card 2.</p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Home;

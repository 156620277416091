import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "../src/routes";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import 'draft-js/dist/Draft.css';
// import "draft-js-inline-toolbar-plugin/lib/plugin.css";

// USE FOR NEW CHART
import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="App" style={{ fontFamily: currentLanguage == "lao" ? "Phetsarath_OT" : "Myriad_Pro" }}>
      <ApolloProvider client={client}>
        <Router>
          <AppRouter />
        </Router>
      </ApolloProvider>
      <ToastContainer />
    </div>
  );
}

export default App;

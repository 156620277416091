import { faCircleArrowRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, WEB_VERSION, appColor } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';

export default function RegisterScreen({
    fullname,
    setFullname,
    phone,
    setPhone,
    loginFail,
    username,
    setUsername,
    isValidEmail,
    showRegisterPassword,
    password,
    setPassword,
    setShowRegisterPassword,
    showConfirmPassword,
    confirmPassword,
    setConfirmPassword,
    setShowConfirmPassword,
    vipCode,
    setVipCode,
    setConfirmPolicy,
    confirmPolicy,
    requestOtp,
    isLoading,
    registerType,
    onGoogleLogin,
    onFacebookLogin,
}) {
    const routeNavigate = useNavigate();
    const { t, i18n } = useTranslation();

    const onError = () => {
        console.log('Login Failed');
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "85%",
                height: "100dvh",
                alignItems: "center",
                paddingTop: 16,
                position: "relative",
                overflow: "scroll",
                color: "#fff",
            }}
        >
            <input
                type="text"
                placeholder={t("name-and-lastname")}
                value={fullname}
                onChange={(event) => setFullname(event?.target?.value)}
                style={{
                    backgroundColor: "transparent",
                    border: "1px solid " + (loginFail == t("please-enter-the-information-completely") && !fullname ? "red" : "#fff"),
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderRadius: 0,
                    height: 40,
                    width: "100%",
                    padding: 8,
                    color: "#fff",
                }}
            />
            <div style={{ height: 20 }} />
            <input
                type="text"
                placeholder={t("phone-number")}
                value={phone}
                onChange={(event) => setPhone(event?.target?.value)}
                style={{
                    backgroundColor: "transparent",
                    border: "1px solid " + (loginFail == t("please-enter-the-information-completely") && !phone ? "red" : "#fff"),
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderRadius: 0,
                    height: 40,
                    width: "100%",
                    padding: 8,
                    color: "#fff",
                }}
            />
            <div style={{ height: 20 }} />
            <input
                type="email"
                placeholder="Email"
                value={username}
                onChange={(event) => setUsername(event?.target?.value)}
                style={{
                    backgroundColor: "transparent",
                    border: "1px solid " + ((loginFail == t("please-enter-the-information-completely") || loginFail == t("email-is-incorrect-please-try-again")) && (!username || !isValidEmail(username)) ? "red" : "#fff"),
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderRadius: 0,
                    height: 40,
                    width: "100%",
                    padding: 8,
                    color: "#fff",
                }}
            />
            <div style={{ height: 20 }} />
            <div style={{ position: 'relative', width: "100%", height: 40 }}>
                <input
                    type={showRegisterPassword ? "text" : "password"}
                    placeholder={t("password")}
                    value={password}
                    onChange={(event) => setPassword(event?.target?.value)}
                    style={{
                        backgroundColor: "transparent",
                        border: "1px solid " + (loginFail == t("please-enter-the-information-completely") && !password ? "red" : "#fff"),
                        borderTopWidth: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderRadius: 0,
                        height: 40,
                        width: "100%",
                        padding: 8,
                        color: "#fff",
                    }}
                />
                <FontAwesomeIcon
                    icon={showRegisterPassword ? faEyeSlash : faEye}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        color: "#fff"
                    }}
                    onClick={() => setShowRegisterPassword(!showRegisterPassword)}
                />
            </div>
            <div style={{ height: 20 }} />
            <div style={{ position: 'relative', width: "100%", height: 40 }}>
                <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder={t("confirm-password")}
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event?.target?.value)}
                    style={{
                        backgroundColor: "transparent",
                        border: "1px solid " + (loginFail == t("please-enter-the-information-completely") && !confirmPassword ? "red" : "#fff"),
                        borderTopWidth: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderRadius: 0,
                        height: 40,
                        width: "100%",
                        padding: 8,
                        color: "#fff",
                    }}
                />
                <FontAwesomeIcon
                    icon={showConfirmPassword ? faEyeSlash : faEye}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        color: "#fff"
                    }}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
            </div>
            {password && confirmPassword && password != confirmPassword && <div style={{ color: "red", fontSize: 12, textAlign: "start", width: "100%" }}>{t("your-verification-code-does-not-match")}</div>}
            <div style={{ height: 20 }} />
            <input
                type="text"
                placeholder={"VIP Code"}
                value={vipCode}
                onChange={(event) => setVipCode(event?.target?.value)}
                style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderRadius: 0,
                    height: 40,
                    width: "100%",
                    padding: 8,
                    color: "#fff",
                }}
            />

            <div style={{ height: 26 }} />
            <div style={{ display: "flex", textAlign: "start", width: "100%" }}>
                <input
                    type="checkbox"
                    onChange={() => setConfirmPolicy(!confirmPolicy)}
                    style={{
                        backgroundColor: "transparent",
                        border: "1px solid #fff",
                        borderRadius: 4,
                        height: 16,
                        width: 16,
                    }}
                />
                <div style={{ width: 8 }} />
                <div style={{ fontSize: 12, textAlign: "start", color: "#fff", textAlign: "start", width: "100%" }}>
                    <span>{t("confirm-that-you-are-18-years-of-age-or-older-and-accept")}</span>
                    <div style={{ color: "rgb(91,139,291)", cursor: "pointer" }} onClick={() => routeNavigate("/policy")}>
                        <u>{t("service-agreement")}</u>
                    </div>
                </div>
            </div>

            <div style={{ height: 20 }} />
            <button
                onClick={() => requestOtp()}
                disabled={!confirmPolicy}
                style={{
                    backgroundColor: !confirmPolicy ? "rgba(91,139,291, 0.2)" : appColor.yellowActive,
                    color: !confirmPolicy ? "#fff" : "#1b1b1b",
                    borderWidth: 0,
                    borderRadius: 4,
                    height: 40,
                    width: "85%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {isLoading ? <Spinner animation="border" role="status"></Spinner> : t("register")}
                &nbsp;{" "}
                {!isLoading && <FontAwesomeIcon icon={faCircleArrowRight} style={{ color: !confirmPolicy ? "rgba(255,255,255,0.8)" : "#1b1b1b" }} />}
            </button>

            <div style={{ height: 15 }} />
            {registerType != "SUCCESS" && <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center", color: "gray" }}>
                <div style={{ border: "0.5px solid gray", width: "25%" }} />
                <div style={{ width: 10 }} />
                {t("OR")} {t("register")} {t("via")}
                <div style={{ width: 10 }} />
                <div style={{ border: "0.5px solid gray", width: "25%" }} />
            </div>}
            <div style={{ height: 15 }} />
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogin
                    onSuccess={onGoogleLogin}
                    onError={onError}
                    render={(renderProps) => (
                        <button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="custom-google-button"
                        >
                            Login with Google
                        </button>
                    )}
                />
            </GoogleOAuthProvider>
            <div style={{ height: 10 }} />
            <FacebookLogin
                appId={FACEBOOK_APP_ID}
                fields="name,email,picture"
                callback={onFacebookLogin}
                cssClass="my-facebook-button"
                textButton="Login with Facebook"
            />
            <div style={{ height: 20 }} />
            <div>{WEB_VERSION}</div>
            <div style={{ width: "100%", bottom: 0, position: "relative", bottom: 0, paddingBottom: 20 }}>
                <div style={{ color: "#ffffff", textAlign: "start", width: "100%" }}>{t("contact-us")}</div>
                <div style={{ display: "flex" }}>
                    <img src="/social-media/instagram.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/whatsapp.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/twitter.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/snapchat.jpeg" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/tiktok.png" style={{ width: 22, height: 22, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/facebook.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                </div>
            </div>
        </div>
    )
}

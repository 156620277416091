import moment from "moment/moment";

export const currencyFormat = (num) => {
  return num != undefined
    ? num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    : 0;
};
export const MODAL_STYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const appProfitCalculator = (value, profitRate) => {
  if (value === 0) return 0;
  // convert "9,936.00" to float
  if (value.toString().includes(",")) value = parseFloat(value.replace(/,/g, ""));
  let totalCal = parseFloat(value) + (parseFloat(value) * parseFloat(profitRate)) / 100;
  return totalCal;
};

export const appFormatDateTime = (value) => {
  // return moment(value).format("yyyy/MM/DD HH:mm")
  return value
}

export const appCreditShowUSDT = (value) => {
  const usdtCredits = value.filter(item => item.type === "USDT" && item.credit > 0);
  if (usdtCredits.length > 0) {
    return parseFloat(usdtCredits[0].credit);
  }
  return 0;
}

export const appCreditShowREAL = (value) => {
  const realCredits = value.filter(item => item.type === "REAL" && item.credit > 0);
  if (realCredits.length > 0) {
    return parseFloat(realCredits[0].credit);
  }
  return 0;
}
import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import { appColor } from '../constants';
import { useTranslation } from 'react-i18next';
import { Avatar, Divider } from '@mui/material';
import { currencyFormat } from '../utils';
import { Close } from '@mui/icons-material';

const Fade = React.forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: appColor.bgDark,
    border: '2px solid #FFF',
    boxShadow: 24,
    borderRadius: 2,
    color: appColor.white,
    p: 4,
};

export default function NewAdminReviewSpringModal({
    isMobile,
    open,
    handleOpenAdminReviewModal,
    p2pTranDetail,
    handleConfirmApprove,
    handleConfirmCancel,
}) {


    const { t } = useTranslation();
    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleOpenAdminReviewModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}

            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                pb: 2
                            }}
                        >
                            <Typography id="spring-modal-title" variant="h6" component="h6">
                                {t("Admin Review")}
                            </Typography>
                            <Avatar
                                sx={{
                                    width: 24,
                                    height: 24,
                                    cursor: "pointer",
                                    padding: 2,
                                    backgroundColor: appColor.textGrey,
                                    "&:hover": {
                                        backgroundColor: appColor.textGrey
                                    },
                                }}
                                onClick={handleOpenAdminReviewModal}
                            >
                                <Close sx={{
                                    width: 24,
                                    height: 24,
                                    cursor: "pointer",
                                    // padding: 2,
                                    // backgroundColor: appColor.red,
                                    color: appColor.white
                                }}
                                    onClick={handleOpenAdminReviewModal}
                                />
                            </Avatar>
                        </Box>
                        <Divider />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 4,
                            p: 2,
                            backgroundColor: appColor.grayOpacity,
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1
                            }}>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("ID")}:
                                    </Typography>
                                    {p2pTranDetail?._id}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("Trade Type")}:
                                    </Typography>
                                    {p2pTranDetail?.tradeType}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("Price")}:
                                    </Typography>
                                    {currencyFormat(parseInt(p2pTranDetail?.price))} {p2pTranDetail?.fiatUnitType}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("Crypto amount")}:
                                    </Typography>
                                    {currencyFormat(parseInt(p2pTranDetail?.amount))} {p2pTranDetail?.assetType}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("Fiat amount")}:
                                    </Typography>
                                    {currencyFormat(parseInt(p2pTranDetail?.fiatAmount))} {p2pTranDetail?.fiatUnitType}
                                </Box>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1
                            }}>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("merchantConfirmType")}
                                    </Typography>
                                    {p2pTranDetail?.merchantConfirmType}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("customerConfirmType")}
                                    </Typography>
                                    {p2pTranDetail?.customerConfirmType}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("transectionStatus")}
                                    </Typography>
                                    {p2pTranDetail?.transectionStatus}
                                </Box>
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 4,
                            py: 2,
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                backgroundColor: appColor.black,
                                p: 2
                            }}>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="h6" >
                                        {t("Merchant Name")}:
                                    </Typography>
                                    {p2pTranDetail?.merchant?.merchantName}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("Active")}:
                                    </Typography>
                                    {p2pTranDetail?.merchant?.isActive == true ? "✅" : "❌"}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("Merchant Status")}:
                                    </Typography>
                                    {p2pTranDetail?.merchant?.verificationStatus == true ? "✅" : "❌"}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("owner")}:
                                    </Typography>
                                    {p2pTranDetail?.merchant?.fullName}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("phone-number")}:
                                    </Typography>
                                    {p2pTranDetail?.merchant?.phone}
                                </Box>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                                backgroundColor: appColor.black,
                                p: 2
                            }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="h6" >
                                        {t("name-and-lastname")}:
                                    </Typography>
                                    {p2pTranDetail?.customer?.fullName}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("username")}:
                                    </Typography>
                                    {p2pTranDetail?.customer?.userName}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        gap: 2
                                    }}
                                >
                                    <Typography variant="body2" >
                                        {t("phone-number")}:
                                    </Typography>
                                    {p2pTranDetail?.customer?.phone}
                                </Box>
                            </Box>

                        </Box>

                        <Box sx={{
                            backgroundColor: appColor.bgGrey,
                            px: 1,
                            py: 0.5,
                            borderRadius: 2,
                            border: `1px solid ${appColor.white}`,
                        }}>
                            <Typography color={"red"} variant="body1">{t("Note")}: {'\n'} {p2pTranDetail?.tradeType == "BUY" ? `${t("IF_YOU")} <>[${t("ADMIN_APPROVE")}]<> ${t("the credit will transfer to customer")},  ${t("IF_YOU")} <>[${t("ADMIN_CANCEL")}]<> ${t("the credit will back to merchant")}` : `${t("IF_YOU")} <>[${t("ADMIN_APPROVE")}]<> ${t("the credit will transfer to merchant")}, ${t("IF_YOU")} <>[${t("ADMIN_CANCEL")}]<> ${t("the credit will back to customer")}`}</Typography>
                        </Box>
                        <Box height={4} />
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 4,
                            justifyContent: "end",
                        }}>
                            <Button
                                size={isMobile ? 'small' : 'large'}
                                sx={{
                                    backgroundColor: appColor.bgDark,
                                    color: appColor.white,
                                    border: `0.5px solid ${appColor.bgDark}`,
                                    ":hover": {
                                        // backgroundColor: appColor.bgDark,
                                        border: `0.5px solid ${appColor.bgDark}`,
                                    }
                                }}
                                onClick={() => {
                                    handleConfirmCancel(p2pTranDetail._id)
                                }}

                            >{t("ADMIN_CANCEL")}</Button>
                            <Button
                                size={isMobile ? 'small' : 'large'}
                                sx={{
                                    backgroundColor: appColor.green,
                                    color: appColor.white,
                                    border: `0.5px solid ${appColor.green}`,
                                    ":hover": {
                                        backgroundColor: appColor.greenActive,
                                        border: `0.5px solid ${appColor.green}`,
                                    }
                                }}
                                onClick={() => {
                                    handleConfirmApprove(p2pTranDetail._id)
                                }}

                            >{t("ADMIN_APPROVE")}</Button>
                        </Box>

                    </Box>
                    {/* <ChildModalConfirm openChildModalConfirm={openChildModalConfirm} handleopenChildModalConfirm={handleopenChildModalConfirm} /> */}
                </Fade>
            </Modal>
        </div >
    );
}

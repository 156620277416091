import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MarketPrices from './crypto/MarketPrices';
import CustomNavbar from './navbar/Navbar';
import HomeOrverview from './crypto/HomeOrverview';
// import './home.css';

const Dashboard = () => {
  return (
    <div className='color-body' style={{ width: "100%" }}>
      <HomeOrverview />
      {/* <CustomNavbar />
      <div style={{ height: 80 }} />
      <MarketPrices /> */}
    </div>
  );
};

export default Dashboard;

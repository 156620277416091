import { faCircleArrowRight, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, WEB_VERSION, appColor } from '../../constants';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import forgotPassAnimationData from '../../images/forgot-password-anm.json'
import Lottie from 'react-lottie';
import { toast } from 'react-toastify';
import axios from 'axios';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import { END_POINT_SEVER } from '../../api';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function LoginScreen({
    username,
    setUsername,
    showPassword,
    password,
    setPassword,
    setShowPassword,
    onLogin,
    isLoading,
    onGoogleLogin,
    onFacebookLogin,
}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(false);
    // "request-otp", "verify-otp", "reset-password"
    const screenStates = {
        REQUEST_OTP: "request-otp",
        VERIFY_OTP: "verify-otp",
        RESET_PASSWORD: "reset-password"
    };
    const [stepForgotPassword, setStepForgotPassword] = useState(screenStates.REQUEST_OTP);

    const [emailForgot, setEmailForgot] = useState("");
    const [otpCode, setOTPCode] = useState("");
    const [verifyCode, setVerifyCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const handleChangeEmailForgot = (e) => {
        setEmailForgot(e.target.value);
    }
    const handleChangeOtpCode = (e) => {
        setOTPCode(e.target.value);
    }

    const handleChangeNewPassword = (e) => {
        setNewPassword(e.target.value);
    }
    const handleChangePassword = async () => {
        switch (stepForgotPassword) {
            case screenStates.REQUEST_OTP:
                handleRequestOTP();
                break;
            case screenStates.VERIFY_OTP:
                handleVerifyOTP();
                break;
            case screenStates.RESET_PASSWORD:
                handleResetNewPass();
                break;
            default:
            // do something
        }

    }
    const handleResetNewPass = async () => {
        setLoading(true);
        if (newPassword === "") {
            toast("Please enter your new password")
            setLoading(false);
            return false;
        }
        try {
            var config = {
                method: "post",
                url: `${END_POINT_SEVER}/api/auth/forgot-password`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    "email": emailForgot,
                    "password": newPassword,
                    "verifyCode": verifyCode
                })
            };
            const res = await axios(config);
            if (res.data.error === false) {
                handleClose()
                // console.log('forgot-password :>> ', res.data);
                // setStepForgotPassword(screenStates.VERIFY_OTP)
                toast("Reset password successfully")
                // reload
                window.location.reload();
            }
            setLoading(false);
        } catch (error) {
            toast(error?.response?.data?.message)
            setLoading(false);
            return false;
        }
    }
    const handleVerifyOTP = async () => {
        setLoading(true);
        if (otpCode === "") {
            toast("Please enter the OTP Code has been sent to your email")
            setLoading(false);
            return false;
        }
        try {
            var config = {
                method: "post",
                url: `${END_POINT_SEVER}/api/otp/verify-otp`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    "email": emailForgot,
                    "code": otpCode
                })
            };
            const res = await axios(config);
            if (res.data.error === false) {
                setStepForgotPassword(screenStates.RESET_PASSWORD)
                setVerifyCode(res.data.verifyCode)
            }
            setLoading(false);
        } catch (error) {
            toast(error?.response?.data?.message)
            setLoading(false);
            return false;
        }
    }
    const handleRequestOTP = async () => {
        setLoading(true);
        if (emailForgot === "") {
            toast("Please enter your email")
            setLoading(false);
            return false;
        }
        try {
            var config = {
                method: "post",
                url: `${END_POINT_SEVER}/api/otp/request-otp`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    "email": emailForgot
                })
            };
            const res = await axios(config);
            if (res.data.error === false) {
                // console.log('forgot-password :>> ', res.data);
                setStepForgotPassword(screenStates.VERIFY_OTP)
            }
            setLoading(false);
        } catch (error) {
            toast(error?.response?.data?.message)
            setLoading(false);
            return false;
        }
    }

    // LOTTIE
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: forgotPassAnimationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    // LOTTIE

    const { t, i18n } = useTranslation();

    const onError = () => {
        console.log('Login Failed');
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "85%",
                height: "100%",
                alignItems: "center",
                paddingTop: 16,
                position: "relative"
            }}
        >
            <input
                type="email"
                placeholder="Email"
                value={username}
                onChange={(event) => setUsername(event?.target?.value)}
                style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                    borderTopWidth: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderRadius: 0,
                    height: 40,
                    width: "100%",
                    padding: 8,
                    color: "#fff",
                }}
            />
            <div style={{ height: 20 }} />
            <div style={{ position: 'relative', width: "100%", height: 40 }}>
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder={t("password")}
                    value={password}
                    onChange={(event) => setPassword(event?.target?.value)}
                    style={{
                        backgroundColor: "transparent",
                        border: "1px solid #fff",
                        borderTopWidth: 0,
                        borderLeftWidth: 0,
                        borderRightWidth: 0,
                        borderRadius: 0,
                        height: 40,
                        width: "100%",
                        padding: 8,
                        color: "#fff",
                    }}
                />
                <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        color: "#fff"
                    }}
                    onClick={() => setShowPassword(!showPassword)}
                />
            </div>
            <div style={{ height: 20 }} />
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                width: "100%",
            }}>
                <p style={{
                    color: "#fff",
                    cursor: "pointer",
                }}
                    onClick={handleOpen}
                >forgot password</p>
            </div>
            <div style={{ height: 30 }} />
            <button
                onClick={() => onLogin()}
                style={{
                    backgroundColor: appColor.yellowActive,
                    color: "#1b1b1b",
                    borderWidth: 0,
                    borderRadius: 4,
                    height: 40,
                    width: "85%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {isLoading ? <Spinner animation="border" role="status"></Spinner> : t("login")}
                &nbsp;{" "}
                {!isLoading && <FontAwesomeIcon icon={faCircleArrowRight} style={{ color: "#1b1b1b" }}
                />}
            </button>
            <div style={{ height: 20 }} />
            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "center", color: "gray" }}>
                <div style={{ border: "0.5px solid gray", width: "25%" }} />
                <div style={{ width: 10 }} />
                {t("OR")} {t("register")} {t("via")}
                <div style={{ width: 10 }} />
                <div style={{ border: "0.5px solid gray", width: "25%" }} />
            </div>
            <div style={{ height: 20 }} />
            <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center" }}>
                <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                    <GoogleLogin
                        onSuccess={onGoogleLogin}
                        onError={onError}
                        render={(renderProps) => (
                            <button
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                                className="custom-google-button"
                            >
                                Login with Google
                            </button>
                        )}
                    />
                </GoogleOAuthProvider>
                <div style={{ height: 10 }} />
                <FacebookLogin
                    appId={FACEBOOK_APP_ID}
                    fields="name,email,picture"
                    callback={onFacebookLogin}
                    cssClass="my-facebook-button"
                    textButton="Login with Facebook"
                />
            </div>
            <div style={{ height: 20 }} />
            <div>{WEB_VERSION}</div>
            <div style={{ width: "100%", bottom: 0, position: "absolute", bottom: 0, paddingBottom: 20 }}>
                <div style={{ color: "#ffffff", textAlign: "start", width: "100%" }}>{t("contact-us")}</div>
                <div style={{ display: "flex" }}>
                    <img src="/social-media/instagram.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/whatsapp.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/twitter.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/snapchat.jpeg" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/tiktok.png" style={{ width: 22, height: 22, borderRadius: 4, cursor: "pointer" }} />
                    <div style={{ width: 6 }} />
                    <img src="/social-media/facebook.png" style={{ width: 20, height: 20, borderRadius: 4, cursor: "pointer" }} />
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                }}
            >
                <Box sx={style}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 2,
                    }}>
                        <Lottie
                            options={defaultOptions}
                            height={150}
                            width={150}
                        />
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {stepForgotPassword === screenStates.REQUEST_OTP ? "Forgot Password" : stepForgotPassword === screenStates.VERIFY_OTP ? "Verify OTP" : "Reset Password"}
                        </Typography>
                        <Typography id="modal-modal-title" variant="body2" component="h2">
                            {stepForgotPassword === screenStates.REQUEST_OTP ? "Enter your email and we'll send you a link to reset your password" : stepForgotPassword === screenStates.VERIFY_OTP ? "Enter the OTP code has been sent to your email" : "Enter your new password"}
                        </Typography>
                        {stepForgotPassword === screenStates.REQUEST_OTP ?
                            <>
                                <TextField
                                    fullWidth id="outlined-basic"
                                    variant="outlined"
                                    value={emailForgot}
                                    placeholder={"user@example.com"}
                                    onChange={handleChangeEmailForgot}
                                />
                            </> : stepForgotPassword === screenStates.VERIFY_OTP ? <>

                                <Typography id="modal-modal-title" variant="body2" component="h2">
                                    {emailForgot}
                                </Typography>
                                <TextField
                                    fullWidth id="outlined-basic"
                                    variant="outlined"
                                    value={otpCode}
                                    placeholder={'OTP Code'}
                                    onChange={handleChangeOtpCode}
                                    inputProps={{ maxLength: 6 }}

                                />
                            </> : <>
                                <TextField
                                    fullWidth id="outlined-basic"
                                    variant="outlined"
                                    value={newPassword}
                                    placeholder={'New Password'}
                                    onChange={handleChangeNewPassword}
                                />
                            </>
                        }
                        <Button
                            disabled={emailForgot === "" ? true : false}
                            fullWidth variant="contained" sx={{
                                backgroundColor: appColor.yellow,
                                color: "#1b1b1b",
                                borderWidth: 0,
                                borderRadius: 2,
                                height: 40,
                                // width: "85%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                ":hover": {
                                    backgroundColor: appColor.yellowActive,
                                }
                            }}
                            onClick={handleChangePassword}
                        >{loading ? <Spinner animation="border" role="status"></Spinner> : stepForgotPassword === screenStates.REQUEST_OTP ? "Submit" : stepForgotPassword === screenStates.VERIFY_OTP ? "Verify" : "Submit"} </Button>
                    </Box>
                </Box>
            </Modal>
        </div >
    )
}

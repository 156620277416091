import React from 'react'
import { appColor } from '../../constants'
import { useTranslation } from 'react-i18next';

export default function Marketing({ userData, vipData }) {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", paddingTop: 8 }}>
                <div style={{ height: 10 }} />
                <div style={{ width: "100%", fontWeight: "bold", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(95,182,120)", height: 30, borderRadius: 10 }}>
                    {t("Coin Options Marketing Plan")}
                </div>
                <div style={{ height: 20 }} />
                <div style={{ width: "100%", fontWeight: "bold", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ width: "37%", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(95,182,120)", height: 30, borderRadius: 10 }}>
                        {t("No.")}
                    </div>
                    <div style={{ width: "60%", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(95,182,120)", height: 30, borderRadius: 10 }}>
                        {t("Com Eearn")} %
                    </div>
                </div>
                <div style={{ height: 8 }} />
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ width: "37%", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: appColor.bgDark, height: 30, borderRadius: 10 }}>
                        A1
                    </div>
                    <div style={{ width: "60%", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: appColor.bgDark, height: 30, borderRadius: 10 }}>
                        1.5%
                    </div>
                </div>
                <div style={{ height: 8 }} />
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ width: "37%", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: appColor.bgDark, height: 30, borderRadius: 10 }}>
                        A2
                    </div>
                    <div style={{ width: "60%", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: appColor.bgDark, height: 30, borderRadius: 10 }}>
                        1.0%
                    </div>
                </div>
                <div style={{ height: 8 }} />
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ width: "37%", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: appColor.bgDark, height: 30, borderRadius: 10 }}>
                        A3
                    </div>
                    <div style={{ width: "60%", fontSize: 13, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: appColor.bgDark, height: 30, borderRadius: 10 }}>
                        0.5%
                    </div>
                </div>
            </div>
        </div>
    )
}
